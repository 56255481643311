import { default as api_45legacyeDBE7cQhM6Meta } from "/codebuild/output/src4240263937/src/horizon/pages/api-legacy.vue?macro=true";
import { default as awardsQ4pg2OlrtvMeta } from "/codebuild/output/src4240263937/src/horizon/pages/awards.vue?macro=true";
import { default as programsA81RHUhSBoMeta } from "/codebuild/output/src4240263937/src/horizon/pages/benefits/programs.vue?macro=true";
import { default as _91slug_93LgEU5fI1hWMeta } from "/codebuild/output/src4240263937/src/horizon/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93AB1Xe2LR1YMeta } from "/codebuild/output/src4240263937/src/horizon/pages/blog/category/[slug].vue?macro=true";
import { default as indexuug0YYqI5KMeta } from "/codebuild/output/src4240263937/src/horizon/pages/blog/index.vue?macro=true";
import { default as call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta } from "/codebuild/output/src4240263937/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue?macro=true";
import { default as _91slug_936P4vc4tIp8Meta } from "/codebuild/output/src4240263937/src/horizon/pages/company/[prefix]/[slug].vue?macro=true";
import { default as _91slug_932pTitmoLdQMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/[slug].vue?macro=true";
import { default as aboutgNVSPkGDNhMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/about.vue?macro=true";
import { default as indexYaiI6p7ik3Meta } from "/codebuild/output/src4240263937/src/horizon/pages/company/careers/benefits/index.vue?macro=true";
import { default as engineering_45and_45productXJi2m2StrIMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/engineering-and-product.vue?macro=true";
import { default as indexwRMAEXzImxMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/index.vue?macro=true";
import { default as saleswaUfj7feeXMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/sales.vue?macro=true";
import { default as emerging_45talent_45archivelmtO0qjBbnMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/careers/emerging-talent-archive.vue?macro=true";
import { default as indexW9Uc8tPvAOMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/careers/index.vue?macro=true";
import { default as indexLXvXjWcvJNMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/careers/locations/index.vue?macro=true";
import { default as _91slug_93yCD3lo3hcfMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/[slug].vue?macro=true";
import { default as indexNJugw7XkV4Meta } from "/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/index.vue?macro=true";
import { default as diversityS57feFpYX8Meta } from "/codebuild/output/src4240263937/src/horizon/pages/company/diversity.vue?macro=true";
import { default as indexqTblBSvqdsMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/index.vue?macro=true";
import { default as efficiencyxsqHuAFc9PMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/mission/efficiency.vue?macro=true";
import { default as safety2iLdID0KUuMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/mission/safety.vue?macro=true";
import { default as sustainabilitytWuSIKgTQYMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/mission/sustainability.vue?macro=true";
import { default as in_45the_45newsNyn4WR28mjMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/news/in-the-news.vue?macro=true";
import { default as indexc3XNqdpnICMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/news/index.vue?macro=true";
import { default as press_45releasestYx0lgAKcRMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/news/press-releases.vue?macro=true";
import { default as privacy5iuQQXlQiHMeta } from "/codebuild/output/src4240263937/src/horizon/pages/company/privacy.vue?macro=true";
import { default as cost_45assessment_45toolSKnQowJwbdMeta } from "/codebuild/output/src4240263937/src/horizon/pages/cost-assessment-tool.vue?macro=true";
import { default as _91slug_93guCnrz4M8oMeta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/[slug].vue?macro=true";
import { default as _91slug_932QgMicKsi1Meta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/category/[slug].vue?macro=true";
import { default as indexbKYCa1Bii0Meta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/index.vue?macro=true";
import { default as _91slug_93hfCkmeGpllMeta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/tips/[slug].vue?macro=true";
import { default as _91slug_93fNTpZEMZzFMeta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/tips/category/[slug].vue?macro=true";
import { default as indexywYUctMga6Meta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/tips/index.vue?macro=true";
import { default as _91_46_46_46slug_935Z9aW7OstkMeta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue?macro=true";
import { default as above_45beyondoJXS9mjjNzMeta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/above-beyond.vue?macro=true";
import { default as excellence_45performance0YJSF9ylslMeta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue?macro=true";
import { default as excellence_45servicek1bcTxeka2Meta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-service.vue?macro=true";
import { default as indexDvB05uVue6Meta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/index.vue?macro=true";
import { default as safest_45fleets9LQ91EBh6Meta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue?macro=true";
import { default as driver_45performanceW6k5WIFWkdMeta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/driver-performance.vue?macro=true";
import { default as excellence_45performancedh1bRKiPDMMeta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue?macro=true";
import { default as fleet_45innovatorSAEOW2nzCmMeta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue?macro=true";
import { default as indexmnrc2eGOsXMeta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/index.vue?macro=true";
import { default as public_45fleetDVWplY5H4CMeta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/public-fleet.vue?macro=true";
import { default as safest_45fleetotRDnRX7WJMeta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue?macro=true";
import { default as indexq099FveeH3Meta } from "/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/index.vue?macro=true";
import { default as _91slug_93stRqmNaDwXMeta } from "/codebuild/output/src4240263937/src/horizon/pages/events/[slug].vue?macro=true";
import { default as indexq6Xy1iBajXMeta } from "/codebuild/output/src4240263937/src/horizon/pages/events/index.vue?macro=true";
import { default as fleet_45appkB2ZBF7wHxMeta } from "/codebuild/output/src4240263937/src/horizon/pages/fleet-app.vue?macro=true";
import { default as _91slug_935DavVE462ZMeta } from "/codebuild/output/src4240263937/src/horizon/pages/fleet/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93LeGcgxFoC5Meta } from "/codebuild/output/src4240263937/src/horizon/pages/fleet/[slug].vue?macro=true";
import { default as _91slug_93R2P875pWH7Meta } from "/codebuild/output/src4240263937/src/horizon/pages/fleets/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93hfSCk1nCK5Meta } from "/codebuild/output/src4240263937/src/horizon/pages/fleets/[slug].vue?macro=true";
import { default as _91slug_93M7OSeyUXZ9Meta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/[slug].vue?macro=true";
import { default as attract_45retain_45talent5aStNu196jMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue?macro=true";
import { default as increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue?macro=true";
import { default as indexIuOebhAVCFMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/index.vue?macro=true";
import { default as protect_45optimize_45assetsHRIOed6MWfMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue?macro=true";
import { default as best_45dash_45camsVuY5jvj2U7Meta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue?macro=true";
import { default as indexQeV9dINNXdMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/index.vue?macro=true";
import { default as safety_45program2cQd6qupwUMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-program.vue?macro=true";
import { default as safety_45roitCuC6OS2AtMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-roi.vue?macro=true";
import { default as indexIN0MaOnMucMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/index.vue?macro=true";
import { default as build_45for_45the_45futureaTFEKZ8IShMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue?macro=true";
import { default as empower_45your_45teamsZnT5Z5cMMTMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue?macro=true";
import { default as indexb2SG6bpjLDMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/index.vue?macro=true";
import { default as take_45action_45with_45dataZ1WhrOaJ2OMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue?macro=true";
import { default as _91slug_93p4ENIHYD9eMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue?macro=true";
import { default as index2qBF2twsBYMeta } from "/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue?macro=true";
import { default as indexalh9RmALNPMeta } from "/codebuild/output/src4240263937/src/horizon/pages/index.vue?macro=true";
import { default as _91slug_933JIzcnfnngMeta } from "/codebuild/output/src4240263937/src/horizon/pages/industrial/[slug].vue?macro=true";
import { default as _91slug_93VqcQSa5o7GMeta } from "/codebuild/output/src4240263937/src/horizon/pages/industries/[slug].vue?macro=true";
import { default as indexwSK7PC3y1WMeta } from "/codebuild/output/src4240263937/src/horizon/pages/industries/index.vue?macro=true";
import { default as citiesVtVLa8AZuhMeta } from "/codebuild/output/src4240263937/src/horizon/pages/insights/cities.vue?macro=true";
import { default as index88Px7Y9byqMeta } from "/codebuild/output/src4240263937/src/horizon/pages/integrations/index.vue?macro=true";
import { default as index94ewhCimenMeta } from "/codebuild/output/src4240263937/src/horizon/pages/invite/index.vue?macro=true";
import { default as candidate_45privacy_45noticezwf9Xb6E0MMeta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/candidate-privacy-notice.vue?macro=true";
import { default as cookie_45consenttB95NwMRn1Meta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-consent.vue?macro=true";
import { default as cookie_45policyzOd5Ca0IdQMeta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-policy.vue?macro=true";
import { default as data_45protection_45addendumnKoRDpFlXTMeta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/data-protection-addendum.vue?macro=true";
import { default as express_45order_45termsnAjpxOVv1XMeta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/express-order-terms.vue?macro=true";
import { default as hardware_45warrantydWceAHhtj0Meta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/hardware-warranty.vue?macro=true";
import { default as hosted_45software_45sla3kBkz0EIiOMeta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/hosted-software-sla.vue?macro=true";
import { default as partner_45code_45of_45conductIomJhqSPSjMeta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/partner-code-of-conduct.vue?macro=true";
import { default as platform_45terms_45of_45serviceu9PVbuTb8UMeta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/platform-terms-of-service.vue?macro=true";
import { default as privacyLmAyzafMhNMeta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/privacy.vue?macro=true";
import { default as public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue?macro=true";
import { default as requestingcustomerdatawpRYJXoRQyMeta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/requestingcustomerdata.vue?macro=true";
import { default as securityjbWkAIvo8VMeta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/security.vue?macro=true";
import { default as transparencyreportRQB6WRsYLKMeta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/transparencyreport.vue?macro=true";
import { default as website_45terms_45of_45serviceGSGQlFATNpMeta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/website-terms-of-service.vue?macro=true";
import { default as your_45privacy_45choicesv1eSAuisG5Meta } from "/codebuild/output/src4240263937/src/horizon/pages/legal/your-privacy-choices.vue?macro=true";
import { default as pricingxNu8vhBLZVMeta } from "/codebuild/output/src4240263937/src/horizon/pages/page/pricing.vue?macro=true";
import { default as _91slug_93kPOj65lNMdMeta } from "/codebuild/output/src4240263937/src/horizon/pages/pages/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93bPP4cK4YNIMeta } from "/codebuild/output/src4240263937/src/horizon/pages/pages/[slug].vue?macro=true";
import { default as asset_45trackingwttNlwjsaFMeta } from "/codebuild/output/src4240263937/src/horizon/pages/pages/asset-tracking.vue?macro=true";
import { default as best_45dash_45camsSyyxuAYUxbMeta } from "/codebuild/output/src4240263937/src/horizon/pages/pages/best-dash-cams.vue?macro=true";
import { default as constructionO6w0UMWvgWMeta } from "/codebuild/output/src4240263937/src/horizon/pages/pages/construction.vue?macro=true";
import { default as eversourcehzmHxB7KNwMeta } from "/codebuild/output/src4240263937/src/horizon/pages/pages/eversource.vue?macro=true";
import { default as fleet_45managementexXMWZnxjMMeta } from "/codebuild/output/src4240263937/src/horizon/pages/pages/fleet-management.vue?macro=true";
import { default as groundworksPUF7uLjzapMeta } from "/codebuild/output/src4240263937/src/horizon/pages/pages/groundworks.vue?macro=true";
import { default as nationwideU1MAZa0367Meta } from "/codebuild/output/src4240263937/src/horizon/pages/pages/nationwide.vue?macro=true";
import { default as safety_45coachingPxTgcIxa4TMeta } from "/codebuild/output/src4240263937/src/horizon/pages/pages/safety-coaching.vue?macro=true";
import { default as unifirstnBUtofX4b4Meta } from "/codebuild/output/src4240263937/src/horizon/pages/pages/unifirst.vue?macro=true";
import { default as pricingrjN4BGUVYdMeta } from "/codebuild/output/src4240263937/src/horizon/pages/pricing.vue?macro=true";
import { default as _91slug_93IurlHNdaTSMeta } from "/codebuild/output/src4240263937/src/horizon/pages/products/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93Qa2CmMQ82PMeta } from "/codebuild/output/src4240263937/src/horizon/pages/products/[slug].vue?macro=true";
import { default as asset_45trackingv58ydfGMyCMeta } from "/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue?macro=true";
import { default as equipment_45trackinggZHjz4ncfPMeta } from "/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue?macro=true";
import { default as reefer_45monitoringOUbcnqUvh2Meta } from "/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue?macro=true";
import { default as smart_45trailers8I9mVnRjMbMeta } from "/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue?macro=true";
import { default as trailer_45trackingLYiwQKnI8MMeta } from "/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue?macro=true";
import { default as indexpTzAa6Ye6RMeta } from "/codebuild/output/src4240263937/src/horizon/pages/products/index.vue?macro=true";
import { default as _91slug_93UfhfgbbX3BMeta } from "/codebuild/output/src4240263937/src/horizon/pages/products/models/[slug].vue?macro=true";
import { default as indexypATNXHtFMMeta } from "/codebuild/output/src4240263937/src/horizon/pages/products/models/index.vue?macro=true";
import { default as gps_45fleet_45trackingYfznTfz8HuMeta } from "/codebuild/output/src4240263937/src/horizon/pages/products/telematics/gps-fleet-tracking.vue?macro=true";
import { default as indexH8esizLNEpMeta } from "/codebuild/output/src4240263937/src/horizon/pages/products/telematics/index.vue?macro=true";
import { default as racingUPB4n1LTYjMeta } from "/codebuild/output/src4240263937/src/horizon/pages/racing.vue?macro=true";
import { default as referralsJQfNiCF5xqMeta } from "/codebuild/output/src4240263937/src/horizon/pages/referrals.vue?macro=true";
import { default as _91slug_93du9jYQoDbnMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/[slug].vue?macro=true";
import { default as brand_45assetsQSUz0ePaw6Meta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/brand-assets.vue?macro=true";
import { default as _91slug_93J9I6VfWn8VMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/content/[slug].vue?macro=true";
import { default as email_45preferences_45centerXCW4gCdxDrMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/content/email-preferences-center.vue?macro=true";
import { default as one_45click_45unsubscribepJblB3IuKmMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/content/one-click-unsubscribe.vue?macro=true";
import { default as _91slug_939vdm5DEFpGMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/experts/[slug].vue?macro=true";
import { default as _91slug_93usYu4vUxnrMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/experts/category/[slug].vue?macro=true";
import { default as indexjyeGcpJN6RMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/experts/index.vue?macro=true";
import { default as indexjAdjXqAB49Meta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/index.vue?macro=true";
import { default as _91slug_93Hw4siGgSMcMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/[slug].vue?macro=true";
import { default as _91slug_93CR0VaY2hcDMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/category/[slug].vue?macro=true";
import { default as indexyE4XD7oKeIMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/index.vue?macro=true";
import { default as build_45with_45samsarakeqWR8PWvbMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/build-with-samsara.vue?macro=true";
import { default as evolve_45insurance_45programsIvzq7QQtwDMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue?macro=true";
import { default as index5uwDOC1tcBMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/index.vue?macro=true";
import { default as provide_45servicesw48hTqNvYMMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/provide-services.vue?macro=true";
import { default as sell_45samsaratbITQG5goaMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/sell-samsara.vue?macro=true";
import { default as tourwgy9iIhtLRMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/tour.vue?macro=true";
import { default as videosHfuDefsOsoMeta } from "/codebuild/output/src4240263937/src/horizon/pages/resources/videos.vue?macro=true";
import { default as indexVNwmhwCaTqMeta } from "/codebuild/output/src4240263937/src/horizon/pages/roi-calculator/index.vue?macro=true";
import { default as samsara_45venturesjTPP3tB1fRMeta } from "/codebuild/output/src4240263937/src/horizon/pages/samsara-ventures.vue?macro=true";
import { default as security_45summitxqkr9hloEPMeta } from "/codebuild/output/src4240263937/src/horizon/pages/security-summit.vue?macro=true";
import { default as _91slug_930rbDB5tL0xMeta } from "/codebuild/output/src4240263937/src/horizon/pages/solutions/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93M8Q4Fxsw19Meta } from "/codebuild/output/src4240263937/src/horizon/pages/solutions/[slug].vue?macro=true";
import { default as indexp2fIKbRpOaMeta } from "/codebuild/output/src4240263937/src/horizon/pages/solutions/index.vue?macro=true";
import { default as securityez1MqNX6Z7Meta } from "/codebuild/output/src4240263937/src/horizon/pages/solutions/security.vue?macro=true";
import { default as spaceL9T0qaJXQSMeta } from "/codebuild/output/src4240263937/src/horizon/pages/space.vue?macro=true";
import { default as sparkz7mmwCcyzmMeta } from "/codebuild/output/src4240263937/src/horizon/pages/spark.vue?macro=true";
import { default as _91slug_932vxw4jNoPuMeta } from "/codebuild/output/src4240263937/src/horizon/pages/styles/[slug].vue?macro=true";
import { default as _91slug_933uyGgWBbwTMeta } from "/codebuild/output/src4240263937/src/horizon/pages/support/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93c0jW6Jw03PMeta } from "/codebuild/output/src4240263937/src/horizon/pages/support/[slug].vue?macro=true";
import { default as apps_45privacy_45policyLiJ0KH4XD2Meta } from "/codebuild/output/src4240263937/src/horizon/pages/support/apps-privacy-policy.vue?macro=true";
import { default as customer_45referrals_45faq0vVzCYkBWgMeta } from "/codebuild/output/src4240263937/src/horizon/pages/support/customer-referrals-faq.vue?macro=true";
import { default as driver_45training_45spanish8wgdztL0mXMeta } from "/codebuild/output/src4240263937/src/horizon/pages/support/driver-training-spanish.vue?macro=true";
import { default as driver_45trainingBDpeu912pPMeta } from "/codebuild/output/src4240263937/src/horizon/pages/support/driver-training.vue?macro=true";
import { default as index2EqjIwGTq2Meta } from "/codebuild/output/src4240263937/src/horizon/pages/support/index.vue?macro=true";
import { default as industrialGsQ8zSACURMeta } from "/codebuild/output/src4240263937/src/horizon/pages/support/industrial.vue?macro=true";
import { default as modern_45slavery_45statementdtwvoCcOjiMeta } from "/codebuild/output/src4240263937/src/horizon/pages/support/modern-slavery-statement.vue?macro=true";
import { default as apps_45privacy_45policykmwMzEyw4EMeta } from "/codebuild/output/src4240263937/src/horizon/pages/support/privacy/apps-privacy-policy.vue?macro=true";
import { default as indexddoJyHvbQMMeta } from "/codebuild/output/src4240263937/src/horizon/pages/support/privacy/index.vue?macro=true";
import { default as special_45featureskcsc06N254Meta } from "/codebuild/output/src4240263937/src/horizon/pages/support/privacy/special-features.vue?macro=true";
import { default as subprocessor_45list1oz4msZxIcMeta } from "/codebuild/output/src4240263937/src/horizon/pages/support/privacy/subprocessor-list.vue?macro=true";
import { default as support_45kb_45articlesQG3sIPDT8fMeta } from "/codebuild/output/src4240263937/src/horizon/pages/support/support-kb-articles.vue?macro=true";
import { default as thank_45you_45pageSqJeeknUN0Meta } from "/codebuild/output/src4240263937/src/horizon/pages/thank-you-page.vue?macro=true";
import { default as thankyou_45recruitingkKdk6N8CbxMeta } from "/codebuild/output/src4240263937/src/horizon/pages/thankyou-recruiting.vue?macro=true";
import { default as thankyou_45referral4Hq3qvntnzMeta } from "/codebuild/output/src4240263937/src/horizon/pages/thankyou-referral.vue?macro=true";
import { default as thankyouckrkH6yzejMeta } from "/codebuild/output/src4240263937/src/horizon/pages/thankyou.vue?macro=true";
import { default as elite4l6BK3blkRMeta } from "/codebuild/output/src4240263937/src/horizon/pages/tiers/elite.vue?macro=true";
import { default as plusW55U8ELIbpMeta } from "/codebuild/output/src4240263937/src/horizon/pages/tiers/plus.vue?macro=true";
import { default as premierht5l9UdjJbMeta } from "/codebuild/output/src4240263937/src/horizon/pages/tiers/premier.vue?macro=true";
import { default as starterMqh9aDD8pMMeta } from "/codebuild/output/src4240263937/src/horizon/pages/tiers/starter.vue?macro=true";
import { default as processBU1hmWEcUAMeta } from "/codebuild/output/src4240263937/src/horizon/pages/trial/process.vue?macro=true";
import { default as supportTERmHl442vMeta } from "/codebuild/output/src4240263937/src/horizon/pages/warranty/support.vue?macro=true";
import { default as _91slug_93BDm9O1cj3KMeta } from "/codebuild/output/src4240263937/src/horizon/pages/webinars/[slug].vue?macro=true";
import { default as indexvxUlhk0d8aMeta } from "/codebuild/output/src4240263937/src/horizon/pages/webinars/index.vue?macro=true";
import { default as _91slug_93wtVQXi0bKlMeta } from "/codebuild/output/src4240263937/src/horizon/pages/webinars/thank-you/[slug].vue?macro=true";
export default [
  {
    name: api_45legacyeDBE7cQhM6Meta?.name ?? "api-legacy___en-US",
    path: api_45legacyeDBE7cQhM6Meta?.path ?? "/api-legacy",
    meta: api_45legacyeDBE7cQhM6Meta || {},
    alias: api_45legacyeDBE7cQhM6Meta?.alias || [],
    redirect: api_45legacyeDBE7cQhM6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyeDBE7cQhM6Meta?.name ?? "api-legacy___ca",
    path: api_45legacyeDBE7cQhM6Meta?.path ?? "/ca/api-legacy",
    meta: api_45legacyeDBE7cQhM6Meta || {},
    alias: api_45legacyeDBE7cQhM6Meta?.alias || [],
    redirect: api_45legacyeDBE7cQhM6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyeDBE7cQhM6Meta?.name ?? "api-legacy___nl",
    path: api_45legacyeDBE7cQhM6Meta?.path ?? "/nl/api-legacy",
    meta: api_45legacyeDBE7cQhM6Meta || {},
    alias: api_45legacyeDBE7cQhM6Meta?.alias || [],
    redirect: api_45legacyeDBE7cQhM6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyeDBE7cQhM6Meta?.name ?? "api-legacy___uk",
    path: api_45legacyeDBE7cQhM6Meta?.path ?? "/uk/api-legacy",
    meta: api_45legacyeDBE7cQhM6Meta || {},
    alias: api_45legacyeDBE7cQhM6Meta?.alias || [],
    redirect: api_45legacyeDBE7cQhM6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyeDBE7cQhM6Meta?.name ?? "api-legacy___de",
    path: api_45legacyeDBE7cQhM6Meta?.path ?? "/de/api-legacy",
    meta: api_45legacyeDBE7cQhM6Meta || {},
    alias: api_45legacyeDBE7cQhM6Meta?.alias || [],
    redirect: api_45legacyeDBE7cQhM6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyeDBE7cQhM6Meta?.name ?? "api-legacy___mx",
    path: api_45legacyeDBE7cQhM6Meta?.path ?? "/mx/api-legacy",
    meta: api_45legacyeDBE7cQhM6Meta || {},
    alias: api_45legacyeDBE7cQhM6Meta?.alias || [],
    redirect: api_45legacyeDBE7cQhM6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyeDBE7cQhM6Meta?.name ?? "api-legacy___fr",
    path: api_45legacyeDBE7cQhM6Meta?.path ?? "/fr/api-legacy",
    meta: api_45legacyeDBE7cQhM6Meta || {},
    alias: api_45legacyeDBE7cQhM6Meta?.alias || [],
    redirect: api_45legacyeDBE7cQhM6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyeDBE7cQhM6Meta?.name ?? "api-legacy___fr-ca",
    path: api_45legacyeDBE7cQhM6Meta?.path ?? "/fr-ca/api-legacy",
    meta: api_45legacyeDBE7cQhM6Meta || {},
    alias: api_45legacyeDBE7cQhM6Meta?.alias || [],
    redirect: api_45legacyeDBE7cQhM6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: awardsQ4pg2OlrtvMeta?.name ?? "awards___en-US",
    path: awardsQ4pg2OlrtvMeta?.path ?? "/awards",
    meta: awardsQ4pg2OlrtvMeta || {},
    alias: awardsQ4pg2OlrtvMeta?.alias || [],
    redirect: awardsQ4pg2OlrtvMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsQ4pg2OlrtvMeta?.name ?? "awards___ca",
    path: awardsQ4pg2OlrtvMeta?.path ?? "/ca/awards",
    meta: awardsQ4pg2OlrtvMeta || {},
    alias: awardsQ4pg2OlrtvMeta?.alias || [],
    redirect: awardsQ4pg2OlrtvMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsQ4pg2OlrtvMeta?.name ?? "awards___nl",
    path: awardsQ4pg2OlrtvMeta?.path ?? "/nl/awards",
    meta: awardsQ4pg2OlrtvMeta || {},
    alias: awardsQ4pg2OlrtvMeta?.alias || [],
    redirect: awardsQ4pg2OlrtvMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsQ4pg2OlrtvMeta?.name ?? "awards___uk",
    path: awardsQ4pg2OlrtvMeta?.path ?? "/uk/awards",
    meta: awardsQ4pg2OlrtvMeta || {},
    alias: awardsQ4pg2OlrtvMeta?.alias || [],
    redirect: awardsQ4pg2OlrtvMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsQ4pg2OlrtvMeta?.name ?? "awards___de",
    path: awardsQ4pg2OlrtvMeta?.path ?? "/de/awards",
    meta: awardsQ4pg2OlrtvMeta || {},
    alias: awardsQ4pg2OlrtvMeta?.alias || [],
    redirect: awardsQ4pg2OlrtvMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsQ4pg2OlrtvMeta?.name ?? "awards___mx",
    path: awardsQ4pg2OlrtvMeta?.path ?? "/mx/awards",
    meta: awardsQ4pg2OlrtvMeta || {},
    alias: awardsQ4pg2OlrtvMeta?.alias || [],
    redirect: awardsQ4pg2OlrtvMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsQ4pg2OlrtvMeta?.name ?? "awards___fr",
    path: awardsQ4pg2OlrtvMeta?.path ?? "/fr/awards",
    meta: awardsQ4pg2OlrtvMeta || {},
    alias: awardsQ4pg2OlrtvMeta?.alias || [],
    redirect: awardsQ4pg2OlrtvMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsQ4pg2OlrtvMeta?.name ?? "awards___fr-ca",
    path: awardsQ4pg2OlrtvMeta?.path ?? "/fr-ca/awards",
    meta: awardsQ4pg2OlrtvMeta || {},
    alias: awardsQ4pg2OlrtvMeta?.alias || [],
    redirect: awardsQ4pg2OlrtvMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: programsA81RHUhSBoMeta?.name ?? "benefits-programs___en-US",
    path: programsA81RHUhSBoMeta?.path ?? "/benefits/programs",
    meta: programsA81RHUhSBoMeta || {},
    alias: programsA81RHUhSBoMeta?.alias || [],
    redirect: programsA81RHUhSBoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsA81RHUhSBoMeta?.name ?? "benefits-programs___ca",
    path: programsA81RHUhSBoMeta?.path ?? "/ca/benefits/programs",
    meta: programsA81RHUhSBoMeta || {},
    alias: programsA81RHUhSBoMeta?.alias || [],
    redirect: programsA81RHUhSBoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsA81RHUhSBoMeta?.name ?? "benefits-programs___nl",
    path: programsA81RHUhSBoMeta?.path ?? "/nl/benefits/programs",
    meta: programsA81RHUhSBoMeta || {},
    alias: programsA81RHUhSBoMeta?.alias || [],
    redirect: programsA81RHUhSBoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsA81RHUhSBoMeta?.name ?? "benefits-programs___uk",
    path: programsA81RHUhSBoMeta?.path ?? "/uk/benefits/programs",
    meta: programsA81RHUhSBoMeta || {},
    alias: programsA81RHUhSBoMeta?.alias || [],
    redirect: programsA81RHUhSBoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsA81RHUhSBoMeta?.name ?? "benefits-programs___de",
    path: programsA81RHUhSBoMeta?.path ?? "/de/benefits/programs",
    meta: programsA81RHUhSBoMeta || {},
    alias: programsA81RHUhSBoMeta?.alias || [],
    redirect: programsA81RHUhSBoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsA81RHUhSBoMeta?.name ?? "benefits-programs___mx",
    path: programsA81RHUhSBoMeta?.path ?? "/mx/benefits/programs",
    meta: programsA81RHUhSBoMeta || {},
    alias: programsA81RHUhSBoMeta?.alias || [],
    redirect: programsA81RHUhSBoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsA81RHUhSBoMeta?.name ?? "benefits-programs___fr",
    path: programsA81RHUhSBoMeta?.path ?? "/fr/benefits/programs",
    meta: programsA81RHUhSBoMeta || {},
    alias: programsA81RHUhSBoMeta?.alias || [],
    redirect: programsA81RHUhSBoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsA81RHUhSBoMeta?.name ?? "benefits-programs___fr-ca",
    path: programsA81RHUhSBoMeta?.path ?? "/fr-ca/benefits/programs",
    meta: programsA81RHUhSBoMeta || {},
    alias: programsA81RHUhSBoMeta?.alias || [],
    redirect: programsA81RHUhSBoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LgEU5fI1hWMeta?.name ?? "blog-slug___en-US",
    path: _91slug_93LgEU5fI1hWMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93LgEU5fI1hWMeta || {},
    alias: _91slug_93LgEU5fI1hWMeta?.alias || [],
    redirect: _91slug_93LgEU5fI1hWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LgEU5fI1hWMeta?.name ?? "blog-slug___ca",
    path: _91slug_93LgEU5fI1hWMeta?.path ?? "/ca/blog/:slug()",
    meta: _91slug_93LgEU5fI1hWMeta || {},
    alias: _91slug_93LgEU5fI1hWMeta?.alias || [],
    redirect: _91slug_93LgEU5fI1hWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LgEU5fI1hWMeta?.name ?? "blog-slug___mx",
    path: _91slug_93LgEU5fI1hWMeta?.path ?? "/mx/blog/:slug()",
    meta: _91slug_93LgEU5fI1hWMeta || {},
    alias: _91slug_93LgEU5fI1hWMeta?.alias || [],
    redirect: _91slug_93LgEU5fI1hWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LgEU5fI1hWMeta?.name ?? "blog-slug___uk",
    path: _91slug_93LgEU5fI1hWMeta?.path ?? "/uk/blog/:slug()",
    meta: _91slug_93LgEU5fI1hWMeta || {},
    alias: _91slug_93LgEU5fI1hWMeta?.alias || [],
    redirect: _91slug_93LgEU5fI1hWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LgEU5fI1hWMeta?.name ?? "blog-slug___fr",
    path: _91slug_93LgEU5fI1hWMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93LgEU5fI1hWMeta || {},
    alias: _91slug_93LgEU5fI1hWMeta?.alias || [],
    redirect: _91slug_93LgEU5fI1hWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LgEU5fI1hWMeta?.name ?? "blog-slug___de",
    path: _91slug_93LgEU5fI1hWMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93LgEU5fI1hWMeta || {},
    alias: _91slug_93LgEU5fI1hWMeta?.alias || [],
    redirect: _91slug_93LgEU5fI1hWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AB1Xe2LR1YMeta?.name ?? "blog-category-slug___en-US",
    path: _91slug_93AB1Xe2LR1YMeta?.path ?? "/blog/category/:slug()",
    meta: _91slug_93AB1Xe2LR1YMeta || {},
    alias: _91slug_93AB1Xe2LR1YMeta?.alias || [],
    redirect: _91slug_93AB1Xe2LR1YMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AB1Xe2LR1YMeta?.name ?? "blog-category-slug___ca",
    path: _91slug_93AB1Xe2LR1YMeta?.path ?? "/ca/blog/category/:slug()",
    meta: _91slug_93AB1Xe2LR1YMeta || {},
    alias: _91slug_93AB1Xe2LR1YMeta?.alias || [],
    redirect: _91slug_93AB1Xe2LR1YMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AB1Xe2LR1YMeta?.name ?? "blog-category-slug___mx",
    path: _91slug_93AB1Xe2LR1YMeta?.path ?? "/mx/blog/category/:slug()",
    meta: _91slug_93AB1Xe2LR1YMeta || {},
    alias: _91slug_93AB1Xe2LR1YMeta?.alias || [],
    redirect: _91slug_93AB1Xe2LR1YMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AB1Xe2LR1YMeta?.name ?? "blog-category-slug___uk",
    path: _91slug_93AB1Xe2LR1YMeta?.path ?? "/uk/blog/category/:slug()",
    meta: _91slug_93AB1Xe2LR1YMeta || {},
    alias: _91slug_93AB1Xe2LR1YMeta?.alias || [],
    redirect: _91slug_93AB1Xe2LR1YMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AB1Xe2LR1YMeta?.name ?? "blog-category-slug___fr",
    path: _91slug_93AB1Xe2LR1YMeta?.path ?? "/fr/blog/category/:slug()",
    meta: _91slug_93AB1Xe2LR1YMeta || {},
    alias: _91slug_93AB1Xe2LR1YMeta?.alias || [],
    redirect: _91slug_93AB1Xe2LR1YMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AB1Xe2LR1YMeta?.name ?? "blog-category-slug___de",
    path: _91slug_93AB1Xe2LR1YMeta?.path ?? "/de/blog/category/:slug()",
    meta: _91slug_93AB1Xe2LR1YMeta || {},
    alias: _91slug_93AB1Xe2LR1YMeta?.alias || [],
    redirect: _91slug_93AB1Xe2LR1YMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexuug0YYqI5KMeta?.name ?? "blog___en-US",
    path: indexuug0YYqI5KMeta?.path ?? "/blog",
    meta: indexuug0YYqI5KMeta || {},
    alias: indexuug0YYqI5KMeta?.alias || [],
    redirect: indexuug0YYqI5KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexuug0YYqI5KMeta?.name ?? "blog___ca",
    path: indexuug0YYqI5KMeta?.path ?? "/ca/blog",
    meta: indexuug0YYqI5KMeta || {},
    alias: indexuug0YYqI5KMeta?.alias || [],
    redirect: indexuug0YYqI5KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexuug0YYqI5KMeta?.name ?? "blog___mx",
    path: indexuug0YYqI5KMeta?.path ?? "/mx/blog",
    meta: indexuug0YYqI5KMeta || {},
    alias: indexuug0YYqI5KMeta?.alias || [],
    redirect: indexuug0YYqI5KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexuug0YYqI5KMeta?.name ?? "blog___uk",
    path: indexuug0YYqI5KMeta?.path ?? "/uk/blog",
    meta: indexuug0YYqI5KMeta || {},
    alias: indexuug0YYqI5KMeta?.alias || [],
    redirect: indexuug0YYqI5KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexuug0YYqI5KMeta?.name ?? "blog___fr",
    path: indexuug0YYqI5KMeta?.path ?? "/fr/blog",
    meta: indexuug0YYqI5KMeta || {},
    alias: indexuug0YYqI5KMeta?.alias || [],
    redirect: indexuug0YYqI5KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexuug0YYqI5KMeta?.name ?? "blog___de",
    path: indexuug0YYqI5KMeta?.path ?? "/de/blog",
    meta: indexuug0YYqI5KMeta || {},
    alias: indexuug0YYqI5KMeta?.alias || [],
    redirect: indexuug0YYqI5KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.name ?? "call-for-speakers-at-samsara-beyond___en-US",
    path: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.path ?? "/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.name ?? "call-for-speakers-at-samsara-beyond___ca",
    path: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.path ?? "/ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.name ?? "call-for-speakers-at-samsara-beyond___nl",
    path: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.path ?? "/nl/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.name ?? "call-for-speakers-at-samsara-beyond___uk",
    path: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.path ?? "/uk/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.name ?? "call-for-speakers-at-samsara-beyond___de",
    path: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.path ?? "/de/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.name ?? "call-for-speakers-at-samsara-beyond___mx",
    path: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.path ?? "/mx/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.name ?? "call-for-speakers-at-samsara-beyond___fr",
    path: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.path ?? "/fr/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.name ?? "call-for-speakers-at-samsara-beyond___fr-ca",
    path: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.path ?? "/fr-ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondJ7SQMJHSU8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936P4vc4tIp8Meta?.name ?? "company-prefix-slug___en-US",
    path: _91slug_936P4vc4tIp8Meta?.path ?? "/company/:prefix()/:slug()",
    meta: _91slug_936P4vc4tIp8Meta || {},
    alias: _91slug_936P4vc4tIp8Meta?.alias || [],
    redirect: _91slug_936P4vc4tIp8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936P4vc4tIp8Meta?.name ?? "company-prefix-slug___ca",
    path: _91slug_936P4vc4tIp8Meta?.path ?? "/ca/company/:prefix()/:slug()",
    meta: _91slug_936P4vc4tIp8Meta || {},
    alias: _91slug_936P4vc4tIp8Meta?.alias || [],
    redirect: _91slug_936P4vc4tIp8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936P4vc4tIp8Meta?.name ?? "company-prefix-slug___nl",
    path: _91slug_936P4vc4tIp8Meta?.path ?? "/nl/company/:prefix()/:slug()",
    meta: _91slug_936P4vc4tIp8Meta || {},
    alias: _91slug_936P4vc4tIp8Meta?.alias || [],
    redirect: _91slug_936P4vc4tIp8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936P4vc4tIp8Meta?.name ?? "company-prefix-slug___uk",
    path: _91slug_936P4vc4tIp8Meta?.path ?? "/uk/company/:prefix()/:slug()",
    meta: _91slug_936P4vc4tIp8Meta || {},
    alias: _91slug_936P4vc4tIp8Meta?.alias || [],
    redirect: _91slug_936P4vc4tIp8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936P4vc4tIp8Meta?.name ?? "company-prefix-slug___de",
    path: _91slug_936P4vc4tIp8Meta?.path ?? "/de/company/:prefix()/:slug()",
    meta: _91slug_936P4vc4tIp8Meta || {},
    alias: _91slug_936P4vc4tIp8Meta?.alias || [],
    redirect: _91slug_936P4vc4tIp8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936P4vc4tIp8Meta?.name ?? "company-prefix-slug___mx",
    path: _91slug_936P4vc4tIp8Meta?.path ?? "/mx/company/:prefix()/:slug()",
    meta: _91slug_936P4vc4tIp8Meta || {},
    alias: _91slug_936P4vc4tIp8Meta?.alias || [],
    redirect: _91slug_936P4vc4tIp8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936P4vc4tIp8Meta?.name ?? "company-prefix-slug___fr",
    path: _91slug_936P4vc4tIp8Meta?.path ?? "/fr/company/:prefix()/:slug()",
    meta: _91slug_936P4vc4tIp8Meta || {},
    alias: _91slug_936P4vc4tIp8Meta?.alias || [],
    redirect: _91slug_936P4vc4tIp8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936P4vc4tIp8Meta?.name ?? "company-prefix-slug___fr-ca",
    path: _91slug_936P4vc4tIp8Meta?.path ?? "/fr-ca/company/:prefix()/:slug()",
    meta: _91slug_936P4vc4tIp8Meta || {},
    alias: _91slug_936P4vc4tIp8Meta?.alias || [],
    redirect: _91slug_936P4vc4tIp8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932pTitmoLdQMeta?.name ?? "company-slug___en-US",
    path: _91slug_932pTitmoLdQMeta?.path ?? "/company/:slug()",
    meta: _91slug_932pTitmoLdQMeta || {},
    alias: _91slug_932pTitmoLdQMeta?.alias || [],
    redirect: _91slug_932pTitmoLdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932pTitmoLdQMeta?.name ?? "company-slug___ca",
    path: _91slug_932pTitmoLdQMeta?.path ?? "/ca/company/:slug()",
    meta: _91slug_932pTitmoLdQMeta || {},
    alias: _91slug_932pTitmoLdQMeta?.alias || [],
    redirect: _91slug_932pTitmoLdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932pTitmoLdQMeta?.name ?? "company-slug___nl",
    path: _91slug_932pTitmoLdQMeta?.path ?? "/nl/company/:slug()",
    meta: _91slug_932pTitmoLdQMeta || {},
    alias: _91slug_932pTitmoLdQMeta?.alias || [],
    redirect: _91slug_932pTitmoLdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932pTitmoLdQMeta?.name ?? "company-slug___uk",
    path: _91slug_932pTitmoLdQMeta?.path ?? "/uk/company/:slug()",
    meta: _91slug_932pTitmoLdQMeta || {},
    alias: _91slug_932pTitmoLdQMeta?.alias || [],
    redirect: _91slug_932pTitmoLdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932pTitmoLdQMeta?.name ?? "company-slug___de",
    path: _91slug_932pTitmoLdQMeta?.path ?? "/de/company/:slug()",
    meta: _91slug_932pTitmoLdQMeta || {},
    alias: _91slug_932pTitmoLdQMeta?.alias || [],
    redirect: _91slug_932pTitmoLdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932pTitmoLdQMeta?.name ?? "company-slug___mx",
    path: _91slug_932pTitmoLdQMeta?.path ?? "/mx/company/:slug()",
    meta: _91slug_932pTitmoLdQMeta || {},
    alias: _91slug_932pTitmoLdQMeta?.alias || [],
    redirect: _91slug_932pTitmoLdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932pTitmoLdQMeta?.name ?? "company-slug___fr",
    path: _91slug_932pTitmoLdQMeta?.path ?? "/fr/company/:slug()",
    meta: _91slug_932pTitmoLdQMeta || {},
    alias: _91slug_932pTitmoLdQMeta?.alias || [],
    redirect: _91slug_932pTitmoLdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932pTitmoLdQMeta?.name ?? "company-slug___fr-ca",
    path: _91slug_932pTitmoLdQMeta?.path ?? "/fr-ca/company/:slug()",
    meta: _91slug_932pTitmoLdQMeta || {},
    alias: _91slug_932pTitmoLdQMeta?.alias || [],
    redirect: _91slug_932pTitmoLdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: aboutgNVSPkGDNhMeta?.name ?? "company-about___en-US",
    path: aboutgNVSPkGDNhMeta?.path ?? "/company/about",
    meta: aboutgNVSPkGDNhMeta || {},
    alias: aboutgNVSPkGDNhMeta?.alias || [],
    redirect: aboutgNVSPkGDNhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutgNVSPkGDNhMeta?.name ?? "company-about___ca",
    path: aboutgNVSPkGDNhMeta?.path ?? "/ca/company/about",
    meta: aboutgNVSPkGDNhMeta || {},
    alias: aboutgNVSPkGDNhMeta?.alias || [],
    redirect: aboutgNVSPkGDNhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutgNVSPkGDNhMeta?.name ?? "company-about___nl",
    path: aboutgNVSPkGDNhMeta?.path ?? "/nl/company/about",
    meta: aboutgNVSPkGDNhMeta || {},
    alias: aboutgNVSPkGDNhMeta?.alias || [],
    redirect: aboutgNVSPkGDNhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutgNVSPkGDNhMeta?.name ?? "company-about___uk",
    path: aboutgNVSPkGDNhMeta?.path ?? "/uk/company/about",
    meta: aboutgNVSPkGDNhMeta || {},
    alias: aboutgNVSPkGDNhMeta?.alias || [],
    redirect: aboutgNVSPkGDNhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutgNVSPkGDNhMeta?.name ?? "company-about___de",
    path: aboutgNVSPkGDNhMeta?.path ?? "/de/company/about",
    meta: aboutgNVSPkGDNhMeta || {},
    alias: aboutgNVSPkGDNhMeta?.alias || [],
    redirect: aboutgNVSPkGDNhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutgNVSPkGDNhMeta?.name ?? "company-about___mx",
    path: aboutgNVSPkGDNhMeta?.path ?? "/mx/company/about",
    meta: aboutgNVSPkGDNhMeta || {},
    alias: aboutgNVSPkGDNhMeta?.alias || [],
    redirect: aboutgNVSPkGDNhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutgNVSPkGDNhMeta?.name ?? "company-about___fr",
    path: aboutgNVSPkGDNhMeta?.path ?? "/fr/company/about",
    meta: aboutgNVSPkGDNhMeta || {},
    alias: aboutgNVSPkGDNhMeta?.alias || [],
    redirect: aboutgNVSPkGDNhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutgNVSPkGDNhMeta?.name ?? "company-about___fr-ca",
    path: aboutgNVSPkGDNhMeta?.path ?? "/fr-ca/company/about",
    meta: aboutgNVSPkGDNhMeta || {},
    alias: aboutgNVSPkGDNhMeta?.alias || [],
    redirect: aboutgNVSPkGDNhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: indexYaiI6p7ik3Meta?.name ?? "company-careers-benefits___en-US",
    path: indexYaiI6p7ik3Meta?.path ?? "/company/careers/benefits",
    meta: indexYaiI6p7ik3Meta || {},
    alias: indexYaiI6p7ik3Meta?.alias || [],
    redirect: indexYaiI6p7ik3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexYaiI6p7ik3Meta?.name ?? "company-careers-benefits___ca",
    path: indexYaiI6p7ik3Meta?.path ?? "/ca/company/careers/benefits",
    meta: indexYaiI6p7ik3Meta || {},
    alias: indexYaiI6p7ik3Meta?.alias || [],
    redirect: indexYaiI6p7ik3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexYaiI6p7ik3Meta?.name ?? "company-careers-benefits___nl",
    path: indexYaiI6p7ik3Meta?.path ?? "/nl/company/careers/benefits",
    meta: indexYaiI6p7ik3Meta || {},
    alias: indexYaiI6p7ik3Meta?.alias || [],
    redirect: indexYaiI6p7ik3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexYaiI6p7ik3Meta?.name ?? "company-careers-benefits___uk",
    path: indexYaiI6p7ik3Meta?.path ?? "/uk/company/careers/benefits",
    meta: indexYaiI6p7ik3Meta || {},
    alias: indexYaiI6p7ik3Meta?.alias || [],
    redirect: indexYaiI6p7ik3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexYaiI6p7ik3Meta?.name ?? "company-careers-benefits___de",
    path: indexYaiI6p7ik3Meta?.path ?? "/de/company/careers/benefits",
    meta: indexYaiI6p7ik3Meta || {},
    alias: indexYaiI6p7ik3Meta?.alias || [],
    redirect: indexYaiI6p7ik3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexYaiI6p7ik3Meta?.name ?? "company-careers-benefits___mx",
    path: indexYaiI6p7ik3Meta?.path ?? "/mx/company/careers/benefits",
    meta: indexYaiI6p7ik3Meta || {},
    alias: indexYaiI6p7ik3Meta?.alias || [],
    redirect: indexYaiI6p7ik3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexYaiI6p7ik3Meta?.name ?? "company-careers-benefits___fr",
    path: indexYaiI6p7ik3Meta?.path ?? "/fr/company/careers/benefits",
    meta: indexYaiI6p7ik3Meta || {},
    alias: indexYaiI6p7ik3Meta?.alias || [],
    redirect: indexYaiI6p7ik3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexYaiI6p7ik3Meta?.name ?? "company-careers-benefits___fr-ca",
    path: indexYaiI6p7ik3Meta?.path ?? "/fr-ca/company/careers/benefits",
    meta: indexYaiI6p7ik3Meta || {},
    alias: indexYaiI6p7ik3Meta?.alias || [],
    redirect: indexYaiI6p7ik3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productXJi2m2StrIMeta?.name ?? "company-careers-departments-engineering-and-product___en-US",
    path: engineering_45and_45productXJi2m2StrIMeta?.path ?? "/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productXJi2m2StrIMeta || {},
    alias: engineering_45and_45productXJi2m2StrIMeta?.alias || [],
    redirect: engineering_45and_45productXJi2m2StrIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productXJi2m2StrIMeta?.name ?? "company-careers-departments-engineering-and-product___ca",
    path: engineering_45and_45productXJi2m2StrIMeta?.path ?? "/ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productXJi2m2StrIMeta || {},
    alias: engineering_45and_45productXJi2m2StrIMeta?.alias || [],
    redirect: engineering_45and_45productXJi2m2StrIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productXJi2m2StrIMeta?.name ?? "company-careers-departments-engineering-and-product___nl",
    path: engineering_45and_45productXJi2m2StrIMeta?.path ?? "/nl/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productXJi2m2StrIMeta || {},
    alias: engineering_45and_45productXJi2m2StrIMeta?.alias || [],
    redirect: engineering_45and_45productXJi2m2StrIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productXJi2m2StrIMeta?.name ?? "company-careers-departments-engineering-and-product___uk",
    path: engineering_45and_45productXJi2m2StrIMeta?.path ?? "/uk/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productXJi2m2StrIMeta || {},
    alias: engineering_45and_45productXJi2m2StrIMeta?.alias || [],
    redirect: engineering_45and_45productXJi2m2StrIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productXJi2m2StrIMeta?.name ?? "company-careers-departments-engineering-and-product___de",
    path: engineering_45and_45productXJi2m2StrIMeta?.path ?? "/de/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productXJi2m2StrIMeta || {},
    alias: engineering_45and_45productXJi2m2StrIMeta?.alias || [],
    redirect: engineering_45and_45productXJi2m2StrIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productXJi2m2StrIMeta?.name ?? "company-careers-departments-engineering-and-product___mx",
    path: engineering_45and_45productXJi2m2StrIMeta?.path ?? "/mx/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productXJi2m2StrIMeta || {},
    alias: engineering_45and_45productXJi2m2StrIMeta?.alias || [],
    redirect: engineering_45and_45productXJi2m2StrIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productXJi2m2StrIMeta?.name ?? "company-careers-departments-engineering-and-product___fr",
    path: engineering_45and_45productXJi2m2StrIMeta?.path ?? "/fr/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productXJi2m2StrIMeta || {},
    alias: engineering_45and_45productXJi2m2StrIMeta?.alias || [],
    redirect: engineering_45and_45productXJi2m2StrIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productXJi2m2StrIMeta?.name ?? "company-careers-departments-engineering-and-product___fr-ca",
    path: engineering_45and_45productXJi2m2StrIMeta?.path ?? "/fr-ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productXJi2m2StrIMeta || {},
    alias: engineering_45and_45productXJi2m2StrIMeta?.alias || [],
    redirect: engineering_45and_45productXJi2m2StrIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: indexwRMAEXzImxMeta?.name ?? "company-careers-departments___en-US",
    path: indexwRMAEXzImxMeta?.path ?? "/company/careers/departments",
    meta: indexwRMAEXzImxMeta || {},
    alias: indexwRMAEXzImxMeta?.alias || [],
    redirect: indexwRMAEXzImxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexwRMAEXzImxMeta?.name ?? "company-careers-departments___ca",
    path: indexwRMAEXzImxMeta?.path ?? "/ca/company/careers/departments",
    meta: indexwRMAEXzImxMeta || {},
    alias: indexwRMAEXzImxMeta?.alias || [],
    redirect: indexwRMAEXzImxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexwRMAEXzImxMeta?.name ?? "company-careers-departments___nl",
    path: indexwRMAEXzImxMeta?.path ?? "/nl/company/careers/departments",
    meta: indexwRMAEXzImxMeta || {},
    alias: indexwRMAEXzImxMeta?.alias || [],
    redirect: indexwRMAEXzImxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexwRMAEXzImxMeta?.name ?? "company-careers-departments___uk",
    path: indexwRMAEXzImxMeta?.path ?? "/uk/company/careers/departments",
    meta: indexwRMAEXzImxMeta || {},
    alias: indexwRMAEXzImxMeta?.alias || [],
    redirect: indexwRMAEXzImxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexwRMAEXzImxMeta?.name ?? "company-careers-departments___de",
    path: indexwRMAEXzImxMeta?.path ?? "/de/company/careers/departments",
    meta: indexwRMAEXzImxMeta || {},
    alias: indexwRMAEXzImxMeta?.alias || [],
    redirect: indexwRMAEXzImxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexwRMAEXzImxMeta?.name ?? "company-careers-departments___mx",
    path: indexwRMAEXzImxMeta?.path ?? "/mx/company/careers/departments",
    meta: indexwRMAEXzImxMeta || {},
    alias: indexwRMAEXzImxMeta?.alias || [],
    redirect: indexwRMAEXzImxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexwRMAEXzImxMeta?.name ?? "company-careers-departments___fr",
    path: indexwRMAEXzImxMeta?.path ?? "/fr/company/careers/departments",
    meta: indexwRMAEXzImxMeta || {},
    alias: indexwRMAEXzImxMeta?.alias || [],
    redirect: indexwRMAEXzImxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexwRMAEXzImxMeta?.name ?? "company-careers-departments___fr-ca",
    path: indexwRMAEXzImxMeta?.path ?? "/fr-ca/company/careers/departments",
    meta: indexwRMAEXzImxMeta || {},
    alias: indexwRMAEXzImxMeta?.alias || [],
    redirect: indexwRMAEXzImxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: saleswaUfj7feeXMeta?.name ?? "company-careers-departments-sales___en-US",
    path: saleswaUfj7feeXMeta?.path ?? "/company/careers/departments/sales",
    meta: saleswaUfj7feeXMeta || {},
    alias: saleswaUfj7feeXMeta?.alias || [],
    redirect: saleswaUfj7feeXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: saleswaUfj7feeXMeta?.name ?? "company-careers-departments-sales___ca",
    path: saleswaUfj7feeXMeta?.path ?? "/ca/company/careers/departments/sales",
    meta: saleswaUfj7feeXMeta || {},
    alias: saleswaUfj7feeXMeta?.alias || [],
    redirect: saleswaUfj7feeXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: saleswaUfj7feeXMeta?.name ?? "company-careers-departments-sales___nl",
    path: saleswaUfj7feeXMeta?.path ?? "/nl/company/careers/departments/sales",
    meta: saleswaUfj7feeXMeta || {},
    alias: saleswaUfj7feeXMeta?.alias || [],
    redirect: saleswaUfj7feeXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: saleswaUfj7feeXMeta?.name ?? "company-careers-departments-sales___uk",
    path: saleswaUfj7feeXMeta?.path ?? "/uk/company/careers/departments/sales",
    meta: saleswaUfj7feeXMeta || {},
    alias: saleswaUfj7feeXMeta?.alias || [],
    redirect: saleswaUfj7feeXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: saleswaUfj7feeXMeta?.name ?? "company-careers-departments-sales___de",
    path: saleswaUfj7feeXMeta?.path ?? "/de/company/careers/departments/sales",
    meta: saleswaUfj7feeXMeta || {},
    alias: saleswaUfj7feeXMeta?.alias || [],
    redirect: saleswaUfj7feeXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: saleswaUfj7feeXMeta?.name ?? "company-careers-departments-sales___mx",
    path: saleswaUfj7feeXMeta?.path ?? "/mx/company/careers/departments/sales",
    meta: saleswaUfj7feeXMeta || {},
    alias: saleswaUfj7feeXMeta?.alias || [],
    redirect: saleswaUfj7feeXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: saleswaUfj7feeXMeta?.name ?? "company-careers-departments-sales___fr",
    path: saleswaUfj7feeXMeta?.path ?? "/fr/company/careers/departments/sales",
    meta: saleswaUfj7feeXMeta || {},
    alias: saleswaUfj7feeXMeta?.alias || [],
    redirect: saleswaUfj7feeXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: saleswaUfj7feeXMeta?.name ?? "company-careers-departments-sales___fr-ca",
    path: saleswaUfj7feeXMeta?.path ?? "/fr-ca/company/careers/departments/sales",
    meta: saleswaUfj7feeXMeta || {},
    alias: saleswaUfj7feeXMeta?.alias || [],
    redirect: saleswaUfj7feeXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivelmtO0qjBbnMeta?.name ?? "company-careers-emerging-talent-archive___en-US",
    path: emerging_45talent_45archivelmtO0qjBbnMeta?.path ?? "/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivelmtO0qjBbnMeta || {},
    alias: emerging_45talent_45archivelmtO0qjBbnMeta?.alias || [],
    redirect: emerging_45talent_45archivelmtO0qjBbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivelmtO0qjBbnMeta?.name ?? "company-careers-emerging-talent-archive___ca",
    path: emerging_45talent_45archivelmtO0qjBbnMeta?.path ?? "/ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivelmtO0qjBbnMeta || {},
    alias: emerging_45talent_45archivelmtO0qjBbnMeta?.alias || [],
    redirect: emerging_45talent_45archivelmtO0qjBbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivelmtO0qjBbnMeta?.name ?? "company-careers-emerging-talent-archive___nl",
    path: emerging_45talent_45archivelmtO0qjBbnMeta?.path ?? "/nl/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivelmtO0qjBbnMeta || {},
    alias: emerging_45talent_45archivelmtO0qjBbnMeta?.alias || [],
    redirect: emerging_45talent_45archivelmtO0qjBbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivelmtO0qjBbnMeta?.name ?? "company-careers-emerging-talent-archive___uk",
    path: emerging_45talent_45archivelmtO0qjBbnMeta?.path ?? "/uk/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivelmtO0qjBbnMeta || {},
    alias: emerging_45talent_45archivelmtO0qjBbnMeta?.alias || [],
    redirect: emerging_45talent_45archivelmtO0qjBbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivelmtO0qjBbnMeta?.name ?? "company-careers-emerging-talent-archive___de",
    path: emerging_45talent_45archivelmtO0qjBbnMeta?.path ?? "/de/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivelmtO0qjBbnMeta || {},
    alias: emerging_45talent_45archivelmtO0qjBbnMeta?.alias || [],
    redirect: emerging_45talent_45archivelmtO0qjBbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivelmtO0qjBbnMeta?.name ?? "company-careers-emerging-talent-archive___mx",
    path: emerging_45talent_45archivelmtO0qjBbnMeta?.path ?? "/mx/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivelmtO0qjBbnMeta || {},
    alias: emerging_45talent_45archivelmtO0qjBbnMeta?.alias || [],
    redirect: emerging_45talent_45archivelmtO0qjBbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivelmtO0qjBbnMeta?.name ?? "company-careers-emerging-talent-archive___fr",
    path: emerging_45talent_45archivelmtO0qjBbnMeta?.path ?? "/fr/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivelmtO0qjBbnMeta || {},
    alias: emerging_45talent_45archivelmtO0qjBbnMeta?.alias || [],
    redirect: emerging_45talent_45archivelmtO0qjBbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivelmtO0qjBbnMeta?.name ?? "company-careers-emerging-talent-archive___fr-ca",
    path: emerging_45talent_45archivelmtO0qjBbnMeta?.path ?? "/fr-ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivelmtO0qjBbnMeta || {},
    alias: emerging_45talent_45archivelmtO0qjBbnMeta?.alias || [],
    redirect: emerging_45talent_45archivelmtO0qjBbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: indexW9Uc8tPvAOMeta?.name ?? "company-careers___en-US",
    path: indexW9Uc8tPvAOMeta?.path ?? "/company/careers",
    meta: indexW9Uc8tPvAOMeta || {},
    alias: indexW9Uc8tPvAOMeta?.alias || [],
    redirect: indexW9Uc8tPvAOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexW9Uc8tPvAOMeta?.name ?? "company-careers___ca",
    path: indexW9Uc8tPvAOMeta?.path ?? "/ca/company/careers",
    meta: indexW9Uc8tPvAOMeta || {},
    alias: indexW9Uc8tPvAOMeta?.alias || [],
    redirect: indexW9Uc8tPvAOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexW9Uc8tPvAOMeta?.name ?? "company-careers___nl",
    path: indexW9Uc8tPvAOMeta?.path ?? "/nl/company/careers",
    meta: indexW9Uc8tPvAOMeta || {},
    alias: indexW9Uc8tPvAOMeta?.alias || [],
    redirect: indexW9Uc8tPvAOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexW9Uc8tPvAOMeta?.name ?? "company-careers___uk",
    path: indexW9Uc8tPvAOMeta?.path ?? "/uk/company/careers",
    meta: indexW9Uc8tPvAOMeta || {},
    alias: indexW9Uc8tPvAOMeta?.alias || [],
    redirect: indexW9Uc8tPvAOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexW9Uc8tPvAOMeta?.name ?? "company-careers___de",
    path: indexW9Uc8tPvAOMeta?.path ?? "/de/company/careers",
    meta: indexW9Uc8tPvAOMeta || {},
    alias: indexW9Uc8tPvAOMeta?.alias || [],
    redirect: indexW9Uc8tPvAOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexW9Uc8tPvAOMeta?.name ?? "company-careers___mx",
    path: indexW9Uc8tPvAOMeta?.path ?? "/mx/company/careers",
    meta: indexW9Uc8tPvAOMeta || {},
    alias: indexW9Uc8tPvAOMeta?.alias || [],
    redirect: indexW9Uc8tPvAOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexW9Uc8tPvAOMeta?.name ?? "company-careers___fr",
    path: indexW9Uc8tPvAOMeta?.path ?? "/fr/company/careers",
    meta: indexW9Uc8tPvAOMeta || {},
    alias: indexW9Uc8tPvAOMeta?.alias || [],
    redirect: indexW9Uc8tPvAOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexW9Uc8tPvAOMeta?.name ?? "company-careers___fr-ca",
    path: indexW9Uc8tPvAOMeta?.path ?? "/fr-ca/company/careers",
    meta: indexW9Uc8tPvAOMeta || {},
    alias: indexW9Uc8tPvAOMeta?.alias || [],
    redirect: indexW9Uc8tPvAOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexLXvXjWcvJNMeta?.name ?? "company-careers-locations___en-US",
    path: indexLXvXjWcvJNMeta?.path ?? "/company/careers/locations",
    meta: indexLXvXjWcvJNMeta || {},
    alias: indexLXvXjWcvJNMeta?.alias || [],
    redirect: indexLXvXjWcvJNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexLXvXjWcvJNMeta?.name ?? "company-careers-locations___ca",
    path: indexLXvXjWcvJNMeta?.path ?? "/ca/company/careers/locations",
    meta: indexLXvXjWcvJNMeta || {},
    alias: indexLXvXjWcvJNMeta?.alias || [],
    redirect: indexLXvXjWcvJNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexLXvXjWcvJNMeta?.name ?? "company-careers-locations___nl",
    path: indexLXvXjWcvJNMeta?.path ?? "/nl/company/careers/locations",
    meta: indexLXvXjWcvJNMeta || {},
    alias: indexLXvXjWcvJNMeta?.alias || [],
    redirect: indexLXvXjWcvJNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexLXvXjWcvJNMeta?.name ?? "company-careers-locations___uk",
    path: indexLXvXjWcvJNMeta?.path ?? "/uk/company/careers/locations",
    meta: indexLXvXjWcvJNMeta || {},
    alias: indexLXvXjWcvJNMeta?.alias || [],
    redirect: indexLXvXjWcvJNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexLXvXjWcvJNMeta?.name ?? "company-careers-locations___de",
    path: indexLXvXjWcvJNMeta?.path ?? "/de/company/careers/locations",
    meta: indexLXvXjWcvJNMeta || {},
    alias: indexLXvXjWcvJNMeta?.alias || [],
    redirect: indexLXvXjWcvJNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexLXvXjWcvJNMeta?.name ?? "company-careers-locations___mx",
    path: indexLXvXjWcvJNMeta?.path ?? "/mx/company/careers/locations",
    meta: indexLXvXjWcvJNMeta || {},
    alias: indexLXvXjWcvJNMeta?.alias || [],
    redirect: indexLXvXjWcvJNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexLXvXjWcvJNMeta?.name ?? "company-careers-locations___fr",
    path: indexLXvXjWcvJNMeta?.path ?? "/fr/company/careers/locations",
    meta: indexLXvXjWcvJNMeta || {},
    alias: indexLXvXjWcvJNMeta?.alias || [],
    redirect: indexLXvXjWcvJNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexLXvXjWcvJNMeta?.name ?? "company-careers-locations___fr-ca",
    path: indexLXvXjWcvJNMeta?.path ?? "/fr-ca/company/careers/locations",
    meta: indexLXvXjWcvJNMeta || {},
    alias: indexLXvXjWcvJNMeta?.alias || [],
    redirect: indexLXvXjWcvJNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yCD3lo3hcfMeta?.name ?? "company-careers-roles-slug___en-US",
    path: _91slug_93yCD3lo3hcfMeta?.path ?? "/company/careers/roles/:slug()",
    meta: _91slug_93yCD3lo3hcfMeta || {},
    alias: _91slug_93yCD3lo3hcfMeta?.alias || [],
    redirect: _91slug_93yCD3lo3hcfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yCD3lo3hcfMeta?.name ?? "company-careers-roles-slug___ca",
    path: _91slug_93yCD3lo3hcfMeta?.path ?? "/ca/company/careers/roles/:slug()",
    meta: _91slug_93yCD3lo3hcfMeta || {},
    alias: _91slug_93yCD3lo3hcfMeta?.alias || [],
    redirect: _91slug_93yCD3lo3hcfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yCD3lo3hcfMeta?.name ?? "company-careers-roles-slug___nl",
    path: _91slug_93yCD3lo3hcfMeta?.path ?? "/nl/company/careers/roles/:slug()",
    meta: _91slug_93yCD3lo3hcfMeta || {},
    alias: _91slug_93yCD3lo3hcfMeta?.alias || [],
    redirect: _91slug_93yCD3lo3hcfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yCD3lo3hcfMeta?.name ?? "company-careers-roles-slug___uk",
    path: _91slug_93yCD3lo3hcfMeta?.path ?? "/uk/company/careers/roles/:slug()",
    meta: _91slug_93yCD3lo3hcfMeta || {},
    alias: _91slug_93yCD3lo3hcfMeta?.alias || [],
    redirect: _91slug_93yCD3lo3hcfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yCD3lo3hcfMeta?.name ?? "company-careers-roles-slug___de",
    path: _91slug_93yCD3lo3hcfMeta?.path ?? "/de/company/careers/roles/:slug()",
    meta: _91slug_93yCD3lo3hcfMeta || {},
    alias: _91slug_93yCD3lo3hcfMeta?.alias || [],
    redirect: _91slug_93yCD3lo3hcfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yCD3lo3hcfMeta?.name ?? "company-careers-roles-slug___mx",
    path: _91slug_93yCD3lo3hcfMeta?.path ?? "/mx/company/careers/roles/:slug()",
    meta: _91slug_93yCD3lo3hcfMeta || {},
    alias: _91slug_93yCD3lo3hcfMeta?.alias || [],
    redirect: _91slug_93yCD3lo3hcfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yCD3lo3hcfMeta?.name ?? "company-careers-roles-slug___fr",
    path: _91slug_93yCD3lo3hcfMeta?.path ?? "/fr/company/careers/roles/:slug()",
    meta: _91slug_93yCD3lo3hcfMeta || {},
    alias: _91slug_93yCD3lo3hcfMeta?.alias || [],
    redirect: _91slug_93yCD3lo3hcfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yCD3lo3hcfMeta?.name ?? "company-careers-roles-slug___fr-ca",
    path: _91slug_93yCD3lo3hcfMeta?.path ?? "/fr-ca/company/careers/roles/:slug()",
    meta: _91slug_93yCD3lo3hcfMeta || {},
    alias: _91slug_93yCD3lo3hcfMeta?.alias || [],
    redirect: _91slug_93yCD3lo3hcfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexNJugw7XkV4Meta?.name ?? "company-careers-roles___en-US",
    path: indexNJugw7XkV4Meta?.path ?? "/company/careers/roles",
    meta: indexNJugw7XkV4Meta || {},
    alias: indexNJugw7XkV4Meta?.alias || [],
    redirect: indexNJugw7XkV4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexNJugw7XkV4Meta?.name ?? "company-careers-roles___ca",
    path: indexNJugw7XkV4Meta?.path ?? "/ca/company/careers/roles",
    meta: indexNJugw7XkV4Meta || {},
    alias: indexNJugw7XkV4Meta?.alias || [],
    redirect: indexNJugw7XkV4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexNJugw7XkV4Meta?.name ?? "company-careers-roles___nl",
    path: indexNJugw7XkV4Meta?.path ?? "/nl/company/careers/roles",
    meta: indexNJugw7XkV4Meta || {},
    alias: indexNJugw7XkV4Meta?.alias || [],
    redirect: indexNJugw7XkV4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexNJugw7XkV4Meta?.name ?? "company-careers-roles___uk",
    path: indexNJugw7XkV4Meta?.path ?? "/uk/company/careers/roles",
    meta: indexNJugw7XkV4Meta || {},
    alias: indexNJugw7XkV4Meta?.alias || [],
    redirect: indexNJugw7XkV4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexNJugw7XkV4Meta?.name ?? "company-careers-roles___de",
    path: indexNJugw7XkV4Meta?.path ?? "/de/company/careers/roles",
    meta: indexNJugw7XkV4Meta || {},
    alias: indexNJugw7XkV4Meta?.alias || [],
    redirect: indexNJugw7XkV4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexNJugw7XkV4Meta?.name ?? "company-careers-roles___mx",
    path: indexNJugw7XkV4Meta?.path ?? "/mx/company/careers/roles",
    meta: indexNJugw7XkV4Meta || {},
    alias: indexNJugw7XkV4Meta?.alias || [],
    redirect: indexNJugw7XkV4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexNJugw7XkV4Meta?.name ?? "company-careers-roles___fr",
    path: indexNJugw7XkV4Meta?.path ?? "/fr/company/careers/roles",
    meta: indexNJugw7XkV4Meta || {},
    alias: indexNJugw7XkV4Meta?.alias || [],
    redirect: indexNJugw7XkV4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexNJugw7XkV4Meta?.name ?? "company-careers-roles___fr-ca",
    path: indexNJugw7XkV4Meta?.path ?? "/fr-ca/company/careers/roles",
    meta: indexNJugw7XkV4Meta || {},
    alias: indexNJugw7XkV4Meta?.alias || [],
    redirect: indexNJugw7XkV4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: diversityS57feFpYX8Meta?.name ?? "company-diversity___en-US",
    path: diversityS57feFpYX8Meta?.path ?? "/company/diversity",
    meta: diversityS57feFpYX8Meta || {},
    alias: diversityS57feFpYX8Meta?.alias || [],
    redirect: diversityS57feFpYX8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityS57feFpYX8Meta?.name ?? "company-diversity___ca",
    path: diversityS57feFpYX8Meta?.path ?? "/ca/company/diversity",
    meta: diversityS57feFpYX8Meta || {},
    alias: diversityS57feFpYX8Meta?.alias || [],
    redirect: diversityS57feFpYX8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityS57feFpYX8Meta?.name ?? "company-diversity___nl",
    path: diversityS57feFpYX8Meta?.path ?? "/nl/company/diversity",
    meta: diversityS57feFpYX8Meta || {},
    alias: diversityS57feFpYX8Meta?.alias || [],
    redirect: diversityS57feFpYX8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityS57feFpYX8Meta?.name ?? "company-diversity___uk",
    path: diversityS57feFpYX8Meta?.path ?? "/uk/company/diversity",
    meta: diversityS57feFpYX8Meta || {},
    alias: diversityS57feFpYX8Meta?.alias || [],
    redirect: diversityS57feFpYX8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityS57feFpYX8Meta?.name ?? "company-diversity___de",
    path: diversityS57feFpYX8Meta?.path ?? "/de/company/diversity",
    meta: diversityS57feFpYX8Meta || {},
    alias: diversityS57feFpYX8Meta?.alias || [],
    redirect: diversityS57feFpYX8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityS57feFpYX8Meta?.name ?? "company-diversity___mx",
    path: diversityS57feFpYX8Meta?.path ?? "/mx/company/diversity",
    meta: diversityS57feFpYX8Meta || {},
    alias: diversityS57feFpYX8Meta?.alias || [],
    redirect: diversityS57feFpYX8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityS57feFpYX8Meta?.name ?? "company-diversity___fr",
    path: diversityS57feFpYX8Meta?.path ?? "/fr/company/diversity",
    meta: diversityS57feFpYX8Meta || {},
    alias: diversityS57feFpYX8Meta?.alias || [],
    redirect: diversityS57feFpYX8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityS57feFpYX8Meta?.name ?? "company-diversity___fr-ca",
    path: diversityS57feFpYX8Meta?.path ?? "/fr-ca/company/diversity",
    meta: diversityS57feFpYX8Meta || {},
    alias: diversityS57feFpYX8Meta?.alias || [],
    redirect: diversityS57feFpYX8Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: indexqTblBSvqdsMeta?.name ?? "company___en-US",
    path: indexqTblBSvqdsMeta?.path ?? "/company",
    meta: indexqTblBSvqdsMeta || {},
    alias: indexqTblBSvqdsMeta?.alias || [],
    redirect: indexqTblBSvqdsMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexqTblBSvqdsMeta?.name ?? "company___ca",
    path: indexqTblBSvqdsMeta?.path ?? "/ca/company",
    meta: indexqTblBSvqdsMeta || {},
    alias: indexqTblBSvqdsMeta?.alias || [],
    redirect: indexqTblBSvqdsMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexqTblBSvqdsMeta?.name ?? "company___nl",
    path: indexqTblBSvqdsMeta?.path ?? "/nl/company",
    meta: indexqTblBSvqdsMeta || {},
    alias: indexqTblBSvqdsMeta?.alias || [],
    redirect: indexqTblBSvqdsMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexqTblBSvqdsMeta?.name ?? "company___uk",
    path: indexqTblBSvqdsMeta?.path ?? "/uk/company",
    meta: indexqTblBSvqdsMeta || {},
    alias: indexqTblBSvqdsMeta?.alias || [],
    redirect: indexqTblBSvqdsMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexqTblBSvqdsMeta?.name ?? "company___de",
    path: indexqTblBSvqdsMeta?.path ?? "/de/company",
    meta: indexqTblBSvqdsMeta || {},
    alias: indexqTblBSvqdsMeta?.alias || [],
    redirect: indexqTblBSvqdsMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexqTblBSvqdsMeta?.name ?? "company___mx",
    path: indexqTblBSvqdsMeta?.path ?? "/mx/company",
    meta: indexqTblBSvqdsMeta || {},
    alias: indexqTblBSvqdsMeta?.alias || [],
    redirect: indexqTblBSvqdsMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexqTblBSvqdsMeta?.name ?? "company___fr",
    path: indexqTblBSvqdsMeta?.path ?? "/fr/company",
    meta: indexqTblBSvqdsMeta || {},
    alias: indexqTblBSvqdsMeta?.alias || [],
    redirect: indexqTblBSvqdsMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexqTblBSvqdsMeta?.name ?? "company___fr-ca",
    path: indexqTblBSvqdsMeta?.path ?? "/fr-ca/company",
    meta: indexqTblBSvqdsMeta || {},
    alias: indexqTblBSvqdsMeta?.alias || [],
    redirect: indexqTblBSvqdsMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: efficiencyxsqHuAFc9PMeta?.name ?? "company-mission-efficiency___en-US",
    path: efficiencyxsqHuAFc9PMeta?.path ?? "/company/mission/efficiency",
    meta: efficiencyxsqHuAFc9PMeta || {},
    alias: efficiencyxsqHuAFc9PMeta?.alias || [],
    redirect: efficiencyxsqHuAFc9PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyxsqHuAFc9PMeta?.name ?? "company-mission-efficiency___ca",
    path: efficiencyxsqHuAFc9PMeta?.path ?? "/ca/company/mission/efficiency",
    meta: efficiencyxsqHuAFc9PMeta || {},
    alias: efficiencyxsqHuAFc9PMeta?.alias || [],
    redirect: efficiencyxsqHuAFc9PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyxsqHuAFc9PMeta?.name ?? "company-mission-efficiency___nl",
    path: efficiencyxsqHuAFc9PMeta?.path ?? "/nl/company/mission/efficiency",
    meta: efficiencyxsqHuAFc9PMeta || {},
    alias: efficiencyxsqHuAFc9PMeta?.alias || [],
    redirect: efficiencyxsqHuAFc9PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyxsqHuAFc9PMeta?.name ?? "company-mission-efficiency___uk",
    path: efficiencyxsqHuAFc9PMeta?.path ?? "/uk/company/mission/efficiency",
    meta: efficiencyxsqHuAFc9PMeta || {},
    alias: efficiencyxsqHuAFc9PMeta?.alias || [],
    redirect: efficiencyxsqHuAFc9PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyxsqHuAFc9PMeta?.name ?? "company-mission-efficiency___de",
    path: efficiencyxsqHuAFc9PMeta?.path ?? "/de/company/mission/efficiency",
    meta: efficiencyxsqHuAFc9PMeta || {},
    alias: efficiencyxsqHuAFc9PMeta?.alias || [],
    redirect: efficiencyxsqHuAFc9PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyxsqHuAFc9PMeta?.name ?? "company-mission-efficiency___mx",
    path: efficiencyxsqHuAFc9PMeta?.path ?? "/mx/company/mission/efficiency",
    meta: efficiencyxsqHuAFc9PMeta || {},
    alias: efficiencyxsqHuAFc9PMeta?.alias || [],
    redirect: efficiencyxsqHuAFc9PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyxsqHuAFc9PMeta?.name ?? "company-mission-efficiency___fr",
    path: efficiencyxsqHuAFc9PMeta?.path ?? "/fr/company/mission/efficiency",
    meta: efficiencyxsqHuAFc9PMeta || {},
    alias: efficiencyxsqHuAFc9PMeta?.alias || [],
    redirect: efficiencyxsqHuAFc9PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyxsqHuAFc9PMeta?.name ?? "company-mission-efficiency___fr-ca",
    path: efficiencyxsqHuAFc9PMeta?.path ?? "/fr-ca/company/mission/efficiency",
    meta: efficiencyxsqHuAFc9PMeta || {},
    alias: efficiencyxsqHuAFc9PMeta?.alias || [],
    redirect: efficiencyxsqHuAFc9PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: safety2iLdID0KUuMeta?.name ?? "company-mission-safety___en-US",
    path: safety2iLdID0KUuMeta?.path ?? "/company/mission/safety",
    meta: safety2iLdID0KUuMeta || {},
    alias: safety2iLdID0KUuMeta?.alias || [],
    redirect: safety2iLdID0KUuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2iLdID0KUuMeta?.name ?? "company-mission-safety___ca",
    path: safety2iLdID0KUuMeta?.path ?? "/ca/company/mission/safety",
    meta: safety2iLdID0KUuMeta || {},
    alias: safety2iLdID0KUuMeta?.alias || [],
    redirect: safety2iLdID0KUuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2iLdID0KUuMeta?.name ?? "company-mission-safety___nl",
    path: safety2iLdID0KUuMeta?.path ?? "/nl/company/mission/safety",
    meta: safety2iLdID0KUuMeta || {},
    alias: safety2iLdID0KUuMeta?.alias || [],
    redirect: safety2iLdID0KUuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2iLdID0KUuMeta?.name ?? "company-mission-safety___uk",
    path: safety2iLdID0KUuMeta?.path ?? "/uk/company/mission/safety",
    meta: safety2iLdID0KUuMeta || {},
    alias: safety2iLdID0KUuMeta?.alias || [],
    redirect: safety2iLdID0KUuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2iLdID0KUuMeta?.name ?? "company-mission-safety___de",
    path: safety2iLdID0KUuMeta?.path ?? "/de/company/mission/safety",
    meta: safety2iLdID0KUuMeta || {},
    alias: safety2iLdID0KUuMeta?.alias || [],
    redirect: safety2iLdID0KUuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2iLdID0KUuMeta?.name ?? "company-mission-safety___mx",
    path: safety2iLdID0KUuMeta?.path ?? "/mx/company/mission/safety",
    meta: safety2iLdID0KUuMeta || {},
    alias: safety2iLdID0KUuMeta?.alias || [],
    redirect: safety2iLdID0KUuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2iLdID0KUuMeta?.name ?? "company-mission-safety___fr",
    path: safety2iLdID0KUuMeta?.path ?? "/fr/company/mission/safety",
    meta: safety2iLdID0KUuMeta || {},
    alias: safety2iLdID0KUuMeta?.alias || [],
    redirect: safety2iLdID0KUuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2iLdID0KUuMeta?.name ?? "company-mission-safety___fr-ca",
    path: safety2iLdID0KUuMeta?.path ?? "/fr-ca/company/mission/safety",
    meta: safety2iLdID0KUuMeta || {},
    alias: safety2iLdID0KUuMeta?.alias || [],
    redirect: safety2iLdID0KUuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytWuSIKgTQYMeta?.name ?? "company-mission-sustainability___en-US",
    path: sustainabilitytWuSIKgTQYMeta?.path ?? "/company/mission/sustainability",
    meta: sustainabilitytWuSIKgTQYMeta || {},
    alias: sustainabilitytWuSIKgTQYMeta?.alias || [],
    redirect: sustainabilitytWuSIKgTQYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytWuSIKgTQYMeta?.name ?? "company-mission-sustainability___ca",
    path: sustainabilitytWuSIKgTQYMeta?.path ?? "/ca/company/mission/sustainability",
    meta: sustainabilitytWuSIKgTQYMeta || {},
    alias: sustainabilitytWuSIKgTQYMeta?.alias || [],
    redirect: sustainabilitytWuSIKgTQYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytWuSIKgTQYMeta?.name ?? "company-mission-sustainability___nl",
    path: sustainabilitytWuSIKgTQYMeta?.path ?? "/nl/company/mission/sustainability",
    meta: sustainabilitytWuSIKgTQYMeta || {},
    alias: sustainabilitytWuSIKgTQYMeta?.alias || [],
    redirect: sustainabilitytWuSIKgTQYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytWuSIKgTQYMeta?.name ?? "company-mission-sustainability___uk",
    path: sustainabilitytWuSIKgTQYMeta?.path ?? "/uk/company/mission/sustainability",
    meta: sustainabilitytWuSIKgTQYMeta || {},
    alias: sustainabilitytWuSIKgTQYMeta?.alias || [],
    redirect: sustainabilitytWuSIKgTQYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytWuSIKgTQYMeta?.name ?? "company-mission-sustainability___de",
    path: sustainabilitytWuSIKgTQYMeta?.path ?? "/de/company/mission/sustainability",
    meta: sustainabilitytWuSIKgTQYMeta || {},
    alias: sustainabilitytWuSIKgTQYMeta?.alias || [],
    redirect: sustainabilitytWuSIKgTQYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytWuSIKgTQYMeta?.name ?? "company-mission-sustainability___mx",
    path: sustainabilitytWuSIKgTQYMeta?.path ?? "/mx/company/mission/sustainability",
    meta: sustainabilitytWuSIKgTQYMeta || {},
    alias: sustainabilitytWuSIKgTQYMeta?.alias || [],
    redirect: sustainabilitytWuSIKgTQYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytWuSIKgTQYMeta?.name ?? "company-mission-sustainability___fr",
    path: sustainabilitytWuSIKgTQYMeta?.path ?? "/fr/company/mission/sustainability",
    meta: sustainabilitytWuSIKgTQYMeta || {},
    alias: sustainabilitytWuSIKgTQYMeta?.alias || [],
    redirect: sustainabilitytWuSIKgTQYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitytWuSIKgTQYMeta?.name ?? "company-mission-sustainability___fr-ca",
    path: sustainabilitytWuSIKgTQYMeta?.path ?? "/fr-ca/company/mission/sustainability",
    meta: sustainabilitytWuSIKgTQYMeta || {},
    alias: sustainabilitytWuSIKgTQYMeta?.alias || [],
    redirect: sustainabilitytWuSIKgTQYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNyn4WR28mjMeta?.name ?? "company-news-in-the-news___en-US",
    path: in_45the_45newsNyn4WR28mjMeta?.path ?? "/company/news/in-the-news",
    meta: in_45the_45newsNyn4WR28mjMeta || {},
    alias: in_45the_45newsNyn4WR28mjMeta?.alias || [],
    redirect: in_45the_45newsNyn4WR28mjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNyn4WR28mjMeta?.name ?? "company-news-in-the-news___ca",
    path: in_45the_45newsNyn4WR28mjMeta?.path ?? "/ca/company/news/in-the-news",
    meta: in_45the_45newsNyn4WR28mjMeta || {},
    alias: in_45the_45newsNyn4WR28mjMeta?.alias || [],
    redirect: in_45the_45newsNyn4WR28mjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNyn4WR28mjMeta?.name ?? "company-news-in-the-news___nl",
    path: in_45the_45newsNyn4WR28mjMeta?.path ?? "/nl/company/news/in-the-news",
    meta: in_45the_45newsNyn4WR28mjMeta || {},
    alias: in_45the_45newsNyn4WR28mjMeta?.alias || [],
    redirect: in_45the_45newsNyn4WR28mjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNyn4WR28mjMeta?.name ?? "company-news-in-the-news___uk",
    path: in_45the_45newsNyn4WR28mjMeta?.path ?? "/uk/company/news/in-the-news",
    meta: in_45the_45newsNyn4WR28mjMeta || {},
    alias: in_45the_45newsNyn4WR28mjMeta?.alias || [],
    redirect: in_45the_45newsNyn4WR28mjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNyn4WR28mjMeta?.name ?? "company-news-in-the-news___de",
    path: in_45the_45newsNyn4WR28mjMeta?.path ?? "/de/company/news/in-the-news",
    meta: in_45the_45newsNyn4WR28mjMeta || {},
    alias: in_45the_45newsNyn4WR28mjMeta?.alias || [],
    redirect: in_45the_45newsNyn4WR28mjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNyn4WR28mjMeta?.name ?? "company-news-in-the-news___mx",
    path: in_45the_45newsNyn4WR28mjMeta?.path ?? "/mx/company/news/in-the-news",
    meta: in_45the_45newsNyn4WR28mjMeta || {},
    alias: in_45the_45newsNyn4WR28mjMeta?.alias || [],
    redirect: in_45the_45newsNyn4WR28mjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNyn4WR28mjMeta?.name ?? "company-news-in-the-news___fr",
    path: in_45the_45newsNyn4WR28mjMeta?.path ?? "/fr/company/news/in-the-news",
    meta: in_45the_45newsNyn4WR28mjMeta || {},
    alias: in_45the_45newsNyn4WR28mjMeta?.alias || [],
    redirect: in_45the_45newsNyn4WR28mjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsNyn4WR28mjMeta?.name ?? "company-news-in-the-news___fr-ca",
    path: in_45the_45newsNyn4WR28mjMeta?.path ?? "/fr-ca/company/news/in-the-news",
    meta: in_45the_45newsNyn4WR28mjMeta || {},
    alias: in_45the_45newsNyn4WR28mjMeta?.alias || [],
    redirect: in_45the_45newsNyn4WR28mjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: indexc3XNqdpnICMeta?.name ?? "company-news___en-US",
    path: indexc3XNqdpnICMeta?.path ?? "/company/news",
    meta: indexc3XNqdpnICMeta || {},
    alias: indexc3XNqdpnICMeta?.alias || [],
    redirect: indexc3XNqdpnICMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexc3XNqdpnICMeta?.name ?? "company-news___ca",
    path: indexc3XNqdpnICMeta?.path ?? "/ca/company/news",
    meta: indexc3XNqdpnICMeta || {},
    alias: indexc3XNqdpnICMeta?.alias || [],
    redirect: indexc3XNqdpnICMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexc3XNqdpnICMeta?.name ?? "company-news___mx",
    path: indexc3XNqdpnICMeta?.path ?? "/mx/company/news",
    meta: indexc3XNqdpnICMeta || {},
    alias: indexc3XNqdpnICMeta?.alias || [],
    redirect: indexc3XNqdpnICMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexc3XNqdpnICMeta?.name ?? "company-news___uk",
    path: indexc3XNqdpnICMeta?.path ?? "/uk/company/news",
    meta: indexc3XNqdpnICMeta || {},
    alias: indexc3XNqdpnICMeta?.alias || [],
    redirect: indexc3XNqdpnICMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: press_45releasestYx0lgAKcRMeta?.name ?? "company-news-press-releases___en-US",
    path: press_45releasestYx0lgAKcRMeta?.path ?? "/company/news/press-releases",
    meta: press_45releasestYx0lgAKcRMeta || {},
    alias: press_45releasestYx0lgAKcRMeta?.alias || [],
    redirect: press_45releasestYx0lgAKcRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasestYx0lgAKcRMeta?.name ?? "company-news-press-releases___ca",
    path: press_45releasestYx0lgAKcRMeta?.path ?? "/ca/company/news/press-releases",
    meta: press_45releasestYx0lgAKcRMeta || {},
    alias: press_45releasestYx0lgAKcRMeta?.alias || [],
    redirect: press_45releasestYx0lgAKcRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasestYx0lgAKcRMeta?.name ?? "company-news-press-releases___nl",
    path: press_45releasestYx0lgAKcRMeta?.path ?? "/nl/company/news/press-releases",
    meta: press_45releasestYx0lgAKcRMeta || {},
    alias: press_45releasestYx0lgAKcRMeta?.alias || [],
    redirect: press_45releasestYx0lgAKcRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasestYx0lgAKcRMeta?.name ?? "company-news-press-releases___uk",
    path: press_45releasestYx0lgAKcRMeta?.path ?? "/uk/company/news/press-releases",
    meta: press_45releasestYx0lgAKcRMeta || {},
    alias: press_45releasestYx0lgAKcRMeta?.alias || [],
    redirect: press_45releasestYx0lgAKcRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasestYx0lgAKcRMeta?.name ?? "company-news-press-releases___de",
    path: press_45releasestYx0lgAKcRMeta?.path ?? "/de/company/news/press-releases",
    meta: press_45releasestYx0lgAKcRMeta || {},
    alias: press_45releasestYx0lgAKcRMeta?.alias || [],
    redirect: press_45releasestYx0lgAKcRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasestYx0lgAKcRMeta?.name ?? "company-news-press-releases___mx",
    path: press_45releasestYx0lgAKcRMeta?.path ?? "/mx/company/news/press-releases",
    meta: press_45releasestYx0lgAKcRMeta || {},
    alias: press_45releasestYx0lgAKcRMeta?.alias || [],
    redirect: press_45releasestYx0lgAKcRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasestYx0lgAKcRMeta?.name ?? "company-news-press-releases___fr",
    path: press_45releasestYx0lgAKcRMeta?.path ?? "/fr/company/news/press-releases",
    meta: press_45releasestYx0lgAKcRMeta || {},
    alias: press_45releasestYx0lgAKcRMeta?.alias || [],
    redirect: press_45releasestYx0lgAKcRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasestYx0lgAKcRMeta?.name ?? "company-news-press-releases___fr-ca",
    path: press_45releasestYx0lgAKcRMeta?.path ?? "/fr-ca/company/news/press-releases",
    meta: press_45releasestYx0lgAKcRMeta || {},
    alias: press_45releasestYx0lgAKcRMeta?.alias || [],
    redirect: press_45releasestYx0lgAKcRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: privacy5iuQQXlQiHMeta?.name ?? "company-privacy___en-US",
    path: privacy5iuQQXlQiHMeta?.path ?? "/company/privacy",
    meta: privacy5iuQQXlQiHMeta || {},
    alias: privacy5iuQQXlQiHMeta?.alias || [],
    redirect: privacy5iuQQXlQiHMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5iuQQXlQiHMeta?.name ?? "company-privacy___ca",
    path: privacy5iuQQXlQiHMeta?.path ?? "/ca/company/privacy",
    meta: privacy5iuQQXlQiHMeta || {},
    alias: privacy5iuQQXlQiHMeta?.alias || [],
    redirect: privacy5iuQQXlQiHMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5iuQQXlQiHMeta?.name ?? "company-privacy___nl",
    path: privacy5iuQQXlQiHMeta?.path ?? "/nl/company/privacy",
    meta: privacy5iuQQXlQiHMeta || {},
    alias: privacy5iuQQXlQiHMeta?.alias || [],
    redirect: privacy5iuQQXlQiHMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5iuQQXlQiHMeta?.name ?? "company-privacy___uk",
    path: privacy5iuQQXlQiHMeta?.path ?? "/uk/company/privacy",
    meta: privacy5iuQQXlQiHMeta || {},
    alias: privacy5iuQQXlQiHMeta?.alias || [],
    redirect: privacy5iuQQXlQiHMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5iuQQXlQiHMeta?.name ?? "company-privacy___de",
    path: privacy5iuQQXlQiHMeta?.path ?? "/de/company/privacy",
    meta: privacy5iuQQXlQiHMeta || {},
    alias: privacy5iuQQXlQiHMeta?.alias || [],
    redirect: privacy5iuQQXlQiHMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5iuQQXlQiHMeta?.name ?? "company-privacy___mx",
    path: privacy5iuQQXlQiHMeta?.path ?? "/mx/company/privacy",
    meta: privacy5iuQQXlQiHMeta || {},
    alias: privacy5iuQQXlQiHMeta?.alias || [],
    redirect: privacy5iuQQXlQiHMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5iuQQXlQiHMeta?.name ?? "company-privacy___fr",
    path: privacy5iuQQXlQiHMeta?.path ?? "/fr/company/privacy",
    meta: privacy5iuQQXlQiHMeta || {},
    alias: privacy5iuQQXlQiHMeta?.alias || [],
    redirect: privacy5iuQQXlQiHMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy5iuQQXlQiHMeta?.name ?? "company-privacy___fr-ca",
    path: privacy5iuQQXlQiHMeta?.path ?? "/fr-ca/company/privacy",
    meta: privacy5iuQQXlQiHMeta || {},
    alias: privacy5iuQQXlQiHMeta?.alias || [],
    redirect: privacy5iuQQXlQiHMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolSKnQowJwbdMeta?.name ?? "cost-assessment-tool___en-US",
    path: cost_45assessment_45toolSKnQowJwbdMeta?.path ?? "/cost-assessment-tool",
    meta: cost_45assessment_45toolSKnQowJwbdMeta || {},
    alias: cost_45assessment_45toolSKnQowJwbdMeta?.alias || [],
    redirect: cost_45assessment_45toolSKnQowJwbdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolSKnQowJwbdMeta?.name ?? "cost-assessment-tool___ca",
    path: cost_45assessment_45toolSKnQowJwbdMeta?.path ?? "/ca/cost-assessment-tool",
    meta: cost_45assessment_45toolSKnQowJwbdMeta || {},
    alias: cost_45assessment_45toolSKnQowJwbdMeta?.alias || [],
    redirect: cost_45assessment_45toolSKnQowJwbdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolSKnQowJwbdMeta?.name ?? "cost-assessment-tool___nl",
    path: cost_45assessment_45toolSKnQowJwbdMeta?.path ?? "/nl/cost-assessment-tool",
    meta: cost_45assessment_45toolSKnQowJwbdMeta || {},
    alias: cost_45assessment_45toolSKnQowJwbdMeta?.alias || [],
    redirect: cost_45assessment_45toolSKnQowJwbdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolSKnQowJwbdMeta?.name ?? "cost-assessment-tool___uk",
    path: cost_45assessment_45toolSKnQowJwbdMeta?.path ?? "/uk/cost-assessment-tool",
    meta: cost_45assessment_45toolSKnQowJwbdMeta || {},
    alias: cost_45assessment_45toolSKnQowJwbdMeta?.alias || [],
    redirect: cost_45assessment_45toolSKnQowJwbdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolSKnQowJwbdMeta?.name ?? "cost-assessment-tool___de",
    path: cost_45assessment_45toolSKnQowJwbdMeta?.path ?? "/de/cost-assessment-tool",
    meta: cost_45assessment_45toolSKnQowJwbdMeta || {},
    alias: cost_45assessment_45toolSKnQowJwbdMeta?.alias || [],
    redirect: cost_45assessment_45toolSKnQowJwbdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolSKnQowJwbdMeta?.name ?? "cost-assessment-tool___mx",
    path: cost_45assessment_45toolSKnQowJwbdMeta?.path ?? "/mx/cost-assessment-tool",
    meta: cost_45assessment_45toolSKnQowJwbdMeta || {},
    alias: cost_45assessment_45toolSKnQowJwbdMeta?.alias || [],
    redirect: cost_45assessment_45toolSKnQowJwbdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolSKnQowJwbdMeta?.name ?? "cost-assessment-tool___fr",
    path: cost_45assessment_45toolSKnQowJwbdMeta?.path ?? "/fr/cost-assessment-tool",
    meta: cost_45assessment_45toolSKnQowJwbdMeta || {},
    alias: cost_45assessment_45toolSKnQowJwbdMeta?.alias || [],
    redirect: cost_45assessment_45toolSKnQowJwbdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolSKnQowJwbdMeta?.name ?? "cost-assessment-tool___fr-ca",
    path: cost_45assessment_45toolSKnQowJwbdMeta?.path ?? "/fr-ca/cost-assessment-tool",
    meta: cost_45assessment_45toolSKnQowJwbdMeta || {},
    alias: cost_45assessment_45toolSKnQowJwbdMeta?.alias || [],
    redirect: cost_45assessment_45toolSKnQowJwbdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93guCnrz4M8oMeta?.name ?? "customers-slug___en-US",
    path: _91slug_93guCnrz4M8oMeta?.path ?? "/customers/:slug()",
    meta: _91slug_93guCnrz4M8oMeta || {},
    alias: _91slug_93guCnrz4M8oMeta?.alias || [],
    redirect: _91slug_93guCnrz4M8oMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93guCnrz4M8oMeta?.name ?? "customers-slug___ca",
    path: _91slug_93guCnrz4M8oMeta?.path ?? "/ca/customers/:slug()",
    meta: _91slug_93guCnrz4M8oMeta || {},
    alias: _91slug_93guCnrz4M8oMeta?.alias || [],
    redirect: _91slug_93guCnrz4M8oMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93guCnrz4M8oMeta?.name ?? "customers-slug___nl",
    path: _91slug_93guCnrz4M8oMeta?.path ?? "/nl/customers/:slug()",
    meta: _91slug_93guCnrz4M8oMeta || {},
    alias: _91slug_93guCnrz4M8oMeta?.alias || [],
    redirect: _91slug_93guCnrz4M8oMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93guCnrz4M8oMeta?.name ?? "customers-slug___uk",
    path: _91slug_93guCnrz4M8oMeta?.path ?? "/uk/customers/:slug()",
    meta: _91slug_93guCnrz4M8oMeta || {},
    alias: _91slug_93guCnrz4M8oMeta?.alias || [],
    redirect: _91slug_93guCnrz4M8oMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93guCnrz4M8oMeta?.name ?? "customers-slug___de",
    path: _91slug_93guCnrz4M8oMeta?.path ?? "/de/customers/:slug()",
    meta: _91slug_93guCnrz4M8oMeta || {},
    alias: _91slug_93guCnrz4M8oMeta?.alias || [],
    redirect: _91slug_93guCnrz4M8oMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93guCnrz4M8oMeta?.name ?? "customers-slug___mx",
    path: _91slug_93guCnrz4M8oMeta?.path ?? "/mx/customers/:slug()",
    meta: _91slug_93guCnrz4M8oMeta || {},
    alias: _91slug_93guCnrz4M8oMeta?.alias || [],
    redirect: _91slug_93guCnrz4M8oMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93guCnrz4M8oMeta?.name ?? "customers-slug___fr",
    path: _91slug_93guCnrz4M8oMeta?.path ?? "/fr/customers/:slug()",
    meta: _91slug_93guCnrz4M8oMeta || {},
    alias: _91slug_93guCnrz4M8oMeta?.alias || [],
    redirect: _91slug_93guCnrz4M8oMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93guCnrz4M8oMeta?.name ?? "customers-slug___fr-ca",
    path: _91slug_93guCnrz4M8oMeta?.path ?? "/fr-ca/customers/:slug()",
    meta: _91slug_93guCnrz4M8oMeta || {},
    alias: _91slug_93guCnrz4M8oMeta?.alias || [],
    redirect: _91slug_93guCnrz4M8oMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932QgMicKsi1Meta?.name ?? "customers-category-slug___en-US",
    path: _91slug_932QgMicKsi1Meta?.path ?? "/customers/category/:slug()",
    meta: _91slug_932QgMicKsi1Meta || {},
    alias: _91slug_932QgMicKsi1Meta?.alias || [],
    redirect: _91slug_932QgMicKsi1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932QgMicKsi1Meta?.name ?? "customers-category-slug___ca",
    path: _91slug_932QgMicKsi1Meta?.path ?? "/ca/customers/category/:slug()",
    meta: _91slug_932QgMicKsi1Meta || {},
    alias: _91slug_932QgMicKsi1Meta?.alias || [],
    redirect: _91slug_932QgMicKsi1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932QgMicKsi1Meta?.name ?? "customers-category-slug___nl",
    path: _91slug_932QgMicKsi1Meta?.path ?? "/nl/customers/category/:slug()",
    meta: _91slug_932QgMicKsi1Meta || {},
    alias: _91slug_932QgMicKsi1Meta?.alias || [],
    redirect: _91slug_932QgMicKsi1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932QgMicKsi1Meta?.name ?? "customers-category-slug___uk",
    path: _91slug_932QgMicKsi1Meta?.path ?? "/uk/customers/category/:slug()",
    meta: _91slug_932QgMicKsi1Meta || {},
    alias: _91slug_932QgMicKsi1Meta?.alias || [],
    redirect: _91slug_932QgMicKsi1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932QgMicKsi1Meta?.name ?? "customers-category-slug___de",
    path: _91slug_932QgMicKsi1Meta?.path ?? "/de/customers/category/:slug()",
    meta: _91slug_932QgMicKsi1Meta || {},
    alias: _91slug_932QgMicKsi1Meta?.alias || [],
    redirect: _91slug_932QgMicKsi1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932QgMicKsi1Meta?.name ?? "customers-category-slug___mx",
    path: _91slug_932QgMicKsi1Meta?.path ?? "/mx/customers/category/:slug()",
    meta: _91slug_932QgMicKsi1Meta || {},
    alias: _91slug_932QgMicKsi1Meta?.alias || [],
    redirect: _91slug_932QgMicKsi1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932QgMicKsi1Meta?.name ?? "customers-category-slug___fr",
    path: _91slug_932QgMicKsi1Meta?.path ?? "/fr/customers/category/:slug()",
    meta: _91slug_932QgMicKsi1Meta || {},
    alias: _91slug_932QgMicKsi1Meta?.alias || [],
    redirect: _91slug_932QgMicKsi1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932QgMicKsi1Meta?.name ?? "customers-category-slug___fr-ca",
    path: _91slug_932QgMicKsi1Meta?.path ?? "/fr-ca/customers/category/:slug()",
    meta: _91slug_932QgMicKsi1Meta || {},
    alias: _91slug_932QgMicKsi1Meta?.alias || [],
    redirect: _91slug_932QgMicKsi1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbKYCa1Bii0Meta?.name ?? "customers___en-US",
    path: indexbKYCa1Bii0Meta?.path ?? "/customers",
    meta: indexbKYCa1Bii0Meta || {},
    alias: indexbKYCa1Bii0Meta?.alias || [],
    redirect: indexbKYCa1Bii0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbKYCa1Bii0Meta?.name ?? "customers___ca",
    path: indexbKYCa1Bii0Meta?.path ?? "/ca/customers",
    meta: indexbKYCa1Bii0Meta || {},
    alias: indexbKYCa1Bii0Meta?.alias || [],
    redirect: indexbKYCa1Bii0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbKYCa1Bii0Meta?.name ?? "customers___nl",
    path: indexbKYCa1Bii0Meta?.path ?? "/nl/customers",
    meta: indexbKYCa1Bii0Meta || {},
    alias: indexbKYCa1Bii0Meta?.alias || [],
    redirect: indexbKYCa1Bii0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbKYCa1Bii0Meta?.name ?? "customers___uk",
    path: indexbKYCa1Bii0Meta?.path ?? "/uk/customers",
    meta: indexbKYCa1Bii0Meta || {},
    alias: indexbKYCa1Bii0Meta?.alias || [],
    redirect: indexbKYCa1Bii0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbKYCa1Bii0Meta?.name ?? "customers___de",
    path: indexbKYCa1Bii0Meta?.path ?? "/de/customers",
    meta: indexbKYCa1Bii0Meta || {},
    alias: indexbKYCa1Bii0Meta?.alias || [],
    redirect: indexbKYCa1Bii0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbKYCa1Bii0Meta?.name ?? "customers___mx",
    path: indexbKYCa1Bii0Meta?.path ?? "/mx/customers",
    meta: indexbKYCa1Bii0Meta || {},
    alias: indexbKYCa1Bii0Meta?.alias || [],
    redirect: indexbKYCa1Bii0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbKYCa1Bii0Meta?.name ?? "customers___fr",
    path: indexbKYCa1Bii0Meta?.path ?? "/fr/customers",
    meta: indexbKYCa1Bii0Meta || {},
    alias: indexbKYCa1Bii0Meta?.alias || [],
    redirect: indexbKYCa1Bii0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexbKYCa1Bii0Meta?.name ?? "customers___fr-ca",
    path: indexbKYCa1Bii0Meta?.path ?? "/fr-ca/customers",
    meta: indexbKYCa1Bii0Meta || {},
    alias: indexbKYCa1Bii0Meta?.alias || [],
    redirect: indexbKYCa1Bii0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfCkmeGpllMeta?.name ?? "customers-tips-slug___en-US",
    path: _91slug_93hfCkmeGpllMeta?.path ?? "/customers/tips/:slug()",
    meta: _91slug_93hfCkmeGpllMeta || {},
    alias: _91slug_93hfCkmeGpllMeta?.alias || [],
    redirect: _91slug_93hfCkmeGpllMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfCkmeGpllMeta?.name ?? "customers-tips-slug___ca",
    path: _91slug_93hfCkmeGpllMeta?.path ?? "/ca/customers/tips/:slug()",
    meta: _91slug_93hfCkmeGpllMeta || {},
    alias: _91slug_93hfCkmeGpllMeta?.alias || [],
    redirect: _91slug_93hfCkmeGpllMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfCkmeGpllMeta?.name ?? "customers-tips-slug___nl",
    path: _91slug_93hfCkmeGpllMeta?.path ?? "/nl/customers/tips/:slug()",
    meta: _91slug_93hfCkmeGpllMeta || {},
    alias: _91slug_93hfCkmeGpllMeta?.alias || [],
    redirect: _91slug_93hfCkmeGpllMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfCkmeGpllMeta?.name ?? "customers-tips-slug___uk",
    path: _91slug_93hfCkmeGpllMeta?.path ?? "/uk/customers/tips/:slug()",
    meta: _91slug_93hfCkmeGpllMeta || {},
    alias: _91slug_93hfCkmeGpllMeta?.alias || [],
    redirect: _91slug_93hfCkmeGpllMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfCkmeGpllMeta?.name ?? "customers-tips-slug___de",
    path: _91slug_93hfCkmeGpllMeta?.path ?? "/de/customers/tips/:slug()",
    meta: _91slug_93hfCkmeGpllMeta || {},
    alias: _91slug_93hfCkmeGpllMeta?.alias || [],
    redirect: _91slug_93hfCkmeGpllMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfCkmeGpllMeta?.name ?? "customers-tips-slug___mx",
    path: _91slug_93hfCkmeGpllMeta?.path ?? "/mx/customers/tips/:slug()",
    meta: _91slug_93hfCkmeGpllMeta || {},
    alias: _91slug_93hfCkmeGpllMeta?.alias || [],
    redirect: _91slug_93hfCkmeGpllMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfCkmeGpllMeta?.name ?? "customers-tips-slug___fr",
    path: _91slug_93hfCkmeGpllMeta?.path ?? "/fr/customers/tips/:slug()",
    meta: _91slug_93hfCkmeGpllMeta || {},
    alias: _91slug_93hfCkmeGpllMeta?.alias || [],
    redirect: _91slug_93hfCkmeGpllMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfCkmeGpllMeta?.name ?? "customers-tips-slug___fr-ca",
    path: _91slug_93hfCkmeGpllMeta?.path ?? "/fr-ca/customers/tips/:slug()",
    meta: _91slug_93hfCkmeGpllMeta || {},
    alias: _91slug_93hfCkmeGpllMeta?.alias || [],
    redirect: _91slug_93hfCkmeGpllMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNTpZEMZzFMeta?.name ?? "customers-tips-category-slug___en-US",
    path: _91slug_93fNTpZEMZzFMeta?.path ?? "/customers/tips/category/:slug()",
    meta: _91slug_93fNTpZEMZzFMeta || {},
    alias: _91slug_93fNTpZEMZzFMeta?.alias || [],
    redirect: _91slug_93fNTpZEMZzFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNTpZEMZzFMeta?.name ?? "customers-tips-category-slug___ca",
    path: _91slug_93fNTpZEMZzFMeta?.path ?? "/ca/customers/tips/category/:slug()",
    meta: _91slug_93fNTpZEMZzFMeta || {},
    alias: _91slug_93fNTpZEMZzFMeta?.alias || [],
    redirect: _91slug_93fNTpZEMZzFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNTpZEMZzFMeta?.name ?? "customers-tips-category-slug___nl",
    path: _91slug_93fNTpZEMZzFMeta?.path ?? "/nl/customers/tips/category/:slug()",
    meta: _91slug_93fNTpZEMZzFMeta || {},
    alias: _91slug_93fNTpZEMZzFMeta?.alias || [],
    redirect: _91slug_93fNTpZEMZzFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNTpZEMZzFMeta?.name ?? "customers-tips-category-slug___uk",
    path: _91slug_93fNTpZEMZzFMeta?.path ?? "/uk/customers/tips/category/:slug()",
    meta: _91slug_93fNTpZEMZzFMeta || {},
    alias: _91slug_93fNTpZEMZzFMeta?.alias || [],
    redirect: _91slug_93fNTpZEMZzFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNTpZEMZzFMeta?.name ?? "customers-tips-category-slug___de",
    path: _91slug_93fNTpZEMZzFMeta?.path ?? "/de/customers/tips/category/:slug()",
    meta: _91slug_93fNTpZEMZzFMeta || {},
    alias: _91slug_93fNTpZEMZzFMeta?.alias || [],
    redirect: _91slug_93fNTpZEMZzFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNTpZEMZzFMeta?.name ?? "customers-tips-category-slug___mx",
    path: _91slug_93fNTpZEMZzFMeta?.path ?? "/mx/customers/tips/category/:slug()",
    meta: _91slug_93fNTpZEMZzFMeta || {},
    alias: _91slug_93fNTpZEMZzFMeta?.alias || [],
    redirect: _91slug_93fNTpZEMZzFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNTpZEMZzFMeta?.name ?? "customers-tips-category-slug___fr",
    path: _91slug_93fNTpZEMZzFMeta?.path ?? "/fr/customers/tips/category/:slug()",
    meta: _91slug_93fNTpZEMZzFMeta || {},
    alias: _91slug_93fNTpZEMZzFMeta?.alias || [],
    redirect: _91slug_93fNTpZEMZzFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fNTpZEMZzFMeta?.name ?? "customers-tips-category-slug___fr-ca",
    path: _91slug_93fNTpZEMZzFMeta?.path ?? "/fr-ca/customers/tips/category/:slug()",
    meta: _91slug_93fNTpZEMZzFMeta || {},
    alias: _91slug_93fNTpZEMZzFMeta?.alias || [],
    redirect: _91slug_93fNTpZEMZzFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexywYUctMga6Meta?.name ?? "customers-tips___en-US",
    path: indexywYUctMga6Meta?.path ?? "/customers/tips",
    meta: indexywYUctMga6Meta || {},
    alias: indexywYUctMga6Meta?.alias || [],
    redirect: indexywYUctMga6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexywYUctMga6Meta?.name ?? "customers-tips___ca",
    path: indexywYUctMga6Meta?.path ?? "/ca/customers/tips",
    meta: indexywYUctMga6Meta || {},
    alias: indexywYUctMga6Meta?.alias || [],
    redirect: indexywYUctMga6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexywYUctMga6Meta?.name ?? "customers-tips___nl",
    path: indexywYUctMga6Meta?.path ?? "/nl/customers/tips",
    meta: indexywYUctMga6Meta || {},
    alias: indexywYUctMga6Meta?.alias || [],
    redirect: indexywYUctMga6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexywYUctMga6Meta?.name ?? "customers-tips___uk",
    path: indexywYUctMga6Meta?.path ?? "/uk/customers/tips",
    meta: indexywYUctMga6Meta || {},
    alias: indexywYUctMga6Meta?.alias || [],
    redirect: indexywYUctMga6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexywYUctMga6Meta?.name ?? "customers-tips___de",
    path: indexywYUctMga6Meta?.path ?? "/de/customers/tips",
    meta: indexywYUctMga6Meta || {},
    alias: indexywYUctMga6Meta?.alias || [],
    redirect: indexywYUctMga6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexywYUctMga6Meta?.name ?? "customers-tips___mx",
    path: indexywYUctMga6Meta?.path ?? "/mx/customers/tips",
    meta: indexywYUctMga6Meta || {},
    alias: indexywYUctMga6Meta?.alias || [],
    redirect: indexywYUctMga6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexywYUctMga6Meta?.name ?? "customers-tips___fr",
    path: indexywYUctMga6Meta?.path ?? "/fr/customers/tips",
    meta: indexywYUctMga6Meta || {},
    alias: indexywYUctMga6Meta?.alias || [],
    redirect: indexywYUctMga6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexywYUctMga6Meta?.name ?? "customers-tips___fr-ca",
    path: indexywYUctMga6Meta?.path ?? "/fr-ca/customers/tips",
    meta: indexywYUctMga6Meta || {},
    alias: indexywYUctMga6Meta?.alias || [],
    redirect: indexywYUctMga6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_935Z9aW7OstkMeta?.name ?? "customers-topfleets-prefix-slug___en-US",
    path: _91_46_46_46slug_935Z9aW7OstkMeta?.path ?? "/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_935Z9aW7OstkMeta || {},
    alias: _91_46_46_46slug_935Z9aW7OstkMeta?.alias || [],
    redirect: _91_46_46_46slug_935Z9aW7OstkMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_935Z9aW7OstkMeta?.name ?? "customers-topfleets-prefix-slug___ca",
    path: _91_46_46_46slug_935Z9aW7OstkMeta?.path ?? "/ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_935Z9aW7OstkMeta || {},
    alias: _91_46_46_46slug_935Z9aW7OstkMeta?.alias || [],
    redirect: _91_46_46_46slug_935Z9aW7OstkMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_935Z9aW7OstkMeta?.name ?? "customers-topfleets-prefix-slug___nl",
    path: _91_46_46_46slug_935Z9aW7OstkMeta?.path ?? "/nl/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_935Z9aW7OstkMeta || {},
    alias: _91_46_46_46slug_935Z9aW7OstkMeta?.alias || [],
    redirect: _91_46_46_46slug_935Z9aW7OstkMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_935Z9aW7OstkMeta?.name ?? "customers-topfleets-prefix-slug___uk",
    path: _91_46_46_46slug_935Z9aW7OstkMeta?.path ?? "/uk/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_935Z9aW7OstkMeta || {},
    alias: _91_46_46_46slug_935Z9aW7OstkMeta?.alias || [],
    redirect: _91_46_46_46slug_935Z9aW7OstkMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_935Z9aW7OstkMeta?.name ?? "customers-topfleets-prefix-slug___de",
    path: _91_46_46_46slug_935Z9aW7OstkMeta?.path ?? "/de/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_935Z9aW7OstkMeta || {},
    alias: _91_46_46_46slug_935Z9aW7OstkMeta?.alias || [],
    redirect: _91_46_46_46slug_935Z9aW7OstkMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_935Z9aW7OstkMeta?.name ?? "customers-topfleets-prefix-slug___mx",
    path: _91_46_46_46slug_935Z9aW7OstkMeta?.path ?? "/mx/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_935Z9aW7OstkMeta || {},
    alias: _91_46_46_46slug_935Z9aW7OstkMeta?.alias || [],
    redirect: _91_46_46_46slug_935Z9aW7OstkMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_935Z9aW7OstkMeta?.name ?? "customers-topfleets-prefix-slug___fr",
    path: _91_46_46_46slug_935Z9aW7OstkMeta?.path ?? "/fr/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_935Z9aW7OstkMeta || {},
    alias: _91_46_46_46slug_935Z9aW7OstkMeta?.alias || [],
    redirect: _91_46_46_46slug_935Z9aW7OstkMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_935Z9aW7OstkMeta?.name ?? "customers-topfleets-prefix-slug___fr-ca",
    path: _91_46_46_46slug_935Z9aW7OstkMeta?.path ?? "/fr-ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_935Z9aW7OstkMeta || {},
    alias: _91_46_46_46slug_935Z9aW7OstkMeta?.alias || [],
    redirect: _91_46_46_46slug_935Z9aW7OstkMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: above_45beyondoJXS9mjjNzMeta?.name ?? "customers-topfleets-2019-above-beyond___en-US",
    path: above_45beyondoJXS9mjjNzMeta?.path ?? "/customers/topfleets/2019/above-beyond",
    meta: above_45beyondoJXS9mjjNzMeta || {},
    alias: above_45beyondoJXS9mjjNzMeta?.alias || [],
    redirect: above_45beyondoJXS9mjjNzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondoJXS9mjjNzMeta?.name ?? "customers-topfleets-2019-above-beyond___ca",
    path: above_45beyondoJXS9mjjNzMeta?.path ?? "/ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondoJXS9mjjNzMeta || {},
    alias: above_45beyondoJXS9mjjNzMeta?.alias || [],
    redirect: above_45beyondoJXS9mjjNzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondoJXS9mjjNzMeta?.name ?? "customers-topfleets-2019-above-beyond___nl",
    path: above_45beyondoJXS9mjjNzMeta?.path ?? "/nl/customers/topfleets/2019/above-beyond",
    meta: above_45beyondoJXS9mjjNzMeta || {},
    alias: above_45beyondoJXS9mjjNzMeta?.alias || [],
    redirect: above_45beyondoJXS9mjjNzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondoJXS9mjjNzMeta?.name ?? "customers-topfleets-2019-above-beyond___uk",
    path: above_45beyondoJXS9mjjNzMeta?.path ?? "/uk/customers/topfleets/2019/above-beyond",
    meta: above_45beyondoJXS9mjjNzMeta || {},
    alias: above_45beyondoJXS9mjjNzMeta?.alias || [],
    redirect: above_45beyondoJXS9mjjNzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondoJXS9mjjNzMeta?.name ?? "customers-topfleets-2019-above-beyond___de",
    path: above_45beyondoJXS9mjjNzMeta?.path ?? "/de/customers/topfleets/2019/above-beyond",
    meta: above_45beyondoJXS9mjjNzMeta || {},
    alias: above_45beyondoJXS9mjjNzMeta?.alias || [],
    redirect: above_45beyondoJXS9mjjNzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondoJXS9mjjNzMeta?.name ?? "customers-topfleets-2019-above-beyond___mx",
    path: above_45beyondoJXS9mjjNzMeta?.path ?? "/mx/customers/topfleets/2019/above-beyond",
    meta: above_45beyondoJXS9mjjNzMeta || {},
    alias: above_45beyondoJXS9mjjNzMeta?.alias || [],
    redirect: above_45beyondoJXS9mjjNzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondoJXS9mjjNzMeta?.name ?? "customers-topfleets-2019-above-beyond___fr",
    path: above_45beyondoJXS9mjjNzMeta?.path ?? "/fr/customers/topfleets/2019/above-beyond",
    meta: above_45beyondoJXS9mjjNzMeta || {},
    alias: above_45beyondoJXS9mjjNzMeta?.alias || [],
    redirect: above_45beyondoJXS9mjjNzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondoJXS9mjjNzMeta?.name ?? "customers-topfleets-2019-above-beyond___fr-ca",
    path: above_45beyondoJXS9mjjNzMeta?.path ?? "/fr-ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondoJXS9mjjNzMeta || {},
    alias: above_45beyondoJXS9mjjNzMeta?.alias || [],
    redirect: above_45beyondoJXS9mjjNzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0YJSF9ylslMeta?.name ?? "customers-topfleets-2019-excellence-performance___en-US",
    path: excellence_45performance0YJSF9ylslMeta?.path ?? "/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0YJSF9ylslMeta || {},
    alias: excellence_45performance0YJSF9ylslMeta?.alias || [],
    redirect: excellence_45performance0YJSF9ylslMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0YJSF9ylslMeta?.name ?? "customers-topfleets-2019-excellence-performance___ca",
    path: excellence_45performance0YJSF9ylslMeta?.path ?? "/ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0YJSF9ylslMeta || {},
    alias: excellence_45performance0YJSF9ylslMeta?.alias || [],
    redirect: excellence_45performance0YJSF9ylslMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0YJSF9ylslMeta?.name ?? "customers-topfleets-2019-excellence-performance___nl",
    path: excellence_45performance0YJSF9ylslMeta?.path ?? "/nl/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0YJSF9ylslMeta || {},
    alias: excellence_45performance0YJSF9ylslMeta?.alias || [],
    redirect: excellence_45performance0YJSF9ylslMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0YJSF9ylslMeta?.name ?? "customers-topfleets-2019-excellence-performance___uk",
    path: excellence_45performance0YJSF9ylslMeta?.path ?? "/uk/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0YJSF9ylslMeta || {},
    alias: excellence_45performance0YJSF9ylslMeta?.alias || [],
    redirect: excellence_45performance0YJSF9ylslMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0YJSF9ylslMeta?.name ?? "customers-topfleets-2019-excellence-performance___de",
    path: excellence_45performance0YJSF9ylslMeta?.path ?? "/de/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0YJSF9ylslMeta || {},
    alias: excellence_45performance0YJSF9ylslMeta?.alias || [],
    redirect: excellence_45performance0YJSF9ylslMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0YJSF9ylslMeta?.name ?? "customers-topfleets-2019-excellence-performance___mx",
    path: excellence_45performance0YJSF9ylslMeta?.path ?? "/mx/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0YJSF9ylslMeta || {},
    alias: excellence_45performance0YJSF9ylslMeta?.alias || [],
    redirect: excellence_45performance0YJSF9ylslMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0YJSF9ylslMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr",
    path: excellence_45performance0YJSF9ylslMeta?.path ?? "/fr/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0YJSF9ylslMeta || {},
    alias: excellence_45performance0YJSF9ylslMeta?.alias || [],
    redirect: excellence_45performance0YJSF9ylslMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance0YJSF9ylslMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr-ca",
    path: excellence_45performance0YJSF9ylslMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance0YJSF9ylslMeta || {},
    alias: excellence_45performance0YJSF9ylslMeta?.alias || [],
    redirect: excellence_45performance0YJSF9ylslMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45servicek1bcTxeka2Meta?.name ?? "customers-topfleets-2019-excellence-service___en-US",
    path: excellence_45servicek1bcTxeka2Meta?.path ?? "/customers/topfleets/2019/excellence-service",
    meta: excellence_45servicek1bcTxeka2Meta || {},
    alias: excellence_45servicek1bcTxeka2Meta?.alias || [],
    redirect: excellence_45servicek1bcTxeka2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45servicek1bcTxeka2Meta?.name ?? "customers-topfleets-2019-excellence-service___ca",
    path: excellence_45servicek1bcTxeka2Meta?.path ?? "/ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45servicek1bcTxeka2Meta || {},
    alias: excellence_45servicek1bcTxeka2Meta?.alias || [],
    redirect: excellence_45servicek1bcTxeka2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45servicek1bcTxeka2Meta?.name ?? "customers-topfleets-2019-excellence-service___nl",
    path: excellence_45servicek1bcTxeka2Meta?.path ?? "/nl/customers/topfleets/2019/excellence-service",
    meta: excellence_45servicek1bcTxeka2Meta || {},
    alias: excellence_45servicek1bcTxeka2Meta?.alias || [],
    redirect: excellence_45servicek1bcTxeka2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45servicek1bcTxeka2Meta?.name ?? "customers-topfleets-2019-excellence-service___uk",
    path: excellence_45servicek1bcTxeka2Meta?.path ?? "/uk/customers/topfleets/2019/excellence-service",
    meta: excellence_45servicek1bcTxeka2Meta || {},
    alias: excellence_45servicek1bcTxeka2Meta?.alias || [],
    redirect: excellence_45servicek1bcTxeka2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45servicek1bcTxeka2Meta?.name ?? "customers-topfleets-2019-excellence-service___de",
    path: excellence_45servicek1bcTxeka2Meta?.path ?? "/de/customers/topfleets/2019/excellence-service",
    meta: excellence_45servicek1bcTxeka2Meta || {},
    alias: excellence_45servicek1bcTxeka2Meta?.alias || [],
    redirect: excellence_45servicek1bcTxeka2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45servicek1bcTxeka2Meta?.name ?? "customers-topfleets-2019-excellence-service___mx",
    path: excellence_45servicek1bcTxeka2Meta?.path ?? "/mx/customers/topfleets/2019/excellence-service",
    meta: excellence_45servicek1bcTxeka2Meta || {},
    alias: excellence_45servicek1bcTxeka2Meta?.alias || [],
    redirect: excellence_45servicek1bcTxeka2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45servicek1bcTxeka2Meta?.name ?? "customers-topfleets-2019-excellence-service___fr",
    path: excellence_45servicek1bcTxeka2Meta?.path ?? "/fr/customers/topfleets/2019/excellence-service",
    meta: excellence_45servicek1bcTxeka2Meta || {},
    alias: excellence_45servicek1bcTxeka2Meta?.alias || [],
    redirect: excellence_45servicek1bcTxeka2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45servicek1bcTxeka2Meta?.name ?? "customers-topfleets-2019-excellence-service___fr-ca",
    path: excellence_45servicek1bcTxeka2Meta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45servicek1bcTxeka2Meta || {},
    alias: excellence_45servicek1bcTxeka2Meta?.alias || [],
    redirect: excellence_45servicek1bcTxeka2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: indexDvB05uVue6Meta?.name ?? "customers-topfleets-2019___en-US",
    path: indexDvB05uVue6Meta?.path ?? "/customers/topfleets/2019",
    meta: indexDvB05uVue6Meta || {},
    alias: indexDvB05uVue6Meta?.alias || [],
    redirect: indexDvB05uVue6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvB05uVue6Meta?.name ?? "customers-topfleets-2019___ca",
    path: indexDvB05uVue6Meta?.path ?? "/ca/customers/topfleets/2019",
    meta: indexDvB05uVue6Meta || {},
    alias: indexDvB05uVue6Meta?.alias || [],
    redirect: indexDvB05uVue6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvB05uVue6Meta?.name ?? "customers-topfleets-2019___nl",
    path: indexDvB05uVue6Meta?.path ?? "/nl/customers/topfleets/2019",
    meta: indexDvB05uVue6Meta || {},
    alias: indexDvB05uVue6Meta?.alias || [],
    redirect: indexDvB05uVue6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvB05uVue6Meta?.name ?? "customers-topfleets-2019___uk",
    path: indexDvB05uVue6Meta?.path ?? "/uk/customers/topfleets/2019",
    meta: indexDvB05uVue6Meta || {},
    alias: indexDvB05uVue6Meta?.alias || [],
    redirect: indexDvB05uVue6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvB05uVue6Meta?.name ?? "customers-topfleets-2019___de",
    path: indexDvB05uVue6Meta?.path ?? "/de/customers/topfleets/2019",
    meta: indexDvB05uVue6Meta || {},
    alias: indexDvB05uVue6Meta?.alias || [],
    redirect: indexDvB05uVue6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvB05uVue6Meta?.name ?? "customers-topfleets-2019___mx",
    path: indexDvB05uVue6Meta?.path ?? "/mx/customers/topfleets/2019",
    meta: indexDvB05uVue6Meta || {},
    alias: indexDvB05uVue6Meta?.alias || [],
    redirect: indexDvB05uVue6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvB05uVue6Meta?.name ?? "customers-topfleets-2019___fr",
    path: indexDvB05uVue6Meta?.path ?? "/fr/customers/topfleets/2019",
    meta: indexDvB05uVue6Meta || {},
    alias: indexDvB05uVue6Meta?.alias || [],
    redirect: indexDvB05uVue6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexDvB05uVue6Meta?.name ?? "customers-topfleets-2019___fr-ca",
    path: indexDvB05uVue6Meta?.path ?? "/fr-ca/customers/topfleets/2019",
    meta: indexDvB05uVue6Meta || {},
    alias: indexDvB05uVue6Meta?.alias || [],
    redirect: indexDvB05uVue6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleets9LQ91EBh6Meta?.name ?? "customers-topfleets-2019-safest-fleet___en-US",
    path: safest_45fleets9LQ91EBh6Meta?.path ?? "/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleets9LQ91EBh6Meta || {},
    alias: safest_45fleets9LQ91EBh6Meta?.alias || [],
    redirect: safest_45fleets9LQ91EBh6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleets9LQ91EBh6Meta?.name ?? "customers-topfleets-2019-safest-fleet___ca",
    path: safest_45fleets9LQ91EBh6Meta?.path ?? "/ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleets9LQ91EBh6Meta || {},
    alias: safest_45fleets9LQ91EBh6Meta?.alias || [],
    redirect: safest_45fleets9LQ91EBh6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleets9LQ91EBh6Meta?.name ?? "customers-topfleets-2019-safest-fleet___nl",
    path: safest_45fleets9LQ91EBh6Meta?.path ?? "/nl/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleets9LQ91EBh6Meta || {},
    alias: safest_45fleets9LQ91EBh6Meta?.alias || [],
    redirect: safest_45fleets9LQ91EBh6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleets9LQ91EBh6Meta?.name ?? "customers-topfleets-2019-safest-fleet___uk",
    path: safest_45fleets9LQ91EBh6Meta?.path ?? "/uk/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleets9LQ91EBh6Meta || {},
    alias: safest_45fleets9LQ91EBh6Meta?.alias || [],
    redirect: safest_45fleets9LQ91EBh6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleets9LQ91EBh6Meta?.name ?? "customers-topfleets-2019-safest-fleet___de",
    path: safest_45fleets9LQ91EBh6Meta?.path ?? "/de/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleets9LQ91EBh6Meta || {},
    alias: safest_45fleets9LQ91EBh6Meta?.alias || [],
    redirect: safest_45fleets9LQ91EBh6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleets9LQ91EBh6Meta?.name ?? "customers-topfleets-2019-safest-fleet___mx",
    path: safest_45fleets9LQ91EBh6Meta?.path ?? "/mx/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleets9LQ91EBh6Meta || {},
    alias: safest_45fleets9LQ91EBh6Meta?.alias || [],
    redirect: safest_45fleets9LQ91EBh6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleets9LQ91EBh6Meta?.name ?? "customers-topfleets-2019-safest-fleet___fr",
    path: safest_45fleets9LQ91EBh6Meta?.path ?? "/fr/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleets9LQ91EBh6Meta || {},
    alias: safest_45fleets9LQ91EBh6Meta?.alias || [],
    redirect: safest_45fleets9LQ91EBh6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleets9LQ91EBh6Meta?.name ?? "customers-topfleets-2019-safest-fleet___fr-ca",
    path: safest_45fleets9LQ91EBh6Meta?.path ?? "/fr-ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleets9LQ91EBh6Meta || {},
    alias: safest_45fleets9LQ91EBh6Meta?.alias || [],
    redirect: safest_45fleets9LQ91EBh6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW6k5WIFWkdMeta?.name ?? "customers-topfleets-2020-driver-performance___en-US",
    path: driver_45performanceW6k5WIFWkdMeta?.path ?? "/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW6k5WIFWkdMeta || {},
    alias: driver_45performanceW6k5WIFWkdMeta?.alias || [],
    redirect: driver_45performanceW6k5WIFWkdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW6k5WIFWkdMeta?.name ?? "customers-topfleets-2020-driver-performance___ca",
    path: driver_45performanceW6k5WIFWkdMeta?.path ?? "/ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW6k5WIFWkdMeta || {},
    alias: driver_45performanceW6k5WIFWkdMeta?.alias || [],
    redirect: driver_45performanceW6k5WIFWkdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW6k5WIFWkdMeta?.name ?? "customers-topfleets-2020-driver-performance___nl",
    path: driver_45performanceW6k5WIFWkdMeta?.path ?? "/nl/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW6k5WIFWkdMeta || {},
    alias: driver_45performanceW6k5WIFWkdMeta?.alias || [],
    redirect: driver_45performanceW6k5WIFWkdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW6k5WIFWkdMeta?.name ?? "customers-topfleets-2020-driver-performance___uk",
    path: driver_45performanceW6k5WIFWkdMeta?.path ?? "/uk/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW6k5WIFWkdMeta || {},
    alias: driver_45performanceW6k5WIFWkdMeta?.alias || [],
    redirect: driver_45performanceW6k5WIFWkdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW6k5WIFWkdMeta?.name ?? "customers-topfleets-2020-driver-performance___de",
    path: driver_45performanceW6k5WIFWkdMeta?.path ?? "/de/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW6k5WIFWkdMeta || {},
    alias: driver_45performanceW6k5WIFWkdMeta?.alias || [],
    redirect: driver_45performanceW6k5WIFWkdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW6k5WIFWkdMeta?.name ?? "customers-topfleets-2020-driver-performance___mx",
    path: driver_45performanceW6k5WIFWkdMeta?.path ?? "/mx/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW6k5WIFWkdMeta || {},
    alias: driver_45performanceW6k5WIFWkdMeta?.alias || [],
    redirect: driver_45performanceW6k5WIFWkdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW6k5WIFWkdMeta?.name ?? "customers-topfleets-2020-driver-performance___fr",
    path: driver_45performanceW6k5WIFWkdMeta?.path ?? "/fr/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW6k5WIFWkdMeta || {},
    alias: driver_45performanceW6k5WIFWkdMeta?.alias || [],
    redirect: driver_45performanceW6k5WIFWkdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceW6k5WIFWkdMeta?.name ?? "customers-topfleets-2020-driver-performance___fr-ca",
    path: driver_45performanceW6k5WIFWkdMeta?.path ?? "/fr-ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceW6k5WIFWkdMeta || {},
    alias: driver_45performanceW6k5WIFWkdMeta?.alias || [],
    redirect: driver_45performanceW6k5WIFWkdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancedh1bRKiPDMMeta?.name ?? "customers-topfleets-2020-excellence-performance___en-US",
    path: excellence_45performancedh1bRKiPDMMeta?.path ?? "/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancedh1bRKiPDMMeta || {},
    alias: excellence_45performancedh1bRKiPDMMeta?.alias || [],
    redirect: excellence_45performancedh1bRKiPDMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancedh1bRKiPDMMeta?.name ?? "customers-topfleets-2020-excellence-performance___ca",
    path: excellence_45performancedh1bRKiPDMMeta?.path ?? "/ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancedh1bRKiPDMMeta || {},
    alias: excellence_45performancedh1bRKiPDMMeta?.alias || [],
    redirect: excellence_45performancedh1bRKiPDMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancedh1bRKiPDMMeta?.name ?? "customers-topfleets-2020-excellence-performance___nl",
    path: excellence_45performancedh1bRKiPDMMeta?.path ?? "/nl/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancedh1bRKiPDMMeta || {},
    alias: excellence_45performancedh1bRKiPDMMeta?.alias || [],
    redirect: excellence_45performancedh1bRKiPDMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancedh1bRKiPDMMeta?.name ?? "customers-topfleets-2020-excellence-performance___uk",
    path: excellence_45performancedh1bRKiPDMMeta?.path ?? "/uk/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancedh1bRKiPDMMeta || {},
    alias: excellence_45performancedh1bRKiPDMMeta?.alias || [],
    redirect: excellence_45performancedh1bRKiPDMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancedh1bRKiPDMMeta?.name ?? "customers-topfleets-2020-excellence-performance___de",
    path: excellence_45performancedh1bRKiPDMMeta?.path ?? "/de/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancedh1bRKiPDMMeta || {},
    alias: excellence_45performancedh1bRKiPDMMeta?.alias || [],
    redirect: excellence_45performancedh1bRKiPDMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancedh1bRKiPDMMeta?.name ?? "customers-topfleets-2020-excellence-performance___mx",
    path: excellence_45performancedh1bRKiPDMMeta?.path ?? "/mx/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancedh1bRKiPDMMeta || {},
    alias: excellence_45performancedh1bRKiPDMMeta?.alias || [],
    redirect: excellence_45performancedh1bRKiPDMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancedh1bRKiPDMMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr",
    path: excellence_45performancedh1bRKiPDMMeta?.path ?? "/fr/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancedh1bRKiPDMMeta || {},
    alias: excellence_45performancedh1bRKiPDMMeta?.alias || [],
    redirect: excellence_45performancedh1bRKiPDMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancedh1bRKiPDMMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr-ca",
    path: excellence_45performancedh1bRKiPDMMeta?.path ?? "/fr-ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancedh1bRKiPDMMeta || {},
    alias: excellence_45performancedh1bRKiPDMMeta?.alias || [],
    redirect: excellence_45performancedh1bRKiPDMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorSAEOW2nzCmMeta?.name ?? "customers-topfleets-2020-fleet-innovator___en-US",
    path: fleet_45innovatorSAEOW2nzCmMeta?.path ?? "/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorSAEOW2nzCmMeta || {},
    alias: fleet_45innovatorSAEOW2nzCmMeta?.alias || [],
    redirect: fleet_45innovatorSAEOW2nzCmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorSAEOW2nzCmMeta?.name ?? "customers-topfleets-2020-fleet-innovator___ca",
    path: fleet_45innovatorSAEOW2nzCmMeta?.path ?? "/ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorSAEOW2nzCmMeta || {},
    alias: fleet_45innovatorSAEOW2nzCmMeta?.alias || [],
    redirect: fleet_45innovatorSAEOW2nzCmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorSAEOW2nzCmMeta?.name ?? "customers-topfleets-2020-fleet-innovator___nl",
    path: fleet_45innovatorSAEOW2nzCmMeta?.path ?? "/nl/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorSAEOW2nzCmMeta || {},
    alias: fleet_45innovatorSAEOW2nzCmMeta?.alias || [],
    redirect: fleet_45innovatorSAEOW2nzCmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorSAEOW2nzCmMeta?.name ?? "customers-topfleets-2020-fleet-innovator___uk",
    path: fleet_45innovatorSAEOW2nzCmMeta?.path ?? "/uk/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorSAEOW2nzCmMeta || {},
    alias: fleet_45innovatorSAEOW2nzCmMeta?.alias || [],
    redirect: fleet_45innovatorSAEOW2nzCmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorSAEOW2nzCmMeta?.name ?? "customers-topfleets-2020-fleet-innovator___de",
    path: fleet_45innovatorSAEOW2nzCmMeta?.path ?? "/de/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorSAEOW2nzCmMeta || {},
    alias: fleet_45innovatorSAEOW2nzCmMeta?.alias || [],
    redirect: fleet_45innovatorSAEOW2nzCmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorSAEOW2nzCmMeta?.name ?? "customers-topfleets-2020-fleet-innovator___mx",
    path: fleet_45innovatorSAEOW2nzCmMeta?.path ?? "/mx/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorSAEOW2nzCmMeta || {},
    alias: fleet_45innovatorSAEOW2nzCmMeta?.alias || [],
    redirect: fleet_45innovatorSAEOW2nzCmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorSAEOW2nzCmMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr",
    path: fleet_45innovatorSAEOW2nzCmMeta?.path ?? "/fr/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorSAEOW2nzCmMeta || {},
    alias: fleet_45innovatorSAEOW2nzCmMeta?.alias || [],
    redirect: fleet_45innovatorSAEOW2nzCmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovatorSAEOW2nzCmMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr-ca",
    path: fleet_45innovatorSAEOW2nzCmMeta?.path ?? "/fr-ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovatorSAEOW2nzCmMeta || {},
    alias: fleet_45innovatorSAEOW2nzCmMeta?.alias || [],
    redirect: fleet_45innovatorSAEOW2nzCmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: indexmnrc2eGOsXMeta?.name ?? "customers-topfleets-2020___en-US",
    path: indexmnrc2eGOsXMeta?.path ?? "/customers/topfleets/2020",
    meta: indexmnrc2eGOsXMeta || {},
    alias: indexmnrc2eGOsXMeta?.alias || [],
    redirect: indexmnrc2eGOsXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmnrc2eGOsXMeta?.name ?? "customers-topfleets-2020___ca",
    path: indexmnrc2eGOsXMeta?.path ?? "/ca/customers/topfleets/2020",
    meta: indexmnrc2eGOsXMeta || {},
    alias: indexmnrc2eGOsXMeta?.alias || [],
    redirect: indexmnrc2eGOsXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmnrc2eGOsXMeta?.name ?? "customers-topfleets-2020___nl",
    path: indexmnrc2eGOsXMeta?.path ?? "/nl/customers/topfleets/2020",
    meta: indexmnrc2eGOsXMeta || {},
    alias: indexmnrc2eGOsXMeta?.alias || [],
    redirect: indexmnrc2eGOsXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmnrc2eGOsXMeta?.name ?? "customers-topfleets-2020___uk",
    path: indexmnrc2eGOsXMeta?.path ?? "/uk/customers/topfleets/2020",
    meta: indexmnrc2eGOsXMeta || {},
    alias: indexmnrc2eGOsXMeta?.alias || [],
    redirect: indexmnrc2eGOsXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmnrc2eGOsXMeta?.name ?? "customers-topfleets-2020___de",
    path: indexmnrc2eGOsXMeta?.path ?? "/de/customers/topfleets/2020",
    meta: indexmnrc2eGOsXMeta || {},
    alias: indexmnrc2eGOsXMeta?.alias || [],
    redirect: indexmnrc2eGOsXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmnrc2eGOsXMeta?.name ?? "customers-topfleets-2020___mx",
    path: indexmnrc2eGOsXMeta?.path ?? "/mx/customers/topfleets/2020",
    meta: indexmnrc2eGOsXMeta || {},
    alias: indexmnrc2eGOsXMeta?.alias || [],
    redirect: indexmnrc2eGOsXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmnrc2eGOsXMeta?.name ?? "customers-topfleets-2020___fr",
    path: indexmnrc2eGOsXMeta?.path ?? "/fr/customers/topfleets/2020",
    meta: indexmnrc2eGOsXMeta || {},
    alias: indexmnrc2eGOsXMeta?.alias || [],
    redirect: indexmnrc2eGOsXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexmnrc2eGOsXMeta?.name ?? "customers-topfleets-2020___fr-ca",
    path: indexmnrc2eGOsXMeta?.path ?? "/fr-ca/customers/topfleets/2020",
    meta: indexmnrc2eGOsXMeta || {},
    alias: indexmnrc2eGOsXMeta?.alias || [],
    redirect: indexmnrc2eGOsXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetDVWplY5H4CMeta?.name ?? "customers-topfleets-2020-public-fleet___en-US",
    path: public_45fleetDVWplY5H4CMeta?.path ?? "/customers/topfleets/2020/public-fleet",
    meta: public_45fleetDVWplY5H4CMeta || {},
    alias: public_45fleetDVWplY5H4CMeta?.alias || [],
    redirect: public_45fleetDVWplY5H4CMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetDVWplY5H4CMeta?.name ?? "customers-topfleets-2020-public-fleet___ca",
    path: public_45fleetDVWplY5H4CMeta?.path ?? "/ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetDVWplY5H4CMeta || {},
    alias: public_45fleetDVWplY5H4CMeta?.alias || [],
    redirect: public_45fleetDVWplY5H4CMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetDVWplY5H4CMeta?.name ?? "customers-topfleets-2020-public-fleet___nl",
    path: public_45fleetDVWplY5H4CMeta?.path ?? "/nl/customers/topfleets/2020/public-fleet",
    meta: public_45fleetDVWplY5H4CMeta || {},
    alias: public_45fleetDVWplY5H4CMeta?.alias || [],
    redirect: public_45fleetDVWplY5H4CMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetDVWplY5H4CMeta?.name ?? "customers-topfleets-2020-public-fleet___uk",
    path: public_45fleetDVWplY5H4CMeta?.path ?? "/uk/customers/topfleets/2020/public-fleet",
    meta: public_45fleetDVWplY5H4CMeta || {},
    alias: public_45fleetDVWplY5H4CMeta?.alias || [],
    redirect: public_45fleetDVWplY5H4CMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetDVWplY5H4CMeta?.name ?? "customers-topfleets-2020-public-fleet___de",
    path: public_45fleetDVWplY5H4CMeta?.path ?? "/de/customers/topfleets/2020/public-fleet",
    meta: public_45fleetDVWplY5H4CMeta || {},
    alias: public_45fleetDVWplY5H4CMeta?.alias || [],
    redirect: public_45fleetDVWplY5H4CMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetDVWplY5H4CMeta?.name ?? "customers-topfleets-2020-public-fleet___mx",
    path: public_45fleetDVWplY5H4CMeta?.path ?? "/mx/customers/topfleets/2020/public-fleet",
    meta: public_45fleetDVWplY5H4CMeta || {},
    alias: public_45fleetDVWplY5H4CMeta?.alias || [],
    redirect: public_45fleetDVWplY5H4CMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetDVWplY5H4CMeta?.name ?? "customers-topfleets-2020-public-fleet___fr",
    path: public_45fleetDVWplY5H4CMeta?.path ?? "/fr/customers/topfleets/2020/public-fleet",
    meta: public_45fleetDVWplY5H4CMeta || {},
    alias: public_45fleetDVWplY5H4CMeta?.alias || [],
    redirect: public_45fleetDVWplY5H4CMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetDVWplY5H4CMeta?.name ?? "customers-topfleets-2020-public-fleet___fr-ca",
    path: public_45fleetDVWplY5H4CMeta?.path ?? "/fr-ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetDVWplY5H4CMeta || {},
    alias: public_45fleetDVWplY5H4CMeta?.alias || [],
    redirect: public_45fleetDVWplY5H4CMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetotRDnRX7WJMeta?.name ?? "customers-topfleets-2020-safest-fleet___en-US",
    path: safest_45fleetotRDnRX7WJMeta?.path ?? "/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetotRDnRX7WJMeta || {},
    alias: safest_45fleetotRDnRX7WJMeta?.alias || [],
    redirect: safest_45fleetotRDnRX7WJMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetotRDnRX7WJMeta?.name ?? "customers-topfleets-2020-safest-fleet___ca",
    path: safest_45fleetotRDnRX7WJMeta?.path ?? "/ca/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetotRDnRX7WJMeta || {},
    alias: safest_45fleetotRDnRX7WJMeta?.alias || [],
    redirect: safest_45fleetotRDnRX7WJMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: indexq099FveeH3Meta?.name ?? "customers-topfleets___en-US",
    path: indexq099FveeH3Meta?.path ?? "/customers/topfleets",
    meta: indexq099FveeH3Meta || {},
    alias: indexq099FveeH3Meta?.alias || [],
    redirect: indexq099FveeH3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexq099FveeH3Meta?.name ?? "customers-topfleets___ca",
    path: indexq099FveeH3Meta?.path ?? "/ca/customers/topfleets",
    meta: indexq099FveeH3Meta || {},
    alias: indexq099FveeH3Meta?.alias || [],
    redirect: indexq099FveeH3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexq099FveeH3Meta?.name ?? "customers-topfleets___nl",
    path: indexq099FveeH3Meta?.path ?? "/nl/customers/topfleets",
    meta: indexq099FveeH3Meta || {},
    alias: indexq099FveeH3Meta?.alias || [],
    redirect: indexq099FveeH3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexq099FveeH3Meta?.name ?? "customers-topfleets___uk",
    path: indexq099FveeH3Meta?.path ?? "/uk/customers/topfleets",
    meta: indexq099FveeH3Meta || {},
    alias: indexq099FveeH3Meta?.alias || [],
    redirect: indexq099FveeH3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexq099FveeH3Meta?.name ?? "customers-topfleets___de",
    path: indexq099FveeH3Meta?.path ?? "/de/customers/topfleets",
    meta: indexq099FveeH3Meta || {},
    alias: indexq099FveeH3Meta?.alias || [],
    redirect: indexq099FveeH3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexq099FveeH3Meta?.name ?? "customers-topfleets___mx",
    path: indexq099FveeH3Meta?.path ?? "/mx/customers/topfleets",
    meta: indexq099FveeH3Meta || {},
    alias: indexq099FveeH3Meta?.alias || [],
    redirect: indexq099FveeH3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexq099FveeH3Meta?.name ?? "customers-topfleets___fr",
    path: indexq099FveeH3Meta?.path ?? "/fr/customers/topfleets",
    meta: indexq099FveeH3Meta || {},
    alias: indexq099FveeH3Meta?.alias || [],
    redirect: indexq099FveeH3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexq099FveeH3Meta?.name ?? "customers-topfleets___fr-ca",
    path: indexq099FveeH3Meta?.path ?? "/fr-ca/customers/topfleets",
    meta: indexq099FveeH3Meta || {},
    alias: indexq099FveeH3Meta?.alias || [],
    redirect: indexq099FveeH3Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93stRqmNaDwXMeta?.name ?? "events-slug___en-US",
    path: _91slug_93stRqmNaDwXMeta?.path ?? "/events/:slug()",
    meta: _91slug_93stRqmNaDwXMeta || {},
    alias: _91slug_93stRqmNaDwXMeta?.alias || [],
    redirect: _91slug_93stRqmNaDwXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93stRqmNaDwXMeta?.name ?? "events-slug___ca",
    path: _91slug_93stRqmNaDwXMeta?.path ?? "/ca/events/:slug()",
    meta: _91slug_93stRqmNaDwXMeta || {},
    alias: _91slug_93stRqmNaDwXMeta?.alias || [],
    redirect: _91slug_93stRqmNaDwXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93stRqmNaDwXMeta?.name ?? "events-slug___nl",
    path: _91slug_93stRqmNaDwXMeta?.path ?? "/nl/events/:slug()",
    meta: _91slug_93stRqmNaDwXMeta || {},
    alias: _91slug_93stRqmNaDwXMeta?.alias || [],
    redirect: _91slug_93stRqmNaDwXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93stRqmNaDwXMeta?.name ?? "events-slug___uk",
    path: _91slug_93stRqmNaDwXMeta?.path ?? "/uk/events/:slug()",
    meta: _91slug_93stRqmNaDwXMeta || {},
    alias: _91slug_93stRqmNaDwXMeta?.alias || [],
    redirect: _91slug_93stRqmNaDwXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93stRqmNaDwXMeta?.name ?? "events-slug___de",
    path: _91slug_93stRqmNaDwXMeta?.path ?? "/de/events/:slug()",
    meta: _91slug_93stRqmNaDwXMeta || {},
    alias: _91slug_93stRqmNaDwXMeta?.alias || [],
    redirect: _91slug_93stRqmNaDwXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93stRqmNaDwXMeta?.name ?? "events-slug___mx",
    path: _91slug_93stRqmNaDwXMeta?.path ?? "/mx/events/:slug()",
    meta: _91slug_93stRqmNaDwXMeta || {},
    alias: _91slug_93stRqmNaDwXMeta?.alias || [],
    redirect: _91slug_93stRqmNaDwXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93stRqmNaDwXMeta?.name ?? "events-slug___fr",
    path: _91slug_93stRqmNaDwXMeta?.path ?? "/fr/events/:slug()",
    meta: _91slug_93stRqmNaDwXMeta || {},
    alias: _91slug_93stRqmNaDwXMeta?.alias || [],
    redirect: _91slug_93stRqmNaDwXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93stRqmNaDwXMeta?.name ?? "events-slug___fr-ca",
    path: _91slug_93stRqmNaDwXMeta?.path ?? "/fr-ca/events/:slug()",
    meta: _91slug_93stRqmNaDwXMeta || {},
    alias: _91slug_93stRqmNaDwXMeta?.alias || [],
    redirect: _91slug_93stRqmNaDwXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexq6Xy1iBajXMeta?.name ?? "events___en-US",
    path: indexq6Xy1iBajXMeta?.path ?? "/events",
    meta: indexq6Xy1iBajXMeta || {},
    alias: indexq6Xy1iBajXMeta?.alias || [],
    redirect: indexq6Xy1iBajXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexq6Xy1iBajXMeta?.name ?? "events___ca",
    path: indexq6Xy1iBajXMeta?.path ?? "/ca/events",
    meta: indexq6Xy1iBajXMeta || {},
    alias: indexq6Xy1iBajXMeta?.alias || [],
    redirect: indexq6Xy1iBajXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexq6Xy1iBajXMeta?.name ?? "events___nl",
    path: indexq6Xy1iBajXMeta?.path ?? "/nl/events",
    meta: indexq6Xy1iBajXMeta || {},
    alias: indexq6Xy1iBajXMeta?.alias || [],
    redirect: indexq6Xy1iBajXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexq6Xy1iBajXMeta?.name ?? "events___uk",
    path: indexq6Xy1iBajXMeta?.path ?? "/uk/events",
    meta: indexq6Xy1iBajXMeta || {},
    alias: indexq6Xy1iBajXMeta?.alias || [],
    redirect: indexq6Xy1iBajXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexq6Xy1iBajXMeta?.name ?? "events___de",
    path: indexq6Xy1iBajXMeta?.path ?? "/de/events",
    meta: indexq6Xy1iBajXMeta || {},
    alias: indexq6Xy1iBajXMeta?.alias || [],
    redirect: indexq6Xy1iBajXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexq6Xy1iBajXMeta?.name ?? "events___mx",
    path: indexq6Xy1iBajXMeta?.path ?? "/mx/events",
    meta: indexq6Xy1iBajXMeta || {},
    alias: indexq6Xy1iBajXMeta?.alias || [],
    redirect: indexq6Xy1iBajXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexq6Xy1iBajXMeta?.name ?? "events___fr",
    path: indexq6Xy1iBajXMeta?.path ?? "/fr/events",
    meta: indexq6Xy1iBajXMeta || {},
    alias: indexq6Xy1iBajXMeta?.alias || [],
    redirect: indexq6Xy1iBajXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexq6Xy1iBajXMeta?.name ?? "events___fr-ca",
    path: indexq6Xy1iBajXMeta?.path ?? "/fr-ca/events",
    meta: indexq6Xy1iBajXMeta || {},
    alias: indexq6Xy1iBajXMeta?.alias || [],
    redirect: indexq6Xy1iBajXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appkB2ZBF7wHxMeta?.name ?? "fleet-app___en-US",
    path: fleet_45appkB2ZBF7wHxMeta?.path ?? "/fleet-app",
    meta: fleet_45appkB2ZBF7wHxMeta || {},
    alias: fleet_45appkB2ZBF7wHxMeta?.alias || [],
    redirect: fleet_45appkB2ZBF7wHxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appkB2ZBF7wHxMeta?.name ?? "fleet-app___ca",
    path: fleet_45appkB2ZBF7wHxMeta?.path ?? "/ca/fleet-app",
    meta: fleet_45appkB2ZBF7wHxMeta || {},
    alias: fleet_45appkB2ZBF7wHxMeta?.alias || [],
    redirect: fleet_45appkB2ZBF7wHxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appkB2ZBF7wHxMeta?.name ?? "fleet-app___nl",
    path: fleet_45appkB2ZBF7wHxMeta?.path ?? "/nl/fleet-app",
    meta: fleet_45appkB2ZBF7wHxMeta || {},
    alias: fleet_45appkB2ZBF7wHxMeta?.alias || [],
    redirect: fleet_45appkB2ZBF7wHxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appkB2ZBF7wHxMeta?.name ?? "fleet-app___uk",
    path: fleet_45appkB2ZBF7wHxMeta?.path ?? "/uk/fleet-app",
    meta: fleet_45appkB2ZBF7wHxMeta || {},
    alias: fleet_45appkB2ZBF7wHxMeta?.alias || [],
    redirect: fleet_45appkB2ZBF7wHxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appkB2ZBF7wHxMeta?.name ?? "fleet-app___de",
    path: fleet_45appkB2ZBF7wHxMeta?.path ?? "/de/fleet-app",
    meta: fleet_45appkB2ZBF7wHxMeta || {},
    alias: fleet_45appkB2ZBF7wHxMeta?.alias || [],
    redirect: fleet_45appkB2ZBF7wHxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appkB2ZBF7wHxMeta?.name ?? "fleet-app___mx",
    path: fleet_45appkB2ZBF7wHxMeta?.path ?? "/mx/fleet-app",
    meta: fleet_45appkB2ZBF7wHxMeta || {},
    alias: fleet_45appkB2ZBF7wHxMeta?.alias || [],
    redirect: fleet_45appkB2ZBF7wHxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appkB2ZBF7wHxMeta?.name ?? "fleet-app___fr",
    path: fleet_45appkB2ZBF7wHxMeta?.path ?? "/fr/fleet-app",
    meta: fleet_45appkB2ZBF7wHxMeta || {},
    alias: fleet_45appkB2ZBF7wHxMeta?.alias || [],
    redirect: fleet_45appkB2ZBF7wHxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45appkB2ZBF7wHxMeta?.name ?? "fleet-app___fr-ca",
    path: fleet_45appkB2ZBF7wHxMeta?.path ?? "/fr-ca/fleet-app",
    meta: fleet_45appkB2ZBF7wHxMeta || {},
    alias: fleet_45appkB2ZBF7wHxMeta?.alias || [],
    redirect: fleet_45appkB2ZBF7wHxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: _91slug_935DavVE462ZMeta?.name ?? "fleet-prefix-slug___en-US",
    path: _91slug_935DavVE462ZMeta?.path ?? "/fleet/:prefix()/:slug()",
    meta: _91slug_935DavVE462ZMeta || {},
    alias: _91slug_935DavVE462ZMeta?.alias || [],
    redirect: _91slug_935DavVE462ZMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935DavVE462ZMeta?.name ?? "fleet-prefix-slug___ca",
    path: _91slug_935DavVE462ZMeta?.path ?? "/ca/fleet/:prefix()/:slug()",
    meta: _91slug_935DavVE462ZMeta || {},
    alias: _91slug_935DavVE462ZMeta?.alias || [],
    redirect: _91slug_935DavVE462ZMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935DavVE462ZMeta?.name ?? "fleet-prefix-slug___nl",
    path: _91slug_935DavVE462ZMeta?.path ?? "/nl/fleet/:prefix()/:slug()",
    meta: _91slug_935DavVE462ZMeta || {},
    alias: _91slug_935DavVE462ZMeta?.alias || [],
    redirect: _91slug_935DavVE462ZMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935DavVE462ZMeta?.name ?? "fleet-prefix-slug___uk",
    path: _91slug_935DavVE462ZMeta?.path ?? "/uk/fleet/:prefix()/:slug()",
    meta: _91slug_935DavVE462ZMeta || {},
    alias: _91slug_935DavVE462ZMeta?.alias || [],
    redirect: _91slug_935DavVE462ZMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935DavVE462ZMeta?.name ?? "fleet-prefix-slug___de",
    path: _91slug_935DavVE462ZMeta?.path ?? "/de/fleet/:prefix()/:slug()",
    meta: _91slug_935DavVE462ZMeta || {},
    alias: _91slug_935DavVE462ZMeta?.alias || [],
    redirect: _91slug_935DavVE462ZMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935DavVE462ZMeta?.name ?? "fleet-prefix-slug___mx",
    path: _91slug_935DavVE462ZMeta?.path ?? "/mx/fleet/:prefix()/:slug()",
    meta: _91slug_935DavVE462ZMeta || {},
    alias: _91slug_935DavVE462ZMeta?.alias || [],
    redirect: _91slug_935DavVE462ZMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935DavVE462ZMeta?.name ?? "fleet-prefix-slug___fr",
    path: _91slug_935DavVE462ZMeta?.path ?? "/fr/fleet/:prefix()/:slug()",
    meta: _91slug_935DavVE462ZMeta || {},
    alias: _91slug_935DavVE462ZMeta?.alias || [],
    redirect: _91slug_935DavVE462ZMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_935DavVE462ZMeta?.name ?? "fleet-prefix-slug___fr-ca",
    path: _91slug_935DavVE462ZMeta?.path ?? "/fr-ca/fleet/:prefix()/:slug()",
    meta: _91slug_935DavVE462ZMeta || {},
    alias: _91slug_935DavVE462ZMeta?.alias || [],
    redirect: _91slug_935DavVE462ZMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LeGcgxFoC5Meta?.name ?? "fleet-slug___en-US",
    path: _91slug_93LeGcgxFoC5Meta?.path ?? "/fleet/:slug()",
    meta: _91slug_93LeGcgxFoC5Meta || {},
    alias: _91slug_93LeGcgxFoC5Meta?.alias || [],
    redirect: _91slug_93LeGcgxFoC5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LeGcgxFoC5Meta?.name ?? "fleet-slug___ca",
    path: _91slug_93LeGcgxFoC5Meta?.path ?? "/ca/fleet/:slug()",
    meta: _91slug_93LeGcgxFoC5Meta || {},
    alias: _91slug_93LeGcgxFoC5Meta?.alias || [],
    redirect: _91slug_93LeGcgxFoC5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LeGcgxFoC5Meta?.name ?? "fleet-slug___nl",
    path: _91slug_93LeGcgxFoC5Meta?.path ?? "/nl/fleet/:slug()",
    meta: _91slug_93LeGcgxFoC5Meta || {},
    alias: _91slug_93LeGcgxFoC5Meta?.alias || [],
    redirect: _91slug_93LeGcgxFoC5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LeGcgxFoC5Meta?.name ?? "fleet-slug___uk",
    path: _91slug_93LeGcgxFoC5Meta?.path ?? "/uk/fleet/:slug()",
    meta: _91slug_93LeGcgxFoC5Meta || {},
    alias: _91slug_93LeGcgxFoC5Meta?.alias || [],
    redirect: _91slug_93LeGcgxFoC5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LeGcgxFoC5Meta?.name ?? "fleet-slug___de",
    path: _91slug_93LeGcgxFoC5Meta?.path ?? "/de/fleet/:slug()",
    meta: _91slug_93LeGcgxFoC5Meta || {},
    alias: _91slug_93LeGcgxFoC5Meta?.alias || [],
    redirect: _91slug_93LeGcgxFoC5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LeGcgxFoC5Meta?.name ?? "fleet-slug___mx",
    path: _91slug_93LeGcgxFoC5Meta?.path ?? "/mx/fleet/:slug()",
    meta: _91slug_93LeGcgxFoC5Meta || {},
    alias: _91slug_93LeGcgxFoC5Meta?.alias || [],
    redirect: _91slug_93LeGcgxFoC5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LeGcgxFoC5Meta?.name ?? "fleet-slug___fr",
    path: _91slug_93LeGcgxFoC5Meta?.path ?? "/fr/fleet/:slug()",
    meta: _91slug_93LeGcgxFoC5Meta || {},
    alias: _91slug_93LeGcgxFoC5Meta?.alias || [],
    redirect: _91slug_93LeGcgxFoC5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LeGcgxFoC5Meta?.name ?? "fleet-slug___fr-ca",
    path: _91slug_93LeGcgxFoC5Meta?.path ?? "/fr-ca/fleet/:slug()",
    meta: _91slug_93LeGcgxFoC5Meta || {},
    alias: _91slug_93LeGcgxFoC5Meta?.alias || [],
    redirect: _91slug_93LeGcgxFoC5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R2P875pWH7Meta?.name ?? "fleets-prefix-slug___en-US",
    path: _91slug_93R2P875pWH7Meta?.path ?? "/fleets/:prefix()/:slug()",
    meta: _91slug_93R2P875pWH7Meta || {},
    alias: _91slug_93R2P875pWH7Meta?.alias || [],
    redirect: _91slug_93R2P875pWH7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R2P875pWH7Meta?.name ?? "fleets-prefix-slug___ca",
    path: _91slug_93R2P875pWH7Meta?.path ?? "/ca/fleets/:prefix()/:slug()",
    meta: _91slug_93R2P875pWH7Meta || {},
    alias: _91slug_93R2P875pWH7Meta?.alias || [],
    redirect: _91slug_93R2P875pWH7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R2P875pWH7Meta?.name ?? "fleets-prefix-slug___nl",
    path: _91slug_93R2P875pWH7Meta?.path ?? "/nl/fleets/:prefix()/:slug()",
    meta: _91slug_93R2P875pWH7Meta || {},
    alias: _91slug_93R2P875pWH7Meta?.alias || [],
    redirect: _91slug_93R2P875pWH7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R2P875pWH7Meta?.name ?? "fleets-prefix-slug___uk",
    path: _91slug_93R2P875pWH7Meta?.path ?? "/uk/fleets/:prefix()/:slug()",
    meta: _91slug_93R2P875pWH7Meta || {},
    alias: _91slug_93R2P875pWH7Meta?.alias || [],
    redirect: _91slug_93R2P875pWH7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R2P875pWH7Meta?.name ?? "fleets-prefix-slug___de",
    path: _91slug_93R2P875pWH7Meta?.path ?? "/de/fleets/:prefix()/:slug()",
    meta: _91slug_93R2P875pWH7Meta || {},
    alias: _91slug_93R2P875pWH7Meta?.alias || [],
    redirect: _91slug_93R2P875pWH7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R2P875pWH7Meta?.name ?? "fleets-prefix-slug___mx",
    path: _91slug_93R2P875pWH7Meta?.path ?? "/mx/fleets/:prefix()/:slug()",
    meta: _91slug_93R2P875pWH7Meta || {},
    alias: _91slug_93R2P875pWH7Meta?.alias || [],
    redirect: _91slug_93R2P875pWH7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R2P875pWH7Meta?.name ?? "fleets-prefix-slug___fr",
    path: _91slug_93R2P875pWH7Meta?.path ?? "/fr/fleets/:prefix()/:slug()",
    meta: _91slug_93R2P875pWH7Meta || {},
    alias: _91slug_93R2P875pWH7Meta?.alias || [],
    redirect: _91slug_93R2P875pWH7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93R2P875pWH7Meta?.name ?? "fleets-prefix-slug___fr-ca",
    path: _91slug_93R2P875pWH7Meta?.path ?? "/fr-ca/fleets/:prefix()/:slug()",
    meta: _91slug_93R2P875pWH7Meta || {},
    alias: _91slug_93R2P875pWH7Meta?.alias || [],
    redirect: _91slug_93R2P875pWH7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfSCk1nCK5Meta?.name ?? "fleets-slug___en-US",
    path: _91slug_93hfSCk1nCK5Meta?.path ?? "/fleets/:slug()",
    meta: _91slug_93hfSCk1nCK5Meta || {},
    alias: _91slug_93hfSCk1nCK5Meta?.alias || [],
    redirect: _91slug_93hfSCk1nCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfSCk1nCK5Meta?.name ?? "fleets-slug___ca",
    path: _91slug_93hfSCk1nCK5Meta?.path ?? "/ca/fleets/:slug()",
    meta: _91slug_93hfSCk1nCK5Meta || {},
    alias: _91slug_93hfSCk1nCK5Meta?.alias || [],
    redirect: _91slug_93hfSCk1nCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfSCk1nCK5Meta?.name ?? "fleets-slug___nl",
    path: _91slug_93hfSCk1nCK5Meta?.path ?? "/nl/fleets/:slug()",
    meta: _91slug_93hfSCk1nCK5Meta || {},
    alias: _91slug_93hfSCk1nCK5Meta?.alias || [],
    redirect: _91slug_93hfSCk1nCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfSCk1nCK5Meta?.name ?? "fleets-slug___uk",
    path: _91slug_93hfSCk1nCK5Meta?.path ?? "/uk/fleets/:slug()",
    meta: _91slug_93hfSCk1nCK5Meta || {},
    alias: _91slug_93hfSCk1nCK5Meta?.alias || [],
    redirect: _91slug_93hfSCk1nCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfSCk1nCK5Meta?.name ?? "fleets-slug___de",
    path: _91slug_93hfSCk1nCK5Meta?.path ?? "/de/fleets/:slug()",
    meta: _91slug_93hfSCk1nCK5Meta || {},
    alias: _91slug_93hfSCk1nCK5Meta?.alias || [],
    redirect: _91slug_93hfSCk1nCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfSCk1nCK5Meta?.name ?? "fleets-slug___mx",
    path: _91slug_93hfSCk1nCK5Meta?.path ?? "/mx/fleets/:slug()",
    meta: _91slug_93hfSCk1nCK5Meta || {},
    alias: _91slug_93hfSCk1nCK5Meta?.alias || [],
    redirect: _91slug_93hfSCk1nCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfSCk1nCK5Meta?.name ?? "fleets-slug___fr",
    path: _91slug_93hfSCk1nCK5Meta?.path ?? "/fr/fleets/:slug()",
    meta: _91slug_93hfSCk1nCK5Meta || {},
    alias: _91slug_93hfSCk1nCK5Meta?.alias || [],
    redirect: _91slug_93hfSCk1nCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hfSCk1nCK5Meta?.name ?? "fleets-slug___fr-ca",
    path: _91slug_93hfSCk1nCK5Meta?.path ?? "/fr-ca/fleets/:slug()",
    meta: _91slug_93hfSCk1nCK5Meta || {},
    alias: _91slug_93hfSCk1nCK5Meta?.alias || [],
    redirect: _91slug_93hfSCk1nCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M7OSeyUXZ9Meta?.name ?? "guides-slug___en-US",
    path: _91slug_93M7OSeyUXZ9Meta?.path ?? "/guides/:slug()",
    meta: _91slug_93M7OSeyUXZ9Meta || {},
    alias: _91slug_93M7OSeyUXZ9Meta?.alias || [],
    redirect: _91slug_93M7OSeyUXZ9Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M7OSeyUXZ9Meta?.name ?? "guides-slug___ca",
    path: _91slug_93M7OSeyUXZ9Meta?.path ?? "/ca/guides/:slug()",
    meta: _91slug_93M7OSeyUXZ9Meta || {},
    alias: _91slug_93M7OSeyUXZ9Meta?.alias || [],
    redirect: _91slug_93M7OSeyUXZ9Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M7OSeyUXZ9Meta?.name ?? "guides-slug___nl",
    path: _91slug_93M7OSeyUXZ9Meta?.path ?? "/nl/guides/:slug()",
    meta: _91slug_93M7OSeyUXZ9Meta || {},
    alias: _91slug_93M7OSeyUXZ9Meta?.alias || [],
    redirect: _91slug_93M7OSeyUXZ9Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M7OSeyUXZ9Meta?.name ?? "guides-slug___uk",
    path: _91slug_93M7OSeyUXZ9Meta?.path ?? "/uk/guides/:slug()",
    meta: _91slug_93M7OSeyUXZ9Meta || {},
    alias: _91slug_93M7OSeyUXZ9Meta?.alias || [],
    redirect: _91slug_93M7OSeyUXZ9Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M7OSeyUXZ9Meta?.name ?? "guides-slug___de",
    path: _91slug_93M7OSeyUXZ9Meta?.path ?? "/de/guides/:slug()",
    meta: _91slug_93M7OSeyUXZ9Meta || {},
    alias: _91slug_93M7OSeyUXZ9Meta?.alias || [],
    redirect: _91slug_93M7OSeyUXZ9Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M7OSeyUXZ9Meta?.name ?? "guides-slug___mx",
    path: _91slug_93M7OSeyUXZ9Meta?.path ?? "/mx/guides/:slug()",
    meta: _91slug_93M7OSeyUXZ9Meta || {},
    alias: _91slug_93M7OSeyUXZ9Meta?.alias || [],
    redirect: _91slug_93M7OSeyUXZ9Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M7OSeyUXZ9Meta?.name ?? "guides-slug___fr",
    path: _91slug_93M7OSeyUXZ9Meta?.path ?? "/fr/guides/:slug()",
    meta: _91slug_93M7OSeyUXZ9Meta || {},
    alias: _91slug_93M7OSeyUXZ9Meta?.alias || [],
    redirect: _91slug_93M7OSeyUXZ9Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M7OSeyUXZ9Meta?.name ?? "guides-slug___fr-ca",
    path: _91slug_93M7OSeyUXZ9Meta?.path ?? "/fr-ca/guides/:slug()",
    meta: _91slug_93M7OSeyUXZ9Meta || {},
    alias: _91slug_93M7OSeyUXZ9Meta?.alias || [],
    redirect: _91slug_93M7OSeyUXZ9Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5aStNu196jMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___en-US",
    path: attract_45retain_45talent5aStNu196jMeta?.path ?? "/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5aStNu196jMeta || {},
    alias: attract_45retain_45talent5aStNu196jMeta?.alias || [],
    redirect: attract_45retain_45talent5aStNu196jMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5aStNu196jMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___ca",
    path: attract_45retain_45talent5aStNu196jMeta?.path ?? "/ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5aStNu196jMeta || {},
    alias: attract_45retain_45talent5aStNu196jMeta?.alias || [],
    redirect: attract_45retain_45talent5aStNu196jMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5aStNu196jMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___nl",
    path: attract_45retain_45talent5aStNu196jMeta?.path ?? "/nl/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5aStNu196jMeta || {},
    alias: attract_45retain_45talent5aStNu196jMeta?.alias || [],
    redirect: attract_45retain_45talent5aStNu196jMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5aStNu196jMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___uk",
    path: attract_45retain_45talent5aStNu196jMeta?.path ?? "/uk/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5aStNu196jMeta || {},
    alias: attract_45retain_45talent5aStNu196jMeta?.alias || [],
    redirect: attract_45retain_45talent5aStNu196jMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5aStNu196jMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___de",
    path: attract_45retain_45talent5aStNu196jMeta?.path ?? "/de/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5aStNu196jMeta || {},
    alias: attract_45retain_45talent5aStNu196jMeta?.alias || [],
    redirect: attract_45retain_45talent5aStNu196jMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5aStNu196jMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___mx",
    path: attract_45retain_45talent5aStNu196jMeta?.path ?? "/mx/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5aStNu196jMeta || {},
    alias: attract_45retain_45talent5aStNu196jMeta?.alias || [],
    redirect: attract_45retain_45talent5aStNu196jMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5aStNu196jMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr",
    path: attract_45retain_45talent5aStNu196jMeta?.path ?? "/fr/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5aStNu196jMeta || {},
    alias: attract_45retain_45talent5aStNu196jMeta?.alias || [],
    redirect: attract_45retain_45talent5aStNu196jMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talent5aStNu196jMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr-ca",
    path: attract_45retain_45talent5aStNu196jMeta?.path ?? "/fr-ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talent5aStNu196jMeta || {},
    alias: attract_45retain_45talent5aStNu196jMeta?.alias || [],
    redirect: attract_45retain_45talent5aStNu196jMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___en-US",
    path: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.path ?? "/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta || {},
    alias: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___ca",
    path: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.path ?? "/ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta || {},
    alias: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___nl",
    path: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.path ?? "/nl/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta || {},
    alias: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___uk",
    path: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.path ?? "/uk/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta || {},
    alias: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___de",
    path: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.path ?? "/de/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta || {},
    alias: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___mx",
    path: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.path ?? "/mx/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta || {},
    alias: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr",
    path: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.path ?? "/fr/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta || {},
    alias: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr-ca",
    path: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.path ?? "/fr-ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta || {},
    alias: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitsdzN0I0VvDoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: indexIuOebhAVCFMeta?.name ?? "guides-build-business-resilience___en-US",
    path: indexIuOebhAVCFMeta?.path ?? "/guides/build-business-resilience",
    meta: indexIuOebhAVCFMeta || {},
    alias: indexIuOebhAVCFMeta?.alias || [],
    redirect: indexIuOebhAVCFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIuOebhAVCFMeta?.name ?? "guides-build-business-resilience___ca",
    path: indexIuOebhAVCFMeta?.path ?? "/ca/guides/build-business-resilience",
    meta: indexIuOebhAVCFMeta || {},
    alias: indexIuOebhAVCFMeta?.alias || [],
    redirect: indexIuOebhAVCFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIuOebhAVCFMeta?.name ?? "guides-build-business-resilience___nl",
    path: indexIuOebhAVCFMeta?.path ?? "/nl/guides/build-business-resilience",
    meta: indexIuOebhAVCFMeta || {},
    alias: indexIuOebhAVCFMeta?.alias || [],
    redirect: indexIuOebhAVCFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIuOebhAVCFMeta?.name ?? "guides-build-business-resilience___uk",
    path: indexIuOebhAVCFMeta?.path ?? "/uk/guides/build-business-resilience",
    meta: indexIuOebhAVCFMeta || {},
    alias: indexIuOebhAVCFMeta?.alias || [],
    redirect: indexIuOebhAVCFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIuOebhAVCFMeta?.name ?? "guides-build-business-resilience___de",
    path: indexIuOebhAVCFMeta?.path ?? "/de/guides/build-business-resilience",
    meta: indexIuOebhAVCFMeta || {},
    alias: indexIuOebhAVCFMeta?.alias || [],
    redirect: indexIuOebhAVCFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIuOebhAVCFMeta?.name ?? "guides-build-business-resilience___mx",
    path: indexIuOebhAVCFMeta?.path ?? "/mx/guides/build-business-resilience",
    meta: indexIuOebhAVCFMeta || {},
    alias: indexIuOebhAVCFMeta?.alias || [],
    redirect: indexIuOebhAVCFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIuOebhAVCFMeta?.name ?? "guides-build-business-resilience___fr",
    path: indexIuOebhAVCFMeta?.path ?? "/fr/guides/build-business-resilience",
    meta: indexIuOebhAVCFMeta || {},
    alias: indexIuOebhAVCFMeta?.alias || [],
    redirect: indexIuOebhAVCFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexIuOebhAVCFMeta?.name ?? "guides-build-business-resilience___fr-ca",
    path: indexIuOebhAVCFMeta?.path ?? "/fr-ca/guides/build-business-resilience",
    meta: indexIuOebhAVCFMeta || {},
    alias: indexIuOebhAVCFMeta?.alias || [],
    redirect: indexIuOebhAVCFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsHRIOed6MWfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___en-US",
    path: protect_45optimize_45assetsHRIOed6MWfMeta?.path ?? "/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsHRIOed6MWfMeta || {},
    alias: protect_45optimize_45assetsHRIOed6MWfMeta?.alias || [],
    redirect: protect_45optimize_45assetsHRIOed6MWfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsHRIOed6MWfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___ca",
    path: protect_45optimize_45assetsHRIOed6MWfMeta?.path ?? "/ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsHRIOed6MWfMeta || {},
    alias: protect_45optimize_45assetsHRIOed6MWfMeta?.alias || [],
    redirect: protect_45optimize_45assetsHRIOed6MWfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsHRIOed6MWfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___nl",
    path: protect_45optimize_45assetsHRIOed6MWfMeta?.path ?? "/nl/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsHRIOed6MWfMeta || {},
    alias: protect_45optimize_45assetsHRIOed6MWfMeta?.alias || [],
    redirect: protect_45optimize_45assetsHRIOed6MWfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsHRIOed6MWfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___uk",
    path: protect_45optimize_45assetsHRIOed6MWfMeta?.path ?? "/uk/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsHRIOed6MWfMeta || {},
    alias: protect_45optimize_45assetsHRIOed6MWfMeta?.alias || [],
    redirect: protect_45optimize_45assetsHRIOed6MWfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsHRIOed6MWfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___de",
    path: protect_45optimize_45assetsHRIOed6MWfMeta?.path ?? "/de/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsHRIOed6MWfMeta || {},
    alias: protect_45optimize_45assetsHRIOed6MWfMeta?.alias || [],
    redirect: protect_45optimize_45assetsHRIOed6MWfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsHRIOed6MWfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___mx",
    path: protect_45optimize_45assetsHRIOed6MWfMeta?.path ?? "/mx/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsHRIOed6MWfMeta || {},
    alias: protect_45optimize_45assetsHRIOed6MWfMeta?.alias || [],
    redirect: protect_45optimize_45assetsHRIOed6MWfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsHRIOed6MWfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr",
    path: protect_45optimize_45assetsHRIOed6MWfMeta?.path ?? "/fr/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsHRIOed6MWfMeta || {},
    alias: protect_45optimize_45assetsHRIOed6MWfMeta?.alias || [],
    redirect: protect_45optimize_45assetsHRIOed6MWfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetsHRIOed6MWfMeta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr-ca",
    path: protect_45optimize_45assetsHRIOed6MWfMeta?.path ?? "/fr-ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetsHRIOed6MWfMeta || {},
    alias: protect_45optimize_45assetsHRIOed6MWfMeta?.alias || [],
    redirect: protect_45optimize_45assetsHRIOed6MWfMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsVuY5jvj2U7Meta?.name ?? "guides-fleet-safety-best-dash-cams___en-US",
    path: best_45dash_45camsVuY5jvj2U7Meta?.path ?? "/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsVuY5jvj2U7Meta || {},
    alias: best_45dash_45camsVuY5jvj2U7Meta?.alias || [],
    redirect: best_45dash_45camsVuY5jvj2U7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsVuY5jvj2U7Meta?.name ?? "guides-fleet-safety-best-dash-cams___ca",
    path: best_45dash_45camsVuY5jvj2U7Meta?.path ?? "/ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsVuY5jvj2U7Meta || {},
    alias: best_45dash_45camsVuY5jvj2U7Meta?.alias || [],
    redirect: best_45dash_45camsVuY5jvj2U7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsVuY5jvj2U7Meta?.name ?? "guides-fleet-safety-best-dash-cams___nl",
    path: best_45dash_45camsVuY5jvj2U7Meta?.path ?? "/nl/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsVuY5jvj2U7Meta || {},
    alias: best_45dash_45camsVuY5jvj2U7Meta?.alias || [],
    redirect: best_45dash_45camsVuY5jvj2U7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsVuY5jvj2U7Meta?.name ?? "guides-fleet-safety-best-dash-cams___uk",
    path: best_45dash_45camsVuY5jvj2U7Meta?.path ?? "/uk/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsVuY5jvj2U7Meta || {},
    alias: best_45dash_45camsVuY5jvj2U7Meta?.alias || [],
    redirect: best_45dash_45camsVuY5jvj2U7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsVuY5jvj2U7Meta?.name ?? "guides-fleet-safety-best-dash-cams___de",
    path: best_45dash_45camsVuY5jvj2U7Meta?.path ?? "/de/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsVuY5jvj2U7Meta || {},
    alias: best_45dash_45camsVuY5jvj2U7Meta?.alias || [],
    redirect: best_45dash_45camsVuY5jvj2U7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsVuY5jvj2U7Meta?.name ?? "guides-fleet-safety-best-dash-cams___mx",
    path: best_45dash_45camsVuY5jvj2U7Meta?.path ?? "/mx/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsVuY5jvj2U7Meta || {},
    alias: best_45dash_45camsVuY5jvj2U7Meta?.alias || [],
    redirect: best_45dash_45camsVuY5jvj2U7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsVuY5jvj2U7Meta?.name ?? "guides-fleet-safety-best-dash-cams___fr",
    path: best_45dash_45camsVuY5jvj2U7Meta?.path ?? "/fr/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsVuY5jvj2U7Meta || {},
    alias: best_45dash_45camsVuY5jvj2U7Meta?.alias || [],
    redirect: best_45dash_45camsVuY5jvj2U7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsVuY5jvj2U7Meta?.name ?? "guides-fleet-safety-best-dash-cams___fr-ca",
    path: best_45dash_45camsVuY5jvj2U7Meta?.path ?? "/fr-ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsVuY5jvj2U7Meta || {},
    alias: best_45dash_45camsVuY5jvj2U7Meta?.alias || [],
    redirect: best_45dash_45camsVuY5jvj2U7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: indexQeV9dINNXdMeta?.name ?? "guides-fleet-safety___en-US",
    path: indexQeV9dINNXdMeta?.path ?? "/guides/fleet-safety",
    meta: indexQeV9dINNXdMeta || {},
    alias: indexQeV9dINNXdMeta?.alias || [],
    redirect: indexQeV9dINNXdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeV9dINNXdMeta?.name ?? "guides-fleet-safety___ca",
    path: indexQeV9dINNXdMeta?.path ?? "/ca/guides/fleet-safety",
    meta: indexQeV9dINNXdMeta || {},
    alias: indexQeV9dINNXdMeta?.alias || [],
    redirect: indexQeV9dINNXdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeV9dINNXdMeta?.name ?? "guides-fleet-safety___nl",
    path: indexQeV9dINNXdMeta?.path ?? "/nl/guides/fleet-safety",
    meta: indexQeV9dINNXdMeta || {},
    alias: indexQeV9dINNXdMeta?.alias || [],
    redirect: indexQeV9dINNXdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeV9dINNXdMeta?.name ?? "guides-fleet-safety___uk",
    path: indexQeV9dINNXdMeta?.path ?? "/uk/guides/fleet-safety",
    meta: indexQeV9dINNXdMeta || {},
    alias: indexQeV9dINNXdMeta?.alias || [],
    redirect: indexQeV9dINNXdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeV9dINNXdMeta?.name ?? "guides-fleet-safety___de",
    path: indexQeV9dINNXdMeta?.path ?? "/de/guides/fleet-safety",
    meta: indexQeV9dINNXdMeta || {},
    alias: indexQeV9dINNXdMeta?.alias || [],
    redirect: indexQeV9dINNXdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeV9dINNXdMeta?.name ?? "guides-fleet-safety___mx",
    path: indexQeV9dINNXdMeta?.path ?? "/mx/guias/seguridad-flotas",
    meta: indexQeV9dINNXdMeta || {},
    alias: indexQeV9dINNXdMeta?.alias || [],
    redirect: indexQeV9dINNXdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeV9dINNXdMeta?.name ?? "guides-fleet-safety___fr",
    path: indexQeV9dINNXdMeta?.path ?? "/fr/guides/fleet-safety",
    meta: indexQeV9dINNXdMeta || {},
    alias: indexQeV9dINNXdMeta?.alias || [],
    redirect: indexQeV9dINNXdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexQeV9dINNXdMeta?.name ?? "guides-fleet-safety___fr-ca",
    path: indexQeV9dINNXdMeta?.path ?? "/fr-ca/guides/fleet-safety",
    meta: indexQeV9dINNXdMeta || {},
    alias: indexQeV9dINNXdMeta?.alias || [],
    redirect: indexQeV9dINNXdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: safety_45program2cQd6qupwUMeta?.name ?? "guides-fleet-safety-safety-program___en-US",
    path: safety_45program2cQd6qupwUMeta?.path ?? "/guides/fleet-safety/safety-program",
    meta: safety_45program2cQd6qupwUMeta || {},
    alias: safety_45program2cQd6qupwUMeta?.alias || [],
    redirect: safety_45program2cQd6qupwUMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program2cQd6qupwUMeta?.name ?? "guides-fleet-safety-safety-program___ca",
    path: safety_45program2cQd6qupwUMeta?.path ?? "/ca/guides/fleet-safety/safety-program",
    meta: safety_45program2cQd6qupwUMeta || {},
    alias: safety_45program2cQd6qupwUMeta?.alias || [],
    redirect: safety_45program2cQd6qupwUMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program2cQd6qupwUMeta?.name ?? "guides-fleet-safety-safety-program___nl",
    path: safety_45program2cQd6qupwUMeta?.path ?? "/nl/guides/fleet-safety/safety-program",
    meta: safety_45program2cQd6qupwUMeta || {},
    alias: safety_45program2cQd6qupwUMeta?.alias || [],
    redirect: safety_45program2cQd6qupwUMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program2cQd6qupwUMeta?.name ?? "guides-fleet-safety-safety-program___uk",
    path: safety_45program2cQd6qupwUMeta?.path ?? "/uk/guides/fleet-safety/safety-program",
    meta: safety_45program2cQd6qupwUMeta || {},
    alias: safety_45program2cQd6qupwUMeta?.alias || [],
    redirect: safety_45program2cQd6qupwUMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program2cQd6qupwUMeta?.name ?? "guides-fleet-safety-safety-program___de",
    path: safety_45program2cQd6qupwUMeta?.path ?? "/de/guides/fleet-safety/safety-program",
    meta: safety_45program2cQd6qupwUMeta || {},
    alias: safety_45program2cQd6qupwUMeta?.alias || [],
    redirect: safety_45program2cQd6qupwUMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program2cQd6qupwUMeta?.name ?? "guides-fleet-safety-safety-program___mx",
    path: safety_45program2cQd6qupwUMeta?.path ?? "/mx/guides/fleet-safety/safety-program",
    meta: safety_45program2cQd6qupwUMeta || {},
    alias: safety_45program2cQd6qupwUMeta?.alias || [],
    redirect: safety_45program2cQd6qupwUMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program2cQd6qupwUMeta?.name ?? "guides-fleet-safety-safety-program___fr",
    path: safety_45program2cQd6qupwUMeta?.path ?? "/fr/guides/fleet-safety/safety-program",
    meta: safety_45program2cQd6qupwUMeta || {},
    alias: safety_45program2cQd6qupwUMeta?.alias || [],
    redirect: safety_45program2cQd6qupwUMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45program2cQd6qupwUMeta?.name ?? "guides-fleet-safety-safety-program___fr-ca",
    path: safety_45program2cQd6qupwUMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-program",
    meta: safety_45program2cQd6qupwUMeta || {},
    alias: safety_45program2cQd6qupwUMeta?.alias || [],
    redirect: safety_45program2cQd6qupwUMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45roitCuC6OS2AtMeta?.name ?? "guides-fleet-safety-safety-roi___en-US",
    path: safety_45roitCuC6OS2AtMeta?.path ?? "/guides/fleet-safety/safety-roi",
    meta: safety_45roitCuC6OS2AtMeta || {},
    alias: safety_45roitCuC6OS2AtMeta?.alias || [],
    redirect: safety_45roitCuC6OS2AtMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roitCuC6OS2AtMeta?.name ?? "guides-fleet-safety-safety-roi___ca",
    path: safety_45roitCuC6OS2AtMeta?.path ?? "/ca/guides/fleet-safety/safety-roi",
    meta: safety_45roitCuC6OS2AtMeta || {},
    alias: safety_45roitCuC6OS2AtMeta?.alias || [],
    redirect: safety_45roitCuC6OS2AtMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roitCuC6OS2AtMeta?.name ?? "guides-fleet-safety-safety-roi___nl",
    path: safety_45roitCuC6OS2AtMeta?.path ?? "/nl/guides/fleet-safety/safety-roi",
    meta: safety_45roitCuC6OS2AtMeta || {},
    alias: safety_45roitCuC6OS2AtMeta?.alias || [],
    redirect: safety_45roitCuC6OS2AtMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roitCuC6OS2AtMeta?.name ?? "guides-fleet-safety-safety-roi___uk",
    path: safety_45roitCuC6OS2AtMeta?.path ?? "/uk/guides/fleet-safety/safety-roi",
    meta: safety_45roitCuC6OS2AtMeta || {},
    alias: safety_45roitCuC6OS2AtMeta?.alias || [],
    redirect: safety_45roitCuC6OS2AtMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roitCuC6OS2AtMeta?.name ?? "guides-fleet-safety-safety-roi___de",
    path: safety_45roitCuC6OS2AtMeta?.path ?? "/de/guides/fleet-safety/safety-roi",
    meta: safety_45roitCuC6OS2AtMeta || {},
    alias: safety_45roitCuC6OS2AtMeta?.alias || [],
    redirect: safety_45roitCuC6OS2AtMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roitCuC6OS2AtMeta?.name ?? "guides-fleet-safety-safety-roi___mx",
    path: safety_45roitCuC6OS2AtMeta?.path ?? "/mx/guides/fleet-safety/safety-roi",
    meta: safety_45roitCuC6OS2AtMeta || {},
    alias: safety_45roitCuC6OS2AtMeta?.alias || [],
    redirect: safety_45roitCuC6OS2AtMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roitCuC6OS2AtMeta?.name ?? "guides-fleet-safety-safety-roi___fr",
    path: safety_45roitCuC6OS2AtMeta?.path ?? "/fr/guides/fleet-safety/safety-roi",
    meta: safety_45roitCuC6OS2AtMeta || {},
    alias: safety_45roitCuC6OS2AtMeta?.alias || [],
    redirect: safety_45roitCuC6OS2AtMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roitCuC6OS2AtMeta?.name ?? "guides-fleet-safety-safety-roi___fr-ca",
    path: safety_45roitCuC6OS2AtMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-roi",
    meta: safety_45roitCuC6OS2AtMeta || {},
    alias: safety_45roitCuC6OS2AtMeta?.alias || [],
    redirect: safety_45roitCuC6OS2AtMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: indexIN0MaOnMucMeta?.name ?? "guides___en-US",
    path: indexIN0MaOnMucMeta?.path ?? "/guides",
    meta: indexIN0MaOnMucMeta || {},
    alias: indexIN0MaOnMucMeta?.alias || [],
    redirect: indexIN0MaOnMucMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexIN0MaOnMucMeta?.name ?? "guides___ca",
    path: indexIN0MaOnMucMeta?.path ?? "/ca/guides",
    meta: indexIN0MaOnMucMeta || {},
    alias: indexIN0MaOnMucMeta?.alias || [],
    redirect: indexIN0MaOnMucMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexIN0MaOnMucMeta?.name ?? "guides___nl",
    path: indexIN0MaOnMucMeta?.path ?? "/nl/guides",
    meta: indexIN0MaOnMucMeta || {},
    alias: indexIN0MaOnMucMeta?.alias || [],
    redirect: indexIN0MaOnMucMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexIN0MaOnMucMeta?.name ?? "guides___uk",
    path: indexIN0MaOnMucMeta?.path ?? "/uk/guides",
    meta: indexIN0MaOnMucMeta || {},
    alias: indexIN0MaOnMucMeta?.alias || [],
    redirect: indexIN0MaOnMucMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexIN0MaOnMucMeta?.name ?? "guides___de",
    path: indexIN0MaOnMucMeta?.path ?? "/de/guides",
    meta: indexIN0MaOnMucMeta || {},
    alias: indexIN0MaOnMucMeta?.alias || [],
    redirect: indexIN0MaOnMucMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexIN0MaOnMucMeta?.name ?? "guides___mx",
    path: indexIN0MaOnMucMeta?.path ?? "/mx/guides",
    meta: indexIN0MaOnMucMeta || {},
    alias: indexIN0MaOnMucMeta?.alias || [],
    redirect: indexIN0MaOnMucMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexIN0MaOnMucMeta?.name ?? "guides___fr",
    path: indexIN0MaOnMucMeta?.path ?? "/fr/guides",
    meta: indexIN0MaOnMucMeta || {},
    alias: indexIN0MaOnMucMeta?.alias || [],
    redirect: indexIN0MaOnMucMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexIN0MaOnMucMeta?.name ?? "guides___fr-ca",
    path: indexIN0MaOnMucMeta?.path ?? "/fr-ca/guides",
    meta: indexIN0MaOnMucMeta || {},
    alias: indexIN0MaOnMucMeta?.alias || [],
    redirect: indexIN0MaOnMucMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureaTFEKZ8IShMeta?.name ?? "guides-make-more-possible-build-for-the-future___en-US",
    path: build_45for_45the_45futureaTFEKZ8IShMeta?.path ?? "/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureaTFEKZ8IShMeta || {},
    alias: build_45for_45the_45futureaTFEKZ8IShMeta?.alias || [],
    redirect: build_45for_45the_45futureaTFEKZ8IShMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureaTFEKZ8IShMeta?.name ?? "guides-make-more-possible-build-for-the-future___ca",
    path: build_45for_45the_45futureaTFEKZ8IShMeta?.path ?? "/ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureaTFEKZ8IShMeta || {},
    alias: build_45for_45the_45futureaTFEKZ8IShMeta?.alias || [],
    redirect: build_45for_45the_45futureaTFEKZ8IShMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureaTFEKZ8IShMeta?.name ?? "guides-make-more-possible-build-for-the-future___nl",
    path: build_45for_45the_45futureaTFEKZ8IShMeta?.path ?? "/nl/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureaTFEKZ8IShMeta || {},
    alias: build_45for_45the_45futureaTFEKZ8IShMeta?.alias || [],
    redirect: build_45for_45the_45futureaTFEKZ8IShMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureaTFEKZ8IShMeta?.name ?? "guides-make-more-possible-build-for-the-future___uk",
    path: build_45for_45the_45futureaTFEKZ8IShMeta?.path ?? "/uk/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureaTFEKZ8IShMeta || {},
    alias: build_45for_45the_45futureaTFEKZ8IShMeta?.alias || [],
    redirect: build_45for_45the_45futureaTFEKZ8IShMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureaTFEKZ8IShMeta?.name ?? "guides-make-more-possible-build-for-the-future___de",
    path: build_45for_45the_45futureaTFEKZ8IShMeta?.path ?? "/de/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureaTFEKZ8IShMeta || {},
    alias: build_45for_45the_45futureaTFEKZ8IShMeta?.alias || [],
    redirect: build_45for_45the_45futureaTFEKZ8IShMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureaTFEKZ8IShMeta?.name ?? "guides-make-more-possible-build-for-the-future___mx",
    path: build_45for_45the_45futureaTFEKZ8IShMeta?.path ?? "/mx/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureaTFEKZ8IShMeta || {},
    alias: build_45for_45the_45futureaTFEKZ8IShMeta?.alias || [],
    redirect: build_45for_45the_45futureaTFEKZ8IShMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureaTFEKZ8IShMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr",
    path: build_45for_45the_45futureaTFEKZ8IShMeta?.path ?? "/fr/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureaTFEKZ8IShMeta || {},
    alias: build_45for_45the_45futureaTFEKZ8IShMeta?.alias || [],
    redirect: build_45for_45the_45futureaTFEKZ8IShMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureaTFEKZ8IShMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr-ca",
    path: build_45for_45the_45futureaTFEKZ8IShMeta?.path ?? "/fr-ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureaTFEKZ8IShMeta || {},
    alias: build_45for_45the_45futureaTFEKZ8IShMeta?.alias || [],
    redirect: build_45for_45the_45futureaTFEKZ8IShMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsZnT5Z5cMMTMeta?.name ?? "guides-make-more-possible-empower-your-teams___en-US",
    path: empower_45your_45teamsZnT5Z5cMMTMeta?.path ?? "/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsZnT5Z5cMMTMeta || {},
    alias: empower_45your_45teamsZnT5Z5cMMTMeta?.alias || [],
    redirect: empower_45your_45teamsZnT5Z5cMMTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsZnT5Z5cMMTMeta?.name ?? "guides-make-more-possible-empower-your-teams___ca",
    path: empower_45your_45teamsZnT5Z5cMMTMeta?.path ?? "/ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsZnT5Z5cMMTMeta || {},
    alias: empower_45your_45teamsZnT5Z5cMMTMeta?.alias || [],
    redirect: empower_45your_45teamsZnT5Z5cMMTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsZnT5Z5cMMTMeta?.name ?? "guides-make-more-possible-empower-your-teams___nl",
    path: empower_45your_45teamsZnT5Z5cMMTMeta?.path ?? "/nl/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsZnT5Z5cMMTMeta || {},
    alias: empower_45your_45teamsZnT5Z5cMMTMeta?.alias || [],
    redirect: empower_45your_45teamsZnT5Z5cMMTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsZnT5Z5cMMTMeta?.name ?? "guides-make-more-possible-empower-your-teams___uk",
    path: empower_45your_45teamsZnT5Z5cMMTMeta?.path ?? "/uk/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsZnT5Z5cMMTMeta || {},
    alias: empower_45your_45teamsZnT5Z5cMMTMeta?.alias || [],
    redirect: empower_45your_45teamsZnT5Z5cMMTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsZnT5Z5cMMTMeta?.name ?? "guides-make-more-possible-empower-your-teams___de",
    path: empower_45your_45teamsZnT5Z5cMMTMeta?.path ?? "/de/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsZnT5Z5cMMTMeta || {},
    alias: empower_45your_45teamsZnT5Z5cMMTMeta?.alias || [],
    redirect: empower_45your_45teamsZnT5Z5cMMTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsZnT5Z5cMMTMeta?.name ?? "guides-make-more-possible-empower-your-teams___mx",
    path: empower_45your_45teamsZnT5Z5cMMTMeta?.path ?? "/mx/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsZnT5Z5cMMTMeta || {},
    alias: empower_45your_45teamsZnT5Z5cMMTMeta?.alias || [],
    redirect: empower_45your_45teamsZnT5Z5cMMTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsZnT5Z5cMMTMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr",
    path: empower_45your_45teamsZnT5Z5cMMTMeta?.path ?? "/fr/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsZnT5Z5cMMTMeta || {},
    alias: empower_45your_45teamsZnT5Z5cMMTMeta?.alias || [],
    redirect: empower_45your_45teamsZnT5Z5cMMTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsZnT5Z5cMMTMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr-ca",
    path: empower_45your_45teamsZnT5Z5cMMTMeta?.path ?? "/fr-ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsZnT5Z5cMMTMeta || {},
    alias: empower_45your_45teamsZnT5Z5cMMTMeta?.alias || [],
    redirect: empower_45your_45teamsZnT5Z5cMMTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: indexb2SG6bpjLDMeta?.name ?? "guides-make-more-possible___en-US",
    path: indexb2SG6bpjLDMeta?.path ?? "/guides/make-more-possible",
    meta: indexb2SG6bpjLDMeta || {},
    alias: indexb2SG6bpjLDMeta?.alias || [],
    redirect: indexb2SG6bpjLDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexb2SG6bpjLDMeta?.name ?? "guides-make-more-possible___ca",
    path: indexb2SG6bpjLDMeta?.path ?? "/ca/guides/make-more-possible",
    meta: indexb2SG6bpjLDMeta || {},
    alias: indexb2SG6bpjLDMeta?.alias || [],
    redirect: indexb2SG6bpjLDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexb2SG6bpjLDMeta?.name ?? "guides-make-more-possible___nl",
    path: indexb2SG6bpjLDMeta?.path ?? "/nl/guides/make-more-possible",
    meta: indexb2SG6bpjLDMeta || {},
    alias: indexb2SG6bpjLDMeta?.alias || [],
    redirect: indexb2SG6bpjLDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexb2SG6bpjLDMeta?.name ?? "guides-make-more-possible___uk",
    path: indexb2SG6bpjLDMeta?.path ?? "/uk/guides/make-more-possible",
    meta: indexb2SG6bpjLDMeta || {},
    alias: indexb2SG6bpjLDMeta?.alias || [],
    redirect: indexb2SG6bpjLDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexb2SG6bpjLDMeta?.name ?? "guides-make-more-possible___de",
    path: indexb2SG6bpjLDMeta?.path ?? "/de/guides/make-more-possible",
    meta: indexb2SG6bpjLDMeta || {},
    alias: indexb2SG6bpjLDMeta?.alias || [],
    redirect: indexb2SG6bpjLDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexb2SG6bpjLDMeta?.name ?? "guides-make-more-possible___mx",
    path: indexb2SG6bpjLDMeta?.path ?? "/mx/guides/make-more-possible",
    meta: indexb2SG6bpjLDMeta || {},
    alias: indexb2SG6bpjLDMeta?.alias || [],
    redirect: indexb2SG6bpjLDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexb2SG6bpjLDMeta?.name ?? "guides-make-more-possible___fr",
    path: indexb2SG6bpjLDMeta?.path ?? "/fr/guides/make-more-possible",
    meta: indexb2SG6bpjLDMeta || {},
    alias: indexb2SG6bpjLDMeta?.alias || [],
    redirect: indexb2SG6bpjLDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexb2SG6bpjLDMeta?.name ?? "guides-make-more-possible___fr-ca",
    path: indexb2SG6bpjLDMeta?.path ?? "/fr-ca/guides/make-more-possible",
    meta: indexb2SG6bpjLDMeta || {},
    alias: indexb2SG6bpjLDMeta?.alias || [],
    redirect: indexb2SG6bpjLDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataZ1WhrOaJ2OMeta?.name ?? "guides-make-more-possible-take-action-with-data___en-US",
    path: take_45action_45with_45dataZ1WhrOaJ2OMeta?.path ?? "/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataZ1WhrOaJ2OMeta || {},
    alias: take_45action_45with_45dataZ1WhrOaJ2OMeta?.alias || [],
    redirect: take_45action_45with_45dataZ1WhrOaJ2OMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataZ1WhrOaJ2OMeta?.name ?? "guides-make-more-possible-take-action-with-data___ca",
    path: take_45action_45with_45dataZ1WhrOaJ2OMeta?.path ?? "/ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataZ1WhrOaJ2OMeta || {},
    alias: take_45action_45with_45dataZ1WhrOaJ2OMeta?.alias || [],
    redirect: take_45action_45with_45dataZ1WhrOaJ2OMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataZ1WhrOaJ2OMeta?.name ?? "guides-make-more-possible-take-action-with-data___nl",
    path: take_45action_45with_45dataZ1WhrOaJ2OMeta?.path ?? "/nl/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataZ1WhrOaJ2OMeta || {},
    alias: take_45action_45with_45dataZ1WhrOaJ2OMeta?.alias || [],
    redirect: take_45action_45with_45dataZ1WhrOaJ2OMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataZ1WhrOaJ2OMeta?.name ?? "guides-make-more-possible-take-action-with-data___uk",
    path: take_45action_45with_45dataZ1WhrOaJ2OMeta?.path ?? "/uk/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataZ1WhrOaJ2OMeta || {},
    alias: take_45action_45with_45dataZ1WhrOaJ2OMeta?.alias || [],
    redirect: take_45action_45with_45dataZ1WhrOaJ2OMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataZ1WhrOaJ2OMeta?.name ?? "guides-make-more-possible-take-action-with-data___de",
    path: take_45action_45with_45dataZ1WhrOaJ2OMeta?.path ?? "/de/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataZ1WhrOaJ2OMeta || {},
    alias: take_45action_45with_45dataZ1WhrOaJ2OMeta?.alias || [],
    redirect: take_45action_45with_45dataZ1WhrOaJ2OMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataZ1WhrOaJ2OMeta?.name ?? "guides-make-more-possible-take-action-with-data___mx",
    path: take_45action_45with_45dataZ1WhrOaJ2OMeta?.path ?? "/mx/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataZ1WhrOaJ2OMeta || {},
    alias: take_45action_45with_45dataZ1WhrOaJ2OMeta?.alias || [],
    redirect: take_45action_45with_45dataZ1WhrOaJ2OMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataZ1WhrOaJ2OMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr",
    path: take_45action_45with_45dataZ1WhrOaJ2OMeta?.path ?? "/fr/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataZ1WhrOaJ2OMeta || {},
    alias: take_45action_45with_45dataZ1WhrOaJ2OMeta?.alias || [],
    redirect: take_45action_45with_45dataZ1WhrOaJ2OMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataZ1WhrOaJ2OMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr-ca",
    path: take_45action_45with_45dataZ1WhrOaJ2OMeta?.path ?? "/fr-ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataZ1WhrOaJ2OMeta || {},
    alias: take_45action_45with_45dataZ1WhrOaJ2OMeta?.alias || [],
    redirect: take_45action_45with_45dataZ1WhrOaJ2OMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p4ENIHYD9eMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___en-US",
    path: _91slug_93p4ENIHYD9eMeta?.path ?? "/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93p4ENIHYD9eMeta || {},
    alias: _91slug_93p4ENIHYD9eMeta?.alias || [],
    redirect: _91slug_93p4ENIHYD9eMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p4ENIHYD9eMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___ca",
    path: _91slug_93p4ENIHYD9eMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93p4ENIHYD9eMeta || {},
    alias: _91slug_93p4ENIHYD9eMeta?.alias || [],
    redirect: _91slug_93p4ENIHYD9eMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p4ENIHYD9eMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___nl",
    path: _91slug_93p4ENIHYD9eMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93p4ENIHYD9eMeta || {},
    alias: _91slug_93p4ENIHYD9eMeta?.alias || [],
    redirect: _91slug_93p4ENIHYD9eMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p4ENIHYD9eMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___uk",
    path: _91slug_93p4ENIHYD9eMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93p4ENIHYD9eMeta || {},
    alias: _91slug_93p4ENIHYD9eMeta?.alias || [],
    redirect: _91slug_93p4ENIHYD9eMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p4ENIHYD9eMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___de",
    path: _91slug_93p4ENIHYD9eMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93p4ENIHYD9eMeta || {},
    alias: _91slug_93p4ENIHYD9eMeta?.alias || [],
    redirect: _91slug_93p4ENIHYD9eMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p4ENIHYD9eMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___mx",
    path: _91slug_93p4ENIHYD9eMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93p4ENIHYD9eMeta || {},
    alias: _91slug_93p4ENIHYD9eMeta?.alias || [],
    redirect: _91slug_93p4ENIHYD9eMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p4ENIHYD9eMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr",
    path: _91slug_93p4ENIHYD9eMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93p4ENIHYD9eMeta || {},
    alias: _91slug_93p4ENIHYD9eMeta?.alias || [],
    redirect: _91slug_93p4ENIHYD9eMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p4ENIHYD9eMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr-ca",
    path: _91slug_93p4ENIHYD9eMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93p4ENIHYD9eMeta || {},
    alias: _91slug_93p4ENIHYD9eMeta?.alias || [],
    redirect: _91slug_93p4ENIHYD9eMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: index2qBF2twsBYMeta?.name ?? "guides-your-partner-for-the-road-ahead___en-US",
    path: index2qBF2twsBYMeta?.path ?? "/guides/your-partner-for-the-road-ahead",
    meta: index2qBF2twsBYMeta || {},
    alias: index2qBF2twsBYMeta?.alias || [],
    redirect: index2qBF2twsBYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2qBF2twsBYMeta?.name ?? "guides-your-partner-for-the-road-ahead___ca",
    path: index2qBF2twsBYMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead",
    meta: index2qBF2twsBYMeta || {},
    alias: index2qBF2twsBYMeta?.alias || [],
    redirect: index2qBF2twsBYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2qBF2twsBYMeta?.name ?? "guides-your-partner-for-the-road-ahead___nl",
    path: index2qBF2twsBYMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead",
    meta: index2qBF2twsBYMeta || {},
    alias: index2qBF2twsBYMeta?.alias || [],
    redirect: index2qBF2twsBYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2qBF2twsBYMeta?.name ?? "guides-your-partner-for-the-road-ahead___uk",
    path: index2qBF2twsBYMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead",
    meta: index2qBF2twsBYMeta || {},
    alias: index2qBF2twsBYMeta?.alias || [],
    redirect: index2qBF2twsBYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2qBF2twsBYMeta?.name ?? "guides-your-partner-for-the-road-ahead___de",
    path: index2qBF2twsBYMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead",
    meta: index2qBF2twsBYMeta || {},
    alias: index2qBF2twsBYMeta?.alias || [],
    redirect: index2qBF2twsBYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2qBF2twsBYMeta?.name ?? "guides-your-partner-for-the-road-ahead___mx",
    path: index2qBF2twsBYMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead",
    meta: index2qBF2twsBYMeta || {},
    alias: index2qBF2twsBYMeta?.alias || [],
    redirect: index2qBF2twsBYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2qBF2twsBYMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr",
    path: index2qBF2twsBYMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead",
    meta: index2qBF2twsBYMeta || {},
    alias: index2qBF2twsBYMeta?.alias || [],
    redirect: index2qBF2twsBYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: index2qBF2twsBYMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr-ca",
    path: index2qBF2twsBYMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead",
    meta: index2qBF2twsBYMeta || {},
    alias: index2qBF2twsBYMeta?.alias || [],
    redirect: index2qBF2twsBYMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexalh9RmALNPMeta?.name ?? "index___en-US",
    path: indexalh9RmALNPMeta?.path ?? "/",
    meta: indexalh9RmALNPMeta || {},
    alias: indexalh9RmALNPMeta?.alias || [],
    redirect: indexalh9RmALNPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexalh9RmALNPMeta?.name ?? "index___ca",
    path: indexalh9RmALNPMeta?.path ?? "/ca",
    meta: indexalh9RmALNPMeta || {},
    alias: indexalh9RmALNPMeta?.alias || [],
    redirect: indexalh9RmALNPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexalh9RmALNPMeta?.name ?? "index___nl",
    path: indexalh9RmALNPMeta?.path ?? "/nl",
    meta: indexalh9RmALNPMeta || {},
    alias: indexalh9RmALNPMeta?.alias || [],
    redirect: indexalh9RmALNPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexalh9RmALNPMeta?.name ?? "index___uk",
    path: indexalh9RmALNPMeta?.path ?? "/uk",
    meta: indexalh9RmALNPMeta || {},
    alias: indexalh9RmALNPMeta?.alias || [],
    redirect: indexalh9RmALNPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexalh9RmALNPMeta?.name ?? "index___de",
    path: indexalh9RmALNPMeta?.path ?? "/de",
    meta: indexalh9RmALNPMeta || {},
    alias: indexalh9RmALNPMeta?.alias || [],
    redirect: indexalh9RmALNPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexalh9RmALNPMeta?.name ?? "index___mx",
    path: indexalh9RmALNPMeta?.path ?? "/mx",
    meta: indexalh9RmALNPMeta || {},
    alias: indexalh9RmALNPMeta?.alias || [],
    redirect: indexalh9RmALNPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexalh9RmALNPMeta?.name ?? "index___fr",
    path: indexalh9RmALNPMeta?.path ?? "/fr",
    meta: indexalh9RmALNPMeta || {},
    alias: indexalh9RmALNPMeta?.alias || [],
    redirect: indexalh9RmALNPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexalh9RmALNPMeta?.name ?? "index___fr-ca",
    path: indexalh9RmALNPMeta?.path ?? "/fr-ca",
    meta: indexalh9RmALNPMeta || {},
    alias: indexalh9RmALNPMeta?.alias || [],
    redirect: indexalh9RmALNPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_933JIzcnfnngMeta?.name ?? "industrial-slug___en-US",
    path: _91slug_933JIzcnfnngMeta?.path ?? "/industrial/:slug()",
    meta: _91slug_933JIzcnfnngMeta || {},
    alias: _91slug_933JIzcnfnngMeta?.alias || [],
    redirect: _91slug_933JIzcnfnngMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933JIzcnfnngMeta?.name ?? "industrial-slug___ca",
    path: _91slug_933JIzcnfnngMeta?.path ?? "/ca/industrial/:slug()",
    meta: _91slug_933JIzcnfnngMeta || {},
    alias: _91slug_933JIzcnfnngMeta?.alias || [],
    redirect: _91slug_933JIzcnfnngMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933JIzcnfnngMeta?.name ?? "industrial-slug___nl",
    path: _91slug_933JIzcnfnngMeta?.path ?? "/nl/industrial/:slug()",
    meta: _91slug_933JIzcnfnngMeta || {},
    alias: _91slug_933JIzcnfnngMeta?.alias || [],
    redirect: _91slug_933JIzcnfnngMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933JIzcnfnngMeta?.name ?? "industrial-slug___uk",
    path: _91slug_933JIzcnfnngMeta?.path ?? "/uk/industrial/:slug()",
    meta: _91slug_933JIzcnfnngMeta || {},
    alias: _91slug_933JIzcnfnngMeta?.alias || [],
    redirect: _91slug_933JIzcnfnngMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933JIzcnfnngMeta?.name ?? "industrial-slug___de",
    path: _91slug_933JIzcnfnngMeta?.path ?? "/de/industrial/:slug()",
    meta: _91slug_933JIzcnfnngMeta || {},
    alias: _91slug_933JIzcnfnngMeta?.alias || [],
    redirect: _91slug_933JIzcnfnngMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933JIzcnfnngMeta?.name ?? "industrial-slug___mx",
    path: _91slug_933JIzcnfnngMeta?.path ?? "/mx/industrial/:slug()",
    meta: _91slug_933JIzcnfnngMeta || {},
    alias: _91slug_933JIzcnfnngMeta?.alias || [],
    redirect: _91slug_933JIzcnfnngMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933JIzcnfnngMeta?.name ?? "industrial-slug___fr",
    path: _91slug_933JIzcnfnngMeta?.path ?? "/fr/industrial/:slug()",
    meta: _91slug_933JIzcnfnngMeta || {},
    alias: _91slug_933JIzcnfnngMeta?.alias || [],
    redirect: _91slug_933JIzcnfnngMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933JIzcnfnngMeta?.name ?? "industrial-slug___fr-ca",
    path: _91slug_933JIzcnfnngMeta?.path ?? "/fr-ca/industrial/:slug()",
    meta: _91slug_933JIzcnfnngMeta || {},
    alias: _91slug_933JIzcnfnngMeta?.alias || [],
    redirect: _91slug_933JIzcnfnngMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VqcQSa5o7GMeta?.name ?? "industries-slug___en-US",
    path: _91slug_93VqcQSa5o7GMeta?.path ?? "/industries/:slug()",
    meta: _91slug_93VqcQSa5o7GMeta || {},
    alias: _91slug_93VqcQSa5o7GMeta?.alias || [],
    redirect: _91slug_93VqcQSa5o7GMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VqcQSa5o7GMeta?.name ?? "industries-slug___ca",
    path: _91slug_93VqcQSa5o7GMeta?.path ?? "/ca/industries/:slug()",
    meta: _91slug_93VqcQSa5o7GMeta || {},
    alias: _91slug_93VqcQSa5o7GMeta?.alias || [],
    redirect: _91slug_93VqcQSa5o7GMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VqcQSa5o7GMeta?.name ?? "industries-slug___nl",
    path: _91slug_93VqcQSa5o7GMeta?.path ?? "/nl/industries/:slug()",
    meta: _91slug_93VqcQSa5o7GMeta || {},
    alias: _91slug_93VqcQSa5o7GMeta?.alias || [],
    redirect: _91slug_93VqcQSa5o7GMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VqcQSa5o7GMeta?.name ?? "industries-slug___uk",
    path: _91slug_93VqcQSa5o7GMeta?.path ?? "/uk/industries/:slug()",
    meta: _91slug_93VqcQSa5o7GMeta || {},
    alias: _91slug_93VqcQSa5o7GMeta?.alias || [],
    redirect: _91slug_93VqcQSa5o7GMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VqcQSa5o7GMeta?.name ?? "industries-slug___de",
    path: _91slug_93VqcQSa5o7GMeta?.path ?? "/de/industries/:slug()",
    meta: _91slug_93VqcQSa5o7GMeta || {},
    alias: _91slug_93VqcQSa5o7GMeta?.alias || [],
    redirect: _91slug_93VqcQSa5o7GMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VqcQSa5o7GMeta?.name ?? "industries-slug___mx",
    path: _91slug_93VqcQSa5o7GMeta?.path ?? "/mx/industries/:slug()",
    meta: _91slug_93VqcQSa5o7GMeta || {},
    alias: _91slug_93VqcQSa5o7GMeta?.alias || [],
    redirect: _91slug_93VqcQSa5o7GMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VqcQSa5o7GMeta?.name ?? "industries-slug___fr",
    path: _91slug_93VqcQSa5o7GMeta?.path ?? "/fr/industries/:slug()",
    meta: _91slug_93VqcQSa5o7GMeta || {},
    alias: _91slug_93VqcQSa5o7GMeta?.alias || [],
    redirect: _91slug_93VqcQSa5o7GMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VqcQSa5o7GMeta?.name ?? "industries-slug___fr-ca",
    path: _91slug_93VqcQSa5o7GMeta?.path ?? "/fr-ca/industries/:slug()",
    meta: _91slug_93VqcQSa5o7GMeta || {},
    alias: _91slug_93VqcQSa5o7GMeta?.alias || [],
    redirect: _91slug_93VqcQSa5o7GMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexwSK7PC3y1WMeta?.name ?? "industries___en-US",
    path: indexwSK7PC3y1WMeta?.path ?? "/industries",
    meta: indexwSK7PC3y1WMeta || {},
    alias: indexwSK7PC3y1WMeta?.alias || [],
    redirect: indexwSK7PC3y1WMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexwSK7PC3y1WMeta?.name ?? "industries___ca",
    path: indexwSK7PC3y1WMeta?.path ?? "/ca/industries",
    meta: indexwSK7PC3y1WMeta || {},
    alias: indexwSK7PC3y1WMeta?.alias || [],
    redirect: indexwSK7PC3y1WMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexwSK7PC3y1WMeta?.name ?? "industries___nl",
    path: indexwSK7PC3y1WMeta?.path ?? "/nl/industries",
    meta: indexwSK7PC3y1WMeta || {},
    alias: indexwSK7PC3y1WMeta?.alias || [],
    redirect: indexwSK7PC3y1WMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexwSK7PC3y1WMeta?.name ?? "industries___uk",
    path: indexwSK7PC3y1WMeta?.path ?? "/uk/industries",
    meta: indexwSK7PC3y1WMeta || {},
    alias: indexwSK7PC3y1WMeta?.alias || [],
    redirect: indexwSK7PC3y1WMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexwSK7PC3y1WMeta?.name ?? "industries___de",
    path: indexwSK7PC3y1WMeta?.path ?? "/de/industries",
    meta: indexwSK7PC3y1WMeta || {},
    alias: indexwSK7PC3y1WMeta?.alias || [],
    redirect: indexwSK7PC3y1WMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexwSK7PC3y1WMeta?.name ?? "industries___mx",
    path: indexwSK7PC3y1WMeta?.path ?? "/mx/industries",
    meta: indexwSK7PC3y1WMeta || {},
    alias: indexwSK7PC3y1WMeta?.alias || [],
    redirect: indexwSK7PC3y1WMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexwSK7PC3y1WMeta?.name ?? "industries___fr",
    path: indexwSK7PC3y1WMeta?.path ?? "/fr/industries",
    meta: indexwSK7PC3y1WMeta || {},
    alias: indexwSK7PC3y1WMeta?.alias || [],
    redirect: indexwSK7PC3y1WMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexwSK7PC3y1WMeta?.name ?? "industries___fr-ca",
    path: indexwSK7PC3y1WMeta?.path ?? "/fr-ca/industries",
    meta: indexwSK7PC3y1WMeta || {},
    alias: indexwSK7PC3y1WMeta?.alias || [],
    redirect: indexwSK7PC3y1WMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: citiesVtVLa8AZuhMeta?.name ?? "insights-cities___en-US",
    path: citiesVtVLa8AZuhMeta?.path ?? "/insights/cities",
    meta: citiesVtVLa8AZuhMeta || {},
    alias: citiesVtVLa8AZuhMeta?.alias || [],
    redirect: citiesVtVLa8AZuhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesVtVLa8AZuhMeta?.name ?? "insights-cities___ca",
    path: citiesVtVLa8AZuhMeta?.path ?? "/ca/insights/cities",
    meta: citiesVtVLa8AZuhMeta || {},
    alias: citiesVtVLa8AZuhMeta?.alias || [],
    redirect: citiesVtVLa8AZuhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesVtVLa8AZuhMeta?.name ?? "insights-cities___nl",
    path: citiesVtVLa8AZuhMeta?.path ?? "/nl/insights/cities",
    meta: citiesVtVLa8AZuhMeta || {},
    alias: citiesVtVLa8AZuhMeta?.alias || [],
    redirect: citiesVtVLa8AZuhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesVtVLa8AZuhMeta?.name ?? "insights-cities___uk",
    path: citiesVtVLa8AZuhMeta?.path ?? "/uk/insights/cities",
    meta: citiesVtVLa8AZuhMeta || {},
    alias: citiesVtVLa8AZuhMeta?.alias || [],
    redirect: citiesVtVLa8AZuhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesVtVLa8AZuhMeta?.name ?? "insights-cities___de",
    path: citiesVtVLa8AZuhMeta?.path ?? "/de/insights/cities",
    meta: citiesVtVLa8AZuhMeta || {},
    alias: citiesVtVLa8AZuhMeta?.alias || [],
    redirect: citiesVtVLa8AZuhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesVtVLa8AZuhMeta?.name ?? "insights-cities___mx",
    path: citiesVtVLa8AZuhMeta?.path ?? "/mx/insights/cities",
    meta: citiesVtVLa8AZuhMeta || {},
    alias: citiesVtVLa8AZuhMeta?.alias || [],
    redirect: citiesVtVLa8AZuhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesVtVLa8AZuhMeta?.name ?? "insights-cities___fr",
    path: citiesVtVLa8AZuhMeta?.path ?? "/fr/insights/cities",
    meta: citiesVtVLa8AZuhMeta || {},
    alias: citiesVtVLa8AZuhMeta?.alias || [],
    redirect: citiesVtVLa8AZuhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiesVtVLa8AZuhMeta?.name ?? "insights-cities___fr-ca",
    path: citiesVtVLa8AZuhMeta?.path ?? "/fr-ca/insights/cities",
    meta: citiesVtVLa8AZuhMeta || {},
    alias: citiesVtVLa8AZuhMeta?.alias || [],
    redirect: citiesVtVLa8AZuhMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: index88Px7Y9byqMeta?.name ?? "integrations___en-US",
    path: index88Px7Y9byqMeta?.path ?? "/integrations",
    meta: index88Px7Y9byqMeta || {},
    alias: index88Px7Y9byqMeta?.alias || [],
    redirect: index88Px7Y9byqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index88Px7Y9byqMeta?.name ?? "integrations___ca",
    path: index88Px7Y9byqMeta?.path ?? "/ca/integrations",
    meta: index88Px7Y9byqMeta || {},
    alias: index88Px7Y9byqMeta?.alias || [],
    redirect: index88Px7Y9byqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index88Px7Y9byqMeta?.name ?? "integrations___nl",
    path: index88Px7Y9byqMeta?.path ?? "/nl/integrations",
    meta: index88Px7Y9byqMeta || {},
    alias: index88Px7Y9byqMeta?.alias || [],
    redirect: index88Px7Y9byqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index88Px7Y9byqMeta?.name ?? "integrations___uk",
    path: index88Px7Y9byqMeta?.path ?? "/uk/integrations",
    meta: index88Px7Y9byqMeta || {},
    alias: index88Px7Y9byqMeta?.alias || [],
    redirect: index88Px7Y9byqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index88Px7Y9byqMeta?.name ?? "integrations___de",
    path: index88Px7Y9byqMeta?.path ?? "/de/integrations",
    meta: index88Px7Y9byqMeta || {},
    alias: index88Px7Y9byqMeta?.alias || [],
    redirect: index88Px7Y9byqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index88Px7Y9byqMeta?.name ?? "integrations___mx",
    path: index88Px7Y9byqMeta?.path ?? "/mx/integrations",
    meta: index88Px7Y9byqMeta || {},
    alias: index88Px7Y9byqMeta?.alias || [],
    redirect: index88Px7Y9byqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index88Px7Y9byqMeta?.name ?? "integrations___fr",
    path: index88Px7Y9byqMeta?.path ?? "/fr/integrations",
    meta: index88Px7Y9byqMeta || {},
    alias: index88Px7Y9byqMeta?.alias || [],
    redirect: index88Px7Y9byqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index88Px7Y9byqMeta?.name ?? "integrations___fr-ca",
    path: index88Px7Y9byqMeta?.path ?? "/fr-ca/integrations",
    meta: index88Px7Y9byqMeta || {},
    alias: index88Px7Y9byqMeta?.alias || [],
    redirect: index88Px7Y9byqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index94ewhCimenMeta?.name ?? "invite___en-US",
    path: index94ewhCimenMeta?.path ?? "/invite",
    meta: index94ewhCimenMeta || {},
    alias: index94ewhCimenMeta?.alias || [],
    redirect: index94ewhCimenMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index94ewhCimenMeta?.name ?? "invite___ca",
    path: index94ewhCimenMeta?.path ?? "/ca/invite",
    meta: index94ewhCimenMeta || {},
    alias: index94ewhCimenMeta?.alias || [],
    redirect: index94ewhCimenMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index94ewhCimenMeta?.name ?? "invite___nl",
    path: index94ewhCimenMeta?.path ?? "/nl/invite",
    meta: index94ewhCimenMeta || {},
    alias: index94ewhCimenMeta?.alias || [],
    redirect: index94ewhCimenMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index94ewhCimenMeta?.name ?? "invite___uk",
    path: index94ewhCimenMeta?.path ?? "/uk/invite",
    meta: index94ewhCimenMeta || {},
    alias: index94ewhCimenMeta?.alias || [],
    redirect: index94ewhCimenMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index94ewhCimenMeta?.name ?? "invite___de",
    path: index94ewhCimenMeta?.path ?? "/de/invite",
    meta: index94ewhCimenMeta || {},
    alias: index94ewhCimenMeta?.alias || [],
    redirect: index94ewhCimenMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index94ewhCimenMeta?.name ?? "invite___mx",
    path: index94ewhCimenMeta?.path ?? "/mx/invite",
    meta: index94ewhCimenMeta || {},
    alias: index94ewhCimenMeta?.alias || [],
    redirect: index94ewhCimenMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index94ewhCimenMeta?.name ?? "invite___fr",
    path: index94ewhCimenMeta?.path ?? "/fr/invite",
    meta: index94ewhCimenMeta || {},
    alias: index94ewhCimenMeta?.alias || [],
    redirect: index94ewhCimenMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index94ewhCimenMeta?.name ?? "invite___fr-ca",
    path: index94ewhCimenMeta?.path ?? "/fr-ca/invite",
    meta: index94ewhCimenMeta || {},
    alias: index94ewhCimenMeta?.alias || [],
    redirect: index94ewhCimenMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticezwf9Xb6E0MMeta?.name ?? "legal-candidate-privacy-notice___en-US",
    path: candidate_45privacy_45noticezwf9Xb6E0MMeta?.path ?? "/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticezwf9Xb6E0MMeta || {},
    alias: candidate_45privacy_45noticezwf9Xb6E0MMeta?.alias || [],
    redirect: candidate_45privacy_45noticezwf9Xb6E0MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticezwf9Xb6E0MMeta?.name ?? "legal-candidate-privacy-notice___ca",
    path: candidate_45privacy_45noticezwf9Xb6E0MMeta?.path ?? "/ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticezwf9Xb6E0MMeta || {},
    alias: candidate_45privacy_45noticezwf9Xb6E0MMeta?.alias || [],
    redirect: candidate_45privacy_45noticezwf9Xb6E0MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticezwf9Xb6E0MMeta?.name ?? "legal-candidate-privacy-notice___nl",
    path: candidate_45privacy_45noticezwf9Xb6E0MMeta?.path ?? "/nl/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticezwf9Xb6E0MMeta || {},
    alias: candidate_45privacy_45noticezwf9Xb6E0MMeta?.alias || [],
    redirect: candidate_45privacy_45noticezwf9Xb6E0MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticezwf9Xb6E0MMeta?.name ?? "legal-candidate-privacy-notice___uk",
    path: candidate_45privacy_45noticezwf9Xb6E0MMeta?.path ?? "/uk/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticezwf9Xb6E0MMeta || {},
    alias: candidate_45privacy_45noticezwf9Xb6E0MMeta?.alias || [],
    redirect: candidate_45privacy_45noticezwf9Xb6E0MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticezwf9Xb6E0MMeta?.name ?? "legal-candidate-privacy-notice___de",
    path: candidate_45privacy_45noticezwf9Xb6E0MMeta?.path ?? "/de/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticezwf9Xb6E0MMeta || {},
    alias: candidate_45privacy_45noticezwf9Xb6E0MMeta?.alias || [],
    redirect: candidate_45privacy_45noticezwf9Xb6E0MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticezwf9Xb6E0MMeta?.name ?? "legal-candidate-privacy-notice___mx",
    path: candidate_45privacy_45noticezwf9Xb6E0MMeta?.path ?? "/mx/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticezwf9Xb6E0MMeta || {},
    alias: candidate_45privacy_45noticezwf9Xb6E0MMeta?.alias || [],
    redirect: candidate_45privacy_45noticezwf9Xb6E0MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticezwf9Xb6E0MMeta?.name ?? "legal-candidate-privacy-notice___fr",
    path: candidate_45privacy_45noticezwf9Xb6E0MMeta?.path ?? "/fr/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticezwf9Xb6E0MMeta || {},
    alias: candidate_45privacy_45noticezwf9Xb6E0MMeta?.alias || [],
    redirect: candidate_45privacy_45noticezwf9Xb6E0MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticezwf9Xb6E0MMeta?.name ?? "legal-candidate-privacy-notice___fr-ca",
    path: candidate_45privacy_45noticezwf9Xb6E0MMeta?.path ?? "/fr-ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticezwf9Xb6E0MMeta || {},
    alias: candidate_45privacy_45noticezwf9Xb6E0MMeta?.alias || [],
    redirect: candidate_45privacy_45noticezwf9Xb6E0MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttB95NwMRn1Meta?.name ?? "legal-cookie-consent___en-US",
    path: cookie_45consenttB95NwMRn1Meta?.path ?? "/legal/cookie-consent",
    meta: cookie_45consenttB95NwMRn1Meta || {},
    alias: cookie_45consenttB95NwMRn1Meta?.alias || [],
    redirect: cookie_45consenttB95NwMRn1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttB95NwMRn1Meta?.name ?? "legal-cookie-consent___ca",
    path: cookie_45consenttB95NwMRn1Meta?.path ?? "/ca/legal/cookie-consent",
    meta: cookie_45consenttB95NwMRn1Meta || {},
    alias: cookie_45consenttB95NwMRn1Meta?.alias || [],
    redirect: cookie_45consenttB95NwMRn1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttB95NwMRn1Meta?.name ?? "legal-cookie-consent___nl",
    path: cookie_45consenttB95NwMRn1Meta?.path ?? "/nl/legal/cookie-consent",
    meta: cookie_45consenttB95NwMRn1Meta || {},
    alias: cookie_45consenttB95NwMRn1Meta?.alias || [],
    redirect: cookie_45consenttB95NwMRn1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttB95NwMRn1Meta?.name ?? "legal-cookie-consent___uk",
    path: cookie_45consenttB95NwMRn1Meta?.path ?? "/uk/legal/cookie-consent",
    meta: cookie_45consenttB95NwMRn1Meta || {},
    alias: cookie_45consenttB95NwMRn1Meta?.alias || [],
    redirect: cookie_45consenttB95NwMRn1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttB95NwMRn1Meta?.name ?? "legal-cookie-consent___de",
    path: cookie_45consenttB95NwMRn1Meta?.path ?? "/de/legal/cookie-consent",
    meta: cookie_45consenttB95NwMRn1Meta || {},
    alias: cookie_45consenttB95NwMRn1Meta?.alias || [],
    redirect: cookie_45consenttB95NwMRn1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttB95NwMRn1Meta?.name ?? "legal-cookie-consent___mx",
    path: cookie_45consenttB95NwMRn1Meta?.path ?? "/mx/legal/cookie-consent",
    meta: cookie_45consenttB95NwMRn1Meta || {},
    alias: cookie_45consenttB95NwMRn1Meta?.alias || [],
    redirect: cookie_45consenttB95NwMRn1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttB95NwMRn1Meta?.name ?? "legal-cookie-consent___fr",
    path: cookie_45consenttB95NwMRn1Meta?.path ?? "/fr/legal/cookie-consent",
    meta: cookie_45consenttB95NwMRn1Meta || {},
    alias: cookie_45consenttB95NwMRn1Meta?.alias || [],
    redirect: cookie_45consenttB95NwMRn1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consenttB95NwMRn1Meta?.name ?? "legal-cookie-consent___fr-ca",
    path: cookie_45consenttB95NwMRn1Meta?.path ?? "/fr-ca/legal/cookie-consent",
    meta: cookie_45consenttB95NwMRn1Meta || {},
    alias: cookie_45consenttB95NwMRn1Meta?.alias || [],
    redirect: cookie_45consenttB95NwMRn1Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyzOd5Ca0IdQMeta?.name ?? "legal-cookie-policy___en-US",
    path: cookie_45policyzOd5Ca0IdQMeta?.path ?? "/legal/cookie-policy",
    meta: cookie_45policyzOd5Ca0IdQMeta || {},
    alias: cookie_45policyzOd5Ca0IdQMeta?.alias || [],
    redirect: cookie_45policyzOd5Ca0IdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyzOd5Ca0IdQMeta?.name ?? "legal-cookie-policy___ca",
    path: cookie_45policyzOd5Ca0IdQMeta?.path ?? "/ca/legal/cookie-policy",
    meta: cookie_45policyzOd5Ca0IdQMeta || {},
    alias: cookie_45policyzOd5Ca0IdQMeta?.alias || [],
    redirect: cookie_45policyzOd5Ca0IdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyzOd5Ca0IdQMeta?.name ?? "legal-cookie-policy___nl",
    path: cookie_45policyzOd5Ca0IdQMeta?.path ?? "/nl/legal/cookie-policy",
    meta: cookie_45policyzOd5Ca0IdQMeta || {},
    alias: cookie_45policyzOd5Ca0IdQMeta?.alias || [],
    redirect: cookie_45policyzOd5Ca0IdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyzOd5Ca0IdQMeta?.name ?? "legal-cookie-policy___uk",
    path: cookie_45policyzOd5Ca0IdQMeta?.path ?? "/uk/legal/cookie-policy",
    meta: cookie_45policyzOd5Ca0IdQMeta || {},
    alias: cookie_45policyzOd5Ca0IdQMeta?.alias || [],
    redirect: cookie_45policyzOd5Ca0IdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyzOd5Ca0IdQMeta?.name ?? "legal-cookie-policy___de",
    path: cookie_45policyzOd5Ca0IdQMeta?.path ?? "/de/legal/cookie-policy",
    meta: cookie_45policyzOd5Ca0IdQMeta || {},
    alias: cookie_45policyzOd5Ca0IdQMeta?.alias || [],
    redirect: cookie_45policyzOd5Ca0IdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyzOd5Ca0IdQMeta?.name ?? "legal-cookie-policy___mx",
    path: cookie_45policyzOd5Ca0IdQMeta?.path ?? "/mx/legal/cookie-policy",
    meta: cookie_45policyzOd5Ca0IdQMeta || {},
    alias: cookie_45policyzOd5Ca0IdQMeta?.alias || [],
    redirect: cookie_45policyzOd5Ca0IdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyzOd5Ca0IdQMeta?.name ?? "legal-cookie-policy___fr",
    path: cookie_45policyzOd5Ca0IdQMeta?.path ?? "/fr/legal/cookie-policy",
    meta: cookie_45policyzOd5Ca0IdQMeta || {},
    alias: cookie_45policyzOd5Ca0IdQMeta?.alias || [],
    redirect: cookie_45policyzOd5Ca0IdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyzOd5Ca0IdQMeta?.name ?? "legal-cookie-policy___fr-ca",
    path: cookie_45policyzOd5Ca0IdQMeta?.path ?? "/fr-ca/legal/cookie-policy",
    meta: cookie_45policyzOd5Ca0IdQMeta || {},
    alias: cookie_45policyzOd5Ca0IdQMeta?.alias || [],
    redirect: cookie_45policyzOd5Ca0IdQMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumnKoRDpFlXTMeta?.name ?? "legal-data-protection-addendum___en-US",
    path: data_45protection_45addendumnKoRDpFlXTMeta?.path ?? "/legal/data-protection-addendum",
    meta: data_45protection_45addendumnKoRDpFlXTMeta || {},
    alias: data_45protection_45addendumnKoRDpFlXTMeta?.alias || [],
    redirect: data_45protection_45addendumnKoRDpFlXTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumnKoRDpFlXTMeta?.name ?? "legal-data-protection-addendum___ca",
    path: data_45protection_45addendumnKoRDpFlXTMeta?.path ?? "/ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumnKoRDpFlXTMeta || {},
    alias: data_45protection_45addendumnKoRDpFlXTMeta?.alias || [],
    redirect: data_45protection_45addendumnKoRDpFlXTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumnKoRDpFlXTMeta?.name ?? "legal-data-protection-addendum___nl",
    path: data_45protection_45addendumnKoRDpFlXTMeta?.path ?? "/nl/legal/data-protection-addendum",
    meta: data_45protection_45addendumnKoRDpFlXTMeta || {},
    alias: data_45protection_45addendumnKoRDpFlXTMeta?.alias || [],
    redirect: data_45protection_45addendumnKoRDpFlXTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumnKoRDpFlXTMeta?.name ?? "legal-data-protection-addendum___uk",
    path: data_45protection_45addendumnKoRDpFlXTMeta?.path ?? "/uk/legal/data-protection-addendum",
    meta: data_45protection_45addendumnKoRDpFlXTMeta || {},
    alias: data_45protection_45addendumnKoRDpFlXTMeta?.alias || [],
    redirect: data_45protection_45addendumnKoRDpFlXTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumnKoRDpFlXTMeta?.name ?? "legal-data-protection-addendum___de",
    path: data_45protection_45addendumnKoRDpFlXTMeta?.path ?? "/de/legal/data-protection-addendum",
    meta: data_45protection_45addendumnKoRDpFlXTMeta || {},
    alias: data_45protection_45addendumnKoRDpFlXTMeta?.alias || [],
    redirect: data_45protection_45addendumnKoRDpFlXTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumnKoRDpFlXTMeta?.name ?? "legal-data-protection-addendum___mx",
    path: data_45protection_45addendumnKoRDpFlXTMeta?.path ?? "/mx/legal/data-protection-addendum",
    meta: data_45protection_45addendumnKoRDpFlXTMeta || {},
    alias: data_45protection_45addendumnKoRDpFlXTMeta?.alias || [],
    redirect: data_45protection_45addendumnKoRDpFlXTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumnKoRDpFlXTMeta?.name ?? "legal-data-protection-addendum___fr",
    path: data_45protection_45addendumnKoRDpFlXTMeta?.path ?? "/fr/legal/data-protection-addendum",
    meta: data_45protection_45addendumnKoRDpFlXTMeta || {},
    alias: data_45protection_45addendumnKoRDpFlXTMeta?.alias || [],
    redirect: data_45protection_45addendumnKoRDpFlXTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumnKoRDpFlXTMeta?.name ?? "legal-data-protection-addendum___fr-ca",
    path: data_45protection_45addendumnKoRDpFlXTMeta?.path ?? "/fr-ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumnKoRDpFlXTMeta || {},
    alias: data_45protection_45addendumnKoRDpFlXTMeta?.alias || [],
    redirect: data_45protection_45addendumnKoRDpFlXTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnAjpxOVv1XMeta?.name ?? "legal-express-order-terms___en-US",
    path: express_45order_45termsnAjpxOVv1XMeta?.path ?? "/legal/express-order-terms",
    meta: express_45order_45termsnAjpxOVv1XMeta || {},
    alias: express_45order_45termsnAjpxOVv1XMeta?.alias || [],
    redirect: express_45order_45termsnAjpxOVv1XMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnAjpxOVv1XMeta?.name ?? "legal-express-order-terms___ca",
    path: express_45order_45termsnAjpxOVv1XMeta?.path ?? "/ca/legal/express-order-terms",
    meta: express_45order_45termsnAjpxOVv1XMeta || {},
    alias: express_45order_45termsnAjpxOVv1XMeta?.alias || [],
    redirect: express_45order_45termsnAjpxOVv1XMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnAjpxOVv1XMeta?.name ?? "legal-express-order-terms___nl",
    path: express_45order_45termsnAjpxOVv1XMeta?.path ?? "/nl/legal/express-order-terms",
    meta: express_45order_45termsnAjpxOVv1XMeta || {},
    alias: express_45order_45termsnAjpxOVv1XMeta?.alias || [],
    redirect: express_45order_45termsnAjpxOVv1XMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnAjpxOVv1XMeta?.name ?? "legal-express-order-terms___uk",
    path: express_45order_45termsnAjpxOVv1XMeta?.path ?? "/uk/legal/express-order-terms",
    meta: express_45order_45termsnAjpxOVv1XMeta || {},
    alias: express_45order_45termsnAjpxOVv1XMeta?.alias || [],
    redirect: express_45order_45termsnAjpxOVv1XMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnAjpxOVv1XMeta?.name ?? "legal-express-order-terms___de",
    path: express_45order_45termsnAjpxOVv1XMeta?.path ?? "/de/legal/express-order-terms",
    meta: express_45order_45termsnAjpxOVv1XMeta || {},
    alias: express_45order_45termsnAjpxOVv1XMeta?.alias || [],
    redirect: express_45order_45termsnAjpxOVv1XMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnAjpxOVv1XMeta?.name ?? "legal-express-order-terms___mx",
    path: express_45order_45termsnAjpxOVv1XMeta?.path ?? "/mx/legal/express-order-terms",
    meta: express_45order_45termsnAjpxOVv1XMeta || {},
    alias: express_45order_45termsnAjpxOVv1XMeta?.alias || [],
    redirect: express_45order_45termsnAjpxOVv1XMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnAjpxOVv1XMeta?.name ?? "legal-express-order-terms___fr",
    path: express_45order_45termsnAjpxOVv1XMeta?.path ?? "/fr/legal/express-order-terms",
    meta: express_45order_45termsnAjpxOVv1XMeta || {},
    alias: express_45order_45termsnAjpxOVv1XMeta?.alias || [],
    redirect: express_45order_45termsnAjpxOVv1XMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsnAjpxOVv1XMeta?.name ?? "legal-express-order-terms___fr-ca",
    path: express_45order_45termsnAjpxOVv1XMeta?.path ?? "/fr-ca/legal/express-order-terms",
    meta: express_45order_45termsnAjpxOVv1XMeta || {},
    alias: express_45order_45termsnAjpxOVv1XMeta?.alias || [],
    redirect: express_45order_45termsnAjpxOVv1XMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantydWceAHhtj0Meta?.name ?? "legal-hardware-warranty___en-US",
    path: hardware_45warrantydWceAHhtj0Meta?.path ?? "/legal/hardware-warranty",
    meta: hardware_45warrantydWceAHhtj0Meta || {},
    alias: hardware_45warrantydWceAHhtj0Meta?.alias || [],
    redirect: hardware_45warrantydWceAHhtj0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantydWceAHhtj0Meta?.name ?? "legal-hardware-warranty___ca",
    path: hardware_45warrantydWceAHhtj0Meta?.path ?? "/ca/legal/hardware-warranty",
    meta: hardware_45warrantydWceAHhtj0Meta || {},
    alias: hardware_45warrantydWceAHhtj0Meta?.alias || [],
    redirect: hardware_45warrantydWceAHhtj0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantydWceAHhtj0Meta?.name ?? "legal-hardware-warranty___nl",
    path: hardware_45warrantydWceAHhtj0Meta?.path ?? "/nl/legal/hardware-warranty",
    meta: hardware_45warrantydWceAHhtj0Meta || {},
    alias: hardware_45warrantydWceAHhtj0Meta?.alias || [],
    redirect: hardware_45warrantydWceAHhtj0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantydWceAHhtj0Meta?.name ?? "legal-hardware-warranty___uk",
    path: hardware_45warrantydWceAHhtj0Meta?.path ?? "/uk/legal/hardware-warranty",
    meta: hardware_45warrantydWceAHhtj0Meta || {},
    alias: hardware_45warrantydWceAHhtj0Meta?.alias || [],
    redirect: hardware_45warrantydWceAHhtj0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantydWceAHhtj0Meta?.name ?? "legal-hardware-warranty___de",
    path: hardware_45warrantydWceAHhtj0Meta?.path ?? "/de/legal/hardware-warranty",
    meta: hardware_45warrantydWceAHhtj0Meta || {},
    alias: hardware_45warrantydWceAHhtj0Meta?.alias || [],
    redirect: hardware_45warrantydWceAHhtj0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantydWceAHhtj0Meta?.name ?? "legal-hardware-warranty___mx",
    path: hardware_45warrantydWceAHhtj0Meta?.path ?? "/mx/legal/hardware-warranty",
    meta: hardware_45warrantydWceAHhtj0Meta || {},
    alias: hardware_45warrantydWceAHhtj0Meta?.alias || [],
    redirect: hardware_45warrantydWceAHhtj0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantydWceAHhtj0Meta?.name ?? "legal-hardware-warranty___fr",
    path: hardware_45warrantydWceAHhtj0Meta?.path ?? "/fr/legal/hardware-warranty",
    meta: hardware_45warrantydWceAHhtj0Meta || {},
    alias: hardware_45warrantydWceAHhtj0Meta?.alias || [],
    redirect: hardware_45warrantydWceAHhtj0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantydWceAHhtj0Meta?.name ?? "legal-hardware-warranty___fr-ca",
    path: hardware_45warrantydWceAHhtj0Meta?.path ?? "/fr-ca/legal/hardware-warranty",
    meta: hardware_45warrantydWceAHhtj0Meta || {},
    alias: hardware_45warrantydWceAHhtj0Meta?.alias || [],
    redirect: hardware_45warrantydWceAHhtj0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3kBkz0EIiOMeta?.name ?? "legal-hosted-software-sla___en-US",
    path: hosted_45software_45sla3kBkz0EIiOMeta?.path ?? "/legal/hosted-software-sla",
    meta: hosted_45software_45sla3kBkz0EIiOMeta || {},
    alias: hosted_45software_45sla3kBkz0EIiOMeta?.alias || [],
    redirect: hosted_45software_45sla3kBkz0EIiOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3kBkz0EIiOMeta?.name ?? "legal-hosted-software-sla___ca",
    path: hosted_45software_45sla3kBkz0EIiOMeta?.path ?? "/ca/legal/hosted-software-sla",
    meta: hosted_45software_45sla3kBkz0EIiOMeta || {},
    alias: hosted_45software_45sla3kBkz0EIiOMeta?.alias || [],
    redirect: hosted_45software_45sla3kBkz0EIiOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3kBkz0EIiOMeta?.name ?? "legal-hosted-software-sla___nl",
    path: hosted_45software_45sla3kBkz0EIiOMeta?.path ?? "/nl/legal/hosted-software-sla",
    meta: hosted_45software_45sla3kBkz0EIiOMeta || {},
    alias: hosted_45software_45sla3kBkz0EIiOMeta?.alias || [],
    redirect: hosted_45software_45sla3kBkz0EIiOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3kBkz0EIiOMeta?.name ?? "legal-hosted-software-sla___uk",
    path: hosted_45software_45sla3kBkz0EIiOMeta?.path ?? "/uk/legal/hosted-software-sla",
    meta: hosted_45software_45sla3kBkz0EIiOMeta || {},
    alias: hosted_45software_45sla3kBkz0EIiOMeta?.alias || [],
    redirect: hosted_45software_45sla3kBkz0EIiOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3kBkz0EIiOMeta?.name ?? "legal-hosted-software-sla___de",
    path: hosted_45software_45sla3kBkz0EIiOMeta?.path ?? "/de/legal/hosted-software-sla",
    meta: hosted_45software_45sla3kBkz0EIiOMeta || {},
    alias: hosted_45software_45sla3kBkz0EIiOMeta?.alias || [],
    redirect: hosted_45software_45sla3kBkz0EIiOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3kBkz0EIiOMeta?.name ?? "legal-hosted-software-sla___mx",
    path: hosted_45software_45sla3kBkz0EIiOMeta?.path ?? "/mx/legal/hosted-software-sla",
    meta: hosted_45software_45sla3kBkz0EIiOMeta || {},
    alias: hosted_45software_45sla3kBkz0EIiOMeta?.alias || [],
    redirect: hosted_45software_45sla3kBkz0EIiOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3kBkz0EIiOMeta?.name ?? "legal-hosted-software-sla___fr",
    path: hosted_45software_45sla3kBkz0EIiOMeta?.path ?? "/fr/legal/hosted-software-sla",
    meta: hosted_45software_45sla3kBkz0EIiOMeta || {},
    alias: hosted_45software_45sla3kBkz0EIiOMeta?.alias || [],
    redirect: hosted_45software_45sla3kBkz0EIiOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla3kBkz0EIiOMeta?.name ?? "legal-hosted-software-sla___fr-ca",
    path: hosted_45software_45sla3kBkz0EIiOMeta?.path ?? "/fr-ca/legal/hosted-software-sla",
    meta: hosted_45software_45sla3kBkz0EIiOMeta || {},
    alias: hosted_45software_45sla3kBkz0EIiOMeta?.alias || [],
    redirect: hosted_45software_45sla3kBkz0EIiOMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIomJhqSPSjMeta?.name ?? "legal-partner-code-of-conduct___en-US",
    path: partner_45code_45of_45conductIomJhqSPSjMeta?.path ?? "/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIomJhqSPSjMeta || {},
    alias: partner_45code_45of_45conductIomJhqSPSjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIomJhqSPSjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIomJhqSPSjMeta?.name ?? "legal-partner-code-of-conduct___ca",
    path: partner_45code_45of_45conductIomJhqSPSjMeta?.path ?? "/ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIomJhqSPSjMeta || {},
    alias: partner_45code_45of_45conductIomJhqSPSjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIomJhqSPSjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIomJhqSPSjMeta?.name ?? "legal-partner-code-of-conduct___nl",
    path: partner_45code_45of_45conductIomJhqSPSjMeta?.path ?? "/nl/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIomJhqSPSjMeta || {},
    alias: partner_45code_45of_45conductIomJhqSPSjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIomJhqSPSjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIomJhqSPSjMeta?.name ?? "legal-partner-code-of-conduct___uk",
    path: partner_45code_45of_45conductIomJhqSPSjMeta?.path ?? "/uk/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIomJhqSPSjMeta || {},
    alias: partner_45code_45of_45conductIomJhqSPSjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIomJhqSPSjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIomJhqSPSjMeta?.name ?? "legal-partner-code-of-conduct___de",
    path: partner_45code_45of_45conductIomJhqSPSjMeta?.path ?? "/de/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIomJhqSPSjMeta || {},
    alias: partner_45code_45of_45conductIomJhqSPSjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIomJhqSPSjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIomJhqSPSjMeta?.name ?? "legal-partner-code-of-conduct___mx",
    path: partner_45code_45of_45conductIomJhqSPSjMeta?.path ?? "/mx/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIomJhqSPSjMeta || {},
    alias: partner_45code_45of_45conductIomJhqSPSjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIomJhqSPSjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIomJhqSPSjMeta?.name ?? "legal-partner-code-of-conduct___fr",
    path: partner_45code_45of_45conductIomJhqSPSjMeta?.path ?? "/fr/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIomJhqSPSjMeta || {},
    alias: partner_45code_45of_45conductIomJhqSPSjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIomJhqSPSjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductIomJhqSPSjMeta?.name ?? "legal-partner-code-of-conduct___fr-ca",
    path: partner_45code_45of_45conductIomJhqSPSjMeta?.path ?? "/fr-ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductIomJhqSPSjMeta || {},
    alias: partner_45code_45of_45conductIomJhqSPSjMeta?.alias || [],
    redirect: partner_45code_45of_45conductIomJhqSPSjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.name ?? "legal-platform-terms-of-service___en-US",
    path: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.path ?? "/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceu9PVbuTb8UMeta || {},
    alias: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.name ?? "legal-platform-terms-of-service___ca",
    path: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.path ?? "/ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceu9PVbuTb8UMeta || {},
    alias: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.name ?? "legal-platform-terms-of-service___nl",
    path: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.path ?? "/nl/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceu9PVbuTb8UMeta || {},
    alias: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.name ?? "legal-platform-terms-of-service___uk",
    path: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.path ?? "/uk/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceu9PVbuTb8UMeta || {},
    alias: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.name ?? "legal-platform-terms-of-service___de",
    path: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.path ?? "/de/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceu9PVbuTb8UMeta || {},
    alias: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.name ?? "legal-platform-terms-of-service___mx",
    path: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.path ?? "/mx/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceu9PVbuTb8UMeta || {},
    alias: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.name ?? "legal-platform-terms-of-service___fr",
    path: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.path ?? "/fr/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceu9PVbuTb8UMeta || {},
    alias: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.name ?? "legal-platform-terms-of-service___fr-ca",
    path: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.path ?? "/fr-ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceu9PVbuTb8UMeta || {},
    alias: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceu9PVbuTb8UMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: privacyLmAyzafMhNMeta?.name ?? "legal-privacy___en-US",
    path: privacyLmAyzafMhNMeta?.path ?? "/legal/privacy",
    meta: privacyLmAyzafMhNMeta || {},
    alias: privacyLmAyzafMhNMeta?.alias || [],
    redirect: privacyLmAyzafMhNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyLmAyzafMhNMeta?.name ?? "legal-privacy___ca",
    path: privacyLmAyzafMhNMeta?.path ?? "/ca/legal/privacy",
    meta: privacyLmAyzafMhNMeta || {},
    alias: privacyLmAyzafMhNMeta?.alias || [],
    redirect: privacyLmAyzafMhNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyLmAyzafMhNMeta?.name ?? "legal-privacy___nl",
    path: privacyLmAyzafMhNMeta?.path ?? "/nl/legal/privacy",
    meta: privacyLmAyzafMhNMeta || {},
    alias: privacyLmAyzafMhNMeta?.alias || [],
    redirect: privacyLmAyzafMhNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyLmAyzafMhNMeta?.name ?? "legal-privacy___uk",
    path: privacyLmAyzafMhNMeta?.path ?? "/uk/legal/privacy",
    meta: privacyLmAyzafMhNMeta || {},
    alias: privacyLmAyzafMhNMeta?.alias || [],
    redirect: privacyLmAyzafMhNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyLmAyzafMhNMeta?.name ?? "legal-privacy___de",
    path: privacyLmAyzafMhNMeta?.path ?? "/de/legal/privacy",
    meta: privacyLmAyzafMhNMeta || {},
    alias: privacyLmAyzafMhNMeta?.alias || [],
    redirect: privacyLmAyzafMhNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyLmAyzafMhNMeta?.name ?? "legal-privacy___mx",
    path: privacyLmAyzafMhNMeta?.path ?? "/mx/legal/privacy",
    meta: privacyLmAyzafMhNMeta || {},
    alias: privacyLmAyzafMhNMeta?.alias || [],
    redirect: privacyLmAyzafMhNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyLmAyzafMhNMeta?.name ?? "legal-privacy___fr",
    path: privacyLmAyzafMhNMeta?.path ?? "/fr/legal/privacy",
    meta: privacyLmAyzafMhNMeta || {},
    alias: privacyLmAyzafMhNMeta?.alias || [],
    redirect: privacyLmAyzafMhNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyLmAyzafMhNMeta?.name ?? "legal-privacy___fr-ca",
    path: privacyLmAyzafMhNMeta?.path ?? "/fr-ca/legal/privacy",
    meta: privacyLmAyzafMhNMeta || {},
    alias: privacyLmAyzafMhNMeta?.alias || [],
    redirect: privacyLmAyzafMhNMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___en-US",
    path: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.path ?? "/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___ca",
    path: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.path ?? "/ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___nl",
    path: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.path ?? "/nl/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___uk",
    path: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.path ?? "/uk/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___de",
    path: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.path ?? "/de/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___mx",
    path: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.path ?? "/mx/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr",
    path: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.path ?? "/fr/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr-ca",
    path: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.path ?? "/fr-ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45servicedV0x741A6rMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatawpRYJXoRQyMeta?.name ?? "legal-requestingcustomerdata___en-US",
    path: requestingcustomerdatawpRYJXoRQyMeta?.path ?? "/legal/requestingcustomerdata",
    meta: requestingcustomerdatawpRYJXoRQyMeta || {},
    alias: requestingcustomerdatawpRYJXoRQyMeta?.alias || [],
    redirect: requestingcustomerdatawpRYJXoRQyMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatawpRYJXoRQyMeta?.name ?? "legal-requestingcustomerdata___ca",
    path: requestingcustomerdatawpRYJXoRQyMeta?.path ?? "/ca/legal/requestingcustomerdata",
    meta: requestingcustomerdatawpRYJXoRQyMeta || {},
    alias: requestingcustomerdatawpRYJXoRQyMeta?.alias || [],
    redirect: requestingcustomerdatawpRYJXoRQyMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatawpRYJXoRQyMeta?.name ?? "legal-requestingcustomerdata___nl",
    path: requestingcustomerdatawpRYJXoRQyMeta?.path ?? "/nl/legal/requestingcustomerdata",
    meta: requestingcustomerdatawpRYJXoRQyMeta || {},
    alias: requestingcustomerdatawpRYJXoRQyMeta?.alias || [],
    redirect: requestingcustomerdatawpRYJXoRQyMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatawpRYJXoRQyMeta?.name ?? "legal-requestingcustomerdata___uk",
    path: requestingcustomerdatawpRYJXoRQyMeta?.path ?? "/uk/legal/requestingcustomerdata",
    meta: requestingcustomerdatawpRYJXoRQyMeta || {},
    alias: requestingcustomerdatawpRYJXoRQyMeta?.alias || [],
    redirect: requestingcustomerdatawpRYJXoRQyMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatawpRYJXoRQyMeta?.name ?? "legal-requestingcustomerdata___de",
    path: requestingcustomerdatawpRYJXoRQyMeta?.path ?? "/de/legal/requestingcustomerdata",
    meta: requestingcustomerdatawpRYJXoRQyMeta || {},
    alias: requestingcustomerdatawpRYJXoRQyMeta?.alias || [],
    redirect: requestingcustomerdatawpRYJXoRQyMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatawpRYJXoRQyMeta?.name ?? "legal-requestingcustomerdata___mx",
    path: requestingcustomerdatawpRYJXoRQyMeta?.path ?? "/mx/legal/requestingcustomerdata",
    meta: requestingcustomerdatawpRYJXoRQyMeta || {},
    alias: requestingcustomerdatawpRYJXoRQyMeta?.alias || [],
    redirect: requestingcustomerdatawpRYJXoRQyMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatawpRYJXoRQyMeta?.name ?? "legal-requestingcustomerdata___fr",
    path: requestingcustomerdatawpRYJXoRQyMeta?.path ?? "/fr/legal/requestingcustomerdata",
    meta: requestingcustomerdatawpRYJXoRQyMeta || {},
    alias: requestingcustomerdatawpRYJXoRQyMeta?.alias || [],
    redirect: requestingcustomerdatawpRYJXoRQyMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdatawpRYJXoRQyMeta?.name ?? "legal-requestingcustomerdata___fr-ca",
    path: requestingcustomerdatawpRYJXoRQyMeta?.path ?? "/fr-ca/legal/requestingcustomerdata",
    meta: requestingcustomerdatawpRYJXoRQyMeta || {},
    alias: requestingcustomerdatawpRYJXoRQyMeta?.alias || [],
    redirect: requestingcustomerdatawpRYJXoRQyMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: securityjbWkAIvo8VMeta?.name ?? "legal-security___en-US",
    path: securityjbWkAIvo8VMeta?.path ?? "/legal/security",
    meta: securityjbWkAIvo8VMeta || {},
    alias: securityjbWkAIvo8VMeta?.alias || [],
    redirect: securityjbWkAIvo8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityjbWkAIvo8VMeta?.name ?? "legal-security___ca",
    path: securityjbWkAIvo8VMeta?.path ?? "/ca/legal/security",
    meta: securityjbWkAIvo8VMeta || {},
    alias: securityjbWkAIvo8VMeta?.alias || [],
    redirect: securityjbWkAIvo8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityjbWkAIvo8VMeta?.name ?? "legal-security___nl",
    path: securityjbWkAIvo8VMeta?.path ?? "/nl/legal/security",
    meta: securityjbWkAIvo8VMeta || {},
    alias: securityjbWkAIvo8VMeta?.alias || [],
    redirect: securityjbWkAIvo8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityjbWkAIvo8VMeta?.name ?? "legal-security___uk",
    path: securityjbWkAIvo8VMeta?.path ?? "/uk/legal/security",
    meta: securityjbWkAIvo8VMeta || {},
    alias: securityjbWkAIvo8VMeta?.alias || [],
    redirect: securityjbWkAIvo8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityjbWkAIvo8VMeta?.name ?? "legal-security___de",
    path: securityjbWkAIvo8VMeta?.path ?? "/de/legal/security",
    meta: securityjbWkAIvo8VMeta || {},
    alias: securityjbWkAIvo8VMeta?.alias || [],
    redirect: securityjbWkAIvo8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityjbWkAIvo8VMeta?.name ?? "legal-security___mx",
    path: securityjbWkAIvo8VMeta?.path ?? "/mx/legal/security",
    meta: securityjbWkAIvo8VMeta || {},
    alias: securityjbWkAIvo8VMeta?.alias || [],
    redirect: securityjbWkAIvo8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityjbWkAIvo8VMeta?.name ?? "legal-security___fr",
    path: securityjbWkAIvo8VMeta?.path ?? "/fr/legal/security",
    meta: securityjbWkAIvo8VMeta || {},
    alias: securityjbWkAIvo8VMeta?.alias || [],
    redirect: securityjbWkAIvo8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityjbWkAIvo8VMeta?.name ?? "legal-security___fr-ca",
    path: securityjbWkAIvo8VMeta?.path ?? "/fr-ca/legal/security",
    meta: securityjbWkAIvo8VMeta || {},
    alias: securityjbWkAIvo8VMeta?.alias || [],
    redirect: securityjbWkAIvo8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportRQB6WRsYLKMeta?.name ?? "legal-transparencyreport___en-US",
    path: transparencyreportRQB6WRsYLKMeta?.path ?? "/legal/transparencyreport",
    meta: transparencyreportRQB6WRsYLKMeta || {},
    alias: transparencyreportRQB6WRsYLKMeta?.alias || [],
    redirect: transparencyreportRQB6WRsYLKMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportRQB6WRsYLKMeta?.name ?? "legal-transparencyreport___ca",
    path: transparencyreportRQB6WRsYLKMeta?.path ?? "/ca/legal/transparencyreport",
    meta: transparencyreportRQB6WRsYLKMeta || {},
    alias: transparencyreportRQB6WRsYLKMeta?.alias || [],
    redirect: transparencyreportRQB6WRsYLKMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportRQB6WRsYLKMeta?.name ?? "legal-transparencyreport___nl",
    path: transparencyreportRQB6WRsYLKMeta?.path ?? "/nl/legal/transparencyreport",
    meta: transparencyreportRQB6WRsYLKMeta || {},
    alias: transparencyreportRQB6WRsYLKMeta?.alias || [],
    redirect: transparencyreportRQB6WRsYLKMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportRQB6WRsYLKMeta?.name ?? "legal-transparencyreport___uk",
    path: transparencyreportRQB6WRsYLKMeta?.path ?? "/uk/legal/transparencyreport",
    meta: transparencyreportRQB6WRsYLKMeta || {},
    alias: transparencyreportRQB6WRsYLKMeta?.alias || [],
    redirect: transparencyreportRQB6WRsYLKMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportRQB6WRsYLKMeta?.name ?? "legal-transparencyreport___de",
    path: transparencyreportRQB6WRsYLKMeta?.path ?? "/de/legal/transparencyreport",
    meta: transparencyreportRQB6WRsYLKMeta || {},
    alias: transparencyreportRQB6WRsYLKMeta?.alias || [],
    redirect: transparencyreportRQB6WRsYLKMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportRQB6WRsYLKMeta?.name ?? "legal-transparencyreport___mx",
    path: transparencyreportRQB6WRsYLKMeta?.path ?? "/mx/legal/transparencyreport",
    meta: transparencyreportRQB6WRsYLKMeta || {},
    alias: transparencyreportRQB6WRsYLKMeta?.alias || [],
    redirect: transparencyreportRQB6WRsYLKMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportRQB6WRsYLKMeta?.name ?? "legal-transparencyreport___fr",
    path: transparencyreportRQB6WRsYLKMeta?.path ?? "/fr/legal/transparencyreport",
    meta: transparencyreportRQB6WRsYLKMeta || {},
    alias: transparencyreportRQB6WRsYLKMeta?.alias || [],
    redirect: transparencyreportRQB6WRsYLKMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreportRQB6WRsYLKMeta?.name ?? "legal-transparencyreport___fr-ca",
    path: transparencyreportRQB6WRsYLKMeta?.path ?? "/fr-ca/legal/transparencyreport",
    meta: transparencyreportRQB6WRsYLKMeta || {},
    alias: transparencyreportRQB6WRsYLKMeta?.alias || [],
    redirect: transparencyreportRQB6WRsYLKMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceGSGQlFATNpMeta?.name ?? "legal-website-terms-of-service___en-US",
    path: website_45terms_45of_45serviceGSGQlFATNpMeta?.path ?? "/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceGSGQlFATNpMeta || {},
    alias: website_45terms_45of_45serviceGSGQlFATNpMeta?.alias || [],
    redirect: website_45terms_45of_45serviceGSGQlFATNpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceGSGQlFATNpMeta?.name ?? "legal-website-terms-of-service___ca",
    path: website_45terms_45of_45serviceGSGQlFATNpMeta?.path ?? "/ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceGSGQlFATNpMeta || {},
    alias: website_45terms_45of_45serviceGSGQlFATNpMeta?.alias || [],
    redirect: website_45terms_45of_45serviceGSGQlFATNpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceGSGQlFATNpMeta?.name ?? "legal-website-terms-of-service___nl",
    path: website_45terms_45of_45serviceGSGQlFATNpMeta?.path ?? "/nl/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceGSGQlFATNpMeta || {},
    alias: website_45terms_45of_45serviceGSGQlFATNpMeta?.alias || [],
    redirect: website_45terms_45of_45serviceGSGQlFATNpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceGSGQlFATNpMeta?.name ?? "legal-website-terms-of-service___uk",
    path: website_45terms_45of_45serviceGSGQlFATNpMeta?.path ?? "/uk/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceGSGQlFATNpMeta || {},
    alias: website_45terms_45of_45serviceGSGQlFATNpMeta?.alias || [],
    redirect: website_45terms_45of_45serviceGSGQlFATNpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceGSGQlFATNpMeta?.name ?? "legal-website-terms-of-service___de",
    path: website_45terms_45of_45serviceGSGQlFATNpMeta?.path ?? "/de/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceGSGQlFATNpMeta || {},
    alias: website_45terms_45of_45serviceGSGQlFATNpMeta?.alias || [],
    redirect: website_45terms_45of_45serviceGSGQlFATNpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceGSGQlFATNpMeta?.name ?? "legal-website-terms-of-service___mx",
    path: website_45terms_45of_45serviceGSGQlFATNpMeta?.path ?? "/mx/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceGSGQlFATNpMeta || {},
    alias: website_45terms_45of_45serviceGSGQlFATNpMeta?.alias || [],
    redirect: website_45terms_45of_45serviceGSGQlFATNpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceGSGQlFATNpMeta?.name ?? "legal-website-terms-of-service___fr",
    path: website_45terms_45of_45serviceGSGQlFATNpMeta?.path ?? "/fr/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceGSGQlFATNpMeta || {},
    alias: website_45terms_45of_45serviceGSGQlFATNpMeta?.alias || [],
    redirect: website_45terms_45of_45serviceGSGQlFATNpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45serviceGSGQlFATNpMeta?.name ?? "legal-website-terms-of-service___fr-ca",
    path: website_45terms_45of_45serviceGSGQlFATNpMeta?.path ?? "/fr-ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45serviceGSGQlFATNpMeta || {},
    alias: website_45terms_45of_45serviceGSGQlFATNpMeta?.alias || [],
    redirect: website_45terms_45of_45serviceGSGQlFATNpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesv1eSAuisG5Meta?.name ?? "legal-your-privacy-choices___en-US",
    path: your_45privacy_45choicesv1eSAuisG5Meta?.path ?? "/legal/your-privacy-choices",
    meta: your_45privacy_45choicesv1eSAuisG5Meta || {},
    alias: your_45privacy_45choicesv1eSAuisG5Meta?.alias || [],
    redirect: your_45privacy_45choicesv1eSAuisG5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesv1eSAuisG5Meta?.name ?? "legal-your-privacy-choices___ca",
    path: your_45privacy_45choicesv1eSAuisG5Meta?.path ?? "/ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesv1eSAuisG5Meta || {},
    alias: your_45privacy_45choicesv1eSAuisG5Meta?.alias || [],
    redirect: your_45privacy_45choicesv1eSAuisG5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesv1eSAuisG5Meta?.name ?? "legal-your-privacy-choices___nl",
    path: your_45privacy_45choicesv1eSAuisG5Meta?.path ?? "/nl/legal/your-privacy-choices",
    meta: your_45privacy_45choicesv1eSAuisG5Meta || {},
    alias: your_45privacy_45choicesv1eSAuisG5Meta?.alias || [],
    redirect: your_45privacy_45choicesv1eSAuisG5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesv1eSAuisG5Meta?.name ?? "legal-your-privacy-choices___uk",
    path: your_45privacy_45choicesv1eSAuisG5Meta?.path ?? "/uk/legal/your-privacy-choices",
    meta: your_45privacy_45choicesv1eSAuisG5Meta || {},
    alias: your_45privacy_45choicesv1eSAuisG5Meta?.alias || [],
    redirect: your_45privacy_45choicesv1eSAuisG5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesv1eSAuisG5Meta?.name ?? "legal-your-privacy-choices___de",
    path: your_45privacy_45choicesv1eSAuisG5Meta?.path ?? "/de/legal/your-privacy-choices",
    meta: your_45privacy_45choicesv1eSAuisG5Meta || {},
    alias: your_45privacy_45choicesv1eSAuisG5Meta?.alias || [],
    redirect: your_45privacy_45choicesv1eSAuisG5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesv1eSAuisG5Meta?.name ?? "legal-your-privacy-choices___mx",
    path: your_45privacy_45choicesv1eSAuisG5Meta?.path ?? "/mx/legal/your-privacy-choices",
    meta: your_45privacy_45choicesv1eSAuisG5Meta || {},
    alias: your_45privacy_45choicesv1eSAuisG5Meta?.alias || [],
    redirect: your_45privacy_45choicesv1eSAuisG5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesv1eSAuisG5Meta?.name ?? "legal-your-privacy-choices___fr",
    path: your_45privacy_45choicesv1eSAuisG5Meta?.path ?? "/fr/legal/your-privacy-choices",
    meta: your_45privacy_45choicesv1eSAuisG5Meta || {},
    alias: your_45privacy_45choicesv1eSAuisG5Meta?.alias || [],
    redirect: your_45privacy_45choicesv1eSAuisG5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesv1eSAuisG5Meta?.name ?? "legal-your-privacy-choices___fr-ca",
    path: your_45privacy_45choicesv1eSAuisG5Meta?.path ?? "/fr-ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesv1eSAuisG5Meta || {},
    alias: your_45privacy_45choicesv1eSAuisG5Meta?.alias || [],
    redirect: your_45privacy_45choicesv1eSAuisG5Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: pricingxNu8vhBLZVMeta?.name ?? "page-pricing___en-US",
    path: pricingxNu8vhBLZVMeta?.path ?? "/page/pricing",
    meta: pricingxNu8vhBLZVMeta || {},
    alias: pricingxNu8vhBLZVMeta?.alias || [],
    redirect: pricingxNu8vhBLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingxNu8vhBLZVMeta?.name ?? "page-pricing___ca",
    path: pricingxNu8vhBLZVMeta?.path ?? "/ca/page/pricing",
    meta: pricingxNu8vhBLZVMeta || {},
    alias: pricingxNu8vhBLZVMeta?.alias || [],
    redirect: pricingxNu8vhBLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingxNu8vhBLZVMeta?.name ?? "page-pricing___nl",
    path: pricingxNu8vhBLZVMeta?.path ?? "/nl/page/pricing",
    meta: pricingxNu8vhBLZVMeta || {},
    alias: pricingxNu8vhBLZVMeta?.alias || [],
    redirect: pricingxNu8vhBLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingxNu8vhBLZVMeta?.name ?? "page-pricing___uk",
    path: pricingxNu8vhBLZVMeta?.path ?? "/uk/page/pricing",
    meta: pricingxNu8vhBLZVMeta || {},
    alias: pricingxNu8vhBLZVMeta?.alias || [],
    redirect: pricingxNu8vhBLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingxNu8vhBLZVMeta?.name ?? "page-pricing___de",
    path: pricingxNu8vhBLZVMeta?.path ?? "/de/page/pricing",
    meta: pricingxNu8vhBLZVMeta || {},
    alias: pricingxNu8vhBLZVMeta?.alias || [],
    redirect: pricingxNu8vhBLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingxNu8vhBLZVMeta?.name ?? "page-pricing___mx",
    path: pricingxNu8vhBLZVMeta?.path ?? "/mx/page/pricing",
    meta: pricingxNu8vhBLZVMeta || {},
    alias: pricingxNu8vhBLZVMeta?.alias || [],
    redirect: pricingxNu8vhBLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingxNu8vhBLZVMeta?.name ?? "page-pricing___fr",
    path: pricingxNu8vhBLZVMeta?.path ?? "/fr/page/pricing",
    meta: pricingxNu8vhBLZVMeta || {},
    alias: pricingxNu8vhBLZVMeta?.alias || [],
    redirect: pricingxNu8vhBLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingxNu8vhBLZVMeta?.name ?? "page-pricing___fr-ca",
    path: pricingxNu8vhBLZVMeta?.path ?? "/fr-ca/page/pricing",
    meta: pricingxNu8vhBLZVMeta || {},
    alias: pricingxNu8vhBLZVMeta?.alias || [],
    redirect: pricingxNu8vhBLZVMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPOj65lNMdMeta?.name ?? "pages-prefix-slug___en-US",
    path: _91slug_93kPOj65lNMdMeta?.path ?? "/pages/:prefix()/:slug()",
    meta: _91slug_93kPOj65lNMdMeta || {},
    alias: _91slug_93kPOj65lNMdMeta?.alias || [],
    redirect: _91slug_93kPOj65lNMdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPOj65lNMdMeta?.name ?? "pages-prefix-slug___ca",
    path: _91slug_93kPOj65lNMdMeta?.path ?? "/ca/pages/:prefix()/:slug()",
    meta: _91slug_93kPOj65lNMdMeta || {},
    alias: _91slug_93kPOj65lNMdMeta?.alias || [],
    redirect: _91slug_93kPOj65lNMdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPOj65lNMdMeta?.name ?? "pages-prefix-slug___nl",
    path: _91slug_93kPOj65lNMdMeta?.path ?? "/nl/pages/:prefix()/:slug()",
    meta: _91slug_93kPOj65lNMdMeta || {},
    alias: _91slug_93kPOj65lNMdMeta?.alias || [],
    redirect: _91slug_93kPOj65lNMdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPOj65lNMdMeta?.name ?? "pages-prefix-slug___uk",
    path: _91slug_93kPOj65lNMdMeta?.path ?? "/uk/pages/:prefix()/:slug()",
    meta: _91slug_93kPOj65lNMdMeta || {},
    alias: _91slug_93kPOj65lNMdMeta?.alias || [],
    redirect: _91slug_93kPOj65lNMdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPOj65lNMdMeta?.name ?? "pages-prefix-slug___de",
    path: _91slug_93kPOj65lNMdMeta?.path ?? "/de/pages/:prefix()/:slug()",
    meta: _91slug_93kPOj65lNMdMeta || {},
    alias: _91slug_93kPOj65lNMdMeta?.alias || [],
    redirect: _91slug_93kPOj65lNMdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPOj65lNMdMeta?.name ?? "pages-prefix-slug___mx",
    path: _91slug_93kPOj65lNMdMeta?.path ?? "/mx/pages/:prefix()/:slug()",
    meta: _91slug_93kPOj65lNMdMeta || {},
    alias: _91slug_93kPOj65lNMdMeta?.alias || [],
    redirect: _91slug_93kPOj65lNMdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPOj65lNMdMeta?.name ?? "pages-prefix-slug___fr",
    path: _91slug_93kPOj65lNMdMeta?.path ?? "/fr/pages/:prefix()/:slug()",
    meta: _91slug_93kPOj65lNMdMeta || {},
    alias: _91slug_93kPOj65lNMdMeta?.alias || [],
    redirect: _91slug_93kPOj65lNMdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPOj65lNMdMeta?.name ?? "pages-prefix-slug___fr-ca",
    path: _91slug_93kPOj65lNMdMeta?.path ?? "/fr-ca/pages/:prefix()/:slug()",
    meta: _91slug_93kPOj65lNMdMeta || {},
    alias: _91slug_93kPOj65lNMdMeta?.alias || [],
    redirect: _91slug_93kPOj65lNMdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPP4cK4YNIMeta?.name ?? "pages-slug___en-US",
    path: _91slug_93bPP4cK4YNIMeta?.path ?? "/pages/:slug()",
    meta: _91slug_93bPP4cK4YNIMeta || {},
    alias: _91slug_93bPP4cK4YNIMeta?.alias || [],
    redirect: _91slug_93bPP4cK4YNIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPP4cK4YNIMeta?.name ?? "pages-slug___ca",
    path: _91slug_93bPP4cK4YNIMeta?.path ?? "/ca/pages/:slug()",
    meta: _91slug_93bPP4cK4YNIMeta || {},
    alias: _91slug_93bPP4cK4YNIMeta?.alias || [],
    redirect: _91slug_93bPP4cK4YNIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPP4cK4YNIMeta?.name ?? "pages-slug___nl",
    path: _91slug_93bPP4cK4YNIMeta?.path ?? "/nl/pages/:slug()",
    meta: _91slug_93bPP4cK4YNIMeta || {},
    alias: _91slug_93bPP4cK4YNIMeta?.alias || [],
    redirect: _91slug_93bPP4cK4YNIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPP4cK4YNIMeta?.name ?? "pages-slug___uk",
    path: _91slug_93bPP4cK4YNIMeta?.path ?? "/uk/pages/:slug()",
    meta: _91slug_93bPP4cK4YNIMeta || {},
    alias: _91slug_93bPP4cK4YNIMeta?.alias || [],
    redirect: _91slug_93bPP4cK4YNIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPP4cK4YNIMeta?.name ?? "pages-slug___de",
    path: _91slug_93bPP4cK4YNIMeta?.path ?? "/de/pages/:slug()",
    meta: _91slug_93bPP4cK4YNIMeta || {},
    alias: _91slug_93bPP4cK4YNIMeta?.alias || [],
    redirect: _91slug_93bPP4cK4YNIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPP4cK4YNIMeta?.name ?? "pages-slug___mx",
    path: _91slug_93bPP4cK4YNIMeta?.path ?? "/mx/pages/:slug()",
    meta: _91slug_93bPP4cK4YNIMeta || {},
    alias: _91slug_93bPP4cK4YNIMeta?.alias || [],
    redirect: _91slug_93bPP4cK4YNIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPP4cK4YNIMeta?.name ?? "pages-slug___fr",
    path: _91slug_93bPP4cK4YNIMeta?.path ?? "/fr/pages/:slug()",
    meta: _91slug_93bPP4cK4YNIMeta || {},
    alias: _91slug_93bPP4cK4YNIMeta?.alias || [],
    redirect: _91slug_93bPP4cK4YNIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bPP4cK4YNIMeta?.name ?? "pages-slug___fr-ca",
    path: _91slug_93bPP4cK4YNIMeta?.path ?? "/fr-ca/pages/:slug()",
    meta: _91slug_93bPP4cK4YNIMeta || {},
    alias: _91slug_93bPP4cK4YNIMeta?.alias || [],
    redirect: _91slug_93bPP4cK4YNIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingwttNlwjsaFMeta?.name ?? "pages-asset-tracking___en-US",
    path: asset_45trackingwttNlwjsaFMeta?.path ?? "/pages/asset-tracking",
    meta: asset_45trackingwttNlwjsaFMeta || {},
    alias: asset_45trackingwttNlwjsaFMeta?.alias || [],
    redirect: asset_45trackingwttNlwjsaFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingwttNlwjsaFMeta?.name ?? "pages-asset-tracking___ca",
    path: asset_45trackingwttNlwjsaFMeta?.path ?? "/ca/pages/asset-tracking",
    meta: asset_45trackingwttNlwjsaFMeta || {},
    alias: asset_45trackingwttNlwjsaFMeta?.alias || [],
    redirect: asset_45trackingwttNlwjsaFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingwttNlwjsaFMeta?.name ?? "pages-asset-tracking___nl",
    path: asset_45trackingwttNlwjsaFMeta?.path ?? "/nl/pages/asset-tracking",
    meta: asset_45trackingwttNlwjsaFMeta || {},
    alias: asset_45trackingwttNlwjsaFMeta?.alias || [],
    redirect: asset_45trackingwttNlwjsaFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingwttNlwjsaFMeta?.name ?? "pages-asset-tracking___uk",
    path: asset_45trackingwttNlwjsaFMeta?.path ?? "/uk/pages/asset-tracking",
    meta: asset_45trackingwttNlwjsaFMeta || {},
    alias: asset_45trackingwttNlwjsaFMeta?.alias || [],
    redirect: asset_45trackingwttNlwjsaFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingwttNlwjsaFMeta?.name ?? "pages-asset-tracking___de",
    path: asset_45trackingwttNlwjsaFMeta?.path ?? "/de/pages/asset-tracking",
    meta: asset_45trackingwttNlwjsaFMeta || {},
    alias: asset_45trackingwttNlwjsaFMeta?.alias || [],
    redirect: asset_45trackingwttNlwjsaFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingwttNlwjsaFMeta?.name ?? "pages-asset-tracking___mx",
    path: asset_45trackingwttNlwjsaFMeta?.path ?? "/mx/pages/asset-tracking",
    meta: asset_45trackingwttNlwjsaFMeta || {},
    alias: asset_45trackingwttNlwjsaFMeta?.alias || [],
    redirect: asset_45trackingwttNlwjsaFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingwttNlwjsaFMeta?.name ?? "pages-asset-tracking___fr",
    path: asset_45trackingwttNlwjsaFMeta?.path ?? "/fr/pages/asset-tracking",
    meta: asset_45trackingwttNlwjsaFMeta || {},
    alias: asset_45trackingwttNlwjsaFMeta?.alias || [],
    redirect: asset_45trackingwttNlwjsaFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingwttNlwjsaFMeta?.name ?? "pages-asset-tracking___fr-ca",
    path: asset_45trackingwttNlwjsaFMeta?.path ?? "/fr-ca/pages/asset-tracking",
    meta: asset_45trackingwttNlwjsaFMeta || {},
    alias: asset_45trackingwttNlwjsaFMeta?.alias || [],
    redirect: asset_45trackingwttNlwjsaFMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSyyxuAYUxbMeta?.name ?? "pages-best-dash-cams___en-US",
    path: best_45dash_45camsSyyxuAYUxbMeta?.path ?? "/pages/best-dash-cams",
    meta: best_45dash_45camsSyyxuAYUxbMeta || {},
    alias: best_45dash_45camsSyyxuAYUxbMeta?.alias || [],
    redirect: best_45dash_45camsSyyxuAYUxbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSyyxuAYUxbMeta?.name ?? "pages-best-dash-cams___ca",
    path: best_45dash_45camsSyyxuAYUxbMeta?.path ?? "/ca/pages/best-dash-cams",
    meta: best_45dash_45camsSyyxuAYUxbMeta || {},
    alias: best_45dash_45camsSyyxuAYUxbMeta?.alias || [],
    redirect: best_45dash_45camsSyyxuAYUxbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSyyxuAYUxbMeta?.name ?? "pages-best-dash-cams___nl",
    path: best_45dash_45camsSyyxuAYUxbMeta?.path ?? "/nl/pages/best-dash-cams",
    meta: best_45dash_45camsSyyxuAYUxbMeta || {},
    alias: best_45dash_45camsSyyxuAYUxbMeta?.alias || [],
    redirect: best_45dash_45camsSyyxuAYUxbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSyyxuAYUxbMeta?.name ?? "pages-best-dash-cams___uk",
    path: best_45dash_45camsSyyxuAYUxbMeta?.path ?? "/uk/pages/best-dash-cams",
    meta: best_45dash_45camsSyyxuAYUxbMeta || {},
    alias: best_45dash_45camsSyyxuAYUxbMeta?.alias || [],
    redirect: best_45dash_45camsSyyxuAYUxbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSyyxuAYUxbMeta?.name ?? "pages-best-dash-cams___de",
    path: best_45dash_45camsSyyxuAYUxbMeta?.path ?? "/de/pages/best-dash-cams",
    meta: best_45dash_45camsSyyxuAYUxbMeta || {},
    alias: best_45dash_45camsSyyxuAYUxbMeta?.alias || [],
    redirect: best_45dash_45camsSyyxuAYUxbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSyyxuAYUxbMeta?.name ?? "pages-best-dash-cams___mx",
    path: best_45dash_45camsSyyxuAYUxbMeta?.path ?? "/mx/pages/best-dash-cams",
    meta: best_45dash_45camsSyyxuAYUxbMeta || {},
    alias: best_45dash_45camsSyyxuAYUxbMeta?.alias || [],
    redirect: best_45dash_45camsSyyxuAYUxbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSyyxuAYUxbMeta?.name ?? "pages-best-dash-cams___fr",
    path: best_45dash_45camsSyyxuAYUxbMeta?.path ?? "/fr/pages/best-dash-cams",
    meta: best_45dash_45camsSyyxuAYUxbMeta || {},
    alias: best_45dash_45camsSyyxuAYUxbMeta?.alias || [],
    redirect: best_45dash_45camsSyyxuAYUxbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsSyyxuAYUxbMeta?.name ?? "pages-best-dash-cams___fr-ca",
    path: best_45dash_45camsSyyxuAYUxbMeta?.path ?? "/fr-ca/pages/best-dash-cams",
    meta: best_45dash_45camsSyyxuAYUxbMeta || {},
    alias: best_45dash_45camsSyyxuAYUxbMeta?.alias || [],
    redirect: best_45dash_45camsSyyxuAYUxbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: constructionO6w0UMWvgWMeta?.name ?? "pages-construction___en-US",
    path: constructionO6w0UMWvgWMeta?.path ?? "/pages/construction",
    meta: constructionO6w0UMWvgWMeta || {},
    alias: constructionO6w0UMWvgWMeta?.alias || [],
    redirect: constructionO6w0UMWvgWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionO6w0UMWvgWMeta?.name ?? "pages-construction___ca",
    path: constructionO6w0UMWvgWMeta?.path ?? "/ca/pages/construction",
    meta: constructionO6w0UMWvgWMeta || {},
    alias: constructionO6w0UMWvgWMeta?.alias || [],
    redirect: constructionO6w0UMWvgWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionO6w0UMWvgWMeta?.name ?? "pages-construction___nl",
    path: constructionO6w0UMWvgWMeta?.path ?? "/nl/pages/construction",
    meta: constructionO6w0UMWvgWMeta || {},
    alias: constructionO6w0UMWvgWMeta?.alias || [],
    redirect: constructionO6w0UMWvgWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionO6w0UMWvgWMeta?.name ?? "pages-construction___uk",
    path: constructionO6w0UMWvgWMeta?.path ?? "/uk/pages/construction",
    meta: constructionO6w0UMWvgWMeta || {},
    alias: constructionO6w0UMWvgWMeta?.alias || [],
    redirect: constructionO6w0UMWvgWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionO6w0UMWvgWMeta?.name ?? "pages-construction___de",
    path: constructionO6w0UMWvgWMeta?.path ?? "/de/pages/construction",
    meta: constructionO6w0UMWvgWMeta || {},
    alias: constructionO6w0UMWvgWMeta?.alias || [],
    redirect: constructionO6w0UMWvgWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionO6w0UMWvgWMeta?.name ?? "pages-construction___mx",
    path: constructionO6w0UMWvgWMeta?.path ?? "/mx/pages/construction",
    meta: constructionO6w0UMWvgWMeta || {},
    alias: constructionO6w0UMWvgWMeta?.alias || [],
    redirect: constructionO6w0UMWvgWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionO6w0UMWvgWMeta?.name ?? "pages-construction___fr",
    path: constructionO6w0UMWvgWMeta?.path ?? "/fr/pages/construction",
    meta: constructionO6w0UMWvgWMeta || {},
    alias: constructionO6w0UMWvgWMeta?.alias || [],
    redirect: constructionO6w0UMWvgWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionO6w0UMWvgWMeta?.name ?? "pages-construction___fr-ca",
    path: constructionO6w0UMWvgWMeta?.path ?? "/fr-ca/pages/construction",
    meta: constructionO6w0UMWvgWMeta || {},
    alias: constructionO6w0UMWvgWMeta?.alias || [],
    redirect: constructionO6w0UMWvgWMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: eversourcehzmHxB7KNwMeta?.name ?? "pages-eversource___en-US",
    path: eversourcehzmHxB7KNwMeta?.path ?? "/pages/eversource",
    meta: eversourcehzmHxB7KNwMeta || {},
    alias: eversourcehzmHxB7KNwMeta?.alias || [],
    redirect: eversourcehzmHxB7KNwMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourcehzmHxB7KNwMeta?.name ?? "pages-eversource___ca",
    path: eversourcehzmHxB7KNwMeta?.path ?? "/ca/pages/eversource",
    meta: eversourcehzmHxB7KNwMeta || {},
    alias: eversourcehzmHxB7KNwMeta?.alias || [],
    redirect: eversourcehzmHxB7KNwMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourcehzmHxB7KNwMeta?.name ?? "pages-eversource___nl",
    path: eversourcehzmHxB7KNwMeta?.path ?? "/nl/pages/eversource",
    meta: eversourcehzmHxB7KNwMeta || {},
    alias: eversourcehzmHxB7KNwMeta?.alias || [],
    redirect: eversourcehzmHxB7KNwMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourcehzmHxB7KNwMeta?.name ?? "pages-eversource___uk",
    path: eversourcehzmHxB7KNwMeta?.path ?? "/uk/pages/eversource",
    meta: eversourcehzmHxB7KNwMeta || {},
    alias: eversourcehzmHxB7KNwMeta?.alias || [],
    redirect: eversourcehzmHxB7KNwMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourcehzmHxB7KNwMeta?.name ?? "pages-eversource___de",
    path: eversourcehzmHxB7KNwMeta?.path ?? "/de/pages/eversource",
    meta: eversourcehzmHxB7KNwMeta || {},
    alias: eversourcehzmHxB7KNwMeta?.alias || [],
    redirect: eversourcehzmHxB7KNwMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourcehzmHxB7KNwMeta?.name ?? "pages-eversource___mx",
    path: eversourcehzmHxB7KNwMeta?.path ?? "/mx/pages/eversource",
    meta: eversourcehzmHxB7KNwMeta || {},
    alias: eversourcehzmHxB7KNwMeta?.alias || [],
    redirect: eversourcehzmHxB7KNwMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourcehzmHxB7KNwMeta?.name ?? "pages-eversource___fr",
    path: eversourcehzmHxB7KNwMeta?.path ?? "/fr/pages/eversource",
    meta: eversourcehzmHxB7KNwMeta || {},
    alias: eversourcehzmHxB7KNwMeta?.alias || [],
    redirect: eversourcehzmHxB7KNwMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourcehzmHxB7KNwMeta?.name ?? "pages-eversource___fr-ca",
    path: eversourcehzmHxB7KNwMeta?.path ?? "/fr-ca/pages/eversource",
    meta: eversourcehzmHxB7KNwMeta || {},
    alias: eversourcehzmHxB7KNwMeta?.alias || [],
    redirect: eversourcehzmHxB7KNwMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementexXMWZnxjMMeta?.name ?? "pages-fleet-management___en-US",
    path: fleet_45managementexXMWZnxjMMeta?.path ?? "/pages/fleet-management",
    meta: fleet_45managementexXMWZnxjMMeta || {},
    alias: fleet_45managementexXMWZnxjMMeta?.alias || [],
    redirect: fleet_45managementexXMWZnxjMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementexXMWZnxjMMeta?.name ?? "pages-fleet-management___ca",
    path: fleet_45managementexXMWZnxjMMeta?.path ?? "/ca/pages/fleet-management",
    meta: fleet_45managementexXMWZnxjMMeta || {},
    alias: fleet_45managementexXMWZnxjMMeta?.alias || [],
    redirect: fleet_45managementexXMWZnxjMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementexXMWZnxjMMeta?.name ?? "pages-fleet-management___nl",
    path: fleet_45managementexXMWZnxjMMeta?.path ?? "/nl/pages/fleet-management",
    meta: fleet_45managementexXMWZnxjMMeta || {},
    alias: fleet_45managementexXMWZnxjMMeta?.alias || [],
    redirect: fleet_45managementexXMWZnxjMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementexXMWZnxjMMeta?.name ?? "pages-fleet-management___uk",
    path: fleet_45managementexXMWZnxjMMeta?.path ?? "/uk/pages/fleet-management",
    meta: fleet_45managementexXMWZnxjMMeta || {},
    alias: fleet_45managementexXMWZnxjMMeta?.alias || [],
    redirect: fleet_45managementexXMWZnxjMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementexXMWZnxjMMeta?.name ?? "pages-fleet-management___de",
    path: fleet_45managementexXMWZnxjMMeta?.path ?? "/de/pages/fleet-management",
    meta: fleet_45managementexXMWZnxjMMeta || {},
    alias: fleet_45managementexXMWZnxjMMeta?.alias || [],
    redirect: fleet_45managementexXMWZnxjMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementexXMWZnxjMMeta?.name ?? "pages-fleet-management___mx",
    path: fleet_45managementexXMWZnxjMMeta?.path ?? "/mx/pages/fleet-management",
    meta: fleet_45managementexXMWZnxjMMeta || {},
    alias: fleet_45managementexXMWZnxjMMeta?.alias || [],
    redirect: fleet_45managementexXMWZnxjMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementexXMWZnxjMMeta?.name ?? "pages-fleet-management___fr",
    path: fleet_45managementexXMWZnxjMMeta?.path ?? "/fr/pages/fleet-management",
    meta: fleet_45managementexXMWZnxjMMeta || {},
    alias: fleet_45managementexXMWZnxjMMeta?.alias || [],
    redirect: fleet_45managementexXMWZnxjMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementexXMWZnxjMMeta?.name ?? "pages-fleet-management___fr-ca",
    path: fleet_45managementexXMWZnxjMMeta?.path ?? "/fr-ca/pages/fleet-management",
    meta: fleet_45managementexXMWZnxjMMeta || {},
    alias: fleet_45managementexXMWZnxjMMeta?.alias || [],
    redirect: fleet_45managementexXMWZnxjMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: groundworksPUF7uLjzapMeta?.name ?? "pages-groundworks___en-US",
    path: groundworksPUF7uLjzapMeta?.path ?? "/pages/groundworks",
    meta: groundworksPUF7uLjzapMeta || {},
    alias: groundworksPUF7uLjzapMeta?.alias || [],
    redirect: groundworksPUF7uLjzapMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksPUF7uLjzapMeta?.name ?? "pages-groundworks___ca",
    path: groundworksPUF7uLjzapMeta?.path ?? "/ca/pages/groundworks",
    meta: groundworksPUF7uLjzapMeta || {},
    alias: groundworksPUF7uLjzapMeta?.alias || [],
    redirect: groundworksPUF7uLjzapMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksPUF7uLjzapMeta?.name ?? "pages-groundworks___nl",
    path: groundworksPUF7uLjzapMeta?.path ?? "/nl/pages/groundworks",
    meta: groundworksPUF7uLjzapMeta || {},
    alias: groundworksPUF7uLjzapMeta?.alias || [],
    redirect: groundworksPUF7uLjzapMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksPUF7uLjzapMeta?.name ?? "pages-groundworks___uk",
    path: groundworksPUF7uLjzapMeta?.path ?? "/uk/pages/groundworks",
    meta: groundworksPUF7uLjzapMeta || {},
    alias: groundworksPUF7uLjzapMeta?.alias || [],
    redirect: groundworksPUF7uLjzapMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksPUF7uLjzapMeta?.name ?? "pages-groundworks___de",
    path: groundworksPUF7uLjzapMeta?.path ?? "/de/pages/groundworks",
    meta: groundworksPUF7uLjzapMeta || {},
    alias: groundworksPUF7uLjzapMeta?.alias || [],
    redirect: groundworksPUF7uLjzapMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksPUF7uLjzapMeta?.name ?? "pages-groundworks___mx",
    path: groundworksPUF7uLjzapMeta?.path ?? "/mx/pages/groundworks",
    meta: groundworksPUF7uLjzapMeta || {},
    alias: groundworksPUF7uLjzapMeta?.alias || [],
    redirect: groundworksPUF7uLjzapMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksPUF7uLjzapMeta?.name ?? "pages-groundworks___fr",
    path: groundworksPUF7uLjzapMeta?.path ?? "/fr/pages/groundworks",
    meta: groundworksPUF7uLjzapMeta || {},
    alias: groundworksPUF7uLjzapMeta?.alias || [],
    redirect: groundworksPUF7uLjzapMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworksPUF7uLjzapMeta?.name ?? "pages-groundworks___fr-ca",
    path: groundworksPUF7uLjzapMeta?.path ?? "/fr-ca/pages/groundworks",
    meta: groundworksPUF7uLjzapMeta || {},
    alias: groundworksPUF7uLjzapMeta?.alias || [],
    redirect: groundworksPUF7uLjzapMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: nationwideU1MAZa0367Meta?.name ?? "pages-nationwide___en-US",
    path: nationwideU1MAZa0367Meta?.path ?? "/pages/nationwide",
    meta: nationwideU1MAZa0367Meta || {},
    alias: nationwideU1MAZa0367Meta?.alias || [],
    redirect: nationwideU1MAZa0367Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideU1MAZa0367Meta?.name ?? "pages-nationwide___ca",
    path: nationwideU1MAZa0367Meta?.path ?? "/ca/pages/nationwide",
    meta: nationwideU1MAZa0367Meta || {},
    alias: nationwideU1MAZa0367Meta?.alias || [],
    redirect: nationwideU1MAZa0367Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideU1MAZa0367Meta?.name ?? "pages-nationwide___nl",
    path: nationwideU1MAZa0367Meta?.path ?? "/nl/pages/nationwide",
    meta: nationwideU1MAZa0367Meta || {},
    alias: nationwideU1MAZa0367Meta?.alias || [],
    redirect: nationwideU1MAZa0367Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideU1MAZa0367Meta?.name ?? "pages-nationwide___uk",
    path: nationwideU1MAZa0367Meta?.path ?? "/uk/pages/nationwide",
    meta: nationwideU1MAZa0367Meta || {},
    alias: nationwideU1MAZa0367Meta?.alias || [],
    redirect: nationwideU1MAZa0367Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideU1MAZa0367Meta?.name ?? "pages-nationwide___de",
    path: nationwideU1MAZa0367Meta?.path ?? "/de/pages/nationwide",
    meta: nationwideU1MAZa0367Meta || {},
    alias: nationwideU1MAZa0367Meta?.alias || [],
    redirect: nationwideU1MAZa0367Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideU1MAZa0367Meta?.name ?? "pages-nationwide___mx",
    path: nationwideU1MAZa0367Meta?.path ?? "/mx/pages/nationwide",
    meta: nationwideU1MAZa0367Meta || {},
    alias: nationwideU1MAZa0367Meta?.alias || [],
    redirect: nationwideU1MAZa0367Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideU1MAZa0367Meta?.name ?? "pages-nationwide___fr",
    path: nationwideU1MAZa0367Meta?.path ?? "/fr/pages/nationwide",
    meta: nationwideU1MAZa0367Meta || {},
    alias: nationwideU1MAZa0367Meta?.alias || [],
    redirect: nationwideU1MAZa0367Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwideU1MAZa0367Meta?.name ?? "pages-nationwide___fr-ca",
    path: nationwideU1MAZa0367Meta?.path ?? "/fr-ca/pages/nationwide",
    meta: nationwideU1MAZa0367Meta || {},
    alias: nationwideU1MAZa0367Meta?.alias || [],
    redirect: nationwideU1MAZa0367Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPxTgcIxa4TMeta?.name ?? "pages-safety-coaching___en-US",
    path: safety_45coachingPxTgcIxa4TMeta?.path ?? "/pages/safety-coaching",
    meta: safety_45coachingPxTgcIxa4TMeta || {},
    alias: safety_45coachingPxTgcIxa4TMeta?.alias || [],
    redirect: safety_45coachingPxTgcIxa4TMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPxTgcIxa4TMeta?.name ?? "pages-safety-coaching___ca",
    path: safety_45coachingPxTgcIxa4TMeta?.path ?? "/ca/pages/safety-coaching",
    meta: safety_45coachingPxTgcIxa4TMeta || {},
    alias: safety_45coachingPxTgcIxa4TMeta?.alias || [],
    redirect: safety_45coachingPxTgcIxa4TMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPxTgcIxa4TMeta?.name ?? "pages-safety-coaching___nl",
    path: safety_45coachingPxTgcIxa4TMeta?.path ?? "/nl/pages/safety-coaching",
    meta: safety_45coachingPxTgcIxa4TMeta || {},
    alias: safety_45coachingPxTgcIxa4TMeta?.alias || [],
    redirect: safety_45coachingPxTgcIxa4TMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPxTgcIxa4TMeta?.name ?? "pages-safety-coaching___uk",
    path: safety_45coachingPxTgcIxa4TMeta?.path ?? "/uk/pages/safety-coaching",
    meta: safety_45coachingPxTgcIxa4TMeta || {},
    alias: safety_45coachingPxTgcIxa4TMeta?.alias || [],
    redirect: safety_45coachingPxTgcIxa4TMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPxTgcIxa4TMeta?.name ?? "pages-safety-coaching___de",
    path: safety_45coachingPxTgcIxa4TMeta?.path ?? "/de/pages/safety-coaching",
    meta: safety_45coachingPxTgcIxa4TMeta || {},
    alias: safety_45coachingPxTgcIxa4TMeta?.alias || [],
    redirect: safety_45coachingPxTgcIxa4TMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPxTgcIxa4TMeta?.name ?? "pages-safety-coaching___mx",
    path: safety_45coachingPxTgcIxa4TMeta?.path ?? "/mx/pages/safety-coaching",
    meta: safety_45coachingPxTgcIxa4TMeta || {},
    alias: safety_45coachingPxTgcIxa4TMeta?.alias || [],
    redirect: safety_45coachingPxTgcIxa4TMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPxTgcIxa4TMeta?.name ?? "pages-safety-coaching___fr",
    path: safety_45coachingPxTgcIxa4TMeta?.path ?? "/fr/pages/safety-coaching",
    meta: safety_45coachingPxTgcIxa4TMeta || {},
    alias: safety_45coachingPxTgcIxa4TMeta?.alias || [],
    redirect: safety_45coachingPxTgcIxa4TMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingPxTgcIxa4TMeta?.name ?? "pages-safety-coaching___fr-ca",
    path: safety_45coachingPxTgcIxa4TMeta?.path ?? "/fr-ca/pages/safety-coaching",
    meta: safety_45coachingPxTgcIxa4TMeta || {},
    alias: safety_45coachingPxTgcIxa4TMeta?.alias || [],
    redirect: safety_45coachingPxTgcIxa4TMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: unifirstnBUtofX4b4Meta?.name ?? "pages-unifirst___en-US",
    path: unifirstnBUtofX4b4Meta?.path ?? "/pages/unifirst",
    meta: unifirstnBUtofX4b4Meta || {},
    alias: unifirstnBUtofX4b4Meta?.alias || [],
    redirect: unifirstnBUtofX4b4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstnBUtofX4b4Meta?.name ?? "pages-unifirst___ca",
    path: unifirstnBUtofX4b4Meta?.path ?? "/ca/pages/unifirst",
    meta: unifirstnBUtofX4b4Meta || {},
    alias: unifirstnBUtofX4b4Meta?.alias || [],
    redirect: unifirstnBUtofX4b4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstnBUtofX4b4Meta?.name ?? "pages-unifirst___nl",
    path: unifirstnBUtofX4b4Meta?.path ?? "/nl/pages/unifirst",
    meta: unifirstnBUtofX4b4Meta || {},
    alias: unifirstnBUtofX4b4Meta?.alias || [],
    redirect: unifirstnBUtofX4b4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstnBUtofX4b4Meta?.name ?? "pages-unifirst___uk",
    path: unifirstnBUtofX4b4Meta?.path ?? "/uk/pages/unifirst",
    meta: unifirstnBUtofX4b4Meta || {},
    alias: unifirstnBUtofX4b4Meta?.alias || [],
    redirect: unifirstnBUtofX4b4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstnBUtofX4b4Meta?.name ?? "pages-unifirst___de",
    path: unifirstnBUtofX4b4Meta?.path ?? "/de/pages/unifirst",
    meta: unifirstnBUtofX4b4Meta || {},
    alias: unifirstnBUtofX4b4Meta?.alias || [],
    redirect: unifirstnBUtofX4b4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstnBUtofX4b4Meta?.name ?? "pages-unifirst___mx",
    path: unifirstnBUtofX4b4Meta?.path ?? "/mx/pages/unifirst",
    meta: unifirstnBUtofX4b4Meta || {},
    alias: unifirstnBUtofX4b4Meta?.alias || [],
    redirect: unifirstnBUtofX4b4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstnBUtofX4b4Meta?.name ?? "pages-unifirst___fr",
    path: unifirstnBUtofX4b4Meta?.path ?? "/fr/pages/unifirst",
    meta: unifirstnBUtofX4b4Meta || {},
    alias: unifirstnBUtofX4b4Meta?.alias || [],
    redirect: unifirstnBUtofX4b4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstnBUtofX4b4Meta?.name ?? "pages-unifirst___fr-ca",
    path: unifirstnBUtofX4b4Meta?.path ?? "/fr-ca/pages/unifirst",
    meta: unifirstnBUtofX4b4Meta || {},
    alias: unifirstnBUtofX4b4Meta?.alias || [],
    redirect: unifirstnBUtofX4b4Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: pricingrjN4BGUVYdMeta?.name ?? "pricing___en-US",
    path: pricingrjN4BGUVYdMeta?.path ?? "/pricing",
    meta: pricingrjN4BGUVYdMeta || {},
    alias: pricingrjN4BGUVYdMeta?.alias || [],
    redirect: pricingrjN4BGUVYdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingrjN4BGUVYdMeta?.name ?? "pricing___ca",
    path: pricingrjN4BGUVYdMeta?.path ?? "/ca/pricing",
    meta: pricingrjN4BGUVYdMeta || {},
    alias: pricingrjN4BGUVYdMeta?.alias || [],
    redirect: pricingrjN4BGUVYdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingrjN4BGUVYdMeta?.name ?? "pricing___nl",
    path: pricingrjN4BGUVYdMeta?.path ?? "/nl/pricing",
    meta: pricingrjN4BGUVYdMeta || {},
    alias: pricingrjN4BGUVYdMeta?.alias || [],
    redirect: pricingrjN4BGUVYdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingrjN4BGUVYdMeta?.name ?? "pricing___uk",
    path: pricingrjN4BGUVYdMeta?.path ?? "/uk/pricing",
    meta: pricingrjN4BGUVYdMeta || {},
    alias: pricingrjN4BGUVYdMeta?.alias || [],
    redirect: pricingrjN4BGUVYdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingrjN4BGUVYdMeta?.name ?? "pricing___de",
    path: pricingrjN4BGUVYdMeta?.path ?? "/de/pricing",
    meta: pricingrjN4BGUVYdMeta || {},
    alias: pricingrjN4BGUVYdMeta?.alias || [],
    redirect: pricingrjN4BGUVYdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingrjN4BGUVYdMeta?.name ?? "pricing___mx",
    path: pricingrjN4BGUVYdMeta?.path ?? "/mx/prueba-gratis",
    meta: pricingrjN4BGUVYdMeta || {},
    alias: pricingrjN4BGUVYdMeta?.alias || [],
    redirect: pricingrjN4BGUVYdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingrjN4BGUVYdMeta?.name ?? "pricing___fr",
    path: pricingrjN4BGUVYdMeta?.path ?? "/fr/pricing",
    meta: pricingrjN4BGUVYdMeta || {},
    alias: pricingrjN4BGUVYdMeta?.alias || [],
    redirect: pricingrjN4BGUVYdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingrjN4BGUVYdMeta?.name ?? "pricing___fr-ca",
    path: pricingrjN4BGUVYdMeta?.path ?? "/fr-ca/pricing",
    meta: pricingrjN4BGUVYdMeta || {},
    alias: pricingrjN4BGUVYdMeta?.alias || [],
    redirect: pricingrjN4BGUVYdMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IurlHNdaTSMeta?.name ?? "products-prefix-slug___en-US",
    path: _91slug_93IurlHNdaTSMeta?.path ?? "/products/:prefix()/:slug()",
    meta: { ...(_91slug_93IurlHNdaTSMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93IurlHNdaTSMeta?.alias || [],
    redirect: _91slug_93IurlHNdaTSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IurlHNdaTSMeta?.name ?? "products-prefix-slug___ca",
    path: _91slug_93IurlHNdaTSMeta?.path ?? "/ca/products/:prefix()/:slug()",
    meta: { ...(_91slug_93IurlHNdaTSMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93IurlHNdaTSMeta?.alias || [],
    redirect: _91slug_93IurlHNdaTSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IurlHNdaTSMeta?.name ?? "products-prefix-slug___nl",
    path: _91slug_93IurlHNdaTSMeta?.path ?? "/nl/products/:prefix()/:slug()",
    meta: { ...(_91slug_93IurlHNdaTSMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93IurlHNdaTSMeta?.alias || [],
    redirect: _91slug_93IurlHNdaTSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IurlHNdaTSMeta?.name ?? "products-prefix-slug___uk",
    path: _91slug_93IurlHNdaTSMeta?.path ?? "/uk/products/:prefix()/:slug()",
    meta: { ...(_91slug_93IurlHNdaTSMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93IurlHNdaTSMeta?.alias || [],
    redirect: _91slug_93IurlHNdaTSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IurlHNdaTSMeta?.name ?? "products-prefix-slug___de",
    path: _91slug_93IurlHNdaTSMeta?.path ?? "/de/products/:prefix()/:slug()",
    meta: { ...(_91slug_93IurlHNdaTSMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93IurlHNdaTSMeta?.alias || [],
    redirect: _91slug_93IurlHNdaTSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IurlHNdaTSMeta?.name ?? "products-prefix-slug___mx",
    path: _91slug_93IurlHNdaTSMeta?.path ?? "/mx/products/:prefix()/:slug()",
    meta: { ...(_91slug_93IurlHNdaTSMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93IurlHNdaTSMeta?.alias || [],
    redirect: _91slug_93IurlHNdaTSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IurlHNdaTSMeta?.name ?? "products-prefix-slug___fr",
    path: _91slug_93IurlHNdaTSMeta?.path ?? "/fr/products/:prefix()/:slug()",
    meta: { ...(_91slug_93IurlHNdaTSMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93IurlHNdaTSMeta?.alias || [],
    redirect: _91slug_93IurlHNdaTSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IurlHNdaTSMeta?.name ?? "products-prefix-slug___fr-ca",
    path: _91slug_93IurlHNdaTSMeta?.path ?? "/fr-ca/products/:prefix()/:slug()",
    meta: { ...(_91slug_93IurlHNdaTSMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93IurlHNdaTSMeta?.alias || [],
    redirect: _91slug_93IurlHNdaTSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qa2CmMQ82PMeta?.name ?? "products-slug___en-US",
    path: _91slug_93Qa2CmMQ82PMeta?.path ?? "/products/:slug()",
    meta: { ...(_91slug_93Qa2CmMQ82PMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Qa2CmMQ82PMeta?.alias || [],
    redirect: _91slug_93Qa2CmMQ82PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qa2CmMQ82PMeta?.name ?? "products-slug___ca",
    path: _91slug_93Qa2CmMQ82PMeta?.path ?? "/ca/products/:slug()",
    meta: { ...(_91slug_93Qa2CmMQ82PMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Qa2CmMQ82PMeta?.alias || [],
    redirect: _91slug_93Qa2CmMQ82PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qa2CmMQ82PMeta?.name ?? "products-slug___nl",
    path: _91slug_93Qa2CmMQ82PMeta?.path ?? "/nl/products/:slug()",
    meta: { ...(_91slug_93Qa2CmMQ82PMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Qa2CmMQ82PMeta?.alias || [],
    redirect: _91slug_93Qa2CmMQ82PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qa2CmMQ82PMeta?.name ?? "products-slug___uk",
    path: _91slug_93Qa2CmMQ82PMeta?.path ?? "/uk/products/:slug()",
    meta: { ...(_91slug_93Qa2CmMQ82PMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Qa2CmMQ82PMeta?.alias || [],
    redirect: _91slug_93Qa2CmMQ82PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qa2CmMQ82PMeta?.name ?? "products-slug___de",
    path: _91slug_93Qa2CmMQ82PMeta?.path ?? "/de/products/:slug()",
    meta: { ...(_91slug_93Qa2CmMQ82PMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Qa2CmMQ82PMeta?.alias || [],
    redirect: _91slug_93Qa2CmMQ82PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qa2CmMQ82PMeta?.name ?? "products-slug___mx",
    path: _91slug_93Qa2CmMQ82PMeta?.path ?? "/mx/products/:slug()",
    meta: { ...(_91slug_93Qa2CmMQ82PMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Qa2CmMQ82PMeta?.alias || [],
    redirect: _91slug_93Qa2CmMQ82PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qa2CmMQ82PMeta?.name ?? "products-slug___fr",
    path: _91slug_93Qa2CmMQ82PMeta?.path ?? "/fr/products/:slug()",
    meta: { ...(_91slug_93Qa2CmMQ82PMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Qa2CmMQ82PMeta?.alias || [],
    redirect: _91slug_93Qa2CmMQ82PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Qa2CmMQ82PMeta?.name ?? "products-slug___fr-ca",
    path: _91slug_93Qa2CmMQ82PMeta?.path ?? "/fr-ca/products/:slug()",
    meta: { ...(_91slug_93Qa2CmMQ82PMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93Qa2CmMQ82PMeta?.alias || [],
    redirect: _91slug_93Qa2CmMQ82PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingv58ydfGMyCMeta?.name ?? "products-equipment-monitoring-asset-tracking___en-US",
    path: asset_45trackingv58ydfGMyCMeta?.path ?? "/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingv58ydfGMyCMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingv58ydfGMyCMeta?.alias || [],
    redirect: asset_45trackingv58ydfGMyCMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingv58ydfGMyCMeta?.name ?? "products-equipment-monitoring-asset-tracking___ca",
    path: asset_45trackingv58ydfGMyCMeta?.path ?? "/ca/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingv58ydfGMyCMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingv58ydfGMyCMeta?.alias || [],
    redirect: asset_45trackingv58ydfGMyCMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingv58ydfGMyCMeta?.name ?? "products-equipment-monitoring-asset-tracking___nl",
    path: asset_45trackingv58ydfGMyCMeta?.path ?? "/nl/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingv58ydfGMyCMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingv58ydfGMyCMeta?.alias || [],
    redirect: asset_45trackingv58ydfGMyCMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingv58ydfGMyCMeta?.name ?? "products-equipment-monitoring-asset-tracking___uk",
    path: asset_45trackingv58ydfGMyCMeta?.path ?? "/uk/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingv58ydfGMyCMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingv58ydfGMyCMeta?.alias || [],
    redirect: asset_45trackingv58ydfGMyCMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingv58ydfGMyCMeta?.name ?? "products-equipment-monitoring-asset-tracking___de",
    path: asset_45trackingv58ydfGMyCMeta?.path ?? "/de/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingv58ydfGMyCMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingv58ydfGMyCMeta?.alias || [],
    redirect: asset_45trackingv58ydfGMyCMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingv58ydfGMyCMeta?.name ?? "products-equipment-monitoring-asset-tracking___mx",
    path: asset_45trackingv58ydfGMyCMeta?.path ?? "/mx/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingv58ydfGMyCMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingv58ydfGMyCMeta?.alias || [],
    redirect: asset_45trackingv58ydfGMyCMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingv58ydfGMyCMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr",
    path: asset_45trackingv58ydfGMyCMeta?.path ?? "/fr/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingv58ydfGMyCMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingv58ydfGMyCMeta?.alias || [],
    redirect: asset_45trackingv58ydfGMyCMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingv58ydfGMyCMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr-ca",
    path: asset_45trackingv58ydfGMyCMeta?.path ?? "/fr-ca/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingv58ydfGMyCMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingv58ydfGMyCMeta?.alias || [],
    redirect: asset_45trackingv58ydfGMyCMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinggZHjz4ncfPMeta?.name ?? "products-equipment-monitoring-equipment-tracking___en-US",
    path: equipment_45trackinggZHjz4ncfPMeta?.path ?? "/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinggZHjz4ncfPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinggZHjz4ncfPMeta?.alias || [],
    redirect: equipment_45trackinggZHjz4ncfPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinggZHjz4ncfPMeta?.name ?? "products-equipment-monitoring-equipment-tracking___ca",
    path: equipment_45trackinggZHjz4ncfPMeta?.path ?? "/ca/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinggZHjz4ncfPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinggZHjz4ncfPMeta?.alias || [],
    redirect: equipment_45trackinggZHjz4ncfPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinggZHjz4ncfPMeta?.name ?? "products-equipment-monitoring-equipment-tracking___nl",
    path: equipment_45trackinggZHjz4ncfPMeta?.path ?? "/nl/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinggZHjz4ncfPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinggZHjz4ncfPMeta?.alias || [],
    redirect: equipment_45trackinggZHjz4ncfPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinggZHjz4ncfPMeta?.name ?? "products-equipment-monitoring-equipment-tracking___uk",
    path: equipment_45trackinggZHjz4ncfPMeta?.path ?? "/uk/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinggZHjz4ncfPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinggZHjz4ncfPMeta?.alias || [],
    redirect: equipment_45trackinggZHjz4ncfPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinggZHjz4ncfPMeta?.name ?? "products-equipment-monitoring-equipment-tracking___de",
    path: equipment_45trackinggZHjz4ncfPMeta?.path ?? "/de/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinggZHjz4ncfPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinggZHjz4ncfPMeta?.alias || [],
    redirect: equipment_45trackinggZHjz4ncfPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinggZHjz4ncfPMeta?.name ?? "products-equipment-monitoring-equipment-tracking___mx",
    path: equipment_45trackinggZHjz4ncfPMeta?.path ?? "/mx/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinggZHjz4ncfPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinggZHjz4ncfPMeta?.alias || [],
    redirect: equipment_45trackinggZHjz4ncfPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinggZHjz4ncfPMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr",
    path: equipment_45trackinggZHjz4ncfPMeta?.path ?? "/fr/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinggZHjz4ncfPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinggZHjz4ncfPMeta?.alias || [],
    redirect: equipment_45trackinggZHjz4ncfPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackinggZHjz4ncfPMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr-ca",
    path: equipment_45trackinggZHjz4ncfPMeta?.path ?? "/fr-ca/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackinggZHjz4ncfPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackinggZHjz4ncfPMeta?.alias || [],
    redirect: equipment_45trackinggZHjz4ncfPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringOUbcnqUvh2Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___en-US",
    path: reefer_45monitoringOUbcnqUvh2Meta?.path ?? "/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringOUbcnqUvh2Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringOUbcnqUvh2Meta?.alias || [],
    redirect: reefer_45monitoringOUbcnqUvh2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringOUbcnqUvh2Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___ca",
    path: reefer_45monitoringOUbcnqUvh2Meta?.path ?? "/ca/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringOUbcnqUvh2Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringOUbcnqUvh2Meta?.alias || [],
    redirect: reefer_45monitoringOUbcnqUvh2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringOUbcnqUvh2Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___nl",
    path: reefer_45monitoringOUbcnqUvh2Meta?.path ?? "/nl/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringOUbcnqUvh2Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringOUbcnqUvh2Meta?.alias || [],
    redirect: reefer_45monitoringOUbcnqUvh2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringOUbcnqUvh2Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___uk",
    path: reefer_45monitoringOUbcnqUvh2Meta?.path ?? "/uk/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringOUbcnqUvh2Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringOUbcnqUvh2Meta?.alias || [],
    redirect: reefer_45monitoringOUbcnqUvh2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringOUbcnqUvh2Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___de",
    path: reefer_45monitoringOUbcnqUvh2Meta?.path ?? "/de/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringOUbcnqUvh2Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringOUbcnqUvh2Meta?.alias || [],
    redirect: reefer_45monitoringOUbcnqUvh2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringOUbcnqUvh2Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___mx",
    path: reefer_45monitoringOUbcnqUvh2Meta?.path ?? "/mx/productos/rastreo-equipos/refrigerados",
    meta: { ...(reefer_45monitoringOUbcnqUvh2Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringOUbcnqUvh2Meta?.alias || [],
    redirect: reefer_45monitoringOUbcnqUvh2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringOUbcnqUvh2Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr",
    path: reefer_45monitoringOUbcnqUvh2Meta?.path ?? "/fr/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringOUbcnqUvh2Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringOUbcnqUvh2Meta?.alias || [],
    redirect: reefer_45monitoringOUbcnqUvh2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringOUbcnqUvh2Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr-ca",
    path: reefer_45monitoringOUbcnqUvh2Meta?.path ?? "/fr-ca/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringOUbcnqUvh2Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringOUbcnqUvh2Meta?.alias || [],
    redirect: reefer_45monitoringOUbcnqUvh2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailers8I9mVnRjMbMeta?.name ?? "products-equipment-monitoring-smart-trailers___en-US",
    path: smart_45trailers8I9mVnRjMbMeta?.path ?? "/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailers8I9mVnRjMbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailers8I9mVnRjMbMeta?.alias || [],
    redirect: smart_45trailers8I9mVnRjMbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailers8I9mVnRjMbMeta?.name ?? "products-equipment-monitoring-smart-trailers___ca",
    path: smart_45trailers8I9mVnRjMbMeta?.path ?? "/ca/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailers8I9mVnRjMbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailers8I9mVnRjMbMeta?.alias || [],
    redirect: smart_45trailers8I9mVnRjMbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailers8I9mVnRjMbMeta?.name ?? "products-equipment-monitoring-smart-trailers___nl",
    path: smart_45trailers8I9mVnRjMbMeta?.path ?? "/nl/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailers8I9mVnRjMbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailers8I9mVnRjMbMeta?.alias || [],
    redirect: smart_45trailers8I9mVnRjMbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailers8I9mVnRjMbMeta?.name ?? "products-equipment-monitoring-smart-trailers___uk",
    path: smart_45trailers8I9mVnRjMbMeta?.path ?? "/uk/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailers8I9mVnRjMbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailers8I9mVnRjMbMeta?.alias || [],
    redirect: smart_45trailers8I9mVnRjMbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailers8I9mVnRjMbMeta?.name ?? "products-equipment-monitoring-smart-trailers___de",
    path: smart_45trailers8I9mVnRjMbMeta?.path ?? "/de/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailers8I9mVnRjMbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailers8I9mVnRjMbMeta?.alias || [],
    redirect: smart_45trailers8I9mVnRjMbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailers8I9mVnRjMbMeta?.name ?? "products-equipment-monitoring-smart-trailers___mx",
    path: smart_45trailers8I9mVnRjMbMeta?.path ?? "/mx/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailers8I9mVnRjMbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailers8I9mVnRjMbMeta?.alias || [],
    redirect: smart_45trailers8I9mVnRjMbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailers8I9mVnRjMbMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr",
    path: smart_45trailers8I9mVnRjMbMeta?.path ?? "/fr/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailers8I9mVnRjMbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailers8I9mVnRjMbMeta?.alias || [],
    redirect: smart_45trailers8I9mVnRjMbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailers8I9mVnRjMbMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr-ca",
    path: smart_45trailers8I9mVnRjMbMeta?.path ?? "/fr-ca/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailers8I9mVnRjMbMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailers8I9mVnRjMbMeta?.alias || [],
    redirect: smart_45trailers8I9mVnRjMbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingLYiwQKnI8MMeta?.name ?? "products-equipment-monitoring-trailer-tracking___en-US",
    path: trailer_45trackingLYiwQKnI8MMeta?.path ?? "/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingLYiwQKnI8MMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingLYiwQKnI8MMeta?.alias || [],
    redirect: trailer_45trackingLYiwQKnI8MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingLYiwQKnI8MMeta?.name ?? "products-equipment-monitoring-trailer-tracking___ca",
    path: trailer_45trackingLYiwQKnI8MMeta?.path ?? "/ca/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingLYiwQKnI8MMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingLYiwQKnI8MMeta?.alias || [],
    redirect: trailer_45trackingLYiwQKnI8MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingLYiwQKnI8MMeta?.name ?? "products-equipment-monitoring-trailer-tracking___nl",
    path: trailer_45trackingLYiwQKnI8MMeta?.path ?? "/nl/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingLYiwQKnI8MMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingLYiwQKnI8MMeta?.alias || [],
    redirect: trailer_45trackingLYiwQKnI8MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingLYiwQKnI8MMeta?.name ?? "products-equipment-monitoring-trailer-tracking___uk",
    path: trailer_45trackingLYiwQKnI8MMeta?.path ?? "/uk/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingLYiwQKnI8MMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingLYiwQKnI8MMeta?.alias || [],
    redirect: trailer_45trackingLYiwQKnI8MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingLYiwQKnI8MMeta?.name ?? "products-equipment-monitoring-trailer-tracking___de",
    path: trailer_45trackingLYiwQKnI8MMeta?.path ?? "/de/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingLYiwQKnI8MMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingLYiwQKnI8MMeta?.alias || [],
    redirect: trailer_45trackingLYiwQKnI8MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingLYiwQKnI8MMeta?.name ?? "products-equipment-monitoring-trailer-tracking___mx",
    path: trailer_45trackingLYiwQKnI8MMeta?.path ?? "/mx/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingLYiwQKnI8MMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingLYiwQKnI8MMeta?.alias || [],
    redirect: trailer_45trackingLYiwQKnI8MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingLYiwQKnI8MMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr",
    path: trailer_45trackingLYiwQKnI8MMeta?.path ?? "/fr/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingLYiwQKnI8MMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingLYiwQKnI8MMeta?.alias || [],
    redirect: trailer_45trackingLYiwQKnI8MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingLYiwQKnI8MMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr-ca",
    path: trailer_45trackingLYiwQKnI8MMeta?.path ?? "/fr-ca/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingLYiwQKnI8MMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingLYiwQKnI8MMeta?.alias || [],
    redirect: trailer_45trackingLYiwQKnI8MMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexpTzAa6Ye6RMeta?.name ?? "products___en-US",
    path: indexpTzAa6Ye6RMeta?.path ?? "/products",
    meta: { ...(indexpTzAa6Ye6RMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpTzAa6Ye6RMeta?.alias || [],
    redirect: indexpTzAa6Ye6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexpTzAa6Ye6RMeta?.name ?? "products___ca",
    path: indexpTzAa6Ye6RMeta?.path ?? "/ca/products",
    meta: { ...(indexpTzAa6Ye6RMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpTzAa6Ye6RMeta?.alias || [],
    redirect: indexpTzAa6Ye6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexpTzAa6Ye6RMeta?.name ?? "products___nl",
    path: indexpTzAa6Ye6RMeta?.path ?? "/nl/products",
    meta: { ...(indexpTzAa6Ye6RMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpTzAa6Ye6RMeta?.alias || [],
    redirect: indexpTzAa6Ye6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexpTzAa6Ye6RMeta?.name ?? "products___uk",
    path: indexpTzAa6Ye6RMeta?.path ?? "/uk/products",
    meta: { ...(indexpTzAa6Ye6RMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpTzAa6Ye6RMeta?.alias || [],
    redirect: indexpTzAa6Ye6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexpTzAa6Ye6RMeta?.name ?? "products___de",
    path: indexpTzAa6Ye6RMeta?.path ?? "/de/products",
    meta: { ...(indexpTzAa6Ye6RMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpTzAa6Ye6RMeta?.alias || [],
    redirect: indexpTzAa6Ye6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexpTzAa6Ye6RMeta?.name ?? "products___mx",
    path: indexpTzAa6Ye6RMeta?.path ?? "/mx/products",
    meta: { ...(indexpTzAa6Ye6RMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpTzAa6Ye6RMeta?.alias || [],
    redirect: indexpTzAa6Ye6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexpTzAa6Ye6RMeta?.name ?? "products___fr",
    path: indexpTzAa6Ye6RMeta?.path ?? "/fr/products",
    meta: { ...(indexpTzAa6Ye6RMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpTzAa6Ye6RMeta?.alias || [],
    redirect: indexpTzAa6Ye6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexpTzAa6Ye6RMeta?.name ?? "products___fr-ca",
    path: indexpTzAa6Ye6RMeta?.path ?? "/fr-ca/products",
    meta: { ...(indexpTzAa6Ye6RMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexpTzAa6Ye6RMeta?.alias || [],
    redirect: indexpTzAa6Ye6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UfhfgbbX3BMeta?.name ?? "products-models-slug___en-US",
    path: _91slug_93UfhfgbbX3BMeta?.path ?? "/products/models/:slug()",
    meta: { ...(_91slug_93UfhfgbbX3BMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93UfhfgbbX3BMeta?.alias || [],
    redirect: _91slug_93UfhfgbbX3BMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UfhfgbbX3BMeta?.name ?? "products-models-slug___ca",
    path: _91slug_93UfhfgbbX3BMeta?.path ?? "/ca/products/models/:slug()",
    meta: { ...(_91slug_93UfhfgbbX3BMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93UfhfgbbX3BMeta?.alias || [],
    redirect: _91slug_93UfhfgbbX3BMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UfhfgbbX3BMeta?.name ?? "products-models-slug___nl",
    path: _91slug_93UfhfgbbX3BMeta?.path ?? "/nl/products/models/:slug()",
    meta: { ...(_91slug_93UfhfgbbX3BMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93UfhfgbbX3BMeta?.alias || [],
    redirect: _91slug_93UfhfgbbX3BMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UfhfgbbX3BMeta?.name ?? "products-models-slug___uk",
    path: _91slug_93UfhfgbbX3BMeta?.path ?? "/uk/products/models/:slug()",
    meta: { ...(_91slug_93UfhfgbbX3BMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93UfhfgbbX3BMeta?.alias || [],
    redirect: _91slug_93UfhfgbbX3BMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UfhfgbbX3BMeta?.name ?? "products-models-slug___de",
    path: _91slug_93UfhfgbbX3BMeta?.path ?? "/de/products/models/:slug()",
    meta: { ...(_91slug_93UfhfgbbX3BMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93UfhfgbbX3BMeta?.alias || [],
    redirect: _91slug_93UfhfgbbX3BMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UfhfgbbX3BMeta?.name ?? "products-models-slug___mx",
    path: _91slug_93UfhfgbbX3BMeta?.path ?? "/mx/products/models/:slug()",
    meta: { ...(_91slug_93UfhfgbbX3BMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93UfhfgbbX3BMeta?.alias || [],
    redirect: _91slug_93UfhfgbbX3BMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UfhfgbbX3BMeta?.name ?? "products-models-slug___fr",
    path: _91slug_93UfhfgbbX3BMeta?.path ?? "/fr/products/models/:slug()",
    meta: { ...(_91slug_93UfhfgbbX3BMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93UfhfgbbX3BMeta?.alias || [],
    redirect: _91slug_93UfhfgbbX3BMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UfhfgbbX3BMeta?.name ?? "products-models-slug___fr-ca",
    path: _91slug_93UfhfgbbX3BMeta?.path ?? "/fr-ca/products/models/:slug()",
    meta: { ...(_91slug_93UfhfgbbX3BMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93UfhfgbbX3BMeta?.alias || [],
    redirect: _91slug_93UfhfgbbX3BMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexypATNXHtFMMeta?.name ?? "products-models___en-US",
    path: indexypATNXHtFMMeta?.path ?? "/products/models",
    meta: { ...(indexypATNXHtFMMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexypATNXHtFMMeta?.alias || [],
    redirect: indexypATNXHtFMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexypATNXHtFMMeta?.name ?? "products-models___ca",
    path: indexypATNXHtFMMeta?.path ?? "/ca/products/models",
    meta: { ...(indexypATNXHtFMMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexypATNXHtFMMeta?.alias || [],
    redirect: indexypATNXHtFMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexypATNXHtFMMeta?.name ?? "products-models___nl",
    path: indexypATNXHtFMMeta?.path ?? "/nl/products/models",
    meta: { ...(indexypATNXHtFMMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexypATNXHtFMMeta?.alias || [],
    redirect: indexypATNXHtFMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexypATNXHtFMMeta?.name ?? "products-models___uk",
    path: indexypATNXHtFMMeta?.path ?? "/uk/products/models",
    meta: { ...(indexypATNXHtFMMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexypATNXHtFMMeta?.alias || [],
    redirect: indexypATNXHtFMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexypATNXHtFMMeta?.name ?? "products-models___de",
    path: indexypATNXHtFMMeta?.path ?? "/de/products/models",
    meta: { ...(indexypATNXHtFMMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexypATNXHtFMMeta?.alias || [],
    redirect: indexypATNXHtFMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexypATNXHtFMMeta?.name ?? "products-models___mx",
    path: indexypATNXHtFMMeta?.path ?? "/mx/products/models",
    meta: { ...(indexypATNXHtFMMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexypATNXHtFMMeta?.alias || [],
    redirect: indexypATNXHtFMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexypATNXHtFMMeta?.name ?? "products-models___fr",
    path: indexypATNXHtFMMeta?.path ?? "/fr/products/models",
    meta: { ...(indexypATNXHtFMMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexypATNXHtFMMeta?.alias || [],
    redirect: indexypATNXHtFMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexypATNXHtFMMeta?.name ?? "products-models___fr-ca",
    path: indexypATNXHtFMMeta?.path ?? "/fr-ca/products/models",
    meta: { ...(indexypATNXHtFMMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexypATNXHtFMMeta?.alias || [],
    redirect: indexypATNXHtFMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingYfznTfz8HuMeta?.name ?? "products-telematics-gps-fleet-tracking___en-US",
    path: gps_45fleet_45trackingYfznTfz8HuMeta?.path ?? "/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingYfznTfz8HuMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingYfznTfz8HuMeta?.alias || [],
    redirect: gps_45fleet_45trackingYfznTfz8HuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingYfznTfz8HuMeta?.name ?? "products-telematics-gps-fleet-tracking___ca",
    path: gps_45fleet_45trackingYfznTfz8HuMeta?.path ?? "/ca/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingYfznTfz8HuMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingYfznTfz8HuMeta?.alias || [],
    redirect: gps_45fleet_45trackingYfznTfz8HuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingYfznTfz8HuMeta?.name ?? "products-telematics-gps-fleet-tracking___nl",
    path: gps_45fleet_45trackingYfznTfz8HuMeta?.path ?? "/nl/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingYfznTfz8HuMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingYfznTfz8HuMeta?.alias || [],
    redirect: gps_45fleet_45trackingYfznTfz8HuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingYfznTfz8HuMeta?.name ?? "products-telematics-gps-fleet-tracking___uk",
    path: gps_45fleet_45trackingYfznTfz8HuMeta?.path ?? "/uk/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingYfznTfz8HuMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingYfznTfz8HuMeta?.alias || [],
    redirect: gps_45fleet_45trackingYfznTfz8HuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingYfznTfz8HuMeta?.name ?? "products-telematics-gps-fleet-tracking___de",
    path: gps_45fleet_45trackingYfznTfz8HuMeta?.path ?? "/de/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingYfznTfz8HuMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingYfznTfz8HuMeta?.alias || [],
    redirect: gps_45fleet_45trackingYfznTfz8HuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingYfznTfz8HuMeta?.name ?? "products-telematics-gps-fleet-tracking___mx",
    path: gps_45fleet_45trackingYfznTfz8HuMeta?.path ?? "/mx/productos/telematica/rastreo-gps-flota",
    meta: { ...(gps_45fleet_45trackingYfznTfz8HuMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingYfznTfz8HuMeta?.alias || [],
    redirect: gps_45fleet_45trackingYfznTfz8HuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingYfznTfz8HuMeta?.name ?? "products-telematics-gps-fleet-tracking___fr",
    path: gps_45fleet_45trackingYfznTfz8HuMeta?.path ?? "/fr/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingYfznTfz8HuMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingYfznTfz8HuMeta?.alias || [],
    redirect: gps_45fleet_45trackingYfznTfz8HuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingYfznTfz8HuMeta?.name ?? "products-telematics-gps-fleet-tracking___fr-ca",
    path: gps_45fleet_45trackingYfznTfz8HuMeta?.path ?? "/fr-ca/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingYfznTfz8HuMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingYfznTfz8HuMeta?.alias || [],
    redirect: gps_45fleet_45trackingYfznTfz8HuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexH8esizLNEpMeta?.name ?? "products-telematics___en-US",
    path: indexH8esizLNEpMeta?.path ?? "/products/telematics",
    meta: { ...(indexH8esizLNEpMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexH8esizLNEpMeta?.alias || [],
    redirect: indexH8esizLNEpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexH8esizLNEpMeta?.name ?? "products-telematics___ca",
    path: indexH8esizLNEpMeta?.path ?? "/ca/products/telematics",
    meta: { ...(indexH8esizLNEpMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexH8esizLNEpMeta?.alias || [],
    redirect: indexH8esizLNEpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexH8esizLNEpMeta?.name ?? "products-telematics___nl",
    path: indexH8esizLNEpMeta?.path ?? "/nl/products/telematics",
    meta: { ...(indexH8esizLNEpMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexH8esizLNEpMeta?.alias || [],
    redirect: indexH8esizLNEpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexH8esizLNEpMeta?.name ?? "products-telematics___uk",
    path: indexH8esizLNEpMeta?.path ?? "/uk/products/telematics",
    meta: { ...(indexH8esizLNEpMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexH8esizLNEpMeta?.alias || [],
    redirect: indexH8esizLNEpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexH8esizLNEpMeta?.name ?? "products-telematics___de",
    path: indexH8esizLNEpMeta?.path ?? "/de/products/telematics",
    meta: { ...(indexH8esizLNEpMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexH8esizLNEpMeta?.alias || [],
    redirect: indexH8esizLNEpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexH8esizLNEpMeta?.name ?? "products-telematics___mx",
    path: indexH8esizLNEpMeta?.path ?? "/mx/products/flota",
    meta: { ...(indexH8esizLNEpMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexH8esizLNEpMeta?.alias || [],
    redirect: indexH8esizLNEpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexH8esizLNEpMeta?.name ?? "products-telematics___fr",
    path: indexH8esizLNEpMeta?.path ?? "/fr/products/telematics",
    meta: { ...(indexH8esizLNEpMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexH8esizLNEpMeta?.alias || [],
    redirect: indexH8esizLNEpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexH8esizLNEpMeta?.name ?? "products-telematics___fr-ca",
    path: indexH8esizLNEpMeta?.path ?? "/fr-ca/products/telematics",
    meta: { ...(indexH8esizLNEpMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexH8esizLNEpMeta?.alias || [],
    redirect: indexH8esizLNEpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: racingUPB4n1LTYjMeta?.name ?? "racing___en-US",
    path: racingUPB4n1LTYjMeta?.path ?? "/racing",
    meta: racingUPB4n1LTYjMeta || {},
    alias: racingUPB4n1LTYjMeta?.alias || [],
    redirect: racingUPB4n1LTYjMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/racing.vue").then(m => m.default || m)
  },
  {
    name: referralsJQfNiCF5xqMeta?.name ?? "referrals___en-US",
    path: referralsJQfNiCF5xqMeta?.path ?? "/referrals",
    meta: referralsJQfNiCF5xqMeta || {},
    alias: referralsJQfNiCF5xqMeta?.alias || [],
    redirect: referralsJQfNiCF5xqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsJQfNiCF5xqMeta?.name ?? "referrals___ca",
    path: referralsJQfNiCF5xqMeta?.path ?? "/ca/referrals",
    meta: referralsJQfNiCF5xqMeta || {},
    alias: referralsJQfNiCF5xqMeta?.alias || [],
    redirect: referralsJQfNiCF5xqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsJQfNiCF5xqMeta?.name ?? "referrals___nl",
    path: referralsJQfNiCF5xqMeta?.path ?? "/nl/referrals",
    meta: referralsJQfNiCF5xqMeta || {},
    alias: referralsJQfNiCF5xqMeta?.alias || [],
    redirect: referralsJQfNiCF5xqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsJQfNiCF5xqMeta?.name ?? "referrals___uk",
    path: referralsJQfNiCF5xqMeta?.path ?? "/uk/referrals",
    meta: referralsJQfNiCF5xqMeta || {},
    alias: referralsJQfNiCF5xqMeta?.alias || [],
    redirect: referralsJQfNiCF5xqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsJQfNiCF5xqMeta?.name ?? "referrals___de",
    path: referralsJQfNiCF5xqMeta?.path ?? "/de/referrals",
    meta: referralsJQfNiCF5xqMeta || {},
    alias: referralsJQfNiCF5xqMeta?.alias || [],
    redirect: referralsJQfNiCF5xqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsJQfNiCF5xqMeta?.name ?? "referrals___mx",
    path: referralsJQfNiCF5xqMeta?.path ?? "/mx/referrals",
    meta: referralsJQfNiCF5xqMeta || {},
    alias: referralsJQfNiCF5xqMeta?.alias || [],
    redirect: referralsJQfNiCF5xqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsJQfNiCF5xqMeta?.name ?? "referrals___fr",
    path: referralsJQfNiCF5xqMeta?.path ?? "/fr/referrals",
    meta: referralsJQfNiCF5xqMeta || {},
    alias: referralsJQfNiCF5xqMeta?.alias || [],
    redirect: referralsJQfNiCF5xqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsJQfNiCF5xqMeta?.name ?? "referrals___fr-ca",
    path: referralsJQfNiCF5xqMeta?.path ?? "/fr-ca/referrals",
    meta: referralsJQfNiCF5xqMeta || {},
    alias: referralsJQfNiCF5xqMeta?.alias || [],
    redirect: referralsJQfNiCF5xqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93du9jYQoDbnMeta?.name ?? "resources-slug___en-US",
    path: _91slug_93du9jYQoDbnMeta?.path ?? "/resources/:slug()",
    meta: _91slug_93du9jYQoDbnMeta || {},
    alias: _91slug_93du9jYQoDbnMeta?.alias || [],
    redirect: _91slug_93du9jYQoDbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93du9jYQoDbnMeta?.name ?? "resources-slug___ca",
    path: _91slug_93du9jYQoDbnMeta?.path ?? "/ca/resources/:slug()",
    meta: _91slug_93du9jYQoDbnMeta || {},
    alias: _91slug_93du9jYQoDbnMeta?.alias || [],
    redirect: _91slug_93du9jYQoDbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93du9jYQoDbnMeta?.name ?? "resources-slug___nl",
    path: _91slug_93du9jYQoDbnMeta?.path ?? "/nl/resources/:slug()",
    meta: _91slug_93du9jYQoDbnMeta || {},
    alias: _91slug_93du9jYQoDbnMeta?.alias || [],
    redirect: _91slug_93du9jYQoDbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93du9jYQoDbnMeta?.name ?? "resources-slug___uk",
    path: _91slug_93du9jYQoDbnMeta?.path ?? "/uk/resources/:slug()",
    meta: _91slug_93du9jYQoDbnMeta || {},
    alias: _91slug_93du9jYQoDbnMeta?.alias || [],
    redirect: _91slug_93du9jYQoDbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93du9jYQoDbnMeta?.name ?? "resources-slug___de",
    path: _91slug_93du9jYQoDbnMeta?.path ?? "/de/resources/:slug()",
    meta: _91slug_93du9jYQoDbnMeta || {},
    alias: _91slug_93du9jYQoDbnMeta?.alias || [],
    redirect: _91slug_93du9jYQoDbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93du9jYQoDbnMeta?.name ?? "resources-slug___mx",
    path: _91slug_93du9jYQoDbnMeta?.path ?? "/mx/resources/:slug()",
    meta: _91slug_93du9jYQoDbnMeta || {},
    alias: _91slug_93du9jYQoDbnMeta?.alias || [],
    redirect: _91slug_93du9jYQoDbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93du9jYQoDbnMeta?.name ?? "resources-slug___fr",
    path: _91slug_93du9jYQoDbnMeta?.path ?? "/fr/resources/:slug()",
    meta: _91slug_93du9jYQoDbnMeta || {},
    alias: _91slug_93du9jYQoDbnMeta?.alias || [],
    redirect: _91slug_93du9jYQoDbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93du9jYQoDbnMeta?.name ?? "resources-slug___fr-ca",
    path: _91slug_93du9jYQoDbnMeta?.path ?? "/fr-ca/resources/:slug()",
    meta: _91slug_93du9jYQoDbnMeta || {},
    alias: _91slug_93du9jYQoDbnMeta?.alias || [],
    redirect: _91slug_93du9jYQoDbnMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsQSUz0ePaw6Meta?.name ?? "resources-brand-assets___en-US",
    path: brand_45assetsQSUz0ePaw6Meta?.path ?? "/resources/brand-assets",
    meta: brand_45assetsQSUz0ePaw6Meta || {},
    alias: brand_45assetsQSUz0ePaw6Meta?.alias || [],
    redirect: brand_45assetsQSUz0ePaw6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsQSUz0ePaw6Meta?.name ?? "resources-brand-assets___ca",
    path: brand_45assetsQSUz0ePaw6Meta?.path ?? "/ca/resources/brand-assets",
    meta: brand_45assetsQSUz0ePaw6Meta || {},
    alias: brand_45assetsQSUz0ePaw6Meta?.alias || [],
    redirect: brand_45assetsQSUz0ePaw6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsQSUz0ePaw6Meta?.name ?? "resources-brand-assets___nl",
    path: brand_45assetsQSUz0ePaw6Meta?.path ?? "/nl/resources/brand-assets",
    meta: brand_45assetsQSUz0ePaw6Meta || {},
    alias: brand_45assetsQSUz0ePaw6Meta?.alias || [],
    redirect: brand_45assetsQSUz0ePaw6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsQSUz0ePaw6Meta?.name ?? "resources-brand-assets___uk",
    path: brand_45assetsQSUz0ePaw6Meta?.path ?? "/uk/resources/brand-assets",
    meta: brand_45assetsQSUz0ePaw6Meta || {},
    alias: brand_45assetsQSUz0ePaw6Meta?.alias || [],
    redirect: brand_45assetsQSUz0ePaw6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsQSUz0ePaw6Meta?.name ?? "resources-brand-assets___de",
    path: brand_45assetsQSUz0ePaw6Meta?.path ?? "/de/resources/brand-assets",
    meta: brand_45assetsQSUz0ePaw6Meta || {},
    alias: brand_45assetsQSUz0ePaw6Meta?.alias || [],
    redirect: brand_45assetsQSUz0ePaw6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsQSUz0ePaw6Meta?.name ?? "resources-brand-assets___mx",
    path: brand_45assetsQSUz0ePaw6Meta?.path ?? "/mx/resources/brand-assets",
    meta: brand_45assetsQSUz0ePaw6Meta || {},
    alias: brand_45assetsQSUz0ePaw6Meta?.alias || [],
    redirect: brand_45assetsQSUz0ePaw6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsQSUz0ePaw6Meta?.name ?? "resources-brand-assets___fr",
    path: brand_45assetsQSUz0ePaw6Meta?.path ?? "/fr/resources/brand-assets",
    meta: brand_45assetsQSUz0ePaw6Meta || {},
    alias: brand_45assetsQSUz0ePaw6Meta?.alias || [],
    redirect: brand_45assetsQSUz0ePaw6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsQSUz0ePaw6Meta?.name ?? "resources-brand-assets___fr-ca",
    path: brand_45assetsQSUz0ePaw6Meta?.path ?? "/fr-ca/resources/brand-assets",
    meta: brand_45assetsQSUz0ePaw6Meta || {},
    alias: brand_45assetsQSUz0ePaw6Meta?.alias || [],
    redirect: brand_45assetsQSUz0ePaw6Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93J9I6VfWn8VMeta?.name ?? "resources-content-slug___en-US",
    path: _91slug_93J9I6VfWn8VMeta?.path ?? "/resources/content/:slug()",
    meta: _91slug_93J9I6VfWn8VMeta || {},
    alias: _91slug_93J9I6VfWn8VMeta?.alias || [],
    redirect: _91slug_93J9I6VfWn8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93J9I6VfWn8VMeta?.name ?? "resources-content-slug___ca",
    path: _91slug_93J9I6VfWn8VMeta?.path ?? "/ca/resources/content/:slug()",
    meta: _91slug_93J9I6VfWn8VMeta || {},
    alias: _91slug_93J9I6VfWn8VMeta?.alias || [],
    redirect: _91slug_93J9I6VfWn8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93J9I6VfWn8VMeta?.name ?? "resources-content-slug___nl",
    path: _91slug_93J9I6VfWn8VMeta?.path ?? "/nl/resources/content/:slug()",
    meta: _91slug_93J9I6VfWn8VMeta || {},
    alias: _91slug_93J9I6VfWn8VMeta?.alias || [],
    redirect: _91slug_93J9I6VfWn8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93J9I6VfWn8VMeta?.name ?? "resources-content-slug___uk",
    path: _91slug_93J9I6VfWn8VMeta?.path ?? "/uk/resources/content/:slug()",
    meta: _91slug_93J9I6VfWn8VMeta || {},
    alias: _91slug_93J9I6VfWn8VMeta?.alias || [],
    redirect: _91slug_93J9I6VfWn8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93J9I6VfWn8VMeta?.name ?? "resources-content-slug___de",
    path: _91slug_93J9I6VfWn8VMeta?.path ?? "/de/resources/content/:slug()",
    meta: _91slug_93J9I6VfWn8VMeta || {},
    alias: _91slug_93J9I6VfWn8VMeta?.alias || [],
    redirect: _91slug_93J9I6VfWn8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93J9I6VfWn8VMeta?.name ?? "resources-content-slug___mx",
    path: _91slug_93J9I6VfWn8VMeta?.path ?? "/mx/resources/content/:slug()",
    meta: _91slug_93J9I6VfWn8VMeta || {},
    alias: _91slug_93J9I6VfWn8VMeta?.alias || [],
    redirect: _91slug_93J9I6VfWn8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93J9I6VfWn8VMeta?.name ?? "resources-content-slug___fr",
    path: _91slug_93J9I6VfWn8VMeta?.path ?? "/fr/resources/content/:slug()",
    meta: _91slug_93J9I6VfWn8VMeta || {},
    alias: _91slug_93J9I6VfWn8VMeta?.alias || [],
    redirect: _91slug_93J9I6VfWn8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93J9I6VfWn8VMeta?.name ?? "resources-content-slug___fr-ca",
    path: _91slug_93J9I6VfWn8VMeta?.path ?? "/fr-ca/resources/content/:slug()",
    meta: _91slug_93J9I6VfWn8VMeta || {},
    alias: _91slug_93J9I6VfWn8VMeta?.alias || [],
    redirect: _91slug_93J9I6VfWn8VMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerXCW4gCdxDrMeta?.name ?? "resources-content-email-preferences-center___en-US",
    path: email_45preferences_45centerXCW4gCdxDrMeta?.path ?? "/resources/content/email-preferences-center",
    meta: email_45preferences_45centerXCW4gCdxDrMeta || {},
    alias: email_45preferences_45centerXCW4gCdxDrMeta?.alias || [],
    redirect: email_45preferences_45centerXCW4gCdxDrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerXCW4gCdxDrMeta?.name ?? "resources-content-email-preferences-center___ca",
    path: email_45preferences_45centerXCW4gCdxDrMeta?.path ?? "/ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerXCW4gCdxDrMeta || {},
    alias: email_45preferences_45centerXCW4gCdxDrMeta?.alias || [],
    redirect: email_45preferences_45centerXCW4gCdxDrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerXCW4gCdxDrMeta?.name ?? "resources-content-email-preferences-center___nl",
    path: email_45preferences_45centerXCW4gCdxDrMeta?.path ?? "/nl/resources/content/email-preferences-center",
    meta: email_45preferences_45centerXCW4gCdxDrMeta || {},
    alias: email_45preferences_45centerXCW4gCdxDrMeta?.alias || [],
    redirect: email_45preferences_45centerXCW4gCdxDrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerXCW4gCdxDrMeta?.name ?? "resources-content-email-preferences-center___uk",
    path: email_45preferences_45centerXCW4gCdxDrMeta?.path ?? "/uk/resources/content/email-preferences-center",
    meta: email_45preferences_45centerXCW4gCdxDrMeta || {},
    alias: email_45preferences_45centerXCW4gCdxDrMeta?.alias || [],
    redirect: email_45preferences_45centerXCW4gCdxDrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerXCW4gCdxDrMeta?.name ?? "resources-content-email-preferences-center___de",
    path: email_45preferences_45centerXCW4gCdxDrMeta?.path ?? "/de/resources/content/email-preferences-center",
    meta: email_45preferences_45centerXCW4gCdxDrMeta || {},
    alias: email_45preferences_45centerXCW4gCdxDrMeta?.alias || [],
    redirect: email_45preferences_45centerXCW4gCdxDrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerXCW4gCdxDrMeta?.name ?? "resources-content-email-preferences-center___mx",
    path: email_45preferences_45centerXCW4gCdxDrMeta?.path ?? "/mx/resources/content/email-preferences-center",
    meta: email_45preferences_45centerXCW4gCdxDrMeta || {},
    alias: email_45preferences_45centerXCW4gCdxDrMeta?.alias || [],
    redirect: email_45preferences_45centerXCW4gCdxDrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerXCW4gCdxDrMeta?.name ?? "resources-content-email-preferences-center___fr",
    path: email_45preferences_45centerXCW4gCdxDrMeta?.path ?? "/fr/resources/content/email-preferences-center",
    meta: email_45preferences_45centerXCW4gCdxDrMeta || {},
    alias: email_45preferences_45centerXCW4gCdxDrMeta?.alias || [],
    redirect: email_45preferences_45centerXCW4gCdxDrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerXCW4gCdxDrMeta?.name ?? "resources-content-email-preferences-center___fr-ca",
    path: email_45preferences_45centerXCW4gCdxDrMeta?.path ?? "/fr-ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerXCW4gCdxDrMeta || {},
    alias: email_45preferences_45centerXCW4gCdxDrMeta?.alias || [],
    redirect: email_45preferences_45centerXCW4gCdxDrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribepJblB3IuKmMeta?.name ?? "resources-content-one-click-unsubscribe___en-US",
    path: one_45click_45unsubscribepJblB3IuKmMeta?.path ?? "/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribepJblB3IuKmMeta || {},
    alias: one_45click_45unsubscribepJblB3IuKmMeta?.alias || [],
    redirect: one_45click_45unsubscribepJblB3IuKmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribepJblB3IuKmMeta?.name ?? "resources-content-one-click-unsubscribe___ca",
    path: one_45click_45unsubscribepJblB3IuKmMeta?.path ?? "/ca/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribepJblB3IuKmMeta || {},
    alias: one_45click_45unsubscribepJblB3IuKmMeta?.alias || [],
    redirect: one_45click_45unsubscribepJblB3IuKmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribepJblB3IuKmMeta?.name ?? "resources-content-one-click-unsubscribe___nl",
    path: one_45click_45unsubscribepJblB3IuKmMeta?.path ?? "/nl/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribepJblB3IuKmMeta || {},
    alias: one_45click_45unsubscribepJblB3IuKmMeta?.alias || [],
    redirect: one_45click_45unsubscribepJblB3IuKmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribepJblB3IuKmMeta?.name ?? "resources-content-one-click-unsubscribe___uk",
    path: one_45click_45unsubscribepJblB3IuKmMeta?.path ?? "/uk/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribepJblB3IuKmMeta || {},
    alias: one_45click_45unsubscribepJblB3IuKmMeta?.alias || [],
    redirect: one_45click_45unsubscribepJblB3IuKmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribepJblB3IuKmMeta?.name ?? "resources-content-one-click-unsubscribe___de",
    path: one_45click_45unsubscribepJblB3IuKmMeta?.path ?? "/de/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribepJblB3IuKmMeta || {},
    alias: one_45click_45unsubscribepJblB3IuKmMeta?.alias || [],
    redirect: one_45click_45unsubscribepJblB3IuKmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribepJblB3IuKmMeta?.name ?? "resources-content-one-click-unsubscribe___mx",
    path: one_45click_45unsubscribepJblB3IuKmMeta?.path ?? "/mx/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribepJblB3IuKmMeta || {},
    alias: one_45click_45unsubscribepJblB3IuKmMeta?.alias || [],
    redirect: one_45click_45unsubscribepJblB3IuKmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribepJblB3IuKmMeta?.name ?? "resources-content-one-click-unsubscribe___fr",
    path: one_45click_45unsubscribepJblB3IuKmMeta?.path ?? "/fr/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribepJblB3IuKmMeta || {},
    alias: one_45click_45unsubscribepJblB3IuKmMeta?.alias || [],
    redirect: one_45click_45unsubscribepJblB3IuKmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribepJblB3IuKmMeta?.name ?? "resources-content-one-click-unsubscribe___fr-ca",
    path: one_45click_45unsubscribepJblB3IuKmMeta?.path ?? "/fr-ca/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribepJblB3IuKmMeta || {},
    alias: one_45click_45unsubscribepJblB3IuKmMeta?.alias || [],
    redirect: one_45click_45unsubscribepJblB3IuKmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939vdm5DEFpGMeta?.name ?? "resources-experts-slug___en-US",
    path: _91slug_939vdm5DEFpGMeta?.path ?? "/resources/experts/:slug()",
    meta: _91slug_939vdm5DEFpGMeta || {},
    alias: _91slug_939vdm5DEFpGMeta?.alias || [],
    redirect: _91slug_939vdm5DEFpGMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939vdm5DEFpGMeta?.name ?? "resources-experts-slug___ca",
    path: _91slug_939vdm5DEFpGMeta?.path ?? "/ca/resources/experts/:slug()",
    meta: _91slug_939vdm5DEFpGMeta || {},
    alias: _91slug_939vdm5DEFpGMeta?.alias || [],
    redirect: _91slug_939vdm5DEFpGMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939vdm5DEFpGMeta?.name ?? "resources-experts-slug___nl",
    path: _91slug_939vdm5DEFpGMeta?.path ?? "/nl/resources/experts/:slug()",
    meta: _91slug_939vdm5DEFpGMeta || {},
    alias: _91slug_939vdm5DEFpGMeta?.alias || [],
    redirect: _91slug_939vdm5DEFpGMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939vdm5DEFpGMeta?.name ?? "resources-experts-slug___uk",
    path: _91slug_939vdm5DEFpGMeta?.path ?? "/uk/resources/experts/:slug()",
    meta: _91slug_939vdm5DEFpGMeta || {},
    alias: _91slug_939vdm5DEFpGMeta?.alias || [],
    redirect: _91slug_939vdm5DEFpGMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939vdm5DEFpGMeta?.name ?? "resources-experts-slug___de",
    path: _91slug_939vdm5DEFpGMeta?.path ?? "/de/resources/experts/:slug()",
    meta: _91slug_939vdm5DEFpGMeta || {},
    alias: _91slug_939vdm5DEFpGMeta?.alias || [],
    redirect: _91slug_939vdm5DEFpGMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939vdm5DEFpGMeta?.name ?? "resources-experts-slug___mx",
    path: _91slug_939vdm5DEFpGMeta?.path ?? "/mx/resources/experts/:slug()",
    meta: _91slug_939vdm5DEFpGMeta || {},
    alias: _91slug_939vdm5DEFpGMeta?.alias || [],
    redirect: _91slug_939vdm5DEFpGMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939vdm5DEFpGMeta?.name ?? "resources-experts-slug___fr",
    path: _91slug_939vdm5DEFpGMeta?.path ?? "/fr/resources/experts/:slug()",
    meta: _91slug_939vdm5DEFpGMeta || {},
    alias: _91slug_939vdm5DEFpGMeta?.alias || [],
    redirect: _91slug_939vdm5DEFpGMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939vdm5DEFpGMeta?.name ?? "resources-experts-slug___fr-ca",
    path: _91slug_939vdm5DEFpGMeta?.path ?? "/fr-ca/resources/experts/:slug()",
    meta: _91slug_939vdm5DEFpGMeta || {},
    alias: _91slug_939vdm5DEFpGMeta?.alias || [],
    redirect: _91slug_939vdm5DEFpGMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93usYu4vUxnrMeta?.name ?? "resources-experts-category-slug___en-US",
    path: _91slug_93usYu4vUxnrMeta?.path ?? "/resources/experts/category/:slug()",
    meta: _91slug_93usYu4vUxnrMeta || {},
    alias: _91slug_93usYu4vUxnrMeta?.alias || [],
    redirect: _91slug_93usYu4vUxnrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93usYu4vUxnrMeta?.name ?? "resources-experts-category-slug___ca",
    path: _91slug_93usYu4vUxnrMeta?.path ?? "/ca/resources/experts/category/:slug()",
    meta: _91slug_93usYu4vUxnrMeta || {},
    alias: _91slug_93usYu4vUxnrMeta?.alias || [],
    redirect: _91slug_93usYu4vUxnrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93usYu4vUxnrMeta?.name ?? "resources-experts-category-slug___nl",
    path: _91slug_93usYu4vUxnrMeta?.path ?? "/nl/resources/experts/category/:slug()",
    meta: _91slug_93usYu4vUxnrMeta || {},
    alias: _91slug_93usYu4vUxnrMeta?.alias || [],
    redirect: _91slug_93usYu4vUxnrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93usYu4vUxnrMeta?.name ?? "resources-experts-category-slug___uk",
    path: _91slug_93usYu4vUxnrMeta?.path ?? "/uk/resources/experts/category/:slug()",
    meta: _91slug_93usYu4vUxnrMeta || {},
    alias: _91slug_93usYu4vUxnrMeta?.alias || [],
    redirect: _91slug_93usYu4vUxnrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93usYu4vUxnrMeta?.name ?? "resources-experts-category-slug___de",
    path: _91slug_93usYu4vUxnrMeta?.path ?? "/de/resources/experts/category/:slug()",
    meta: _91slug_93usYu4vUxnrMeta || {},
    alias: _91slug_93usYu4vUxnrMeta?.alias || [],
    redirect: _91slug_93usYu4vUxnrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93usYu4vUxnrMeta?.name ?? "resources-experts-category-slug___mx",
    path: _91slug_93usYu4vUxnrMeta?.path ?? "/mx/resources/experts/category/:slug()",
    meta: _91slug_93usYu4vUxnrMeta || {},
    alias: _91slug_93usYu4vUxnrMeta?.alias || [],
    redirect: _91slug_93usYu4vUxnrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93usYu4vUxnrMeta?.name ?? "resources-experts-category-slug___fr",
    path: _91slug_93usYu4vUxnrMeta?.path ?? "/fr/resources/experts/category/:slug()",
    meta: _91slug_93usYu4vUxnrMeta || {},
    alias: _91slug_93usYu4vUxnrMeta?.alias || [],
    redirect: _91slug_93usYu4vUxnrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93usYu4vUxnrMeta?.name ?? "resources-experts-category-slug___fr-ca",
    path: _91slug_93usYu4vUxnrMeta?.path ?? "/fr-ca/resources/experts/category/:slug()",
    meta: _91slug_93usYu4vUxnrMeta || {},
    alias: _91slug_93usYu4vUxnrMeta?.alias || [],
    redirect: _91slug_93usYu4vUxnrMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexjyeGcpJN6RMeta?.name ?? "resources-experts___en-US",
    path: indexjyeGcpJN6RMeta?.path ?? "/resources/experts",
    meta: indexjyeGcpJN6RMeta || {},
    alias: indexjyeGcpJN6RMeta?.alias || [],
    redirect: indexjyeGcpJN6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexjyeGcpJN6RMeta?.name ?? "resources-experts___ca",
    path: indexjyeGcpJN6RMeta?.path ?? "/ca/resources/experts",
    meta: indexjyeGcpJN6RMeta || {},
    alias: indexjyeGcpJN6RMeta?.alias || [],
    redirect: indexjyeGcpJN6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexjyeGcpJN6RMeta?.name ?? "resources-experts___nl",
    path: indexjyeGcpJN6RMeta?.path ?? "/nl/resources/experts",
    meta: indexjyeGcpJN6RMeta || {},
    alias: indexjyeGcpJN6RMeta?.alias || [],
    redirect: indexjyeGcpJN6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexjyeGcpJN6RMeta?.name ?? "resources-experts___uk",
    path: indexjyeGcpJN6RMeta?.path ?? "/uk/resources/experts",
    meta: indexjyeGcpJN6RMeta || {},
    alias: indexjyeGcpJN6RMeta?.alias || [],
    redirect: indexjyeGcpJN6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexjyeGcpJN6RMeta?.name ?? "resources-experts___de",
    path: indexjyeGcpJN6RMeta?.path ?? "/de/resources/experts",
    meta: indexjyeGcpJN6RMeta || {},
    alias: indexjyeGcpJN6RMeta?.alias || [],
    redirect: indexjyeGcpJN6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexjyeGcpJN6RMeta?.name ?? "resources-experts___mx",
    path: indexjyeGcpJN6RMeta?.path ?? "/mx/resources/experts",
    meta: indexjyeGcpJN6RMeta || {},
    alias: indexjyeGcpJN6RMeta?.alias || [],
    redirect: indexjyeGcpJN6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexjyeGcpJN6RMeta?.name ?? "resources-experts___fr",
    path: indexjyeGcpJN6RMeta?.path ?? "/fr/resources/experts",
    meta: indexjyeGcpJN6RMeta || {},
    alias: indexjyeGcpJN6RMeta?.alias || [],
    redirect: indexjyeGcpJN6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexjyeGcpJN6RMeta?.name ?? "resources-experts___fr-ca",
    path: indexjyeGcpJN6RMeta?.path ?? "/fr-ca/resources/experts",
    meta: indexjyeGcpJN6RMeta || {},
    alias: indexjyeGcpJN6RMeta?.alias || [],
    redirect: indexjyeGcpJN6RMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAdjXqAB49Meta?.name ?? "resources___en-US",
    path: indexjAdjXqAB49Meta?.path ?? "/resources",
    meta: indexjAdjXqAB49Meta || {},
    alias: indexjAdjXqAB49Meta?.alias || [],
    redirect: indexjAdjXqAB49Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAdjXqAB49Meta?.name ?? "resources___ca",
    path: indexjAdjXqAB49Meta?.path ?? "/ca/resources",
    meta: indexjAdjXqAB49Meta || {},
    alias: indexjAdjXqAB49Meta?.alias || [],
    redirect: indexjAdjXqAB49Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAdjXqAB49Meta?.name ?? "resources___nl",
    path: indexjAdjXqAB49Meta?.path ?? "/nl/resources",
    meta: indexjAdjXqAB49Meta || {},
    alias: indexjAdjXqAB49Meta?.alias || [],
    redirect: indexjAdjXqAB49Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAdjXqAB49Meta?.name ?? "resources___uk",
    path: indexjAdjXqAB49Meta?.path ?? "/uk/resources",
    meta: indexjAdjXqAB49Meta || {},
    alias: indexjAdjXqAB49Meta?.alias || [],
    redirect: indexjAdjXqAB49Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAdjXqAB49Meta?.name ?? "resources___de",
    path: indexjAdjXqAB49Meta?.path ?? "/de/resources",
    meta: indexjAdjXqAB49Meta || {},
    alias: indexjAdjXqAB49Meta?.alias || [],
    redirect: indexjAdjXqAB49Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAdjXqAB49Meta?.name ?? "resources___mx",
    path: indexjAdjXqAB49Meta?.path ?? "/mx/resources",
    meta: indexjAdjXqAB49Meta || {},
    alias: indexjAdjXqAB49Meta?.alias || [],
    redirect: indexjAdjXqAB49Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAdjXqAB49Meta?.name ?? "resources___fr",
    path: indexjAdjXqAB49Meta?.path ?? "/fr/resources",
    meta: indexjAdjXqAB49Meta || {},
    alias: indexjAdjXqAB49Meta?.alias || [],
    redirect: indexjAdjXqAB49Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAdjXqAB49Meta?.name ?? "resources___fr-ca",
    path: indexjAdjXqAB49Meta?.path ?? "/fr-ca/resources",
    meta: indexjAdjXqAB49Meta || {},
    alias: indexjAdjXqAB49Meta?.alias || [],
    redirect: indexjAdjXqAB49Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Hw4siGgSMcMeta?.name ?? "resources-marketplace-slug___en-US",
    path: _91slug_93Hw4siGgSMcMeta?.path ?? "/resources/marketplace/:slug()",
    meta: _91slug_93Hw4siGgSMcMeta || {},
    alias: _91slug_93Hw4siGgSMcMeta?.alias || [],
    redirect: _91slug_93Hw4siGgSMcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Hw4siGgSMcMeta?.name ?? "resources-marketplace-slug___ca",
    path: _91slug_93Hw4siGgSMcMeta?.path ?? "/ca/resources/marketplace/:slug()",
    meta: _91slug_93Hw4siGgSMcMeta || {},
    alias: _91slug_93Hw4siGgSMcMeta?.alias || [],
    redirect: _91slug_93Hw4siGgSMcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Hw4siGgSMcMeta?.name ?? "resources-marketplace-slug___nl",
    path: _91slug_93Hw4siGgSMcMeta?.path ?? "/nl/resources/marketplace/:slug()",
    meta: _91slug_93Hw4siGgSMcMeta || {},
    alias: _91slug_93Hw4siGgSMcMeta?.alias || [],
    redirect: _91slug_93Hw4siGgSMcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Hw4siGgSMcMeta?.name ?? "resources-marketplace-slug___uk",
    path: _91slug_93Hw4siGgSMcMeta?.path ?? "/uk/resources/marketplace/:slug()",
    meta: _91slug_93Hw4siGgSMcMeta || {},
    alias: _91slug_93Hw4siGgSMcMeta?.alias || [],
    redirect: _91slug_93Hw4siGgSMcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Hw4siGgSMcMeta?.name ?? "resources-marketplace-slug___de",
    path: _91slug_93Hw4siGgSMcMeta?.path ?? "/de/resources/marketplace/:slug()",
    meta: _91slug_93Hw4siGgSMcMeta || {},
    alias: _91slug_93Hw4siGgSMcMeta?.alias || [],
    redirect: _91slug_93Hw4siGgSMcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Hw4siGgSMcMeta?.name ?? "resources-marketplace-slug___mx",
    path: _91slug_93Hw4siGgSMcMeta?.path ?? "/mx/resources/marketplace/:slug()",
    meta: _91slug_93Hw4siGgSMcMeta || {},
    alias: _91slug_93Hw4siGgSMcMeta?.alias || [],
    redirect: _91slug_93Hw4siGgSMcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Hw4siGgSMcMeta?.name ?? "resources-marketplace-slug___fr",
    path: _91slug_93Hw4siGgSMcMeta?.path ?? "/fr/resources/marketplace/:slug()",
    meta: _91slug_93Hw4siGgSMcMeta || {},
    alias: _91slug_93Hw4siGgSMcMeta?.alias || [],
    redirect: _91slug_93Hw4siGgSMcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Hw4siGgSMcMeta?.name ?? "resources-marketplace-slug___fr-ca",
    path: _91slug_93Hw4siGgSMcMeta?.path ?? "/fr-ca/resources/marketplace/:slug()",
    meta: _91slug_93Hw4siGgSMcMeta || {},
    alias: _91slug_93Hw4siGgSMcMeta?.alias || [],
    redirect: _91slug_93Hw4siGgSMcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CR0VaY2hcDMeta?.name ?? "resources-marketplace-category-slug___en-US",
    path: _91slug_93CR0VaY2hcDMeta?.path ?? "/resources/marketplace/category/:slug()",
    meta: _91slug_93CR0VaY2hcDMeta || {},
    alias: _91slug_93CR0VaY2hcDMeta?.alias || [],
    redirect: _91slug_93CR0VaY2hcDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CR0VaY2hcDMeta?.name ?? "resources-marketplace-category-slug___ca",
    path: _91slug_93CR0VaY2hcDMeta?.path ?? "/ca/resources/marketplace/category/:slug()",
    meta: _91slug_93CR0VaY2hcDMeta || {},
    alias: _91slug_93CR0VaY2hcDMeta?.alias || [],
    redirect: _91slug_93CR0VaY2hcDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CR0VaY2hcDMeta?.name ?? "resources-marketplace-category-slug___nl",
    path: _91slug_93CR0VaY2hcDMeta?.path ?? "/nl/resources/marketplace/category/:slug()",
    meta: _91slug_93CR0VaY2hcDMeta || {},
    alias: _91slug_93CR0VaY2hcDMeta?.alias || [],
    redirect: _91slug_93CR0VaY2hcDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CR0VaY2hcDMeta?.name ?? "resources-marketplace-category-slug___uk",
    path: _91slug_93CR0VaY2hcDMeta?.path ?? "/uk/resources/marketplace/category/:slug()",
    meta: _91slug_93CR0VaY2hcDMeta || {},
    alias: _91slug_93CR0VaY2hcDMeta?.alias || [],
    redirect: _91slug_93CR0VaY2hcDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CR0VaY2hcDMeta?.name ?? "resources-marketplace-category-slug___de",
    path: _91slug_93CR0VaY2hcDMeta?.path ?? "/de/resources/marketplace/category/:slug()",
    meta: _91slug_93CR0VaY2hcDMeta || {},
    alias: _91slug_93CR0VaY2hcDMeta?.alias || [],
    redirect: _91slug_93CR0VaY2hcDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CR0VaY2hcDMeta?.name ?? "resources-marketplace-category-slug___mx",
    path: _91slug_93CR0VaY2hcDMeta?.path ?? "/mx/resources/marketplace/category/:slug()",
    meta: _91slug_93CR0VaY2hcDMeta || {},
    alias: _91slug_93CR0VaY2hcDMeta?.alias || [],
    redirect: _91slug_93CR0VaY2hcDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CR0VaY2hcDMeta?.name ?? "resources-marketplace-category-slug___fr",
    path: _91slug_93CR0VaY2hcDMeta?.path ?? "/fr/resources/marketplace/category/:slug()",
    meta: _91slug_93CR0VaY2hcDMeta || {},
    alias: _91slug_93CR0VaY2hcDMeta?.alias || [],
    redirect: _91slug_93CR0VaY2hcDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CR0VaY2hcDMeta?.name ?? "resources-marketplace-category-slug___fr-ca",
    path: _91slug_93CR0VaY2hcDMeta?.path ?? "/fr-ca/resources/marketplace/category/:slug()",
    meta: _91slug_93CR0VaY2hcDMeta || {},
    alias: _91slug_93CR0VaY2hcDMeta?.alias || [],
    redirect: _91slug_93CR0VaY2hcDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyE4XD7oKeIMeta?.name ?? "resources-marketplace___en-US",
    path: indexyE4XD7oKeIMeta?.path ?? "/resources/marketplace",
    meta: indexyE4XD7oKeIMeta || {},
    alias: indexyE4XD7oKeIMeta?.alias || [],
    redirect: indexyE4XD7oKeIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexyE4XD7oKeIMeta?.name ?? "resources-marketplace___ca",
    path: indexyE4XD7oKeIMeta?.path ?? "/ca/resources/marketplace",
    meta: indexyE4XD7oKeIMeta || {},
    alias: indexyE4XD7oKeIMeta?.alias || [],
    redirect: indexyE4XD7oKeIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexyE4XD7oKeIMeta?.name ?? "resources-marketplace___nl",
    path: indexyE4XD7oKeIMeta?.path ?? "/nl/resources/marketplace",
    meta: indexyE4XD7oKeIMeta || {},
    alias: indexyE4XD7oKeIMeta?.alias || [],
    redirect: indexyE4XD7oKeIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexyE4XD7oKeIMeta?.name ?? "resources-marketplace___uk",
    path: indexyE4XD7oKeIMeta?.path ?? "/uk/resources/marketplace",
    meta: indexyE4XD7oKeIMeta || {},
    alias: indexyE4XD7oKeIMeta?.alias || [],
    redirect: indexyE4XD7oKeIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexyE4XD7oKeIMeta?.name ?? "resources-marketplace___de",
    path: indexyE4XD7oKeIMeta?.path ?? "/de/resources/marketplace",
    meta: indexyE4XD7oKeIMeta || {},
    alias: indexyE4XD7oKeIMeta?.alias || [],
    redirect: indexyE4XD7oKeIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexyE4XD7oKeIMeta?.name ?? "resources-marketplace___mx",
    path: indexyE4XD7oKeIMeta?.path ?? "/mx/resources/marketplace",
    meta: indexyE4XD7oKeIMeta || {},
    alias: indexyE4XD7oKeIMeta?.alias || [],
    redirect: indexyE4XD7oKeIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexyE4XD7oKeIMeta?.name ?? "resources-marketplace___fr",
    path: indexyE4XD7oKeIMeta?.path ?? "/fr/resources/marketplace",
    meta: indexyE4XD7oKeIMeta || {},
    alias: indexyE4XD7oKeIMeta?.alias || [],
    redirect: indexyE4XD7oKeIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexyE4XD7oKeIMeta?.name ?? "resources-marketplace___fr-ca",
    path: indexyE4XD7oKeIMeta?.path ?? "/fr-ca/resources/marketplace",
    meta: indexyE4XD7oKeIMeta || {},
    alias: indexyE4XD7oKeIMeta?.alias || [],
    redirect: indexyE4XD7oKeIMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarakeqWR8PWvbMeta?.name ?? "resources-partner-programs-build-with-samsara___en-US",
    path: build_45with_45samsarakeqWR8PWvbMeta?.path ?? "/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarakeqWR8PWvbMeta || {},
    alias: build_45with_45samsarakeqWR8PWvbMeta?.alias || [],
    redirect: build_45with_45samsarakeqWR8PWvbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarakeqWR8PWvbMeta?.name ?? "resources-partner-programs-build-with-samsara___ca",
    path: build_45with_45samsarakeqWR8PWvbMeta?.path ?? "/ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarakeqWR8PWvbMeta || {},
    alias: build_45with_45samsarakeqWR8PWvbMeta?.alias || [],
    redirect: build_45with_45samsarakeqWR8PWvbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarakeqWR8PWvbMeta?.name ?? "resources-partner-programs-build-with-samsara___nl",
    path: build_45with_45samsarakeqWR8PWvbMeta?.path ?? "/nl/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarakeqWR8PWvbMeta || {},
    alias: build_45with_45samsarakeqWR8PWvbMeta?.alias || [],
    redirect: build_45with_45samsarakeqWR8PWvbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarakeqWR8PWvbMeta?.name ?? "resources-partner-programs-build-with-samsara___uk",
    path: build_45with_45samsarakeqWR8PWvbMeta?.path ?? "/uk/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarakeqWR8PWvbMeta || {},
    alias: build_45with_45samsarakeqWR8PWvbMeta?.alias || [],
    redirect: build_45with_45samsarakeqWR8PWvbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarakeqWR8PWvbMeta?.name ?? "resources-partner-programs-build-with-samsara___de",
    path: build_45with_45samsarakeqWR8PWvbMeta?.path ?? "/de/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarakeqWR8PWvbMeta || {},
    alias: build_45with_45samsarakeqWR8PWvbMeta?.alias || [],
    redirect: build_45with_45samsarakeqWR8PWvbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarakeqWR8PWvbMeta?.name ?? "resources-partner-programs-build-with-samsara___mx",
    path: build_45with_45samsarakeqWR8PWvbMeta?.path ?? "/mx/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarakeqWR8PWvbMeta || {},
    alias: build_45with_45samsarakeqWR8PWvbMeta?.alias || [],
    redirect: build_45with_45samsarakeqWR8PWvbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarakeqWR8PWvbMeta?.name ?? "resources-partner-programs-build-with-samsara___fr",
    path: build_45with_45samsarakeqWR8PWvbMeta?.path ?? "/fr/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarakeqWR8PWvbMeta || {},
    alias: build_45with_45samsarakeqWR8PWvbMeta?.alias || [],
    redirect: build_45with_45samsarakeqWR8PWvbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsarakeqWR8PWvbMeta?.name ?? "resources-partner-programs-build-with-samsara___fr-ca",
    path: build_45with_45samsarakeqWR8PWvbMeta?.path ?? "/fr-ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsarakeqWR8PWvbMeta || {},
    alias: build_45with_45samsarakeqWR8PWvbMeta?.alias || [],
    redirect: build_45with_45samsarakeqWR8PWvbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsIvzq7QQtwDMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___en-US",
    path: evolve_45insurance_45programsIvzq7QQtwDMeta?.path ?? "/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsIvzq7QQtwDMeta || {},
    alias: evolve_45insurance_45programsIvzq7QQtwDMeta?.alias || [],
    redirect: evolve_45insurance_45programsIvzq7QQtwDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsIvzq7QQtwDMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___ca",
    path: evolve_45insurance_45programsIvzq7QQtwDMeta?.path ?? "/ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsIvzq7QQtwDMeta || {},
    alias: evolve_45insurance_45programsIvzq7QQtwDMeta?.alias || [],
    redirect: evolve_45insurance_45programsIvzq7QQtwDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsIvzq7QQtwDMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___nl",
    path: evolve_45insurance_45programsIvzq7QQtwDMeta?.path ?? "/nl/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsIvzq7QQtwDMeta || {},
    alias: evolve_45insurance_45programsIvzq7QQtwDMeta?.alias || [],
    redirect: evolve_45insurance_45programsIvzq7QQtwDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsIvzq7QQtwDMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___uk",
    path: evolve_45insurance_45programsIvzq7QQtwDMeta?.path ?? "/uk/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsIvzq7QQtwDMeta || {},
    alias: evolve_45insurance_45programsIvzq7QQtwDMeta?.alias || [],
    redirect: evolve_45insurance_45programsIvzq7QQtwDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsIvzq7QQtwDMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___de",
    path: evolve_45insurance_45programsIvzq7QQtwDMeta?.path ?? "/de/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsIvzq7QQtwDMeta || {},
    alias: evolve_45insurance_45programsIvzq7QQtwDMeta?.alias || [],
    redirect: evolve_45insurance_45programsIvzq7QQtwDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsIvzq7QQtwDMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___mx",
    path: evolve_45insurance_45programsIvzq7QQtwDMeta?.path ?? "/mx/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsIvzq7QQtwDMeta || {},
    alias: evolve_45insurance_45programsIvzq7QQtwDMeta?.alias || [],
    redirect: evolve_45insurance_45programsIvzq7QQtwDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsIvzq7QQtwDMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr",
    path: evolve_45insurance_45programsIvzq7QQtwDMeta?.path ?? "/fr/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsIvzq7QQtwDMeta || {},
    alias: evolve_45insurance_45programsIvzq7QQtwDMeta?.alias || [],
    redirect: evolve_45insurance_45programsIvzq7QQtwDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsIvzq7QQtwDMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr-ca",
    path: evolve_45insurance_45programsIvzq7QQtwDMeta?.path ?? "/fr-ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsIvzq7QQtwDMeta || {},
    alias: evolve_45insurance_45programsIvzq7QQtwDMeta?.alias || [],
    redirect: evolve_45insurance_45programsIvzq7QQtwDMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: index5uwDOC1tcBMeta?.name ?? "resources-partner-programs___en-US",
    path: index5uwDOC1tcBMeta?.path ?? "/resources/partner-programs",
    meta: index5uwDOC1tcBMeta || {},
    alias: index5uwDOC1tcBMeta?.alias || [],
    redirect: index5uwDOC1tcBMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index5uwDOC1tcBMeta?.name ?? "resources-partner-programs___ca",
    path: index5uwDOC1tcBMeta?.path ?? "/ca/resources/partner-programs",
    meta: index5uwDOC1tcBMeta || {},
    alias: index5uwDOC1tcBMeta?.alias || [],
    redirect: index5uwDOC1tcBMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index5uwDOC1tcBMeta?.name ?? "resources-partner-programs___nl",
    path: index5uwDOC1tcBMeta?.path ?? "/nl/resources/partner-programs",
    meta: index5uwDOC1tcBMeta || {},
    alias: index5uwDOC1tcBMeta?.alias || [],
    redirect: index5uwDOC1tcBMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index5uwDOC1tcBMeta?.name ?? "resources-partner-programs___uk",
    path: index5uwDOC1tcBMeta?.path ?? "/uk/resources/partner-programs",
    meta: index5uwDOC1tcBMeta || {},
    alias: index5uwDOC1tcBMeta?.alias || [],
    redirect: index5uwDOC1tcBMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index5uwDOC1tcBMeta?.name ?? "resources-partner-programs___de",
    path: index5uwDOC1tcBMeta?.path ?? "/de/resources/partner-programs",
    meta: index5uwDOC1tcBMeta || {},
    alias: index5uwDOC1tcBMeta?.alias || [],
    redirect: index5uwDOC1tcBMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index5uwDOC1tcBMeta?.name ?? "resources-partner-programs___mx",
    path: index5uwDOC1tcBMeta?.path ?? "/mx/resources/partner-programs",
    meta: index5uwDOC1tcBMeta || {},
    alias: index5uwDOC1tcBMeta?.alias || [],
    redirect: index5uwDOC1tcBMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index5uwDOC1tcBMeta?.name ?? "resources-partner-programs___fr",
    path: index5uwDOC1tcBMeta?.path ?? "/fr/resources/partner-programs",
    meta: index5uwDOC1tcBMeta || {},
    alias: index5uwDOC1tcBMeta?.alias || [],
    redirect: index5uwDOC1tcBMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index5uwDOC1tcBMeta?.name ?? "resources-partner-programs___fr-ca",
    path: index5uwDOC1tcBMeta?.path ?? "/fr-ca/resources/partner-programs",
    meta: index5uwDOC1tcBMeta || {},
    alias: index5uwDOC1tcBMeta?.alias || [],
    redirect: index5uwDOC1tcBMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesw48hTqNvYMMeta?.name ?? "resources-partner-programs-provide-services___en-US",
    path: provide_45servicesw48hTqNvYMMeta?.path ?? "/resources/partner-programs/provide-services",
    meta: provide_45servicesw48hTqNvYMMeta || {},
    alias: provide_45servicesw48hTqNvYMMeta?.alias || [],
    redirect: provide_45servicesw48hTqNvYMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesw48hTqNvYMMeta?.name ?? "resources-partner-programs-provide-services___ca",
    path: provide_45servicesw48hTqNvYMMeta?.path ?? "/ca/resources/partner-programs/provide-services",
    meta: provide_45servicesw48hTqNvYMMeta || {},
    alias: provide_45servicesw48hTqNvYMMeta?.alias || [],
    redirect: provide_45servicesw48hTqNvYMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesw48hTqNvYMMeta?.name ?? "resources-partner-programs-provide-services___nl",
    path: provide_45servicesw48hTqNvYMMeta?.path ?? "/nl/resources/partner-programs/provide-services",
    meta: provide_45servicesw48hTqNvYMMeta || {},
    alias: provide_45servicesw48hTqNvYMMeta?.alias || [],
    redirect: provide_45servicesw48hTqNvYMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesw48hTqNvYMMeta?.name ?? "resources-partner-programs-provide-services___uk",
    path: provide_45servicesw48hTqNvYMMeta?.path ?? "/uk/resources/partner-programs/provide-services",
    meta: provide_45servicesw48hTqNvYMMeta || {},
    alias: provide_45servicesw48hTqNvYMMeta?.alias || [],
    redirect: provide_45servicesw48hTqNvYMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesw48hTqNvYMMeta?.name ?? "resources-partner-programs-provide-services___de",
    path: provide_45servicesw48hTqNvYMMeta?.path ?? "/de/resources/partner-programs/provide-services",
    meta: provide_45servicesw48hTqNvYMMeta || {},
    alias: provide_45servicesw48hTqNvYMMeta?.alias || [],
    redirect: provide_45servicesw48hTqNvYMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesw48hTqNvYMMeta?.name ?? "resources-partner-programs-provide-services___mx",
    path: provide_45servicesw48hTqNvYMMeta?.path ?? "/mx/resources/partner-programs/provide-services",
    meta: provide_45servicesw48hTqNvYMMeta || {},
    alias: provide_45servicesw48hTqNvYMMeta?.alias || [],
    redirect: provide_45servicesw48hTqNvYMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesw48hTqNvYMMeta?.name ?? "resources-partner-programs-provide-services___fr",
    path: provide_45servicesw48hTqNvYMMeta?.path ?? "/fr/resources/partner-programs/provide-services",
    meta: provide_45servicesw48hTqNvYMMeta || {},
    alias: provide_45servicesw48hTqNvYMMeta?.alias || [],
    redirect: provide_45servicesw48hTqNvYMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesw48hTqNvYMMeta?.name ?? "resources-partner-programs-provide-services___fr-ca",
    path: provide_45servicesw48hTqNvYMMeta?.path ?? "/fr-ca/resources/partner-programs/provide-services",
    meta: provide_45servicesw48hTqNvYMMeta || {},
    alias: provide_45servicesw48hTqNvYMMeta?.alias || [],
    redirect: provide_45servicesw48hTqNvYMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaratbITQG5goaMeta?.name ?? "resources-partner-programs-sell-samsara___en-US",
    path: sell_45samsaratbITQG5goaMeta?.path ?? "/resources/partner-programs/sell-samsara",
    meta: sell_45samsaratbITQG5goaMeta || {},
    alias: sell_45samsaratbITQG5goaMeta?.alias || [],
    redirect: sell_45samsaratbITQG5goaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaratbITQG5goaMeta?.name ?? "resources-partner-programs-sell-samsara___ca",
    path: sell_45samsaratbITQG5goaMeta?.path ?? "/ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsaratbITQG5goaMeta || {},
    alias: sell_45samsaratbITQG5goaMeta?.alias || [],
    redirect: sell_45samsaratbITQG5goaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaratbITQG5goaMeta?.name ?? "resources-partner-programs-sell-samsara___nl",
    path: sell_45samsaratbITQG5goaMeta?.path ?? "/nl/resources/partner-programs/sell-samsara",
    meta: sell_45samsaratbITQG5goaMeta || {},
    alias: sell_45samsaratbITQG5goaMeta?.alias || [],
    redirect: sell_45samsaratbITQG5goaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaratbITQG5goaMeta?.name ?? "resources-partner-programs-sell-samsara___uk",
    path: sell_45samsaratbITQG5goaMeta?.path ?? "/uk/resources/partner-programs/sell-samsara",
    meta: sell_45samsaratbITQG5goaMeta || {},
    alias: sell_45samsaratbITQG5goaMeta?.alias || [],
    redirect: sell_45samsaratbITQG5goaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaratbITQG5goaMeta?.name ?? "resources-partner-programs-sell-samsara___de",
    path: sell_45samsaratbITQG5goaMeta?.path ?? "/de/resources/partner-programs/sell-samsara",
    meta: sell_45samsaratbITQG5goaMeta || {},
    alias: sell_45samsaratbITQG5goaMeta?.alias || [],
    redirect: sell_45samsaratbITQG5goaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaratbITQG5goaMeta?.name ?? "resources-partner-programs-sell-samsara___mx",
    path: sell_45samsaratbITQG5goaMeta?.path ?? "/mx/resources/partner-programs/sell-samsara",
    meta: sell_45samsaratbITQG5goaMeta || {},
    alias: sell_45samsaratbITQG5goaMeta?.alias || [],
    redirect: sell_45samsaratbITQG5goaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaratbITQG5goaMeta?.name ?? "resources-partner-programs-sell-samsara___fr",
    path: sell_45samsaratbITQG5goaMeta?.path ?? "/fr/resources/partner-programs/sell-samsara",
    meta: sell_45samsaratbITQG5goaMeta || {},
    alias: sell_45samsaratbITQG5goaMeta?.alias || [],
    redirect: sell_45samsaratbITQG5goaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaratbITQG5goaMeta?.name ?? "resources-partner-programs-sell-samsara___fr-ca",
    path: sell_45samsaratbITQG5goaMeta?.path ?? "/fr-ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsaratbITQG5goaMeta || {},
    alias: sell_45samsaratbITQG5goaMeta?.alias || [],
    redirect: sell_45samsaratbITQG5goaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: tourwgy9iIhtLRMeta?.name ?? "resources-tour___en-US",
    path: tourwgy9iIhtLRMeta?.path ?? "/resources/tour",
    meta: tourwgy9iIhtLRMeta || {},
    alias: tourwgy9iIhtLRMeta?.alias || [],
    redirect: tourwgy9iIhtLRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourwgy9iIhtLRMeta?.name ?? "resources-tour___ca",
    path: tourwgy9iIhtLRMeta?.path ?? "/ca/resources/tour",
    meta: tourwgy9iIhtLRMeta || {},
    alias: tourwgy9iIhtLRMeta?.alias || [],
    redirect: tourwgy9iIhtLRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourwgy9iIhtLRMeta?.name ?? "resources-tour___nl",
    path: tourwgy9iIhtLRMeta?.path ?? "/nl/resources/tour",
    meta: tourwgy9iIhtLRMeta || {},
    alias: tourwgy9iIhtLRMeta?.alias || [],
    redirect: tourwgy9iIhtLRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourwgy9iIhtLRMeta?.name ?? "resources-tour___uk",
    path: tourwgy9iIhtLRMeta?.path ?? "/uk/resources/tour",
    meta: tourwgy9iIhtLRMeta || {},
    alias: tourwgy9iIhtLRMeta?.alias || [],
    redirect: tourwgy9iIhtLRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourwgy9iIhtLRMeta?.name ?? "resources-tour___de",
    path: tourwgy9iIhtLRMeta?.path ?? "/de/resources/tour",
    meta: tourwgy9iIhtLRMeta || {},
    alias: tourwgy9iIhtLRMeta?.alias || [],
    redirect: tourwgy9iIhtLRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourwgy9iIhtLRMeta?.name ?? "resources-tour___mx",
    path: tourwgy9iIhtLRMeta?.path ?? "/mx/resources/tour",
    meta: tourwgy9iIhtLRMeta || {},
    alias: tourwgy9iIhtLRMeta?.alias || [],
    redirect: tourwgy9iIhtLRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourwgy9iIhtLRMeta?.name ?? "resources-tour___fr",
    path: tourwgy9iIhtLRMeta?.path ?? "/fr/resources/tour",
    meta: tourwgy9iIhtLRMeta || {},
    alias: tourwgy9iIhtLRMeta?.alias || [],
    redirect: tourwgy9iIhtLRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourwgy9iIhtLRMeta?.name ?? "resources-tour___fr-ca",
    path: tourwgy9iIhtLRMeta?.path ?? "/fr-ca/resources/tour",
    meta: tourwgy9iIhtLRMeta || {},
    alias: tourwgy9iIhtLRMeta?.alias || [],
    redirect: tourwgy9iIhtLRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: videosHfuDefsOsoMeta?.name ?? "resources-videos___en-US",
    path: videosHfuDefsOsoMeta?.path ?? "/resources/videos",
    meta: videosHfuDefsOsoMeta || {},
    alias: videosHfuDefsOsoMeta?.alias || [],
    redirect: videosHfuDefsOsoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosHfuDefsOsoMeta?.name ?? "resources-videos___ca",
    path: videosHfuDefsOsoMeta?.path ?? "/ca/resources/videos",
    meta: videosHfuDefsOsoMeta || {},
    alias: videosHfuDefsOsoMeta?.alias || [],
    redirect: videosHfuDefsOsoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosHfuDefsOsoMeta?.name ?? "resources-videos___nl",
    path: videosHfuDefsOsoMeta?.path ?? "/nl/resources/videos",
    meta: videosHfuDefsOsoMeta || {},
    alias: videosHfuDefsOsoMeta?.alias || [],
    redirect: videosHfuDefsOsoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosHfuDefsOsoMeta?.name ?? "resources-videos___uk",
    path: videosHfuDefsOsoMeta?.path ?? "/uk/resources/videos",
    meta: videosHfuDefsOsoMeta || {},
    alias: videosHfuDefsOsoMeta?.alias || [],
    redirect: videosHfuDefsOsoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosHfuDefsOsoMeta?.name ?? "resources-videos___de",
    path: videosHfuDefsOsoMeta?.path ?? "/de/resources/videos",
    meta: videosHfuDefsOsoMeta || {},
    alias: videosHfuDefsOsoMeta?.alias || [],
    redirect: videosHfuDefsOsoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosHfuDefsOsoMeta?.name ?? "resources-videos___mx",
    path: videosHfuDefsOsoMeta?.path ?? "/mx/resources/videos",
    meta: videosHfuDefsOsoMeta || {},
    alias: videosHfuDefsOsoMeta?.alias || [],
    redirect: videosHfuDefsOsoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosHfuDefsOsoMeta?.name ?? "resources-videos___fr",
    path: videosHfuDefsOsoMeta?.path ?? "/fr/resources/videos",
    meta: videosHfuDefsOsoMeta || {},
    alias: videosHfuDefsOsoMeta?.alias || [],
    redirect: videosHfuDefsOsoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosHfuDefsOsoMeta?.name ?? "resources-videos___fr-ca",
    path: videosHfuDefsOsoMeta?.path ?? "/fr-ca/resources/videos",
    meta: videosHfuDefsOsoMeta || {},
    alias: videosHfuDefsOsoMeta?.alias || [],
    redirect: videosHfuDefsOsoMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: indexVNwmhwCaTqMeta?.name ?? "roi-calculator___en-US",
    path: indexVNwmhwCaTqMeta?.path ?? "/roi-calculator",
    meta: indexVNwmhwCaTqMeta || {},
    alias: indexVNwmhwCaTqMeta?.alias || [],
    redirect: indexVNwmhwCaTqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexVNwmhwCaTqMeta?.name ?? "roi-calculator___ca",
    path: indexVNwmhwCaTqMeta?.path ?? "/ca/roi-calculator",
    meta: indexVNwmhwCaTqMeta || {},
    alias: indexVNwmhwCaTqMeta?.alias || [],
    redirect: indexVNwmhwCaTqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexVNwmhwCaTqMeta?.name ?? "roi-calculator___nl",
    path: indexVNwmhwCaTqMeta?.path ?? "/nl/roi-calculator",
    meta: indexVNwmhwCaTqMeta || {},
    alias: indexVNwmhwCaTqMeta?.alias || [],
    redirect: indexVNwmhwCaTqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexVNwmhwCaTqMeta?.name ?? "roi-calculator___uk",
    path: indexVNwmhwCaTqMeta?.path ?? "/uk/roi-calculator",
    meta: indexVNwmhwCaTqMeta || {},
    alias: indexVNwmhwCaTqMeta?.alias || [],
    redirect: indexVNwmhwCaTqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexVNwmhwCaTqMeta?.name ?? "roi-calculator___de",
    path: indexVNwmhwCaTqMeta?.path ?? "/de/roi-calculator",
    meta: indexVNwmhwCaTqMeta || {},
    alias: indexVNwmhwCaTqMeta?.alias || [],
    redirect: indexVNwmhwCaTqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexVNwmhwCaTqMeta?.name ?? "roi-calculator___mx",
    path: indexVNwmhwCaTqMeta?.path ?? "/mx/roi-calculator",
    meta: indexVNwmhwCaTqMeta || {},
    alias: indexVNwmhwCaTqMeta?.alias || [],
    redirect: indexVNwmhwCaTqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexVNwmhwCaTqMeta?.name ?? "roi-calculator___fr",
    path: indexVNwmhwCaTqMeta?.path ?? "/fr/roi-calculator",
    meta: indexVNwmhwCaTqMeta || {},
    alias: indexVNwmhwCaTqMeta?.alias || [],
    redirect: indexVNwmhwCaTqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexVNwmhwCaTqMeta?.name ?? "roi-calculator___fr-ca",
    path: indexVNwmhwCaTqMeta?.path ?? "/fr-ca/roi-calculator",
    meta: indexVNwmhwCaTqMeta || {},
    alias: indexVNwmhwCaTqMeta?.alias || [],
    redirect: indexVNwmhwCaTqMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesjTPP3tB1fRMeta?.name ?? "samsara-ventures___en-US",
    path: samsara_45venturesjTPP3tB1fRMeta?.path ?? "/samsara-ventures",
    meta: samsara_45venturesjTPP3tB1fRMeta || {},
    alias: samsara_45venturesjTPP3tB1fRMeta?.alias || [],
    redirect: samsara_45venturesjTPP3tB1fRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesjTPP3tB1fRMeta?.name ?? "samsara-ventures___ca",
    path: samsara_45venturesjTPP3tB1fRMeta?.path ?? "/ca/samsara-ventures",
    meta: samsara_45venturesjTPP3tB1fRMeta || {},
    alias: samsara_45venturesjTPP3tB1fRMeta?.alias || [],
    redirect: samsara_45venturesjTPP3tB1fRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesjTPP3tB1fRMeta?.name ?? "samsara-ventures___nl",
    path: samsara_45venturesjTPP3tB1fRMeta?.path ?? "/nl/samsara-ventures",
    meta: samsara_45venturesjTPP3tB1fRMeta || {},
    alias: samsara_45venturesjTPP3tB1fRMeta?.alias || [],
    redirect: samsara_45venturesjTPP3tB1fRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesjTPP3tB1fRMeta?.name ?? "samsara-ventures___uk",
    path: samsara_45venturesjTPP3tB1fRMeta?.path ?? "/uk/samsara-ventures",
    meta: samsara_45venturesjTPP3tB1fRMeta || {},
    alias: samsara_45venturesjTPP3tB1fRMeta?.alias || [],
    redirect: samsara_45venturesjTPP3tB1fRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesjTPP3tB1fRMeta?.name ?? "samsara-ventures___de",
    path: samsara_45venturesjTPP3tB1fRMeta?.path ?? "/de/samsara-ventures",
    meta: samsara_45venturesjTPP3tB1fRMeta || {},
    alias: samsara_45venturesjTPP3tB1fRMeta?.alias || [],
    redirect: samsara_45venturesjTPP3tB1fRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesjTPP3tB1fRMeta?.name ?? "samsara-ventures___mx",
    path: samsara_45venturesjTPP3tB1fRMeta?.path ?? "/mx/samsara-ventures",
    meta: samsara_45venturesjTPP3tB1fRMeta || {},
    alias: samsara_45venturesjTPP3tB1fRMeta?.alias || [],
    redirect: samsara_45venturesjTPP3tB1fRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesjTPP3tB1fRMeta?.name ?? "samsara-ventures___fr",
    path: samsara_45venturesjTPP3tB1fRMeta?.path ?? "/fr/samsara-ventures",
    meta: samsara_45venturesjTPP3tB1fRMeta || {},
    alias: samsara_45venturesjTPP3tB1fRMeta?.alias || [],
    redirect: samsara_45venturesjTPP3tB1fRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturesjTPP3tB1fRMeta?.name ?? "samsara-ventures___fr-ca",
    path: samsara_45venturesjTPP3tB1fRMeta?.path ?? "/fr-ca/samsara-ventures",
    meta: samsara_45venturesjTPP3tB1fRMeta || {},
    alias: samsara_45venturesjTPP3tB1fRMeta?.alias || [],
    redirect: samsara_45venturesjTPP3tB1fRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxqkr9hloEPMeta?.name ?? "security-summit___en-US",
    path: security_45summitxqkr9hloEPMeta?.path ?? "/security-summit",
    meta: security_45summitxqkr9hloEPMeta || {},
    alias: security_45summitxqkr9hloEPMeta?.alias || [],
    redirect: security_45summitxqkr9hloEPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxqkr9hloEPMeta?.name ?? "security-summit___ca",
    path: security_45summitxqkr9hloEPMeta?.path ?? "/ca/security-summit",
    meta: security_45summitxqkr9hloEPMeta || {},
    alias: security_45summitxqkr9hloEPMeta?.alias || [],
    redirect: security_45summitxqkr9hloEPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxqkr9hloEPMeta?.name ?? "security-summit___nl",
    path: security_45summitxqkr9hloEPMeta?.path ?? "/nl/security-summit",
    meta: security_45summitxqkr9hloEPMeta || {},
    alias: security_45summitxqkr9hloEPMeta?.alias || [],
    redirect: security_45summitxqkr9hloEPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxqkr9hloEPMeta?.name ?? "security-summit___uk",
    path: security_45summitxqkr9hloEPMeta?.path ?? "/uk/security-summit",
    meta: security_45summitxqkr9hloEPMeta || {},
    alias: security_45summitxqkr9hloEPMeta?.alias || [],
    redirect: security_45summitxqkr9hloEPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxqkr9hloEPMeta?.name ?? "security-summit___de",
    path: security_45summitxqkr9hloEPMeta?.path ?? "/de/security-summit",
    meta: security_45summitxqkr9hloEPMeta || {},
    alias: security_45summitxqkr9hloEPMeta?.alias || [],
    redirect: security_45summitxqkr9hloEPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxqkr9hloEPMeta?.name ?? "security-summit___mx",
    path: security_45summitxqkr9hloEPMeta?.path ?? "/mx/security-summit",
    meta: security_45summitxqkr9hloEPMeta || {},
    alias: security_45summitxqkr9hloEPMeta?.alias || [],
    redirect: security_45summitxqkr9hloEPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxqkr9hloEPMeta?.name ?? "security-summit___fr",
    path: security_45summitxqkr9hloEPMeta?.path ?? "/fr/security-summit",
    meta: security_45summitxqkr9hloEPMeta || {},
    alias: security_45summitxqkr9hloEPMeta?.alias || [],
    redirect: security_45summitxqkr9hloEPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitxqkr9hloEPMeta?.name ?? "security-summit___fr-ca",
    path: security_45summitxqkr9hloEPMeta?.path ?? "/fr-ca/security-summit",
    meta: security_45summitxqkr9hloEPMeta || {},
    alias: security_45summitxqkr9hloEPMeta?.alias || [],
    redirect: security_45summitxqkr9hloEPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: _91slug_930rbDB5tL0xMeta?.name ?? "solutions-prefix-slug___en-US",
    path: _91slug_930rbDB5tL0xMeta?.path ?? "/solutions/:prefix()/:slug()",
    meta: _91slug_930rbDB5tL0xMeta || {},
    alias: _91slug_930rbDB5tL0xMeta?.alias || [],
    redirect: _91slug_930rbDB5tL0xMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930rbDB5tL0xMeta?.name ?? "solutions-prefix-slug___ca",
    path: _91slug_930rbDB5tL0xMeta?.path ?? "/ca/solutions/:prefix()/:slug()",
    meta: _91slug_930rbDB5tL0xMeta || {},
    alias: _91slug_930rbDB5tL0xMeta?.alias || [],
    redirect: _91slug_930rbDB5tL0xMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930rbDB5tL0xMeta?.name ?? "solutions-prefix-slug___nl",
    path: _91slug_930rbDB5tL0xMeta?.path ?? "/nl/solutions/:prefix()/:slug()",
    meta: _91slug_930rbDB5tL0xMeta || {},
    alias: _91slug_930rbDB5tL0xMeta?.alias || [],
    redirect: _91slug_930rbDB5tL0xMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930rbDB5tL0xMeta?.name ?? "solutions-prefix-slug___uk",
    path: _91slug_930rbDB5tL0xMeta?.path ?? "/uk/solutions/:prefix()/:slug()",
    meta: _91slug_930rbDB5tL0xMeta || {},
    alias: _91slug_930rbDB5tL0xMeta?.alias || [],
    redirect: _91slug_930rbDB5tL0xMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930rbDB5tL0xMeta?.name ?? "solutions-prefix-slug___de",
    path: _91slug_930rbDB5tL0xMeta?.path ?? "/de/solutions/:prefix()/:slug()",
    meta: _91slug_930rbDB5tL0xMeta || {},
    alias: _91slug_930rbDB5tL0xMeta?.alias || [],
    redirect: _91slug_930rbDB5tL0xMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930rbDB5tL0xMeta?.name ?? "solutions-prefix-slug___mx",
    path: _91slug_930rbDB5tL0xMeta?.path ?? "/mx/solutions/:prefix()/:slug()",
    meta: _91slug_930rbDB5tL0xMeta || {},
    alias: _91slug_930rbDB5tL0xMeta?.alias || [],
    redirect: _91slug_930rbDB5tL0xMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930rbDB5tL0xMeta?.name ?? "solutions-prefix-slug___fr",
    path: _91slug_930rbDB5tL0xMeta?.path ?? "/fr/solutions/:prefix()/:slug()",
    meta: _91slug_930rbDB5tL0xMeta || {},
    alias: _91slug_930rbDB5tL0xMeta?.alias || [],
    redirect: _91slug_930rbDB5tL0xMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930rbDB5tL0xMeta?.name ?? "solutions-prefix-slug___fr-ca",
    path: _91slug_930rbDB5tL0xMeta?.path ?? "/fr-ca/solutions/:prefix()/:slug()",
    meta: _91slug_930rbDB5tL0xMeta || {},
    alias: _91slug_930rbDB5tL0xMeta?.alias || [],
    redirect: _91slug_930rbDB5tL0xMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M8Q4Fxsw19Meta?.name ?? "solutions-slug___en-US",
    path: _91slug_93M8Q4Fxsw19Meta?.path ?? "/solutions/:slug()",
    meta: _91slug_93M8Q4Fxsw19Meta || {},
    alias: _91slug_93M8Q4Fxsw19Meta?.alias || [],
    redirect: _91slug_93M8Q4Fxsw19Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M8Q4Fxsw19Meta?.name ?? "solutions-slug___ca",
    path: _91slug_93M8Q4Fxsw19Meta?.path ?? "/ca/solutions/:slug()",
    meta: _91slug_93M8Q4Fxsw19Meta || {},
    alias: _91slug_93M8Q4Fxsw19Meta?.alias || [],
    redirect: _91slug_93M8Q4Fxsw19Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M8Q4Fxsw19Meta?.name ?? "solutions-slug___nl",
    path: _91slug_93M8Q4Fxsw19Meta?.path ?? "/nl/solutions/:slug()",
    meta: _91slug_93M8Q4Fxsw19Meta || {},
    alias: _91slug_93M8Q4Fxsw19Meta?.alias || [],
    redirect: _91slug_93M8Q4Fxsw19Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M8Q4Fxsw19Meta?.name ?? "solutions-slug___uk",
    path: _91slug_93M8Q4Fxsw19Meta?.path ?? "/uk/solutions/:slug()",
    meta: _91slug_93M8Q4Fxsw19Meta || {},
    alias: _91slug_93M8Q4Fxsw19Meta?.alias || [],
    redirect: _91slug_93M8Q4Fxsw19Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M8Q4Fxsw19Meta?.name ?? "solutions-slug___de",
    path: _91slug_93M8Q4Fxsw19Meta?.path ?? "/de/solutions/:slug()",
    meta: _91slug_93M8Q4Fxsw19Meta || {},
    alias: _91slug_93M8Q4Fxsw19Meta?.alias || [],
    redirect: _91slug_93M8Q4Fxsw19Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M8Q4Fxsw19Meta?.name ?? "solutions-slug___mx",
    path: _91slug_93M8Q4Fxsw19Meta?.path ?? "/mx/solutions/:slug()",
    meta: _91slug_93M8Q4Fxsw19Meta || {},
    alias: _91slug_93M8Q4Fxsw19Meta?.alias || [],
    redirect: _91slug_93M8Q4Fxsw19Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M8Q4Fxsw19Meta?.name ?? "solutions-slug___fr",
    path: _91slug_93M8Q4Fxsw19Meta?.path ?? "/fr/solutions/:slug()",
    meta: _91slug_93M8Q4Fxsw19Meta || {},
    alias: _91slug_93M8Q4Fxsw19Meta?.alias || [],
    redirect: _91slug_93M8Q4Fxsw19Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M8Q4Fxsw19Meta?.name ?? "solutions-slug___fr-ca",
    path: _91slug_93M8Q4Fxsw19Meta?.path ?? "/fr-ca/solutions/:slug()",
    meta: _91slug_93M8Q4Fxsw19Meta || {},
    alias: _91slug_93M8Q4Fxsw19Meta?.alias || [],
    redirect: _91slug_93M8Q4Fxsw19Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexp2fIKbRpOaMeta?.name ?? "solutions___en-US",
    path: indexp2fIKbRpOaMeta?.path ?? "/solutions",
    meta: indexp2fIKbRpOaMeta || {},
    alias: indexp2fIKbRpOaMeta?.alias || [],
    redirect: indexp2fIKbRpOaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexp2fIKbRpOaMeta?.name ?? "solutions___ca",
    path: indexp2fIKbRpOaMeta?.path ?? "/ca/solutions",
    meta: indexp2fIKbRpOaMeta || {},
    alias: indexp2fIKbRpOaMeta?.alias || [],
    redirect: indexp2fIKbRpOaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexp2fIKbRpOaMeta?.name ?? "solutions___nl",
    path: indexp2fIKbRpOaMeta?.path ?? "/nl/solutions",
    meta: indexp2fIKbRpOaMeta || {},
    alias: indexp2fIKbRpOaMeta?.alias || [],
    redirect: indexp2fIKbRpOaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexp2fIKbRpOaMeta?.name ?? "solutions___uk",
    path: indexp2fIKbRpOaMeta?.path ?? "/uk/solutions",
    meta: indexp2fIKbRpOaMeta || {},
    alias: indexp2fIKbRpOaMeta?.alias || [],
    redirect: indexp2fIKbRpOaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexp2fIKbRpOaMeta?.name ?? "solutions___de",
    path: indexp2fIKbRpOaMeta?.path ?? "/de/solutions",
    meta: indexp2fIKbRpOaMeta || {},
    alias: indexp2fIKbRpOaMeta?.alias || [],
    redirect: indexp2fIKbRpOaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexp2fIKbRpOaMeta?.name ?? "solutions___mx",
    path: indexp2fIKbRpOaMeta?.path ?? "/mx/solutions",
    meta: indexp2fIKbRpOaMeta || {},
    alias: indexp2fIKbRpOaMeta?.alias || [],
    redirect: indexp2fIKbRpOaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexp2fIKbRpOaMeta?.name ?? "solutions___fr",
    path: indexp2fIKbRpOaMeta?.path ?? "/fr/solutions",
    meta: indexp2fIKbRpOaMeta || {},
    alias: indexp2fIKbRpOaMeta?.alias || [],
    redirect: indexp2fIKbRpOaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexp2fIKbRpOaMeta?.name ?? "solutions___fr-ca",
    path: indexp2fIKbRpOaMeta?.path ?? "/fr-ca/solutions",
    meta: indexp2fIKbRpOaMeta || {},
    alias: indexp2fIKbRpOaMeta?.alias || [],
    redirect: indexp2fIKbRpOaMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: securityez1MqNX6Z7Meta?.name ?? "solutions-security___en-US",
    path: securityez1MqNX6Z7Meta?.path ?? "/solutions/security",
    meta: securityez1MqNX6Z7Meta || {},
    alias: securityez1MqNX6Z7Meta?.alias || [],
    redirect: securityez1MqNX6Z7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityez1MqNX6Z7Meta?.name ?? "solutions-security___ca",
    path: securityez1MqNX6Z7Meta?.path ?? "/ca/solutions/security",
    meta: securityez1MqNX6Z7Meta || {},
    alias: securityez1MqNX6Z7Meta?.alias || [],
    redirect: securityez1MqNX6Z7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityez1MqNX6Z7Meta?.name ?? "solutions-security___nl",
    path: securityez1MqNX6Z7Meta?.path ?? "/nl/solutions/security",
    meta: securityez1MqNX6Z7Meta || {},
    alias: securityez1MqNX6Z7Meta?.alias || [],
    redirect: securityez1MqNX6Z7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityez1MqNX6Z7Meta?.name ?? "solutions-security___uk",
    path: securityez1MqNX6Z7Meta?.path ?? "/uk/solutions/security",
    meta: securityez1MqNX6Z7Meta || {},
    alias: securityez1MqNX6Z7Meta?.alias || [],
    redirect: securityez1MqNX6Z7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityez1MqNX6Z7Meta?.name ?? "solutions-security___de",
    path: securityez1MqNX6Z7Meta?.path ?? "/de/solutions/security",
    meta: securityez1MqNX6Z7Meta || {},
    alias: securityez1MqNX6Z7Meta?.alias || [],
    redirect: securityez1MqNX6Z7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityez1MqNX6Z7Meta?.name ?? "solutions-security___mx",
    path: securityez1MqNX6Z7Meta?.path ?? "/mx/soluciones/seguridad-patrimonial",
    meta: securityez1MqNX6Z7Meta || {},
    alias: securityez1MqNX6Z7Meta?.alias || [],
    redirect: securityez1MqNX6Z7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityez1MqNX6Z7Meta?.name ?? "solutions-security___fr",
    path: securityez1MqNX6Z7Meta?.path ?? "/fr/solutions/security",
    meta: securityez1MqNX6Z7Meta || {},
    alias: securityez1MqNX6Z7Meta?.alias || [],
    redirect: securityez1MqNX6Z7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityez1MqNX6Z7Meta?.name ?? "solutions-security___fr-ca",
    path: securityez1MqNX6Z7Meta?.path ?? "/fr-ca/solutions/security",
    meta: securityez1MqNX6Z7Meta || {},
    alias: securityez1MqNX6Z7Meta?.alias || [],
    redirect: securityez1MqNX6Z7Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: spaceL9T0qaJXQSMeta?.name ?? "space___en-US",
    path: spaceL9T0qaJXQSMeta?.path ?? "/space",
    meta: spaceL9T0qaJXQSMeta || {},
    alias: spaceL9T0qaJXQSMeta?.alias || [],
    redirect: spaceL9T0qaJXQSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceL9T0qaJXQSMeta?.name ?? "space___ca",
    path: spaceL9T0qaJXQSMeta?.path ?? "/ca/space",
    meta: spaceL9T0qaJXQSMeta || {},
    alias: spaceL9T0qaJXQSMeta?.alias || [],
    redirect: spaceL9T0qaJXQSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceL9T0qaJXQSMeta?.name ?? "space___nl",
    path: spaceL9T0qaJXQSMeta?.path ?? "/nl/space",
    meta: spaceL9T0qaJXQSMeta || {},
    alias: spaceL9T0qaJXQSMeta?.alias || [],
    redirect: spaceL9T0qaJXQSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceL9T0qaJXQSMeta?.name ?? "space___uk",
    path: spaceL9T0qaJXQSMeta?.path ?? "/uk/space",
    meta: spaceL9T0qaJXQSMeta || {},
    alias: spaceL9T0qaJXQSMeta?.alias || [],
    redirect: spaceL9T0qaJXQSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceL9T0qaJXQSMeta?.name ?? "space___de",
    path: spaceL9T0qaJXQSMeta?.path ?? "/de/space",
    meta: spaceL9T0qaJXQSMeta || {},
    alias: spaceL9T0qaJXQSMeta?.alias || [],
    redirect: spaceL9T0qaJXQSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceL9T0qaJXQSMeta?.name ?? "space___mx",
    path: spaceL9T0qaJXQSMeta?.path ?? "/mx/space",
    meta: spaceL9T0qaJXQSMeta || {},
    alias: spaceL9T0qaJXQSMeta?.alias || [],
    redirect: spaceL9T0qaJXQSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceL9T0qaJXQSMeta?.name ?? "space___fr",
    path: spaceL9T0qaJXQSMeta?.path ?? "/fr/space",
    meta: spaceL9T0qaJXQSMeta || {},
    alias: spaceL9T0qaJXQSMeta?.alias || [],
    redirect: spaceL9T0qaJXQSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spaceL9T0qaJXQSMeta?.name ?? "space___fr-ca",
    path: spaceL9T0qaJXQSMeta?.path ?? "/fr-ca/space",
    meta: spaceL9T0qaJXQSMeta || {},
    alias: spaceL9T0qaJXQSMeta?.alias || [],
    redirect: spaceL9T0qaJXQSMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: sparkz7mmwCcyzmMeta?.name ?? "spark___en-US",
    path: sparkz7mmwCcyzmMeta?.path ?? "/spark",
    meta: sparkz7mmwCcyzmMeta || {},
    alias: sparkz7mmwCcyzmMeta?.alias || [],
    redirect: sparkz7mmwCcyzmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkz7mmwCcyzmMeta?.name ?? "spark___ca",
    path: sparkz7mmwCcyzmMeta?.path ?? "/ca/spark",
    meta: sparkz7mmwCcyzmMeta || {},
    alias: sparkz7mmwCcyzmMeta?.alias || [],
    redirect: sparkz7mmwCcyzmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkz7mmwCcyzmMeta?.name ?? "spark___nl",
    path: sparkz7mmwCcyzmMeta?.path ?? "/nl/spark",
    meta: sparkz7mmwCcyzmMeta || {},
    alias: sparkz7mmwCcyzmMeta?.alias || [],
    redirect: sparkz7mmwCcyzmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkz7mmwCcyzmMeta?.name ?? "spark___uk",
    path: sparkz7mmwCcyzmMeta?.path ?? "/uk/spark",
    meta: sparkz7mmwCcyzmMeta || {},
    alias: sparkz7mmwCcyzmMeta?.alias || [],
    redirect: sparkz7mmwCcyzmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkz7mmwCcyzmMeta?.name ?? "spark___de",
    path: sparkz7mmwCcyzmMeta?.path ?? "/de/spark",
    meta: sparkz7mmwCcyzmMeta || {},
    alias: sparkz7mmwCcyzmMeta?.alias || [],
    redirect: sparkz7mmwCcyzmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkz7mmwCcyzmMeta?.name ?? "spark___mx",
    path: sparkz7mmwCcyzmMeta?.path ?? "/mx/spark",
    meta: sparkz7mmwCcyzmMeta || {},
    alias: sparkz7mmwCcyzmMeta?.alias || [],
    redirect: sparkz7mmwCcyzmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkz7mmwCcyzmMeta?.name ?? "spark___fr",
    path: sparkz7mmwCcyzmMeta?.path ?? "/fr/spark",
    meta: sparkz7mmwCcyzmMeta || {},
    alias: sparkz7mmwCcyzmMeta?.alias || [],
    redirect: sparkz7mmwCcyzmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkz7mmwCcyzmMeta?.name ?? "spark___fr-ca",
    path: sparkz7mmwCcyzmMeta?.path ?? "/fr-ca/spark",
    meta: sparkz7mmwCcyzmMeta || {},
    alias: sparkz7mmwCcyzmMeta?.alias || [],
    redirect: sparkz7mmwCcyzmMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: _91slug_932vxw4jNoPuMeta?.name ?? "styles-slug___en-US",
    path: _91slug_932vxw4jNoPuMeta?.path ?? "/styles/:slug()",
    meta: _91slug_932vxw4jNoPuMeta || {},
    alias: _91slug_932vxw4jNoPuMeta?.alias || [],
    redirect: _91slug_932vxw4jNoPuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932vxw4jNoPuMeta?.name ?? "styles-slug___ca",
    path: _91slug_932vxw4jNoPuMeta?.path ?? "/ca/styles/:slug()",
    meta: _91slug_932vxw4jNoPuMeta || {},
    alias: _91slug_932vxw4jNoPuMeta?.alias || [],
    redirect: _91slug_932vxw4jNoPuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932vxw4jNoPuMeta?.name ?? "styles-slug___nl",
    path: _91slug_932vxw4jNoPuMeta?.path ?? "/nl/styles/:slug()",
    meta: _91slug_932vxw4jNoPuMeta || {},
    alias: _91slug_932vxw4jNoPuMeta?.alias || [],
    redirect: _91slug_932vxw4jNoPuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932vxw4jNoPuMeta?.name ?? "styles-slug___uk",
    path: _91slug_932vxw4jNoPuMeta?.path ?? "/uk/styles/:slug()",
    meta: _91slug_932vxw4jNoPuMeta || {},
    alias: _91slug_932vxw4jNoPuMeta?.alias || [],
    redirect: _91slug_932vxw4jNoPuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932vxw4jNoPuMeta?.name ?? "styles-slug___de",
    path: _91slug_932vxw4jNoPuMeta?.path ?? "/de/styles/:slug()",
    meta: _91slug_932vxw4jNoPuMeta || {},
    alias: _91slug_932vxw4jNoPuMeta?.alias || [],
    redirect: _91slug_932vxw4jNoPuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932vxw4jNoPuMeta?.name ?? "styles-slug___mx",
    path: _91slug_932vxw4jNoPuMeta?.path ?? "/mx/styles/:slug()",
    meta: _91slug_932vxw4jNoPuMeta || {},
    alias: _91slug_932vxw4jNoPuMeta?.alias || [],
    redirect: _91slug_932vxw4jNoPuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932vxw4jNoPuMeta?.name ?? "styles-slug___fr",
    path: _91slug_932vxw4jNoPuMeta?.path ?? "/fr/styles/:slug()",
    meta: _91slug_932vxw4jNoPuMeta || {},
    alias: _91slug_932vxw4jNoPuMeta?.alias || [],
    redirect: _91slug_932vxw4jNoPuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932vxw4jNoPuMeta?.name ?? "styles-slug___fr-ca",
    path: _91slug_932vxw4jNoPuMeta?.path ?? "/fr-ca/styles/:slug()",
    meta: _91slug_932vxw4jNoPuMeta || {},
    alias: _91slug_932vxw4jNoPuMeta?.alias || [],
    redirect: _91slug_932vxw4jNoPuMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933uyGgWBbwTMeta?.name ?? "support-prefix-slug___en-US",
    path: _91slug_933uyGgWBbwTMeta?.path ?? "/support/:prefix()/:slug()",
    meta: _91slug_933uyGgWBbwTMeta || {},
    alias: _91slug_933uyGgWBbwTMeta?.alias || [],
    redirect: _91slug_933uyGgWBbwTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933uyGgWBbwTMeta?.name ?? "support-prefix-slug___ca",
    path: _91slug_933uyGgWBbwTMeta?.path ?? "/ca/support/:prefix()/:slug()",
    meta: _91slug_933uyGgWBbwTMeta || {},
    alias: _91slug_933uyGgWBbwTMeta?.alias || [],
    redirect: _91slug_933uyGgWBbwTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933uyGgWBbwTMeta?.name ?? "support-prefix-slug___nl",
    path: _91slug_933uyGgWBbwTMeta?.path ?? "/nl/support/:prefix()/:slug()",
    meta: _91slug_933uyGgWBbwTMeta || {},
    alias: _91slug_933uyGgWBbwTMeta?.alias || [],
    redirect: _91slug_933uyGgWBbwTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933uyGgWBbwTMeta?.name ?? "support-prefix-slug___uk",
    path: _91slug_933uyGgWBbwTMeta?.path ?? "/uk/support/:prefix()/:slug()",
    meta: _91slug_933uyGgWBbwTMeta || {},
    alias: _91slug_933uyGgWBbwTMeta?.alias || [],
    redirect: _91slug_933uyGgWBbwTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933uyGgWBbwTMeta?.name ?? "support-prefix-slug___de",
    path: _91slug_933uyGgWBbwTMeta?.path ?? "/de/support/:prefix()/:slug()",
    meta: _91slug_933uyGgWBbwTMeta || {},
    alias: _91slug_933uyGgWBbwTMeta?.alias || [],
    redirect: _91slug_933uyGgWBbwTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933uyGgWBbwTMeta?.name ?? "support-prefix-slug___mx",
    path: _91slug_933uyGgWBbwTMeta?.path ?? "/mx/support/:prefix()/:slug()",
    meta: _91slug_933uyGgWBbwTMeta || {},
    alias: _91slug_933uyGgWBbwTMeta?.alias || [],
    redirect: _91slug_933uyGgWBbwTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933uyGgWBbwTMeta?.name ?? "support-prefix-slug___fr",
    path: _91slug_933uyGgWBbwTMeta?.path ?? "/fr/support/:prefix()/:slug()",
    meta: _91slug_933uyGgWBbwTMeta || {},
    alias: _91slug_933uyGgWBbwTMeta?.alias || [],
    redirect: _91slug_933uyGgWBbwTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933uyGgWBbwTMeta?.name ?? "support-prefix-slug___fr-ca",
    path: _91slug_933uyGgWBbwTMeta?.path ?? "/fr-ca/support/:prefix()/:slug()",
    meta: _91slug_933uyGgWBbwTMeta || {},
    alias: _91slug_933uyGgWBbwTMeta?.alias || [],
    redirect: _91slug_933uyGgWBbwTMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93c0jW6Jw03PMeta?.name ?? "support-slug___en-US",
    path: _91slug_93c0jW6Jw03PMeta?.path ?? "/support/:slug()",
    meta: _91slug_93c0jW6Jw03PMeta || {},
    alias: _91slug_93c0jW6Jw03PMeta?.alias || [],
    redirect: _91slug_93c0jW6Jw03PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93c0jW6Jw03PMeta?.name ?? "support-slug___ca",
    path: _91slug_93c0jW6Jw03PMeta?.path ?? "/ca/support/:slug()",
    meta: _91slug_93c0jW6Jw03PMeta || {},
    alias: _91slug_93c0jW6Jw03PMeta?.alias || [],
    redirect: _91slug_93c0jW6Jw03PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93c0jW6Jw03PMeta?.name ?? "support-slug___nl",
    path: _91slug_93c0jW6Jw03PMeta?.path ?? "/nl/support/:slug()",
    meta: _91slug_93c0jW6Jw03PMeta || {},
    alias: _91slug_93c0jW6Jw03PMeta?.alias || [],
    redirect: _91slug_93c0jW6Jw03PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93c0jW6Jw03PMeta?.name ?? "support-slug___uk",
    path: _91slug_93c0jW6Jw03PMeta?.path ?? "/uk/support/:slug()",
    meta: _91slug_93c0jW6Jw03PMeta || {},
    alias: _91slug_93c0jW6Jw03PMeta?.alias || [],
    redirect: _91slug_93c0jW6Jw03PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93c0jW6Jw03PMeta?.name ?? "support-slug___de",
    path: _91slug_93c0jW6Jw03PMeta?.path ?? "/de/support/:slug()",
    meta: _91slug_93c0jW6Jw03PMeta || {},
    alias: _91slug_93c0jW6Jw03PMeta?.alias || [],
    redirect: _91slug_93c0jW6Jw03PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93c0jW6Jw03PMeta?.name ?? "support-slug___mx",
    path: _91slug_93c0jW6Jw03PMeta?.path ?? "/mx/support/:slug()",
    meta: _91slug_93c0jW6Jw03PMeta || {},
    alias: _91slug_93c0jW6Jw03PMeta?.alias || [],
    redirect: _91slug_93c0jW6Jw03PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93c0jW6Jw03PMeta?.name ?? "support-slug___fr",
    path: _91slug_93c0jW6Jw03PMeta?.path ?? "/fr/support/:slug()",
    meta: _91slug_93c0jW6Jw03PMeta || {},
    alias: _91slug_93c0jW6Jw03PMeta?.alias || [],
    redirect: _91slug_93c0jW6Jw03PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93c0jW6Jw03PMeta?.name ?? "support-slug___fr-ca",
    path: _91slug_93c0jW6Jw03PMeta?.path ?? "/fr-ca/support/:slug()",
    meta: _91slug_93c0jW6Jw03PMeta || {},
    alias: _91slug_93c0jW6Jw03PMeta?.alias || [],
    redirect: _91slug_93c0jW6Jw03PMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLiJ0KH4XD2Meta?.name ?? "support-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyLiJ0KH4XD2Meta?.path ?? "/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLiJ0KH4XD2Meta || {},
    alias: apps_45privacy_45policyLiJ0KH4XD2Meta?.alias || [],
    redirect: apps_45privacy_45policyLiJ0KH4XD2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLiJ0KH4XD2Meta?.name ?? "support-apps-privacy-policy___ca",
    path: apps_45privacy_45policyLiJ0KH4XD2Meta?.path ?? "/ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLiJ0KH4XD2Meta || {},
    alias: apps_45privacy_45policyLiJ0KH4XD2Meta?.alias || [],
    redirect: apps_45privacy_45policyLiJ0KH4XD2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLiJ0KH4XD2Meta?.name ?? "support-apps-privacy-policy___nl",
    path: apps_45privacy_45policyLiJ0KH4XD2Meta?.path ?? "/nl/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLiJ0KH4XD2Meta || {},
    alias: apps_45privacy_45policyLiJ0KH4XD2Meta?.alias || [],
    redirect: apps_45privacy_45policyLiJ0KH4XD2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLiJ0KH4XD2Meta?.name ?? "support-apps-privacy-policy___uk",
    path: apps_45privacy_45policyLiJ0KH4XD2Meta?.path ?? "/uk/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLiJ0KH4XD2Meta || {},
    alias: apps_45privacy_45policyLiJ0KH4XD2Meta?.alias || [],
    redirect: apps_45privacy_45policyLiJ0KH4XD2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLiJ0KH4XD2Meta?.name ?? "support-apps-privacy-policy___de",
    path: apps_45privacy_45policyLiJ0KH4XD2Meta?.path ?? "/de/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLiJ0KH4XD2Meta || {},
    alias: apps_45privacy_45policyLiJ0KH4XD2Meta?.alias || [],
    redirect: apps_45privacy_45policyLiJ0KH4XD2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLiJ0KH4XD2Meta?.name ?? "support-apps-privacy-policy___mx",
    path: apps_45privacy_45policyLiJ0KH4XD2Meta?.path ?? "/mx/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLiJ0KH4XD2Meta || {},
    alias: apps_45privacy_45policyLiJ0KH4XD2Meta?.alias || [],
    redirect: apps_45privacy_45policyLiJ0KH4XD2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLiJ0KH4XD2Meta?.name ?? "support-apps-privacy-policy___fr",
    path: apps_45privacy_45policyLiJ0KH4XD2Meta?.path ?? "/fr/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLiJ0KH4XD2Meta || {},
    alias: apps_45privacy_45policyLiJ0KH4XD2Meta?.alias || [],
    redirect: apps_45privacy_45policyLiJ0KH4XD2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLiJ0KH4XD2Meta?.name ?? "support-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyLiJ0KH4XD2Meta?.path ?? "/fr-ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLiJ0KH4XD2Meta || {},
    alias: apps_45privacy_45policyLiJ0KH4XD2Meta?.alias || [],
    redirect: apps_45privacy_45policyLiJ0KH4XD2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq0vVzCYkBWgMeta?.name ?? "support-customer-referrals-faq___en-US",
    path: customer_45referrals_45faq0vVzCYkBWgMeta?.path ?? "/support/customer-referrals-faq",
    meta: customer_45referrals_45faq0vVzCYkBWgMeta || {},
    alias: customer_45referrals_45faq0vVzCYkBWgMeta?.alias || [],
    redirect: customer_45referrals_45faq0vVzCYkBWgMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq0vVzCYkBWgMeta?.name ?? "support-customer-referrals-faq___ca",
    path: customer_45referrals_45faq0vVzCYkBWgMeta?.path ?? "/ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faq0vVzCYkBWgMeta || {},
    alias: customer_45referrals_45faq0vVzCYkBWgMeta?.alias || [],
    redirect: customer_45referrals_45faq0vVzCYkBWgMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq0vVzCYkBWgMeta?.name ?? "support-customer-referrals-faq___nl",
    path: customer_45referrals_45faq0vVzCYkBWgMeta?.path ?? "/nl/support/customer-referrals-faq",
    meta: customer_45referrals_45faq0vVzCYkBWgMeta || {},
    alias: customer_45referrals_45faq0vVzCYkBWgMeta?.alias || [],
    redirect: customer_45referrals_45faq0vVzCYkBWgMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq0vVzCYkBWgMeta?.name ?? "support-customer-referrals-faq___uk",
    path: customer_45referrals_45faq0vVzCYkBWgMeta?.path ?? "/uk/support/customer-referrals-faq",
    meta: customer_45referrals_45faq0vVzCYkBWgMeta || {},
    alias: customer_45referrals_45faq0vVzCYkBWgMeta?.alias || [],
    redirect: customer_45referrals_45faq0vVzCYkBWgMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq0vVzCYkBWgMeta?.name ?? "support-customer-referrals-faq___de",
    path: customer_45referrals_45faq0vVzCYkBWgMeta?.path ?? "/de/support/customer-referrals-faq",
    meta: customer_45referrals_45faq0vVzCYkBWgMeta || {},
    alias: customer_45referrals_45faq0vVzCYkBWgMeta?.alias || [],
    redirect: customer_45referrals_45faq0vVzCYkBWgMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq0vVzCYkBWgMeta?.name ?? "support-customer-referrals-faq___mx",
    path: customer_45referrals_45faq0vVzCYkBWgMeta?.path ?? "/mx/support/customer-referrals-faq",
    meta: customer_45referrals_45faq0vVzCYkBWgMeta || {},
    alias: customer_45referrals_45faq0vVzCYkBWgMeta?.alias || [],
    redirect: customer_45referrals_45faq0vVzCYkBWgMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq0vVzCYkBWgMeta?.name ?? "support-customer-referrals-faq___fr",
    path: customer_45referrals_45faq0vVzCYkBWgMeta?.path ?? "/fr/support/customer-referrals-faq",
    meta: customer_45referrals_45faq0vVzCYkBWgMeta || {},
    alias: customer_45referrals_45faq0vVzCYkBWgMeta?.alias || [],
    redirect: customer_45referrals_45faq0vVzCYkBWgMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faq0vVzCYkBWgMeta?.name ?? "support-customer-referrals-faq___fr-ca",
    path: customer_45referrals_45faq0vVzCYkBWgMeta?.path ?? "/fr-ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faq0vVzCYkBWgMeta || {},
    alias: customer_45referrals_45faq0vVzCYkBWgMeta?.alias || [],
    redirect: customer_45referrals_45faq0vVzCYkBWgMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish8wgdztL0mXMeta?.name ?? "support-driver-training-spanish___en-US",
    path: driver_45training_45spanish8wgdztL0mXMeta?.path ?? "/support/driver-training-spanish",
    meta: driver_45training_45spanish8wgdztL0mXMeta || {},
    alias: driver_45training_45spanish8wgdztL0mXMeta?.alias || [],
    redirect: driver_45training_45spanish8wgdztL0mXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish8wgdztL0mXMeta?.name ?? "support-driver-training-spanish___ca",
    path: driver_45training_45spanish8wgdztL0mXMeta?.path ?? "/ca/support/driver-training-spanish",
    meta: driver_45training_45spanish8wgdztL0mXMeta || {},
    alias: driver_45training_45spanish8wgdztL0mXMeta?.alias || [],
    redirect: driver_45training_45spanish8wgdztL0mXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish8wgdztL0mXMeta?.name ?? "support-driver-training-spanish___nl",
    path: driver_45training_45spanish8wgdztL0mXMeta?.path ?? "/nl/support/driver-training-spanish",
    meta: driver_45training_45spanish8wgdztL0mXMeta || {},
    alias: driver_45training_45spanish8wgdztL0mXMeta?.alias || [],
    redirect: driver_45training_45spanish8wgdztL0mXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish8wgdztL0mXMeta?.name ?? "support-driver-training-spanish___uk",
    path: driver_45training_45spanish8wgdztL0mXMeta?.path ?? "/uk/support/driver-training-spanish",
    meta: driver_45training_45spanish8wgdztL0mXMeta || {},
    alias: driver_45training_45spanish8wgdztL0mXMeta?.alias || [],
    redirect: driver_45training_45spanish8wgdztL0mXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish8wgdztL0mXMeta?.name ?? "support-driver-training-spanish___de",
    path: driver_45training_45spanish8wgdztL0mXMeta?.path ?? "/de/support/driver-training-spanish",
    meta: driver_45training_45spanish8wgdztL0mXMeta || {},
    alias: driver_45training_45spanish8wgdztL0mXMeta?.alias || [],
    redirect: driver_45training_45spanish8wgdztL0mXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish8wgdztL0mXMeta?.name ?? "support-driver-training-spanish___mx",
    path: driver_45training_45spanish8wgdztL0mXMeta?.path ?? "/mx/support/driver-training-spanish",
    meta: driver_45training_45spanish8wgdztL0mXMeta || {},
    alias: driver_45training_45spanish8wgdztL0mXMeta?.alias || [],
    redirect: driver_45training_45spanish8wgdztL0mXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish8wgdztL0mXMeta?.name ?? "support-driver-training-spanish___fr",
    path: driver_45training_45spanish8wgdztL0mXMeta?.path ?? "/fr/support/driver-training-spanish",
    meta: driver_45training_45spanish8wgdztL0mXMeta || {},
    alias: driver_45training_45spanish8wgdztL0mXMeta?.alias || [],
    redirect: driver_45training_45spanish8wgdztL0mXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanish8wgdztL0mXMeta?.name ?? "support-driver-training-spanish___fr-ca",
    path: driver_45training_45spanish8wgdztL0mXMeta?.path ?? "/fr-ca/support/driver-training-spanish",
    meta: driver_45training_45spanish8wgdztL0mXMeta || {},
    alias: driver_45training_45spanish8wgdztL0mXMeta?.alias || [],
    redirect: driver_45training_45spanish8wgdztL0mXMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingBDpeu912pPMeta?.name ?? "support-driver-training___en-US",
    path: driver_45trainingBDpeu912pPMeta?.path ?? "/support/driver-training",
    meta: driver_45trainingBDpeu912pPMeta || {},
    alias: driver_45trainingBDpeu912pPMeta?.alias || [],
    redirect: driver_45trainingBDpeu912pPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingBDpeu912pPMeta?.name ?? "support-driver-training___ca",
    path: driver_45trainingBDpeu912pPMeta?.path ?? "/ca/support/driver-training",
    meta: driver_45trainingBDpeu912pPMeta || {},
    alias: driver_45trainingBDpeu912pPMeta?.alias || [],
    redirect: driver_45trainingBDpeu912pPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingBDpeu912pPMeta?.name ?? "support-driver-training___nl",
    path: driver_45trainingBDpeu912pPMeta?.path ?? "/nl/support/driver-training",
    meta: driver_45trainingBDpeu912pPMeta || {},
    alias: driver_45trainingBDpeu912pPMeta?.alias || [],
    redirect: driver_45trainingBDpeu912pPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingBDpeu912pPMeta?.name ?? "support-driver-training___uk",
    path: driver_45trainingBDpeu912pPMeta?.path ?? "/uk/support/driver-training",
    meta: driver_45trainingBDpeu912pPMeta || {},
    alias: driver_45trainingBDpeu912pPMeta?.alias || [],
    redirect: driver_45trainingBDpeu912pPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingBDpeu912pPMeta?.name ?? "support-driver-training___de",
    path: driver_45trainingBDpeu912pPMeta?.path ?? "/de/support/driver-training",
    meta: driver_45trainingBDpeu912pPMeta || {},
    alias: driver_45trainingBDpeu912pPMeta?.alias || [],
    redirect: driver_45trainingBDpeu912pPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingBDpeu912pPMeta?.name ?? "support-driver-training___mx",
    path: driver_45trainingBDpeu912pPMeta?.path ?? "/mx/support/driver-training",
    meta: driver_45trainingBDpeu912pPMeta || {},
    alias: driver_45trainingBDpeu912pPMeta?.alias || [],
    redirect: driver_45trainingBDpeu912pPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingBDpeu912pPMeta?.name ?? "support-driver-training___fr",
    path: driver_45trainingBDpeu912pPMeta?.path ?? "/fr/support/driver-training",
    meta: driver_45trainingBDpeu912pPMeta || {},
    alias: driver_45trainingBDpeu912pPMeta?.alias || [],
    redirect: driver_45trainingBDpeu912pPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingBDpeu912pPMeta?.name ?? "support-driver-training___fr-ca",
    path: driver_45trainingBDpeu912pPMeta?.path ?? "/fr-ca/support/driver-training",
    meta: driver_45trainingBDpeu912pPMeta || {},
    alias: driver_45trainingBDpeu912pPMeta?.alias || [],
    redirect: driver_45trainingBDpeu912pPMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: index2EqjIwGTq2Meta?.name ?? "support___en-US",
    path: index2EqjIwGTq2Meta?.path ?? "/support",
    meta: index2EqjIwGTq2Meta || {},
    alias: index2EqjIwGTq2Meta?.alias || [],
    redirect: index2EqjIwGTq2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: index2EqjIwGTq2Meta?.name ?? "support___ca",
    path: index2EqjIwGTq2Meta?.path ?? "/ca/support",
    meta: index2EqjIwGTq2Meta || {},
    alias: index2EqjIwGTq2Meta?.alias || [],
    redirect: index2EqjIwGTq2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: index2EqjIwGTq2Meta?.name ?? "support___nl",
    path: index2EqjIwGTq2Meta?.path ?? "/nl/support",
    meta: index2EqjIwGTq2Meta || {},
    alias: index2EqjIwGTq2Meta?.alias || [],
    redirect: index2EqjIwGTq2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: index2EqjIwGTq2Meta?.name ?? "support___uk",
    path: index2EqjIwGTq2Meta?.path ?? "/uk/support",
    meta: index2EqjIwGTq2Meta || {},
    alias: index2EqjIwGTq2Meta?.alias || [],
    redirect: index2EqjIwGTq2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: index2EqjIwGTq2Meta?.name ?? "support___de",
    path: index2EqjIwGTq2Meta?.path ?? "/de/support",
    meta: index2EqjIwGTq2Meta || {},
    alias: index2EqjIwGTq2Meta?.alias || [],
    redirect: index2EqjIwGTq2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: index2EqjIwGTq2Meta?.name ?? "support___mx",
    path: index2EqjIwGTq2Meta?.path ?? "/mx/support",
    meta: index2EqjIwGTq2Meta || {},
    alias: index2EqjIwGTq2Meta?.alias || [],
    redirect: index2EqjIwGTq2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: index2EqjIwGTq2Meta?.name ?? "support___fr",
    path: index2EqjIwGTq2Meta?.path ?? "/fr/support",
    meta: index2EqjIwGTq2Meta || {},
    alias: index2EqjIwGTq2Meta?.alias || [],
    redirect: index2EqjIwGTq2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: index2EqjIwGTq2Meta?.name ?? "support___fr-ca",
    path: index2EqjIwGTq2Meta?.path ?? "/fr-ca/support",
    meta: index2EqjIwGTq2Meta || {},
    alias: index2EqjIwGTq2Meta?.alias || [],
    redirect: index2EqjIwGTq2Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: industrialGsQ8zSACURMeta?.name ?? "support-industrial___en-US",
    path: industrialGsQ8zSACURMeta?.path ?? "/support/industrial",
    meta: industrialGsQ8zSACURMeta || {},
    alias: industrialGsQ8zSACURMeta?.alias || [],
    redirect: industrialGsQ8zSACURMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialGsQ8zSACURMeta?.name ?? "support-industrial___ca",
    path: industrialGsQ8zSACURMeta?.path ?? "/ca/support/industrial",
    meta: industrialGsQ8zSACURMeta || {},
    alias: industrialGsQ8zSACURMeta?.alias || [],
    redirect: industrialGsQ8zSACURMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialGsQ8zSACURMeta?.name ?? "support-industrial___nl",
    path: industrialGsQ8zSACURMeta?.path ?? "/nl/support/industrial",
    meta: industrialGsQ8zSACURMeta || {},
    alias: industrialGsQ8zSACURMeta?.alias || [],
    redirect: industrialGsQ8zSACURMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialGsQ8zSACURMeta?.name ?? "support-industrial___uk",
    path: industrialGsQ8zSACURMeta?.path ?? "/uk/support/industrial",
    meta: industrialGsQ8zSACURMeta || {},
    alias: industrialGsQ8zSACURMeta?.alias || [],
    redirect: industrialGsQ8zSACURMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialGsQ8zSACURMeta?.name ?? "support-industrial___de",
    path: industrialGsQ8zSACURMeta?.path ?? "/de/support/industrial",
    meta: industrialGsQ8zSACURMeta || {},
    alias: industrialGsQ8zSACURMeta?.alias || [],
    redirect: industrialGsQ8zSACURMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialGsQ8zSACURMeta?.name ?? "support-industrial___mx",
    path: industrialGsQ8zSACURMeta?.path ?? "/mx/support/industrial",
    meta: industrialGsQ8zSACURMeta || {},
    alias: industrialGsQ8zSACURMeta?.alias || [],
    redirect: industrialGsQ8zSACURMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialGsQ8zSACURMeta?.name ?? "support-industrial___fr",
    path: industrialGsQ8zSACURMeta?.path ?? "/fr/support/industrial",
    meta: industrialGsQ8zSACURMeta || {},
    alias: industrialGsQ8zSACURMeta?.alias || [],
    redirect: industrialGsQ8zSACURMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialGsQ8zSACURMeta?.name ?? "support-industrial___fr-ca",
    path: industrialGsQ8zSACURMeta?.path ?? "/fr-ca/support/industrial",
    meta: industrialGsQ8zSACURMeta || {},
    alias: industrialGsQ8zSACURMeta?.alias || [],
    redirect: industrialGsQ8zSACURMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdtwvoCcOjiMeta?.name ?? "support-modern-slavery-statement___en-US",
    path: modern_45slavery_45statementdtwvoCcOjiMeta?.path ?? "/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdtwvoCcOjiMeta || {},
    alias: modern_45slavery_45statementdtwvoCcOjiMeta?.alias || [],
    redirect: modern_45slavery_45statementdtwvoCcOjiMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdtwvoCcOjiMeta?.name ?? "support-modern-slavery-statement___ca",
    path: modern_45slavery_45statementdtwvoCcOjiMeta?.path ?? "/ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdtwvoCcOjiMeta || {},
    alias: modern_45slavery_45statementdtwvoCcOjiMeta?.alias || [],
    redirect: modern_45slavery_45statementdtwvoCcOjiMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdtwvoCcOjiMeta?.name ?? "support-modern-slavery-statement___nl",
    path: modern_45slavery_45statementdtwvoCcOjiMeta?.path ?? "/nl/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdtwvoCcOjiMeta || {},
    alias: modern_45slavery_45statementdtwvoCcOjiMeta?.alias || [],
    redirect: modern_45slavery_45statementdtwvoCcOjiMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdtwvoCcOjiMeta?.name ?? "support-modern-slavery-statement___uk",
    path: modern_45slavery_45statementdtwvoCcOjiMeta?.path ?? "/uk/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdtwvoCcOjiMeta || {},
    alias: modern_45slavery_45statementdtwvoCcOjiMeta?.alias || [],
    redirect: modern_45slavery_45statementdtwvoCcOjiMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdtwvoCcOjiMeta?.name ?? "support-modern-slavery-statement___de",
    path: modern_45slavery_45statementdtwvoCcOjiMeta?.path ?? "/de/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdtwvoCcOjiMeta || {},
    alias: modern_45slavery_45statementdtwvoCcOjiMeta?.alias || [],
    redirect: modern_45slavery_45statementdtwvoCcOjiMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdtwvoCcOjiMeta?.name ?? "support-modern-slavery-statement___mx",
    path: modern_45slavery_45statementdtwvoCcOjiMeta?.path ?? "/mx/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdtwvoCcOjiMeta || {},
    alias: modern_45slavery_45statementdtwvoCcOjiMeta?.alias || [],
    redirect: modern_45slavery_45statementdtwvoCcOjiMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdtwvoCcOjiMeta?.name ?? "support-modern-slavery-statement___fr",
    path: modern_45slavery_45statementdtwvoCcOjiMeta?.path ?? "/fr/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdtwvoCcOjiMeta || {},
    alias: modern_45slavery_45statementdtwvoCcOjiMeta?.alias || [],
    redirect: modern_45slavery_45statementdtwvoCcOjiMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdtwvoCcOjiMeta?.name ?? "support-modern-slavery-statement___fr-ca",
    path: modern_45slavery_45statementdtwvoCcOjiMeta?.path ?? "/fr-ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdtwvoCcOjiMeta || {},
    alias: modern_45slavery_45statementdtwvoCcOjiMeta?.alias || [],
    redirect: modern_45slavery_45statementdtwvoCcOjiMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policykmwMzEyw4EMeta?.name ?? "support-privacy-apps-privacy-policy___en-US",
    path: apps_45privacy_45policykmwMzEyw4EMeta?.path ?? "/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policykmwMzEyw4EMeta || {},
    alias: apps_45privacy_45policykmwMzEyw4EMeta?.alias || [],
    redirect: apps_45privacy_45policykmwMzEyw4EMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policykmwMzEyw4EMeta?.name ?? "support-privacy-apps-privacy-policy___ca",
    path: apps_45privacy_45policykmwMzEyw4EMeta?.path ?? "/ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policykmwMzEyw4EMeta || {},
    alias: apps_45privacy_45policykmwMzEyw4EMeta?.alias || [],
    redirect: apps_45privacy_45policykmwMzEyw4EMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policykmwMzEyw4EMeta?.name ?? "support-privacy-apps-privacy-policy___nl",
    path: apps_45privacy_45policykmwMzEyw4EMeta?.path ?? "/nl/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policykmwMzEyw4EMeta || {},
    alias: apps_45privacy_45policykmwMzEyw4EMeta?.alias || [],
    redirect: apps_45privacy_45policykmwMzEyw4EMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policykmwMzEyw4EMeta?.name ?? "support-privacy-apps-privacy-policy___uk",
    path: apps_45privacy_45policykmwMzEyw4EMeta?.path ?? "/uk/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policykmwMzEyw4EMeta || {},
    alias: apps_45privacy_45policykmwMzEyw4EMeta?.alias || [],
    redirect: apps_45privacy_45policykmwMzEyw4EMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policykmwMzEyw4EMeta?.name ?? "support-privacy-apps-privacy-policy___de",
    path: apps_45privacy_45policykmwMzEyw4EMeta?.path ?? "/de/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policykmwMzEyw4EMeta || {},
    alias: apps_45privacy_45policykmwMzEyw4EMeta?.alias || [],
    redirect: apps_45privacy_45policykmwMzEyw4EMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policykmwMzEyw4EMeta?.name ?? "support-privacy-apps-privacy-policy___mx",
    path: apps_45privacy_45policykmwMzEyw4EMeta?.path ?? "/mx/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policykmwMzEyw4EMeta || {},
    alias: apps_45privacy_45policykmwMzEyw4EMeta?.alias || [],
    redirect: apps_45privacy_45policykmwMzEyw4EMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policykmwMzEyw4EMeta?.name ?? "support-privacy-apps-privacy-policy___fr",
    path: apps_45privacy_45policykmwMzEyw4EMeta?.path ?? "/fr/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policykmwMzEyw4EMeta || {},
    alias: apps_45privacy_45policykmwMzEyw4EMeta?.alias || [],
    redirect: apps_45privacy_45policykmwMzEyw4EMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policykmwMzEyw4EMeta?.name ?? "support-privacy-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policykmwMzEyw4EMeta?.path ?? "/fr-ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policykmwMzEyw4EMeta || {},
    alias: apps_45privacy_45policykmwMzEyw4EMeta?.alias || [],
    redirect: apps_45privacy_45policykmwMzEyw4EMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexddoJyHvbQMMeta?.name ?? "support-privacy___en-US",
    path: indexddoJyHvbQMMeta?.path ?? "/support/privacy",
    meta: indexddoJyHvbQMMeta || {},
    alias: indexddoJyHvbQMMeta?.alias || [],
    redirect: indexddoJyHvbQMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexddoJyHvbQMMeta?.name ?? "support-privacy___ca",
    path: indexddoJyHvbQMMeta?.path ?? "/ca/support/privacy",
    meta: indexddoJyHvbQMMeta || {},
    alias: indexddoJyHvbQMMeta?.alias || [],
    redirect: indexddoJyHvbQMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexddoJyHvbQMMeta?.name ?? "support-privacy___nl",
    path: indexddoJyHvbQMMeta?.path ?? "/nl/support/privacy",
    meta: indexddoJyHvbQMMeta || {},
    alias: indexddoJyHvbQMMeta?.alias || [],
    redirect: indexddoJyHvbQMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexddoJyHvbQMMeta?.name ?? "support-privacy___uk",
    path: indexddoJyHvbQMMeta?.path ?? "/uk/support/privacy",
    meta: indexddoJyHvbQMMeta || {},
    alias: indexddoJyHvbQMMeta?.alias || [],
    redirect: indexddoJyHvbQMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexddoJyHvbQMMeta?.name ?? "support-privacy___de",
    path: indexddoJyHvbQMMeta?.path ?? "/de/support/privacy",
    meta: indexddoJyHvbQMMeta || {},
    alias: indexddoJyHvbQMMeta?.alias || [],
    redirect: indexddoJyHvbQMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexddoJyHvbQMMeta?.name ?? "support-privacy___mx",
    path: indexddoJyHvbQMMeta?.path ?? "/mx/support/privacy",
    meta: indexddoJyHvbQMMeta || {},
    alias: indexddoJyHvbQMMeta?.alias || [],
    redirect: indexddoJyHvbQMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexddoJyHvbQMMeta?.name ?? "support-privacy___fr",
    path: indexddoJyHvbQMMeta?.path ?? "/fr/support/privacy",
    meta: indexddoJyHvbQMMeta || {},
    alias: indexddoJyHvbQMMeta?.alias || [],
    redirect: indexddoJyHvbQMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexddoJyHvbQMMeta?.name ?? "support-privacy___fr-ca",
    path: indexddoJyHvbQMMeta?.path ?? "/fr-ca/support/privacy",
    meta: indexddoJyHvbQMMeta || {},
    alias: indexddoJyHvbQMMeta?.alias || [],
    redirect: indexddoJyHvbQMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: special_45featureskcsc06N254Meta?.name ?? "support-privacy-special-features___en-US",
    path: special_45featureskcsc06N254Meta?.path ?? "/support/privacy/special-features",
    meta: special_45featureskcsc06N254Meta || {},
    alias: special_45featureskcsc06N254Meta?.alias || [],
    redirect: special_45featureskcsc06N254Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureskcsc06N254Meta?.name ?? "support-privacy-special-features___ca",
    path: special_45featureskcsc06N254Meta?.path ?? "/ca/support/privacy/special-features",
    meta: special_45featureskcsc06N254Meta || {},
    alias: special_45featureskcsc06N254Meta?.alias || [],
    redirect: special_45featureskcsc06N254Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureskcsc06N254Meta?.name ?? "support-privacy-special-features___nl",
    path: special_45featureskcsc06N254Meta?.path ?? "/nl/support/privacy/special-features",
    meta: special_45featureskcsc06N254Meta || {},
    alias: special_45featureskcsc06N254Meta?.alias || [],
    redirect: special_45featureskcsc06N254Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureskcsc06N254Meta?.name ?? "support-privacy-special-features___uk",
    path: special_45featureskcsc06N254Meta?.path ?? "/uk/support/privacy/special-features",
    meta: special_45featureskcsc06N254Meta || {},
    alias: special_45featureskcsc06N254Meta?.alias || [],
    redirect: special_45featureskcsc06N254Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureskcsc06N254Meta?.name ?? "support-privacy-special-features___de",
    path: special_45featureskcsc06N254Meta?.path ?? "/de/support/privacy/special-features",
    meta: special_45featureskcsc06N254Meta || {},
    alias: special_45featureskcsc06N254Meta?.alias || [],
    redirect: special_45featureskcsc06N254Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureskcsc06N254Meta?.name ?? "support-privacy-special-features___mx",
    path: special_45featureskcsc06N254Meta?.path ?? "/mx/support/privacy/special-features",
    meta: special_45featureskcsc06N254Meta || {},
    alias: special_45featureskcsc06N254Meta?.alias || [],
    redirect: special_45featureskcsc06N254Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureskcsc06N254Meta?.name ?? "support-privacy-special-features___fr",
    path: special_45featureskcsc06N254Meta?.path ?? "/fr/support/privacy/special-features",
    meta: special_45featureskcsc06N254Meta || {},
    alias: special_45featureskcsc06N254Meta?.alias || [],
    redirect: special_45featureskcsc06N254Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featureskcsc06N254Meta?.name ?? "support-privacy-special-features___fr-ca",
    path: special_45featureskcsc06N254Meta?.path ?? "/fr-ca/support/privacy/special-features",
    meta: special_45featureskcsc06N254Meta || {},
    alias: special_45featureskcsc06N254Meta?.alias || [],
    redirect: special_45featureskcsc06N254Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45list1oz4msZxIcMeta?.name ?? "support-privacy-subprocessor-list___en-US",
    path: subprocessor_45list1oz4msZxIcMeta?.path ?? "/support/privacy/subprocessor-list",
    meta: subprocessor_45list1oz4msZxIcMeta || {},
    alias: subprocessor_45list1oz4msZxIcMeta?.alias || [],
    redirect: subprocessor_45list1oz4msZxIcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45list1oz4msZxIcMeta?.name ?? "support-privacy-subprocessor-list___ca",
    path: subprocessor_45list1oz4msZxIcMeta?.path ?? "/ca/support/privacy/subprocessor-list",
    meta: subprocessor_45list1oz4msZxIcMeta || {},
    alias: subprocessor_45list1oz4msZxIcMeta?.alias || [],
    redirect: subprocessor_45list1oz4msZxIcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45list1oz4msZxIcMeta?.name ?? "support-privacy-subprocessor-list___nl",
    path: subprocessor_45list1oz4msZxIcMeta?.path ?? "/nl/support/privacy/subprocessor-list",
    meta: subprocessor_45list1oz4msZxIcMeta || {},
    alias: subprocessor_45list1oz4msZxIcMeta?.alias || [],
    redirect: subprocessor_45list1oz4msZxIcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45list1oz4msZxIcMeta?.name ?? "support-privacy-subprocessor-list___uk",
    path: subprocessor_45list1oz4msZxIcMeta?.path ?? "/uk/support/privacy/subprocessor-list",
    meta: subprocessor_45list1oz4msZxIcMeta || {},
    alias: subprocessor_45list1oz4msZxIcMeta?.alias || [],
    redirect: subprocessor_45list1oz4msZxIcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45list1oz4msZxIcMeta?.name ?? "support-privacy-subprocessor-list___de",
    path: subprocessor_45list1oz4msZxIcMeta?.path ?? "/de/support/privacy/subprocessor-list",
    meta: subprocessor_45list1oz4msZxIcMeta || {},
    alias: subprocessor_45list1oz4msZxIcMeta?.alias || [],
    redirect: subprocessor_45list1oz4msZxIcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45list1oz4msZxIcMeta?.name ?? "support-privacy-subprocessor-list___mx",
    path: subprocessor_45list1oz4msZxIcMeta?.path ?? "/mx/support/privacy/subprocessor-list",
    meta: subprocessor_45list1oz4msZxIcMeta || {},
    alias: subprocessor_45list1oz4msZxIcMeta?.alias || [],
    redirect: subprocessor_45list1oz4msZxIcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45list1oz4msZxIcMeta?.name ?? "support-privacy-subprocessor-list___fr",
    path: subprocessor_45list1oz4msZxIcMeta?.path ?? "/fr/support/privacy/subprocessor-list",
    meta: subprocessor_45list1oz4msZxIcMeta || {},
    alias: subprocessor_45list1oz4msZxIcMeta?.alias || [],
    redirect: subprocessor_45list1oz4msZxIcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45list1oz4msZxIcMeta?.name ?? "support-privacy-subprocessor-list___fr-ca",
    path: subprocessor_45list1oz4msZxIcMeta?.path ?? "/fr-ca/support/privacy/subprocessor-list",
    meta: subprocessor_45list1oz4msZxIcMeta || {},
    alias: subprocessor_45list1oz4msZxIcMeta?.alias || [],
    redirect: subprocessor_45list1oz4msZxIcMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesQG3sIPDT8fMeta?.name ?? "support-support-kb-articles___nl",
    path: support_45kb_45articlesQG3sIPDT8fMeta?.path ?? "/nl/support/support-kb-articles",
    meta: support_45kb_45articlesQG3sIPDT8fMeta || {},
    alias: support_45kb_45articlesQG3sIPDT8fMeta?.alias || [],
    redirect: support_45kb_45articlesQG3sIPDT8fMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesQG3sIPDT8fMeta?.name ?? "support-support-kb-articles___mx",
    path: support_45kb_45articlesQG3sIPDT8fMeta?.path ?? "/mx/support/support-kb-articles",
    meta: support_45kb_45articlesQG3sIPDT8fMeta || {},
    alias: support_45kb_45articlesQG3sIPDT8fMeta?.alias || [],
    redirect: support_45kb_45articlesQG3sIPDT8fMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesQG3sIPDT8fMeta?.name ?? "support-support-kb-articles___fr",
    path: support_45kb_45articlesQG3sIPDT8fMeta?.path ?? "/fr/support/support-kb-articles",
    meta: support_45kb_45articlesQG3sIPDT8fMeta || {},
    alias: support_45kb_45articlesQG3sIPDT8fMeta?.alias || [],
    redirect: support_45kb_45articlesQG3sIPDT8fMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesQG3sIPDT8fMeta?.name ?? "support-support-kb-articles___de",
    path: support_45kb_45articlesQG3sIPDT8fMeta?.path ?? "/de/support/support-kb-articles",
    meta: support_45kb_45articlesQG3sIPDT8fMeta || {},
    alias: support_45kb_45articlesQG3sIPDT8fMeta?.alias || [],
    redirect: support_45kb_45articlesQG3sIPDT8fMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageSqJeeknUN0Meta?.name ?? "thank-you-page___en-US",
    path: thank_45you_45pageSqJeeknUN0Meta?.path ?? "/thank-you-page",
    meta: thank_45you_45pageSqJeeknUN0Meta || {},
    alias: thank_45you_45pageSqJeeknUN0Meta?.alias || [],
    redirect: thank_45you_45pageSqJeeknUN0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageSqJeeknUN0Meta?.name ?? "thank-you-page___ca",
    path: thank_45you_45pageSqJeeknUN0Meta?.path ?? "/ca/thank-you-page",
    meta: thank_45you_45pageSqJeeknUN0Meta || {},
    alias: thank_45you_45pageSqJeeknUN0Meta?.alias || [],
    redirect: thank_45you_45pageSqJeeknUN0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageSqJeeknUN0Meta?.name ?? "thank-you-page___nl",
    path: thank_45you_45pageSqJeeknUN0Meta?.path ?? "/nl/thank-you-page",
    meta: thank_45you_45pageSqJeeknUN0Meta || {},
    alias: thank_45you_45pageSqJeeknUN0Meta?.alias || [],
    redirect: thank_45you_45pageSqJeeknUN0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageSqJeeknUN0Meta?.name ?? "thank-you-page___uk",
    path: thank_45you_45pageSqJeeknUN0Meta?.path ?? "/uk/thank-you-page",
    meta: thank_45you_45pageSqJeeknUN0Meta || {},
    alias: thank_45you_45pageSqJeeknUN0Meta?.alias || [],
    redirect: thank_45you_45pageSqJeeknUN0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageSqJeeknUN0Meta?.name ?? "thank-you-page___de",
    path: thank_45you_45pageSqJeeknUN0Meta?.path ?? "/de/thank-you-page",
    meta: thank_45you_45pageSqJeeknUN0Meta || {},
    alias: thank_45you_45pageSqJeeknUN0Meta?.alias || [],
    redirect: thank_45you_45pageSqJeeknUN0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageSqJeeknUN0Meta?.name ?? "thank-you-page___mx",
    path: thank_45you_45pageSqJeeknUN0Meta?.path ?? "/mx/thank-you-page",
    meta: thank_45you_45pageSqJeeknUN0Meta || {},
    alias: thank_45you_45pageSqJeeknUN0Meta?.alias || [],
    redirect: thank_45you_45pageSqJeeknUN0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageSqJeeknUN0Meta?.name ?? "thank-you-page___fr",
    path: thank_45you_45pageSqJeeknUN0Meta?.path ?? "/fr/thank-you-page",
    meta: thank_45you_45pageSqJeeknUN0Meta || {},
    alias: thank_45you_45pageSqJeeknUN0Meta?.alias || [],
    redirect: thank_45you_45pageSqJeeknUN0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageSqJeeknUN0Meta?.name ?? "thank-you-page___fr-ca",
    path: thank_45you_45pageSqJeeknUN0Meta?.path ?? "/fr-ca/thank-you-page",
    meta: thank_45you_45pageSqJeeknUN0Meta || {},
    alias: thank_45you_45pageSqJeeknUN0Meta?.alias || [],
    redirect: thank_45you_45pageSqJeeknUN0Meta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingkKdk6N8CbxMeta?.name ?? "thankyou-recruiting___en-US",
    path: thankyou_45recruitingkKdk6N8CbxMeta?.path ?? "/thankyou-recruiting",
    meta: thankyou_45recruitingkKdk6N8CbxMeta || {},
    alias: thankyou_45recruitingkKdk6N8CbxMeta?.alias || [],
    redirect: thankyou_45recruitingkKdk6N8CbxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingkKdk6N8CbxMeta?.name ?? "thankyou-recruiting___ca",
    path: thankyou_45recruitingkKdk6N8CbxMeta?.path ?? "/ca/thankyou-recruiting",
    meta: thankyou_45recruitingkKdk6N8CbxMeta || {},
    alias: thankyou_45recruitingkKdk6N8CbxMeta?.alias || [],
    redirect: thankyou_45recruitingkKdk6N8CbxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingkKdk6N8CbxMeta?.name ?? "thankyou-recruiting___nl",
    path: thankyou_45recruitingkKdk6N8CbxMeta?.path ?? "/nl/thankyou-recruiting",
    meta: thankyou_45recruitingkKdk6N8CbxMeta || {},
    alias: thankyou_45recruitingkKdk6N8CbxMeta?.alias || [],
    redirect: thankyou_45recruitingkKdk6N8CbxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingkKdk6N8CbxMeta?.name ?? "thankyou-recruiting___uk",
    path: thankyou_45recruitingkKdk6N8CbxMeta?.path ?? "/uk/thankyou-recruiting",
    meta: thankyou_45recruitingkKdk6N8CbxMeta || {},
    alias: thankyou_45recruitingkKdk6N8CbxMeta?.alias || [],
    redirect: thankyou_45recruitingkKdk6N8CbxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingkKdk6N8CbxMeta?.name ?? "thankyou-recruiting___de",
    path: thankyou_45recruitingkKdk6N8CbxMeta?.path ?? "/de/thankyou-recruiting",
    meta: thankyou_45recruitingkKdk6N8CbxMeta || {},
    alias: thankyou_45recruitingkKdk6N8CbxMeta?.alias || [],
    redirect: thankyou_45recruitingkKdk6N8CbxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingkKdk6N8CbxMeta?.name ?? "thankyou-recruiting___mx",
    path: thankyou_45recruitingkKdk6N8CbxMeta?.path ?? "/mx/thankyou-recruiting",
    meta: thankyou_45recruitingkKdk6N8CbxMeta || {},
    alias: thankyou_45recruitingkKdk6N8CbxMeta?.alias || [],
    redirect: thankyou_45recruitingkKdk6N8CbxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingkKdk6N8CbxMeta?.name ?? "thankyou-recruiting___fr",
    path: thankyou_45recruitingkKdk6N8CbxMeta?.path ?? "/fr/thankyou-recruiting",
    meta: thankyou_45recruitingkKdk6N8CbxMeta || {},
    alias: thankyou_45recruitingkKdk6N8CbxMeta?.alias || [],
    redirect: thankyou_45recruitingkKdk6N8CbxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingkKdk6N8CbxMeta?.name ?? "thankyou-recruiting___fr-ca",
    path: thankyou_45recruitingkKdk6N8CbxMeta?.path ?? "/fr-ca/thankyou-recruiting",
    meta: thankyou_45recruitingkKdk6N8CbxMeta || {},
    alias: thankyou_45recruitingkKdk6N8CbxMeta?.alias || [],
    redirect: thankyou_45recruitingkKdk6N8CbxMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral4Hq3qvntnzMeta?.name ?? "thankyou-referral___en-US",
    path: thankyou_45referral4Hq3qvntnzMeta?.path ?? "/thankyou-referral",
    meta: thankyou_45referral4Hq3qvntnzMeta || {},
    alias: thankyou_45referral4Hq3qvntnzMeta?.alias || [],
    redirect: thankyou_45referral4Hq3qvntnzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral4Hq3qvntnzMeta?.name ?? "thankyou-referral___ca",
    path: thankyou_45referral4Hq3qvntnzMeta?.path ?? "/ca/thankyou-referral",
    meta: thankyou_45referral4Hq3qvntnzMeta || {},
    alias: thankyou_45referral4Hq3qvntnzMeta?.alias || [],
    redirect: thankyou_45referral4Hq3qvntnzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral4Hq3qvntnzMeta?.name ?? "thankyou-referral___nl",
    path: thankyou_45referral4Hq3qvntnzMeta?.path ?? "/nl/thankyou-referral",
    meta: thankyou_45referral4Hq3qvntnzMeta || {},
    alias: thankyou_45referral4Hq3qvntnzMeta?.alias || [],
    redirect: thankyou_45referral4Hq3qvntnzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral4Hq3qvntnzMeta?.name ?? "thankyou-referral___uk",
    path: thankyou_45referral4Hq3qvntnzMeta?.path ?? "/uk/thankyou-referral",
    meta: thankyou_45referral4Hq3qvntnzMeta || {},
    alias: thankyou_45referral4Hq3qvntnzMeta?.alias || [],
    redirect: thankyou_45referral4Hq3qvntnzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral4Hq3qvntnzMeta?.name ?? "thankyou-referral___de",
    path: thankyou_45referral4Hq3qvntnzMeta?.path ?? "/de/thankyou-referral",
    meta: thankyou_45referral4Hq3qvntnzMeta || {},
    alias: thankyou_45referral4Hq3qvntnzMeta?.alias || [],
    redirect: thankyou_45referral4Hq3qvntnzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral4Hq3qvntnzMeta?.name ?? "thankyou-referral___mx",
    path: thankyou_45referral4Hq3qvntnzMeta?.path ?? "/mx/thankyou-referral",
    meta: thankyou_45referral4Hq3qvntnzMeta || {},
    alias: thankyou_45referral4Hq3qvntnzMeta?.alias || [],
    redirect: thankyou_45referral4Hq3qvntnzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral4Hq3qvntnzMeta?.name ?? "thankyou-referral___fr",
    path: thankyou_45referral4Hq3qvntnzMeta?.path ?? "/fr/thankyou-referral",
    meta: thankyou_45referral4Hq3qvntnzMeta || {},
    alias: thankyou_45referral4Hq3qvntnzMeta?.alias || [],
    redirect: thankyou_45referral4Hq3qvntnzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referral4Hq3qvntnzMeta?.name ?? "thankyou-referral___fr-ca",
    path: thankyou_45referral4Hq3qvntnzMeta?.path ?? "/fr-ca/thankyou-referral",
    meta: thankyou_45referral4Hq3qvntnzMeta || {},
    alias: thankyou_45referral4Hq3qvntnzMeta?.alias || [],
    redirect: thankyou_45referral4Hq3qvntnzMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyouckrkH6yzejMeta?.name ?? "thankyou___en-US",
    path: thankyouckrkH6yzejMeta?.path ?? "/thankyou",
    meta: thankyouckrkH6yzejMeta || {},
    alias: thankyouckrkH6yzejMeta?.alias || [],
    redirect: thankyouckrkH6yzejMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouckrkH6yzejMeta?.name ?? "thankyou___ca",
    path: thankyouckrkH6yzejMeta?.path ?? "/ca/thankyou",
    meta: thankyouckrkH6yzejMeta || {},
    alias: thankyouckrkH6yzejMeta?.alias || [],
    redirect: thankyouckrkH6yzejMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouckrkH6yzejMeta?.name ?? "thankyou___nl",
    path: thankyouckrkH6yzejMeta?.path ?? "/nl/thankyou",
    meta: thankyouckrkH6yzejMeta || {},
    alias: thankyouckrkH6yzejMeta?.alias || [],
    redirect: thankyouckrkH6yzejMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouckrkH6yzejMeta?.name ?? "thankyou___uk",
    path: thankyouckrkH6yzejMeta?.path ?? "/uk/thankyou",
    meta: thankyouckrkH6yzejMeta || {},
    alias: thankyouckrkH6yzejMeta?.alias || [],
    redirect: thankyouckrkH6yzejMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouckrkH6yzejMeta?.name ?? "thankyou___de",
    path: thankyouckrkH6yzejMeta?.path ?? "/de/thankyou",
    meta: thankyouckrkH6yzejMeta || {},
    alias: thankyouckrkH6yzejMeta?.alias || [],
    redirect: thankyouckrkH6yzejMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouckrkH6yzejMeta?.name ?? "thankyou___mx",
    path: thankyouckrkH6yzejMeta?.path ?? "/mx/thankyou",
    meta: thankyouckrkH6yzejMeta || {},
    alias: thankyouckrkH6yzejMeta?.alias || [],
    redirect: thankyouckrkH6yzejMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouckrkH6yzejMeta?.name ?? "thankyou___fr",
    path: thankyouckrkH6yzejMeta?.path ?? "/fr/thankyou",
    meta: thankyouckrkH6yzejMeta || {},
    alias: thankyouckrkH6yzejMeta?.alias || [],
    redirect: thankyouckrkH6yzejMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouckrkH6yzejMeta?.name ?? "thankyou___fr-ca",
    path: thankyouckrkH6yzejMeta?.path ?? "/fr-ca/thankyou",
    meta: thankyouckrkH6yzejMeta || {},
    alias: thankyouckrkH6yzejMeta?.alias || [],
    redirect: thankyouckrkH6yzejMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: elite4l6BK3blkRMeta?.name ?? "tiers-elite___uk",
    path: elite4l6BK3blkRMeta?.path ?? "/uk/tiers/elite",
    meta: elite4l6BK3blkRMeta || {},
    alias: elite4l6BK3blkRMeta?.alias || [],
    redirect: elite4l6BK3blkRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: elite4l6BK3blkRMeta?.name ?? "tiers-elite___en-US",
    path: elite4l6BK3blkRMeta?.path ?? "/tiers/elite",
    meta: elite4l6BK3blkRMeta || {},
    alias: elite4l6BK3blkRMeta?.alias || [],
    redirect: elite4l6BK3blkRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: elite4l6BK3blkRMeta?.name ?? "tiers-elite___ca",
    path: elite4l6BK3blkRMeta?.path ?? "/ca/tiers/elite",
    meta: elite4l6BK3blkRMeta || {},
    alias: elite4l6BK3blkRMeta?.alias || [],
    redirect: elite4l6BK3blkRMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: plusW55U8ELIbpMeta?.name ?? "tiers-plus___uk",
    path: plusW55U8ELIbpMeta?.path ?? "/uk/tiers/plus",
    meta: plusW55U8ELIbpMeta || {},
    alias: plusW55U8ELIbpMeta?.alias || [],
    redirect: plusW55U8ELIbpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusW55U8ELIbpMeta?.name ?? "tiers-plus___en-US",
    path: plusW55U8ELIbpMeta?.path ?? "/tiers/plus",
    meta: plusW55U8ELIbpMeta || {},
    alias: plusW55U8ELIbpMeta?.alias || [],
    redirect: plusW55U8ELIbpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusW55U8ELIbpMeta?.name ?? "tiers-plus___ca",
    path: plusW55U8ELIbpMeta?.path ?? "/ca/tiers/plus",
    meta: plusW55U8ELIbpMeta || {},
    alias: plusW55U8ELIbpMeta?.alias || [],
    redirect: plusW55U8ELIbpMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: premierht5l9UdjJbMeta?.name ?? "tiers-premier___uk",
    path: premierht5l9UdjJbMeta?.path ?? "/uk/tiers/premier",
    meta: premierht5l9UdjJbMeta || {},
    alias: premierht5l9UdjJbMeta?.alias || [],
    redirect: premierht5l9UdjJbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierht5l9UdjJbMeta?.name ?? "tiers-premier___en-US",
    path: premierht5l9UdjJbMeta?.path ?? "/tiers/premier",
    meta: premierht5l9UdjJbMeta || {},
    alias: premierht5l9UdjJbMeta?.alias || [],
    redirect: premierht5l9UdjJbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierht5l9UdjJbMeta?.name ?? "tiers-premier___ca",
    path: premierht5l9UdjJbMeta?.path ?? "/ca/tiers/premier",
    meta: premierht5l9UdjJbMeta || {},
    alias: premierht5l9UdjJbMeta?.alias || [],
    redirect: premierht5l9UdjJbMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: starterMqh9aDD8pMMeta?.name ?? "tiers-starter___uk",
    path: starterMqh9aDD8pMMeta?.path ?? "/uk/tiers/starter",
    meta: starterMqh9aDD8pMMeta || {},
    alias: starterMqh9aDD8pMMeta?.alias || [],
    redirect: starterMqh9aDD8pMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterMqh9aDD8pMMeta?.name ?? "tiers-starter___en-US",
    path: starterMqh9aDD8pMMeta?.path ?? "/tiers/starter",
    meta: starterMqh9aDD8pMMeta || {},
    alias: starterMqh9aDD8pMMeta?.alias || [],
    redirect: starterMqh9aDD8pMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterMqh9aDD8pMMeta?.name ?? "tiers-starter___ca",
    path: starterMqh9aDD8pMMeta?.path ?? "/ca/tiers/starter",
    meta: starterMqh9aDD8pMMeta || {},
    alias: starterMqh9aDD8pMMeta?.alias || [],
    redirect: starterMqh9aDD8pMMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: processBU1hmWEcUAMeta?.name ?? "trial-process___en-US",
    path: processBU1hmWEcUAMeta?.path ?? "/trial/process",
    meta: processBU1hmWEcUAMeta || {},
    alias: processBU1hmWEcUAMeta?.alias || [],
    redirect: processBU1hmWEcUAMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBU1hmWEcUAMeta?.name ?? "trial-process___ca",
    path: processBU1hmWEcUAMeta?.path ?? "/ca/trial/process",
    meta: processBU1hmWEcUAMeta || {},
    alias: processBU1hmWEcUAMeta?.alias || [],
    redirect: processBU1hmWEcUAMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBU1hmWEcUAMeta?.name ?? "trial-process___nl",
    path: processBU1hmWEcUAMeta?.path ?? "/nl/trial/process",
    meta: processBU1hmWEcUAMeta || {},
    alias: processBU1hmWEcUAMeta?.alias || [],
    redirect: processBU1hmWEcUAMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBU1hmWEcUAMeta?.name ?? "trial-process___uk",
    path: processBU1hmWEcUAMeta?.path ?? "/uk/trial/process",
    meta: processBU1hmWEcUAMeta || {},
    alias: processBU1hmWEcUAMeta?.alias || [],
    redirect: processBU1hmWEcUAMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBU1hmWEcUAMeta?.name ?? "trial-process___de",
    path: processBU1hmWEcUAMeta?.path ?? "/de/trial/process",
    meta: processBU1hmWEcUAMeta || {},
    alias: processBU1hmWEcUAMeta?.alias || [],
    redirect: processBU1hmWEcUAMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBU1hmWEcUAMeta?.name ?? "trial-process___mx",
    path: processBU1hmWEcUAMeta?.path ?? "/mx/trial/process",
    meta: processBU1hmWEcUAMeta || {},
    alias: processBU1hmWEcUAMeta?.alias || [],
    redirect: processBU1hmWEcUAMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBU1hmWEcUAMeta?.name ?? "trial-process___fr",
    path: processBU1hmWEcUAMeta?.path ?? "/fr/trial/process",
    meta: processBU1hmWEcUAMeta || {},
    alias: processBU1hmWEcUAMeta?.alias || [],
    redirect: processBU1hmWEcUAMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processBU1hmWEcUAMeta?.name ?? "trial-process___fr-ca",
    path: processBU1hmWEcUAMeta?.path ?? "/fr-ca/trial/process",
    meta: processBU1hmWEcUAMeta || {},
    alias: processBU1hmWEcUAMeta?.alias || [],
    redirect: processBU1hmWEcUAMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: supportTERmHl442vMeta?.name ?? "warranty-support___uk",
    path: supportTERmHl442vMeta?.path ?? "/uk/warranty/support",
    meta: supportTERmHl442vMeta || {},
    alias: supportTERmHl442vMeta?.alias || [],
    redirect: supportTERmHl442vMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportTERmHl442vMeta?.name ?? "warranty-support___en-US",
    path: supportTERmHl442vMeta?.path ?? "/warranty/support",
    meta: supportTERmHl442vMeta || {},
    alias: supportTERmHl442vMeta?.alias || [],
    redirect: supportTERmHl442vMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportTERmHl442vMeta?.name ?? "warranty-support___ca",
    path: supportTERmHl442vMeta?.path ?? "/ca/warranty/support",
    meta: supportTERmHl442vMeta || {},
    alias: supportTERmHl442vMeta?.alias || [],
    redirect: supportTERmHl442vMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportTERmHl442vMeta?.name ?? "warranty-support___ie",
    path: supportTERmHl442vMeta?.path ?? "/ie/warranty/support",
    meta: supportTERmHl442vMeta || {},
    alias: supportTERmHl442vMeta?.alias || [],
    redirect: supportTERmHl442vMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BDm9O1cj3KMeta?.name ?? "webinars-slug___en-US",
    path: _91slug_93BDm9O1cj3KMeta?.path ?? "/webinars/:slug()",
    meta: _91slug_93BDm9O1cj3KMeta || {},
    alias: _91slug_93BDm9O1cj3KMeta?.alias || [],
    redirect: _91slug_93BDm9O1cj3KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BDm9O1cj3KMeta?.name ?? "webinars-slug___ca",
    path: _91slug_93BDm9O1cj3KMeta?.path ?? "/ca/webinars/:slug()",
    meta: _91slug_93BDm9O1cj3KMeta || {},
    alias: _91slug_93BDm9O1cj3KMeta?.alias || [],
    redirect: _91slug_93BDm9O1cj3KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BDm9O1cj3KMeta?.name ?? "webinars-slug___nl",
    path: _91slug_93BDm9O1cj3KMeta?.path ?? "/nl/webinars/:slug()",
    meta: _91slug_93BDm9O1cj3KMeta || {},
    alias: _91slug_93BDm9O1cj3KMeta?.alias || [],
    redirect: _91slug_93BDm9O1cj3KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BDm9O1cj3KMeta?.name ?? "webinars-slug___uk",
    path: _91slug_93BDm9O1cj3KMeta?.path ?? "/uk/webinars/:slug()",
    meta: _91slug_93BDm9O1cj3KMeta || {},
    alias: _91slug_93BDm9O1cj3KMeta?.alias || [],
    redirect: _91slug_93BDm9O1cj3KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BDm9O1cj3KMeta?.name ?? "webinars-slug___de",
    path: _91slug_93BDm9O1cj3KMeta?.path ?? "/de/webinars/:slug()",
    meta: _91slug_93BDm9O1cj3KMeta || {},
    alias: _91slug_93BDm9O1cj3KMeta?.alias || [],
    redirect: _91slug_93BDm9O1cj3KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BDm9O1cj3KMeta?.name ?? "webinars-slug___mx",
    path: _91slug_93BDm9O1cj3KMeta?.path ?? "/mx/webinars/:slug()",
    meta: _91slug_93BDm9O1cj3KMeta || {},
    alias: _91slug_93BDm9O1cj3KMeta?.alias || [],
    redirect: _91slug_93BDm9O1cj3KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BDm9O1cj3KMeta?.name ?? "webinars-slug___fr",
    path: _91slug_93BDm9O1cj3KMeta?.path ?? "/fr/webinars/:slug()",
    meta: _91slug_93BDm9O1cj3KMeta || {},
    alias: _91slug_93BDm9O1cj3KMeta?.alias || [],
    redirect: _91slug_93BDm9O1cj3KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BDm9O1cj3KMeta?.name ?? "webinars-slug___fr-ca",
    path: _91slug_93BDm9O1cj3KMeta?.path ?? "/fr-ca/webinars/:slug()",
    meta: _91slug_93BDm9O1cj3KMeta || {},
    alias: _91slug_93BDm9O1cj3KMeta?.alias || [],
    redirect: _91slug_93BDm9O1cj3KMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvxUlhk0d8aMeta?.name ?? "webinars___en-US",
    path: indexvxUlhk0d8aMeta?.path ?? "/webinars",
    meta: indexvxUlhk0d8aMeta || {},
    alias: indexvxUlhk0d8aMeta?.alias || [],
    redirect: indexvxUlhk0d8aMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexvxUlhk0d8aMeta?.name ?? "webinars___ca",
    path: indexvxUlhk0d8aMeta?.path ?? "/ca/webinars",
    meta: indexvxUlhk0d8aMeta || {},
    alias: indexvxUlhk0d8aMeta?.alias || [],
    redirect: indexvxUlhk0d8aMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexvxUlhk0d8aMeta?.name ?? "webinars___nl",
    path: indexvxUlhk0d8aMeta?.path ?? "/nl/webinars",
    meta: indexvxUlhk0d8aMeta || {},
    alias: indexvxUlhk0d8aMeta?.alias || [],
    redirect: indexvxUlhk0d8aMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexvxUlhk0d8aMeta?.name ?? "webinars___uk",
    path: indexvxUlhk0d8aMeta?.path ?? "/uk/webinars",
    meta: indexvxUlhk0d8aMeta || {},
    alias: indexvxUlhk0d8aMeta?.alias || [],
    redirect: indexvxUlhk0d8aMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexvxUlhk0d8aMeta?.name ?? "webinars___de",
    path: indexvxUlhk0d8aMeta?.path ?? "/de/webinars",
    meta: indexvxUlhk0d8aMeta || {},
    alias: indexvxUlhk0d8aMeta?.alias || [],
    redirect: indexvxUlhk0d8aMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexvxUlhk0d8aMeta?.name ?? "webinars___mx",
    path: indexvxUlhk0d8aMeta?.path ?? "/mx/webinars",
    meta: indexvxUlhk0d8aMeta || {},
    alias: indexvxUlhk0d8aMeta?.alias || [],
    redirect: indexvxUlhk0d8aMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexvxUlhk0d8aMeta?.name ?? "webinars___fr",
    path: indexvxUlhk0d8aMeta?.path ?? "/fr/webinars",
    meta: indexvxUlhk0d8aMeta || {},
    alias: indexvxUlhk0d8aMeta?.alias || [],
    redirect: indexvxUlhk0d8aMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexvxUlhk0d8aMeta?.name ?? "webinars___fr-ca",
    path: indexvxUlhk0d8aMeta?.path ?? "/fr-ca/webinars",
    meta: indexvxUlhk0d8aMeta || {},
    alias: indexvxUlhk0d8aMeta?.alias || [],
    redirect: indexvxUlhk0d8aMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wtVQXi0bKlMeta?.name ?? "webinars-thank-you-slug___en-US",
    path: _91slug_93wtVQXi0bKlMeta?.path ?? "/webinars/thank-you/:slug()",
    meta: _91slug_93wtVQXi0bKlMeta || {},
    alias: _91slug_93wtVQXi0bKlMeta?.alias || [],
    redirect: _91slug_93wtVQXi0bKlMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wtVQXi0bKlMeta?.name ?? "webinars-thank-you-slug___ca",
    path: _91slug_93wtVQXi0bKlMeta?.path ?? "/ca/webinars/thank-you/:slug()",
    meta: _91slug_93wtVQXi0bKlMeta || {},
    alias: _91slug_93wtVQXi0bKlMeta?.alias || [],
    redirect: _91slug_93wtVQXi0bKlMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wtVQXi0bKlMeta?.name ?? "webinars-thank-you-slug___nl",
    path: _91slug_93wtVQXi0bKlMeta?.path ?? "/nl/webinars/thank-you/:slug()",
    meta: _91slug_93wtVQXi0bKlMeta || {},
    alias: _91slug_93wtVQXi0bKlMeta?.alias || [],
    redirect: _91slug_93wtVQXi0bKlMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wtVQXi0bKlMeta?.name ?? "webinars-thank-you-slug___uk",
    path: _91slug_93wtVQXi0bKlMeta?.path ?? "/uk/webinars/thank-you/:slug()",
    meta: _91slug_93wtVQXi0bKlMeta || {},
    alias: _91slug_93wtVQXi0bKlMeta?.alias || [],
    redirect: _91slug_93wtVQXi0bKlMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wtVQXi0bKlMeta?.name ?? "webinars-thank-you-slug___de",
    path: _91slug_93wtVQXi0bKlMeta?.path ?? "/de/webinars/thank-you/:slug()",
    meta: _91slug_93wtVQXi0bKlMeta || {},
    alias: _91slug_93wtVQXi0bKlMeta?.alias || [],
    redirect: _91slug_93wtVQXi0bKlMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wtVQXi0bKlMeta?.name ?? "webinars-thank-you-slug___mx",
    path: _91slug_93wtVQXi0bKlMeta?.path ?? "/mx/webinars/thank-you/:slug()",
    meta: _91slug_93wtVQXi0bKlMeta || {},
    alias: _91slug_93wtVQXi0bKlMeta?.alias || [],
    redirect: _91slug_93wtVQXi0bKlMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wtVQXi0bKlMeta?.name ?? "webinars-thank-you-slug___fr",
    path: _91slug_93wtVQXi0bKlMeta?.path ?? "/fr/webinars/thank-you/:slug()",
    meta: _91slug_93wtVQXi0bKlMeta || {},
    alias: _91slug_93wtVQXi0bKlMeta?.alias || [],
    redirect: _91slug_93wtVQXi0bKlMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wtVQXi0bKlMeta?.name ?? "webinars-thank-you-slug___fr-ca",
    path: _91slug_93wtVQXi0bKlMeta?.path ?? "/fr-ca/webinars/thank-you/:slug()",
    meta: _91slug_93wtVQXi0bKlMeta || {},
    alias: _91slug_93wtVQXi0bKlMeta?.alias || [],
    redirect: _91slug_93wtVQXi0bKlMeta?.redirect,
    component: () => import("/codebuild/output/src4240263937/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  }
]